import { AxiosInstance } from "axios";
import { AutomatedMessage } from "../../models/modules/messaging/AutomatedMessage";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";
import { v4 as uuid } from "uuid";

const url = process.env.REACT_APP_MANAGER_API_URL + "/messaging/";

export async function getAutomatedMessages(axiosInstance: AxiosInstance, propertyId: string): Promise<AutomatedMessage[]> {
    return await axiosInstance
        .get(`${url}${propertyId}/automatedmessages`)
        .then((response) => parseLanguageStringsFromJson(response.data.automatedMessages) as AutomatedMessage[]);
}

export async function createAutomatedMessage(axiosInstance: AxiosInstance, propertyId: string, trigger: string, templateId: string) {
    return await axiosInstance.post(`${url}${propertyId}/triggers/${trigger}/templates/${templateId}`).then((response) => {
        return response.data;
    });
}

export async function deleteAutomatedMessage(axiosInstance: AxiosInstance, propertyId: string, trigger: string, templateId: string) {
    return await axiosInstance.delete(`${url}${propertyId}/triggers/${trigger}/templates/${templateId}`).then((response) => {
        return response.data;
    });
}