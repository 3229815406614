import Box from "@mui/material/Box";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    noPadding?: boolean;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, noPadding } = props;
    const theme: Theme = useTheme();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
        >
            <Box p={3} component="div" sx={{ padding: (noPadding ? "0" : theme.spacing(2)), visibility: value === index ? "visible" : "hidden" }}>
                <Typography component={"div"}>{children}</Typography>
            </Box>
        </div>
    );
};

export default TabPanel;
