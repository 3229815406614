import { AxiosInstance } from "axios";
import { BrandItemModel } from "../../models/brandModels";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";
import { PropertyItemDetailModel, PropertyItemModel } from "../../models/propertyModels";
import { TimeZoneModel } from "../../models/common/TimeZoneModel";

const url = process.env.REACT_APP_MANAGER_API_URL + "/account-management/properties";

export async function getBrandDropdownData(axiosInstance: AxiosInstance) {
    return await axiosInstance
        .get(process.env.REACT_APP_MANAGER_API_URL + "/account-management/brands")
        .then((response) => response.data.brands as BrandItemModel[]);
}

export async function getPropertyGridData(axiosInstance: AxiosInstance, brandId: string) {
    return await axiosInstance
        .get(`${url}?brandId=${brandId}`)
        .then((response) => response.data.properties as PropertyItemModel[]);
}

export async function listProperties(axiosInstance: AxiosInstance, enabledOnly: boolean = false, brandId?: string) {
    var u = `${url}?enabledOnly=${enabledOnly}`;
    if (brandId) {
        u += `&brandId=${brandId}`;
    }

    return await axiosInstance
        .get(u)
        .then((response) => parseLanguageStringsFromJson(response.data.properties) as PropertyItemDetailModel[]);
}

export async function getPropertyById(axiosInstance: AxiosInstance, id: string) {
    return await axiosInstance
        .get(`${url}/${id}`)
        .then((response) => parseLanguageStringsFromJson(response.data.property) as PropertyItemDetailModel);
}

export async function updateProperty(axiosInstance: AxiosInstance, property: PropertyItemDetailModel) {
    const propertyToSave = {
        ...property,
        name: JSON.stringify({ ...property.name }),
        description: JSON.stringify({ ...property.description }),
    };

    return await axiosInstance.put(`${url}/${propertyToSave.id}`, propertyToSave).then((response) => {
        return response.data;
    });
}

export async function createProperty(axiosInstance: AxiosInstance, property: PropertyItemDetailModel) {
    const propertyToSave = {
        ...property,
        name: JSON.stringify({ ...property.name }),
        description: JSON.stringify({ ...property.description }),
    };

    return await axiosInstance.post(url, propertyToSave).then((response) => response.data);
}

export async function deleteProperty(axiosInstance: AxiosInstance, id: string) {
    return await axiosInstance.delete(`${url}/${id}`).then((response) => response.data);
}

export async function listTimeZones(axiosInstance: AxiosInstance) {
    var u = url + "/time-zones";

    return await axiosInstance
        .get(u)
        .then((response) => response.data.timeZones) as TimeZoneModel[];
}
