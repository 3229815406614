import { AxiosInstance } from "axios";
import { Location } from "../../models/menus/Location";
import { LocationItem } from "../../models/menus/LocationItem";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";

const url = process.env.REACT_APP_MANAGER_API_URL + "/menu-management/";

export async function listLocations(axiosInstance: AxiosInstance, propertyCode: string, deviceClass: number) {
    return await axiosInstance
        .get(`${url}${propertyCode}/locations?deviceClass=${deviceClass}`)
        .then((response) => response.data.locations as Location[]);
}

export async function listLocationItems(axiosInstance: AxiosInstance, propertyCode: string, locationCode: string) {
    return await axiosInstance
        .get(`${url}${propertyCode}/locations/${locationCode}/items`)
        .then((response) => parseLanguageStringsFromJson(response.data.locationItems) as LocationItem[]);
}
