import * as React from "react";
import TransferList, { TransferListChangeHandler } from "./TransferList";
import {getClassesForTransferList} from "../../../api/roomClassApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";

export interface RoomClassesProps {
    id?: string;
    assignedClasses: { id: string; name: string }[];
    onChangeHandler: (values: { id: string, name: string }[]) => any;
    disabled?: boolean;
    height?: number;
    permissionKey?: string;
    error?: boolean;
    helperText?: React.ReactNode;
}

const RoomClasses = (props: RoomClassesProps) => {
    const [allClasses, setAllClasses] = React.useState<{id: string; name: string;}[]>([]); 
    const [classes, setClasses] = React.useState<{key: string; value: string;}[]>([]); 
    const [loading, setLoading] = React.useState(false);
    const axiosInstance = useCreateAxios();
    const {user, checkAccess} = useUser();

    React.useEffect(() => {
        const getClasses = async() => {
            setLoading(true);
            const response = await getClassesForTransferList(axiosInstance, user.currentProperty?.code ?? "");
            setAllClasses(response);
            setAssigned(response);
            setLoading(false);
        }

        // retrieve all enabled room classes.
        getClasses();
    }, [])

    function mapToKvpAll(values: {id: string; name: string;}[]) {
        return values.map((item) => ({
            key: item.id,
            value: item.name
        }))
    }

    function setAssigned(values: {id: string; name: string;}[]) {
        var updatedValues: {key: string; value: string;}[] = []
        props.assignedClasses.forEach(v => {
            var roomClass = values.find(c => c.id === v.id)
            if (roomClass !== undefined) {
                updatedValues.push({key: v.id, value: roomClass!!.name})
            }
        });
        setClasses(updatedValues);
    }

    function handleClassAssignmentChanges(currentValues: {key: string; value: string}[]) : TransferListChangeHandler {
        const kvpToClasses = currentValues.map(item => ({
            id: item.key,
            name: item.value,
        }))
        
        return props.onChangeHandler(kvpToClasses);
    }

    if (loading) return <div>Loading...</div>

    return  (
        <TransferList
            id={props.id}
            leftKeyValues={mapToKvpAll(allClasses)}
            rightKeyValues={classes}
            onChangeHandler={handleClassAssignmentChanges}
            disabled={props.disabled}
            height={props.height}
            permissionKey={props.permissionKey}
            error={props.error}
            helperText={props.helperText}
        />
    );
};

export default RoomClasses;
