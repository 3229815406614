import { Orderable } from "../../../components/common/DisplayOrderButton";

export interface Permission {
    typeId: string;
    typeKey: string;
    typeName: string;
    access: number
}

// added extends Orderable for displayOrderButton (not sure if needed)
export interface PermissionCategory extends Orderable {
    id: string;
    name: string;
    displayOrder: number;
    enabled: boolean;
    types: PermissionType[];
}

export interface PermissionType {
    id: string;
    key: string;
    name: string;
    categoryId: string;
    enabled: boolean;
    //categoryName: string;
    accessAllowed: Access;
    adminOnly: boolean;
}

export enum Access
{
    None = 0,
    Read = 1,
    Create = 2,
    Update = 4,
    Delete = 8,
}

export enum PermissionKeys {

}

export const initialPermissionCategoryState: PermissionCategory = {
    id: "",
    name: "",
    displayOrder: 0,
    enabled: true,
    types: []
}

export const initialPermissionTypeState : PermissionType = {
    id: "",
    key: "",
    name: "",
    categoryId: "",
    //categoryName: "",
    enabled: true,
    adminOnly: false,
    accessAllowed: 0
}