import { parseLanguageStringsFromJson } from "../utilities/JsonHelper";
import { StringLanguageDict } from "../models/common/LanguageModel";
import { AxiosInstance } from "axios";

const url = process.env.REACT_APP_MANAGER_API_URL + '/contact/';

export interface ContactOfferModel {
    id?: string;
    confirmationText: StringLanguageDict;
    invitationText: StringLanguageDict;
    thankyouText: StringLanguageDict;
    email: string;
}

export type CreateContactOfferType = ContactOfferModel & {
    key: string; 
    parentId?:string; 
    itemId?:string;
    itemType: string;
}

export async function createContactOffer(axiosInstance: AxiosInstance, propertyId: string, offerData: CreateContactOfferType) {
    const itemToSave = {
        id: offerData.id,
        key: offerData.key,
        parentId: offerData?.parentId,
        itemId: offerData?.itemId,
        email: offerData.email,
        confirmationText: JSON.stringify(offerData.confirmationText),
        invitationText: JSON.stringify(offerData.invitationText),
        thankyouText: JSON.stringify(offerData.thankyouText),
        itemType: offerData.itemType
    }

    return axiosInstance.post(`${url}${propertyId}/offers`, itemToSave);
}

export async function getContactOffer(axiosInstance: AxiosInstance, propertyId: string, key: string, parentId?: string, itemId?: string): Promise<ContactOfferModel> {
    let queryString = `${url}${propertyId}/offers/${key}?parentId=${parentId}&itemId=${itemId}`
        .replaceAll('undefined', ''); // dont know if this is needed anymore, test.

    return axiosInstance.get(queryString).then(res => parseLanguageStringsFromJson(res.data) as ContactOfferModel);
}

export async function updateContactOffer(axiosInstance: AxiosInstance, propertyId: string, contactOfferItem: ContactOfferModel) {
    if (contactOfferItem?.id?.length === 0) throw new Error(`Id required for update`);

    const itemToSave = {
        confirmationText: JSON.stringify({ ...contactOfferItem.confirmationText }),
        invitationText: JSON.stringify({ ...contactOfferItem.invitationText }),
        thankyouText: JSON.stringify({ ...contactOfferItem.thankyouText }),
        email: contactOfferItem.email
    }

    return axiosInstance.put(`${url}${propertyId}/offers/${contactOfferItem.id}`, itemToSave);
}

export async function deleteContactOffer(axiosInstance: AxiosInstance, propertyId: string, id: string) {
    return axiosInstance.delete(`${url}${propertyId}/offers/${id}`);
}