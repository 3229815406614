import { Option, OptionSet } from "../../models/modules/roomservice/Option";

export interface OptionSetToSave {
    id: string;
    name?: string;      
    internalName: string;
    minimum: number,
    maximum: number,
    options: OptionToSave[];
}

export interface OptionToSave {
    id: string;
    name?: string;   
    nameStringified?: string | null;
    pricePerUnit?: number | null;  
    optionSets: OptionSetToSave[]
}

export function stringifyOptionSets(optionSets: OptionSet[]): OptionSetToSave[] {    
    return optionSets.map(os => {
        var optionsToSave: OptionToSave[] = [];
        if (os.options !== null && os.options.length > 0) {
            optionsToSave = stringifyOptions(os.options);
        }

        return {
            id: os.id,
            internalName: os.internalName,
            name: JSON.stringify({ ...os.name }),
            minimum: os.minimum,
            maximum: os.maximum,
            options: optionsToSave
        }
    });
}

export function stringifyOptions(options: Option[]): OptionToSave[] {
    return options.map(o => {
        var optionSetsToSave: OptionSetToSave[] = [];
        if (o.optionSets !== null && o.optionSets.length > 0) {
            optionSetsToSave = stringifyOptionSets(o.optionSets);
        }

        return {
            id: o.id,            
            name: JSON.stringify({ ...o.name }),  
            pricePerUnit: o.pricePerUnit,
            optionSets: optionSetsToSave
        }
    });
}