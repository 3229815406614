import { StringLanguageDict } from "../../common/LanguageModel";

export interface Location {
    id: string;
    name: StringLanguageDict;  
    enabled: boolean;
}

export function mapLocationsToKeyValues(locations: Location[]): { key: string; value: string }[] {
    let kvps: { key: string; value: string }[] = [];

    kvps = locations.map((location, index) => {
        return {
            key: location.id,
            value: location.name?.en,
        };
    });

    return kvps;
}