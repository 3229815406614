import { StringLanguageDict } from "../../common/LanguageModel";

export interface ScheduledActivity {
    id: string;
	activityId: string;
    name: StringLanguageDict | null;
    description: StringLanguageDict | null;    
    locationId: string;
	data: string;
	instances: ScheduledActivityInstance[];
}

export interface ScheduledActivityInstance {
	id: string;
	start: Date;
    end: Date;
}

export const initialScheduledActivityState: ScheduledActivity = {
    id: "",
    activityId: "",
    name: null,
    description: null,
    locationId: "",
    data: "",
    instances: []
}

export function getUniqueActivities(activities: ScheduledActivity[]): { key: string; value: string }[] {
    let kvps: { key: string; value: string }[] = [];

    // Get unique list of activities
    activities.forEach(a => {
        if (!kvps.some(e => e.key === a.activityId)) {
            kvps.push({
                key: a.activityId,
                value: a.name?.en ?? "",
            });
        }
    });
    
    kvps.sort((a, b) => a.value && b.value ? a.value.localeCompare(b.value) : 0);

    return kvps;
}