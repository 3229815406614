import * as React from "react";

type SelectedLanguageContextValue = {
  selectedLanguage: string;
  setSelectedLanguage: React.Dispatch<React.SetStateAction<string>>;
};

const SelectedLanguageContext =
  React.createContext<SelectedLanguageContextValue>({
    selectedLanguage: "en",
    setSelectedLanguage: () => {},
  });

export const useSelectedLanguage = () =>
  React.useContext(SelectedLanguageContext);

const LanguageForm = (props: { children: React.ReactNode }) => {
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>("en");
  const value: SelectedLanguageContextValue = {
    selectedLanguage,
    setSelectedLanguage,
  };

  return (
    <SelectedLanguageContext.Provider value={value}>
      {props.children}
    </SelectedLanguageContext.Provider>
  );
};

export default LanguageForm;
