import { AxiosInstance } from "axios";
import { PermissionCategory, PermissionType } from "../models/configuration/security/Permission";
import { v4 as uuid } from "uuid"; 

const baseUrl = process.env.REACT_APP_MANAGER_API_URL + "/user-management/permissions";

export async function listPermissionCategories(axiosInstance: AxiosInstance): Promise<PermissionCategory[]> {
    return await axiosInstance.get(baseUrl + '/categories')
        .then(response => response.data.categories as PermissionCategory[]);
}

export async function listAdminPermissionCategories(axiosInstance: AxiosInstance): Promise<PermissionCategory[]> {
    return await axiosInstance.get(baseUrl + '/categories?enabled=true')
        .then(response => response.data.categories as PermissionCategory[]);
}

export async function listCustomerPermissionCategories(axiosInstance: AxiosInstance): Promise<PermissionCategory[]> {
    return await axiosInstance.get(baseUrl + '/categories?enabled=true&adminOnly=false')
        .then(response => response.data.categories as PermissionCategory[]);
}

export async function getPermissionCategory(axiosInstance: AxiosInstance, id: string) {
    return await axiosInstance.get(`${baseUrl}/categories/${id}`)
        .then(response => response.data as PermissionCategory)
}

export async function addPermissionCategory(axiosInstance: AxiosInstance, payload: PermissionCategory) {
    // todo: maybe create uuid in caller, or check here and if id is undefined | "", then do it. 
    return await axiosInstance.post(`${baseUrl}/categories/`, { ...payload, id: uuid() })
        .then(response => response.data as PermissionCategory)
}

export async function updatePermissionCategory(axiosInstance: AxiosInstance, payload: PermissionCategory) {
    return await axiosInstance.put(`${baseUrl}/categories/${payload.id}`, payload)
        .then(response => response.data as PermissionCategory)
}

export async function deletePermissionCategory(axiosInstance: AxiosInstance, id: string) {
    return await axiosInstance.delete(`${baseUrl}/categories/${id}`)
        .then(response => response.data as PermissionCategory)
}

export async function getPermissionType(axiosInstance: AxiosInstance, id:string) {
    return await axiosInstance.get(`${baseUrl}/types/${id}`)
        .then(response => response.data as PermissionType)
}

export async function addPermissionType(axiosInstance: AxiosInstance, payload:PermissionType) {
    return await axiosInstance.post(`${baseUrl}/types/`, {...payload, id: uuid()})
        .then(response => response.data as PermissionType)
}

export async function updatePermissionType(axiosInstance: AxiosInstance, payload:PermissionType) {
    return await axiosInstance.put(`${baseUrl}/types/${payload.id}`, payload)
        .then(response => response.data as PermissionType)
}

export async function deletePermissionType(axiosInstance: AxiosInstance, id:string) {
    return await axiosInstance.delete(`${baseUrl}/types/${id}`)
        .then(response => response.data as PermissionType)
}

export async function listPermissionTypes(axiosInstance: AxiosInstance, categoryId: string) {
    return await axiosInstance.get(`${baseUrl}/categories/${categoryId}/types`)
        .then(response => response.data.types as PermissionType[])
}
