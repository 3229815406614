import * as React from "react";
import LocalizedStrings from "react-localization"; 
import en from "./en.json"
import es from "./es.json"

const strings = {
    en: en,
    es: es
}

const localizedStrings = new LocalizedStrings(strings)

const LocalizedStringsContext = React.createContext(localizedStrings);

export const useLocalizedStrings = () => React.useContext(LocalizedStringsContext);

const LocalizedStringsProvider = (props: { children: React.ReactNode }) => {
    return (
        <LocalizedStringsContext.Provider value={localizedStrings} children={props.children} />
    );
}

export default LocalizedStringsProvider;