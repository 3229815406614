import { StringLanguageDict } from "../../common/LanguageModel";

export interface Activity {
    id: string;
    name?: StringLanguageDict | null;
    description?: StringLanguageDict | null;
    types: { id: string; name?: string | undefined }[];
    roomClasses: { id: string; name: string }[];
    enabled: boolean;
    previewOnly: boolean;
}

export const initialActivityState: Activity = {
    id: "",
    name: null,
    description: null,
    types: [],
    roomClasses: [],
    enabled: true,
    previewOnly: false
}

export function mapActivitiesToKeyValues(activities: Activity[]): { key: string; value: string }[] {
    let kvps: { key: string; value: string }[] = [];

    kvps = activities.map((activity, index) => {
        return {
            key: activity.id,
            value: activity.name?.en ?? "",
        };
    });

    return kvps;
}