import { AxiosInstance } from "axios";
import { Location } from "../../models/modules/activities/Location";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";
import { v4 as uuid } from "uuid";

const url = process.env.REACT_APP_MANAGER_API_URL + "/activities/";

export async function getLocations(axiosInstance: AxiosInstance, propertyCode: string): Promise<Location[]> {
    return await axiosInstance
        .get(`${url}${propertyCode}/locations`)
        .then((response) => parseLanguageStringsFromJson(response.data.locations) as Location[]);
}