import { StringLanguageDict } from "../models/common/LanguageModel";

export interface BrandItemModel {
    id: string;
    name: string;
    displayOrder: number;
    enabled: boolean;
}

export interface BrandItemDetailModel {
    id: string;
    name: StringLanguageDict;
    title: StringLanguageDict;
    description: StringLanguageDict;
    displayOrder: number;
    enabled: boolean;
}

export const initialBrandState: BrandItemDetailModel = {
    id: "",
    name: { en: "", es: "" },
    title: { en: "", es: "" },
    description: { en: "", es: "" },
    displayOrder: 0,
    enabled: false,
};

export function mapBrandsToKeyValue(
    brands: BrandItemModel[]
): { key: string; value: string }[] {
    let kvps: { key: string; value: string }[] = [];

    kvps = brands.map((item, index) => {
        return {
            key: item.id,
            value: JSON.parse(item.name).en,
        };
    });

    return kvps;
}
