export interface AccessPoint {
    id: string;
    name: string;    
    macAddress: string;
    group: string;
    model: string;
    firmwareVersion: string;
    numberOfClients: number;
    status: string;
    statusText: string;
    uptime: number;
    lastSeen: Date;
    isComplete: boolean;
}

// Manager API returns enums (options.JsonSerializerOptions.Converters.Add(new JsonStringEnumConverter());) as strings (probably for the permissions stuff)
//export enum AccessPointStatus {
//    Online = 0,
//    Offline = 1,
//    OTher = 2
//}

export function getInitialAccessPointState(): AccessPoint {
    return {
        id: "",
        name: "",
        macAddress: "",
        group: "",
        model: "",
        firmwareVersion: "",
        numberOfClients: 0,
        status: "",
        statusText: "",
        uptime: 0,
        lastSeen: new Date(),
        isComplete: true
    }
}