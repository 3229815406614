import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Typography } from "@mui/material";
import { default as Box, default as Paper } from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import MuiIconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from "@mui/material/Select";
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, useTheme } from '@mui/material/styles';
import axios from "axios";
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { Calendar, NavigateAction, SlotInfo, ToolbarProps, View, momentLocalizer, stringOrDate } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { v4 as uuid } from "uuid";
import { getActivities } from "../../../api/activities/activityApi";
import { getLocations } from "../../../api/activities/locationApi";
import { createScheduledActivity, deleteScheduledActivity, deleteScheduledActivityInstance, getScheduledActivities, updateScheduledActivity } from "../../../api/activities/scheduledActivityApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { DateRange } from '../../../models/common/DateRange';
import { Access } from "../../../models/configuration/security/Permission";
import { Activity as ActivityModel, mapActivitiesToKeyValues } from "../../../models/modules/activities/Activity";
import { Location, mapLocationsToKeyValues } from "../../../models/modules/activities/Location";
import { ScheduledActivity as ScheduledActivityModel, getUniqueActivities, initialScheduledActivityState } from "../../../models/modules/activities/ScheduledActivity";
import { useUser } from "../../../providers/UserProvider";
import { Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import { default as Scheduler, SchedulerRefObject } from "../../common/Scheduler";
import Spacer from "../../common/Spacer";
import DetailsDialog from "../../common/details/DetailsDialog";
import { default as DialogSelect } from "../../common/details/Select";

//document.addEventListener("contextmenu", (event) => {
//    event.preventDefault();
//});

interface ValidationErrors {
    activityId: string;
    locationId: string;    
}

class ScheduleIds {
    id: string;
    activityId: string;

    constructor(id: string, activityId: string) {
        this.id = id;
        this.activityId = activityId;
    }

    static parse(scheduleId: string): ScheduleIds {
        // NOTE: scheduleId here is the vaiable from the canelndar event (not to be confused with the scheduledActivityId).
        return new ScheduleIds(scheduleId.substr(0, 36), scheduleId.substr(36, 36));
    }

    static initialState: ScheduleIds = {
        id: "",
        activityId: ""
    }

    toString(): string {
        return this.id + this.activityId;
    }
}

const ActivitySchedule = () => {
    const initialErrorState: ValidationErrors = {
        activityId: "",
        locationId: ""        
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };    

    const [refreshSchedules, setRefreshSchedules] = React.useState(false);
    const [colorBy, setColorBy] = React.useState(0);
    const [detailsDialogOpen, setDetailsDialogOpen] = React.useState(false);
    const [locations, setLocations] = React.useState<Location[]>([]); 
    const [currentDate, setCurrentDate] = React.useState(new Date());
    const [dataRange, setDataRange] = React.useState<DateRange | null>(null);
    const [activities, setActivities] = React.useState<ActivityModel[]>([]);
    const [filteredActivities, setFilteredActivities] = React.useState<string[]>([]);
    const [selectedSlot, setSelectedSlot] = React.useState<SlotInfo | null>(null);
    const [scheduledActivites, setScheduledActivities] = React.useState<ScheduledActivityModel[]>([]);
    const [selectedScheduleId, setSelectedScheduleId] = React.useState<ScheduleIds>(ScheduleIds.initialState);
    const [selectedSchedule, setSelectedSchedule] = React.useState<ScheduledActivityModel>(initialScheduledActivityState);
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);
    const [selectedScheduleInstanceId, setSelectedScheduleInstanceId] = React.useState<string>("");
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);
    const [contextMenuAnchorEl, setContextMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const contextMenuOpen = Boolean(contextMenuAnchorEl);
    const [isLoading, setIsLoading] = useState(true);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const theme = useTheme();
    const strings = useLocalizedStrings();
    const alertDialog = useAlertDialog();
    const schedulerRef = useRef<SchedulerRefObject>(null);
    const axiosInstance = useCreateAxios();
    const { user, checkAccess } = useUser();                

    function getInitialDateRange(): DateRange {     
        // Since the initial calendar view is by week, get this week's data, but we want EOD of the day.
        var start = dayjs(currentDate).startOf("week");    
        var end = start.add(1, "week").endOf("day");
        return new DateRange(start.toDate(), end.toDate());
    }

    React.useEffect(() => {
        async function getInitialData() {   
            setIsLoading(true);
            
            await getScheduleData(getInitialDateRange(), true);

            try {
                const payload = await getActivities(axiosInstance, user.currentProperty?.code ?? "");
                payload.sort((a, b) => a.name && b.name ? a.name?.en.localeCompare(b.name?.en) : 0);
                setActivities(payload);

                // If we already have a filter don't reset it.
                if (filteredActivities.length === 0) {
                    setFilteredActivities(payload.map(a => a.id));
                }
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingActivities.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }

            try {
                const payload = await getLocations(axiosInstance, user.currentProperty?.code ?? "");
                payload.sort((a, b) => a.name && b.name ? a.name?.en.localeCompare(b.name?.en) : 0);
                setLocations(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingLocations.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }

            setIsLoading(false);
        }

        getInitialData();
    }, [refreshSchedules, user.currentProperty?.code, strings.errorRetreivingPromotionSchedule, strings.errorRetreivingActivities, strings.errorRetrievingLocations]);
    
    async function getSchedulePage(range: DateRange) {
        setIsPageLoading(true);
        await getScheduleData(range)
        setIsPageLoading(false);
    }

    async function getScheduleData(range: DateRange, clearData?: boolean) {
        try {                
            // Check if we already have data for this range (skip this if it's a reload)
            if (!clearData && dataRange?.contains(range)) {
                return;
            }            

            const payload = await getScheduledActivities(axiosInstance, user.currentProperty?.code ?? "", range.start, range.end);
            
            var oldArray = [...scheduledActivites];

            if (clearData || oldArray.length === 0) {
                setScheduledActivities(payload);
            }
            else {
                // Add the data returned to the existing data
                payload.forEach(sa => {
                    // Check if we already have this activity
                    var found = oldArray.find(sa2 => sa2.id === sa.id);
                    if (found) {
                        // If we do, we can add the new instances
                        sa.instances.forEach(i => {
                            // Make sure we don't duplicate the instances
                            if (!(found?.instances.find(i2 => i2.id === i.id))) {
                                found?.instances.push(i);
                            }
                        });
                    }
                    else {
                        // If not add it with all its instances
                        oldArray.push(sa);
                    }
                });

                setScheduledActivities(oldArray);
            }
            
            // Update the current range of the data
            var temp = dataRange;
            if (temp) {
                temp?.extend(range);
                setDataRange(temp);
            }
            else {
                setDataRange(range);
            }
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorRetreivingPromotionSchedule.replace("{{error}}", error.message),
                msgType: "error",
            });
        }
    }

    async function deleteSingleSchedule(id: string, instanceId: string) {
        try {            
            await deleteScheduledActivityInstance(axiosInstance, user.currentProperty?.code ?? "", id, instanceId);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingPromotionSchedule.replace("{{error}}", error.message),
                msgType: "error",
            });

            return;
        }
        
        setContextMenuAnchorEl(null);
        setRefreshSchedules(!refreshSchedules);
        setNotify({
            isOpen: true,
            message: strings.activityScheduleDeletedSuccessfully,
            msgType: "success",
        });       
    }

    async function deleteAllSchedules(id: string) {
        try {
            await deleteScheduledActivity(axiosInstance, user.currentProperty?.code ?? "", id);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingPromotionSchedule.replace("{{error}}", error.message),
                msgType: "error",
            });

            return;
        }
        
        setContextMenuAnchorEl(null);
        setRefreshSchedules(!refreshSchedules);
        setNotify({
            isOpen: true,
            message: strings.activityScheduleDeletedSuccessfully,
            msgType: "success",
        });        
    }

    function handleActivityChange(event: SelectChangeEvent) {
        setSelectedSchedule((prevState) => ({
            ...prevState,
            activityId: event.target.value as string
        }));
    }

    function handleLocationChange(event: SelectChangeEvent) {       
        setSelectedSchedule((prevState) => ({
            ...prevState,
            locationId: event.target.value as string
        }));
    }    

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setDetailsDialogOpen(false);
        setErrors(initialErrorState);
    }

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        var json = schedulerRef?.current?.getJson();

        if (!json || json === "") {
            return false;
        }
         
        try {
            if (selectedScheduleId.id === "") {
                var newSchedule: ScheduledActivityModel = {
                    ...selectedSchedule,
                    id: uuid(),
                    data: json
                };
                    
                await createScheduledActivity(axiosInstance, user.currentProperty?.code ?? "", newSchedule)
            }
            else {
                var newSchedule: ScheduledActivityModel = {
                    ...selectedSchedule,                        
                    data: json
                };

                await updateScheduledActivity(axiosInstance, user.currentProperty?.code ?? "", newSchedule);
            }
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: (selectedScheduleId.id === "" ? strings.errorAddingPromotionSchedule : strings.errorUpdatingPromotionSchedule).replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        setSelectedScheduleId(ScheduleIds.initialState);
        setRefreshSchedules(!refreshSchedules);
        setDetailsDialogOpen(false);
        setErrors(initialErrorState);
        setNotify({
            isOpen: true,
            message: strings.activityScheduleSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleValidateForm() {        
        var scheduleValid = schedulerRef?.current?.validate() ?? false;
        var valid = true;

        var errors = validate<ScheduledActivityModel, ValidationErrors>([
            { property: "activityId", type: ValidationType.Required, message: strings.validationScheduleActivity },
            { property: "locationId", type: ValidationType.Required, message: strings.validationScheduleLocation }            
        ], selectedSchedule);
        if (errors) {
            setErrors(errors);
            valid = false;
        }        

        return scheduleValid && valid;
    }

    // This seems a little hackish (There's no en or en-US, I guess it's just the default)
    var locale = window.navigator.language.toLowerCase();
    if (!locale.startsWith("en")) {
        try {
            require(`moment/locale/${locale}.js`)
        }
        catch {
            console.log(`${locale} not supported.`)
        }
    }
    
    const localizer = momentLocalizer(moment);

    function getEvents() {
        return scheduledActivites.filter(sa => filteredActivities.includes(sa.activityId)).flatMap(sa => sa.instances.map(i =>
            ({ 
                scheduleId: sa.id + sa.activityId, // We need both the id and the activity id 
                scheduleInstanceId: i.id, 
                title: `${sa.name?.en} - ${getLocationName(sa.locationId)}`, 
                allDay: false, 
                start: i.start, 
                end: i.end, 
                backgroundColor: getCellColor(colorBy === 0 ? sa.activityId : sa.locationId) })
        ));
    }

    // Pick a color based on the last 6 chars of the guid (should be pretty random)
    function getCellColor(activityId: string) {
        const hex = activityId.slice(-6);
        return hex;      
    }

    const eventStyleGetter = (event: any) => {        
        var style = {
            backgroundColor: "#" + event.backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: getBrightness(event.backgroundColor) < 65 ? '#ffffff' : '#000000', // Make sure text is readable (may need to tweek the 65 value)
            border: '0px',
            display: 'block'
        };

        return {
            style: style
        };
    };

    // https://stackoverflow.com/questions/12043187/how-to-check-if-hex-color-is-too-black
    function getBrightness(c: string): number {        
        var rgb = parseInt(c, 16);   // convert rrggbb to decimal
        var r = (rgb >> 16) & 0xff;  // extract red
        var g = (rgb >> 8) & 0xff;  // extract green
        var b = (rgb >> 0) & 0xff;  // extract blue

        var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709         
        return luma;
    }

    function handleAddScheduleClick(event: React.MouseEvent<HTMLElement>) {
        setSelectedScheduleId(ScheduleIds.initialState);
        setSelectedSchedule(initialScheduledActivityState);
        setDetailsDialogOpen(true);
    }


    const handleSelectSlot = (slotInfo: SlotInfo) => {
        setSelectedSlot(slotInfo);
    }

    const handleScheduleRightClick = (event: any, e: React.SyntheticEvent<HTMLElement, Event>) => {        
        setContextMenuAnchorEl(e.currentTarget.parentElement);            
        setSelectedScheduleId(ScheduleIds.parse(event.scheduleId));
        setSelectedScheduleInstanceId(event.scheduleInstanceId);
    }
     
    const handleContextMenuClose = () => {
        setContextMenuAnchorEl(null);
    };

    const handleEditSelectedSchedule = async () => {        
        var schedule = scheduledActivites.find(sa => sa.id === selectedScheduleId.id) ?? initialScheduledActivityState;
        setSelectedSchedule(schedule);
        setContextMenuAnchorEl(null);
        setDetailsDialogOpen(true);
    }

    const handleDeleteSelectedSchedule = async () => {        
        alertDialog({
            title: strings.deleteActivityScheduleInstanceAlertTitle,
            message: strings.deleteActivityScheduleInstanceAlertMessage,
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSingleSchedule(selectedScheduleId.id, selectedScheduleInstanceId);
        });
    }

    const handleDeleteAllSelectedSchedule = async () => {       
        alertDialog({
            title: strings.deleteActivityScheduleAlertTitle,
            message: strings.deleteActivityScheduleAlertMessage,
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteAllSchedules(selectedScheduleId.id);
        });        
    }

    const handleScheduleDoubleClick = async (event: any, e: React.SyntheticEvent<HTMLElement, Event>) => {
        //// Don't retrigger a new get of schedule
        //if (selectedScheduleId == event.scheduleId) {
        //    setDetailsDialogOpen(true);
        //}
        //else {
        //    setSelectedScheduleId(event.scheduleId);
        //}
        //await loadSchedule(event.scheduleId);

        var ids = ScheduleIds.parse(event.scheduleId);
        setSelectedScheduleId(ids);
        var schedule = scheduledActivites.find(sa => sa.id === ids.id) ?? initialScheduledActivityState;
        setSelectedSchedule(schedule);
        setDetailsDialogOpen(true);
    }

    function handleNavigate(newDate: Date, view: View, action: NavigateAction) {
        setCurrentDate(newDate);
    }

    // Pull more data based on the view
    async function handleRangeChange(range: Date[] | { start: stringOrDate; end: stringOrDate }, view: View | undefined)  {    
        if (Array.isArray(range)) {
            var tempArray = range as Date[];
            if (tempArray.length > 1) {
                // If the view is week we get an array of 7 dates, but we want EOD of the last day
                const weekRange = new DateRange(tempArray[0], dayjs(tempArray[6]).endOf("day").toDate());
                await getSchedulePage(weekRange);
            }
            else {
                // If the view is day we get an array with 1 day, but we want EOD of the day
                const day = tempArray[0];
                const dayRange = new DateRange(day, dayjs(day).endOf("day").toDate());        
                await getSchedulePage(dayRange);
            }
        }
        else {
             // If the view is month we get a start/end date, but we want EOD of the end date
            const tempRange = range as { start: stringOrDate; end: stringOrDate };                
            const monthRange = new DateRange(tempRange.start as Date, dayjs(tempRange.end as Date).endOf("day").toDate());
            await getSchedulePage(monthRange);
        }                 
    }

    const CustomToolbar = (props: ToolbarProps) => {        
        function onNavigateButtonClick(navigate: NavigateAction) {
            props.onNavigate(navigate);
        }

        function onViewButtonClick(event: React.MouseEvent<HTMLElement>, newViewButton: string) {
            props.onView(newViewButton as View);
        }

        function onColorByButtonClick(event: React.MouseEvent<HTMLElement>, newColorByButton: string) {
            setColorBy(parseInt(newColorByButton));
        }

        const ListItem = styled('li')(({ theme }) => ({
            margin: theme.spacing(0.5),
        }));

        const handleChipClick = (id: string, selected: boolean) => {
            // If currently selected, remove
            if (selected) {
                const index = filteredActivities.indexOf(id);
                if (index !== -1) {
                    var copy = [...filteredActivities];                
                    copy.splice(index, 1);
                    setFilteredActivities(copy);
                }                
            }
            else {
                setFilteredActivities(oldArray => [...oldArray, id]);
            }
        };        

        return (     
            <>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={3}>
                        <Stack direction="row">
                            <MuiIconButton
                                size="large"
                                aria-label="audit"
                                aria-haspopup="true"
                                color="primary"
                                onClick={() => onNavigateButtonClick("TODAY")}
                            >
                                <FirstPageIcon />
                            </MuiIconButton>
                            <MuiIconButton
                                size="large"                            
                                aria-label="audit"
                                aria-haspopup="true"
                                color="primary"
                                onClick={() => onNavigateButtonClick("PREV")}
                            >
                                <KeyboardArrowLeftIcon />
                            </MuiIconButton>
                            <MuiIconButton
                                size="large"
                                aria-label="audit"
                                aria-haspopup="true"
                                color="primary"
                                onClick={() => onNavigateButtonClick("NEXT")}
                            >
                                <KeyboardArrowRightIcon />
                            </MuiIconButton>
                            <Spacer/>
                            <Typography
                                alignSelf="center"
                                variant="button"
                                color="primary"
                            >
                                {props.label}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid container item xs={3} justifyContent="center">                       
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end">
                        <Stack direction="row">
                            {checkAccess("content_activities", Access.Create) &&
                                <>
                                    <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddScheduleClick}>
                                        {strings.newSchedule}
                                    </Button>
                                <Spacer x={2} />
                                </>
                            }
                            <ToggleButtonGroup
                                value={props.view}
                                exclusive
                                size="small"
                                onChange={onViewButtonClick}
                            >
                                <ToggleButton value="month">{strings.month}</ToggleButton>
                                <ToggleButton value="week">{strings.week}</ToggleButton>
                                <ToggleButton value="day">{strings.day}</ToggleButton>
                            </ToggleButtonGroup>
                            <Spacer x={2} />
                            <ToggleButtonGroup
                                value={colorBy.toString()}
                                exclusive
                                size="small"
                                onChange={onColorByButtonClick}
                            >
                                {/*<Tooltip title="Color by Activity">*/}
                                    <ToggleButton value="0">{strings.activity}</ToggleButton>                        
                                {/*</Tooltip>*/}
                                {/*<Tooltip title="Color by Location">*/}
                                    <ToggleButton value="1">{strings.location}</ToggleButton>
                                {/*</Tooltip>*/}
                            </ToggleButtonGroup>                            
                        </Stack>
                    </Grid>
                </Grid>                   
                <Paper sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',                        
                    m: 2,
                    }}
                    component="ul"
                >
                    {getUniqueActivities(scheduledActivites).map((data) => {
                        let icon;
                        let selected = false;

                        if (filteredActivities.includes(data.key)) {
                            icon = <CheckIcon />;
                            selected = true;
                        }

                        return (
                            <ListItem key={data.key}>
                                <Chip                                    
                                    icon={icon}
                                    label={data.value}
                                    color={selected ? "primary" : "default"}
                                    onClick={() => handleChipClick(data.key, selected)}
                                />
                            </ListItem>
                        );
                    })}
                </Paper>        
            </>
        );
    }
    //scheduledActivites.filter(sa => !filteredActivities.includes(sa.activityId))
    const dialogTitle = selectedScheduleId.id === "" ? strings.newScheduledActivityTitle : strings.editScheduledActivityTitle;

    function getLocationName(id: string) {
        return locations.find(l => l.id === id)?.name?.en ?? "";
    }

    if (isLoading) {
        return <LinearProgress color={"primary"} variant={"query"} />;
    }
    else {
        return (
            <Box>
                <Box style={{ height: theme.spacing(2) }}>    
                    { isPageLoading &&
                        <LinearProgress color={"primary"} variant={"query"} />
                    }
                </Box>
                <Box sx={{ height: 900, paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                    <Calendar
                        localizer={localizer}
                        events={getEvents()}
                        date={currentDate}
                        defaultView="week"
                        startAccessor="start"
                        endAccessor="end"
                        components={
                            {
                                toolbar: CustomToolbar,
                                eventWrapper: ({ event, children }) => (
                                    <div
                                        onContextMenu={
                                            e => {
                                                handleScheduleRightClick(event, e);
                                                e.preventDefault();
                                            }
                                        }
                                    >
                                        {children}
                                    </div>
                                )
                            }
                        }
                        eventPropGetter={eventStyleGetter}
                        selectable={true}
                        showMultiDayTimes={true}
                        onSelectSlot={handleSelectSlot}
                        onDoubleClickEvent={handleScheduleDoubleClick}            
                        onNavigate={handleNavigate}
                        onRangeChange={handleRangeChange}
                    />

                    <Menu
                        id="contextMenu"
                        MenuListProps={{
                            'aria-labelledby': 'buttonAddItem',
                        }}
                        anchorEl={contextMenuAnchorEl}
                        open={contextMenuOpen}
                        onClose={handleContextMenuClose}
                    >
                        <MenuItem onClick={handleEditSelectedSchedule}>{checkAccess("content_activities", Access.Update) ? strings.edit : strings.view}</MenuItem>
                        {checkAccess("content_activities", Access.Delete) &&
                            <>
                                <MenuItem onClick={handleDeleteSelectedSchedule}>{strings.delete}</MenuItem>
                                <MenuItem onClick={handleDeleteAllSelectedSchedule}>{strings.deleteAll}</MenuItem>
                            </>
                        }
                    </Menu>
                </Box>

                <DetailsDialog
                    permissionKey="content_activities"
                    adding={selectedScheduleId.id === ""}
                    open={detailsDialogOpen}
                    title={dialogTitle}
                    onCancelClick={handleCancelClick}
                    onValidateForm={handleValidateForm}
                    onSaveClick={handleSaveClick}
                    contentSize={{ width: 590, height: 819.53 }}
                >
                    <Grid container direction="column">
                        <Grid item sx={{ paddingLeft: theme.spacing(2), paddingTop: theme.spacing(2) }}>
                            <DialogSelect
                                label={strings.activity}
                                keyValues={mapActivitiesToKeyValues(activities)}
                                selectedValue={selectedSchedule?.activityId ?? ""}
                                onChangeHandler={handleActivityChange}
                                sx={{ minWidth: 200 }}                                
                                error={Boolean(errors.activityId)}
                                helperText={strings.validationScheduleActivity}
                            />
                            <Spacer />
                            <DialogSelect
                                label={strings.location}
                                keyValues={mapLocationsToKeyValues(locations)}
                                selectedValue={selectedSchedule?.locationId ?? ""}
                                onChangeHandler={handleLocationChange}
                                sx={{ minWidth: 200 }}                                
                                error={Boolean(errors.locationId)}
                                helperText={strings.validationScheduleLocation}
                            />
                            <Spacer />                            
                        </Grid>
                        <Grid item>
                            <Scheduler
                                json={selectedSchedule?.data ?? ""}
                                start={selectedScheduleId.id === "" ? (selectedSlot?.start as Date ?? undefined) : undefined}
                                end={selectedScheduleId.id === "" ? (selectedSlot?.end as Date ?? undefined) : undefined}
                                allowNextDay={true}
                                ref={schedulerRef}                                
                            />
                        </Grid>
                    </Grid>
                </DetailsDialog>

                <NotificationMessage notificationState={[notify, setNotify]} />
            </Box>
        );
    }
};

export default ActivitySchedule;
