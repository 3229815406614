import MuiIconButton from "@mui/material/IconButton";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Tooltip from "@mui/material/Tooltip";
import React, { Ref, useEffect, useImperativeHandle, useState } from "react";
import { useLocalizedStrings } from "../../localization/LocalizedStringsProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useDialog } from "./details/DetailsDialog";
import Spacer from "./Spacer";
import TabPanelKeepMounted from "./TabPanelKeepMounted";
import TextField from './details/TextField';
import { default as MaterialTextField } from "@mui/material/TextField";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Divider from '@mui/material/Divider';
import { default as Select, SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Typography from '@mui/material/Typography';
import FormHelperText from "@mui/material/FormHelperText";
import { Access } from "../../models/configuration/security/Permission";
import { useUser } from "../../providers/UserProvider";
import moment from "moment";
import LocalizedStrings from "react-localization";
import Checkbox from '@mui/material/Checkbox';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone';

export interface SchedulerProps {
    json: string;
    start?: Date;
    end?: Date;
    disabled?: boolean;
    permissionKey?: string;
    startLabel?: string;
    startHelperText?: string;
    hideEnd?: boolean;
    initialRecurrence?: number;
    allowNextDay?: boolean;
}

export enum Recurrence {
    Once,
    Daily,
    Weekly,
    Monthly,
    Yearly
}

export interface Schedule {
    recurrence: Recurrence;
    data: any;
}

export interface ScheduleOnce {
    startDateTime?: Date;
    startDate?: Date;
    startTime?: Date;
    endTime?: Date;
    allDay: boolean;
}

export interface ScheduleDaily {
    startTime: Date;
    endTime: Date;
    allDay: boolean;
    startDate: Date;
    endDate: Date;
}

export interface ScheduleWeekly {
    startTime: Date;
    endTime: Date;
    allDay: boolean;
    daysOfWeek: number;
    startDate: Date;
    endDate: Date;
}

export interface SchdeuleMonthly {
    startTime: Date;
    endTime: Date;
    allDay: boolean;
    day: number;
    startDate: Date;
    endDate: Date;
}

export interface ScheduleYearly {
    startTime: Date;
    endTime: Date;
    allDay: boolean;
    month: number;
    day: number;
    startDate: Date;
    endDate: Date;
}

export interface SchedulerRefObject {
    validate: () => boolean;
    getJson: () => string;
}

const Scheduler = React.forwardRef((props: SchedulerProps, ref: Ref<SchedulerRefObject>) => {
    const emptyWeeklyDays = {
        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false
    }

    const defaultStart = dayjs(props.start) ?? null;
    const defaultEnd = dayjs(props.end) ?? null;

    const [parsedJson, setParsedJson] = useState("{}");
    const [tabValue, setTabValue] = useState((props.initialRecurrence ?? 0) === 0 ? 0 : 1); 
    const [radioValue, setRadioValue] = useState(1);
    const [startValue, setStartValue] = useState<Dayjs | null>(defaultStart);
    const [startError, setStartError] = useState(false);
    const [startTimeValue, setStartTimeValue] = useState<Dayjs | null>(defaultStart);
    const [startTimeError, setStartTimeError] = useState(false);
    const [endTimeValue, setEndTimeValue] = useState<Dayjs | null>(defaultEnd);
    const [endTimeError, setEndTimeError] = useState(false);
    const [endTimeWarning, setEndTimeWarning] = useState(false);
    const [allDay, setAllDay] = useState(false);
    const [recurrenceValue, setRecurrenceValue] = useState(props.initialRecurrence ?? 1);
    const [weeklyDayValues, setWeeklyDayValues] = useState(emptyWeeklyDays);
    const [weeklyDayError, setWeeklyDayError] = useState(false);
    const [monthlyDayValue, setMonthlyDayValue] = useState(defaultStart?.date() ?? 0);
    const [monthlyDayError, setMonthlyDayError] = useState(false);
    const [yearlyMonthValue, setYearlyMonthValue] = useState(((defaultStart?.month() ?? 0) + 1).toString());
    const [yearlyDayValue, setYearlyDayValue] = useState(defaultStart?.date() ?? 0);
    const [yearlyDayError, setYearlyDayError] = useState(false);
    const [startDateValue, setStartDateValue] = useState<Dayjs | null>(defaultStart);
    const [startDateError, setStartDateError] = useState(false);
    const [endDateValue, setEndDateValue] = useState<Dayjs | null>(defaultEnd);
    const [endDateError, setEndDateError] = useState(false);
    const theme = useTheme();
    const strings = useLocalizedStrings();
    const { dirty, setDirty, adding, permissionKey } = useDialog();
    const { checkAccess } = useUser();
    const [readOnly] = React.useState(!checkAccess(props.permissionKey ?? permissionKey, adding ? Access.Create : Access.Update));
    const { user } = useUser(); 

    var today2 = new Date(new Date().toLocaleString("en-US", { timeZone: user.currentProperty?.timeZoneId }));
    today2.setHours(0,0,0,0);

    dayjs.extend(utc);
    dayjs.extend(timezone);
    var today = dayjs().startOf("day").tz(user.currentProperty?.timeZoneId);  
    
    React.useEffect(() => {
        function parseJson() {
            if (props.json === "" || props.json === "{}") {
                return null;
            }

            const parsedJson = JSON.parse(props.json);
            setParsedJson(parsedJson);
            setTabValue(getTabValue(parsedJson));            
            setStartValue(getStartValue(parsedJson));
            
            const ad = getAllDayValue(parsedJson);
            setAllDay(ad);

            if (!ad) {
                setStartTimeValue(getStartTimeValue(parsedJson));
                setEndTimeValue(getEndTimeValue(parsedJson));
            }
            else {
                setStartTimeValue(null);
                setEndTimeValue(null);
            }
            
            const recurrence = getRecurrence(parsedJson);
            setRecurrenceValue(recurrence);
            setRadioValue(recurrence === 0 ? 1 : recurrence);
            setWeeklyDayValues(getWeeklyDays(parsedJson));
            setMonthlyDayValue(getMonthlyDay(parsedJson));
            setYearlyMonthValue(getYearlyMonth(parsedJson));
            setYearlyDayValue(getYearlyDay(parsedJson));
            setStartDateValue(getStartDateValue(parsedJson));
            setEndDateValue(getEndDateValue(parsedJson));
        }

        parseJson();
    }, []);

    function getTabValue(parsedJson: any) {
        return parsedJson.recurrence === Recurrence.Once ? 0 : 1;
    }

    function getStartValue(parsedJson: any) {
        return dayjs(parsedJson.data.startDateTime) ?? defaultStart;
    }

    function getStartTimeValue(parsedJson: any) {
        return dayjs(parsedJson.data.startTime) ?? defaultStart;
    }

    function getEndTimeValue(parsedJson: any) {
        return dayjs(parsedJson.data.endTime) ?? defaultEnd;
    }

    function getAllDayValue(parsedJson: any) {
        return parsedJson.data.allDay ?? false;
    }

    function getRecurrence(parsedJson: any) {
        return parsedJson.recurrence ?? Recurrence.Daily;
    }

    function getWeeklyDays(parsedJson: any) {
        const daysOfWeek = parsedJson.data.daysOfWeek;
        return {
            sun: (daysOfWeek & 1) !== 0,
            mon: (daysOfWeek & 2) !== 0,
            tue: (daysOfWeek & 4) !== 0,
            wed: (daysOfWeek & 8) !== 0,
            thu: (daysOfWeek & 16) !== 0,
            fri: (daysOfWeek & 32) !== 0,
            sat: (daysOfWeek & 64) !== 0
        }
    }

    function getMonthlyDay(parsedJson: any) {
        return parsedJson.data.day ?? defaultStart?.date() ?? 1;
    }

    function getYearlyMonth(parsedJson: any) {
        return parsedJson.data.month ?? ((defaultStart?.month() ?? 0) + 1).toString();
    }

    function getYearlyDay(parsedJson: any) {
        return parsedJson.data.day ?? defaultStart?.date() ?? 1;
    }

    function getStartDateValue(parsedJson: any) {
        return dayjs(parsedJson.data.startDate) ?? defaultStart;
    }

    function getEndDateValue(parsedJson: any) {
        return dayjs(parsedJson.data.endDate) ?? defaultEnd;
    }

    function handleTabChange(event: React.SyntheticEvent, newValue: number) {
        //setDirty(true);
        setTabValue(newValue);
    }

    function handleStartChange(newValue: Dayjs | null) {
        setDirty(true);

        if (newValue?.toString() !== "Invalid Date") {
            setStartValue(newValue);
        }
        //else {
        //    setStartValue(null);
        //}
    }

    function handleAllDayChange(event: React.ChangeEvent<HTMLInputElement>) {
        setDirty(true);
        setAllDay(event.target.checked);

        setStartTimeError(false);
        setEndTimeError(false);
    }    

    function handleStartTimeChange(newValue: Dayjs | null) {
        setDirty(true);

        if (newValue?.toString() !== "Invalid Date") {
            setStartTimeValue(newValue);
        }
        //else {
        //    setStartTimeValue(null);
        //}
    }

    function handleEndTimeChange(newValue: Dayjs | null) {
        setDirty(true);

        // If we allowing it sto go past midnight show a message under the box
        if (props.allowNextDay && startTimeValue && newValue && newValue < startTimeValue) {            
            setEndTimeWarning(true);            
        }
        else {
            setEndTimeWarning(false);  
        }

        if (newValue?.toString() !== "Invalid Date") {
            setEndTimeValue(newValue);
        }
        //else {
        //    setEndTimeValue(null);
        //}
    }

    function handleRecurrenceChange(event: React.ChangeEvent<HTMLInputElement>) {
        setDirty(true);
        const value = parseInt((event.target as HTMLInputElement).value);
        setRecurrenceValue(value);
        setRadioValue(value);
    }

    function handleWeekChange(event: React.ChangeEvent<HTMLInputElement>) {
        setDirty(true);

        const checkbox = event.target as HTMLInputElement;
        setWeeklyDayValues((prevState) => ({
            ...prevState,
            [checkbox.id]: checkbox.checked
        }));
    }

    function handleMonthlyDayChange(event: React.ChangeEvent<HTMLInputElement>) {
        setDirty(true);

        var newValue = parseInt(event.target.value);

        if (isNaN(newValue) || newValue < 1) {
            newValue = 0;
        }
        else if (newValue > 31) {
            newValue = 31;
        }

        setMonthlyDayValue(newValue);
    }

    function handleYearlyMonthChange(event: SelectChangeEvent) {
        setDirty(true);
        setYearlyMonthValue(event.target.value);
        setYearlyDayValue(0);
    }

    function handleYearlyDayChange(event: React.ChangeEvent<HTMLInputElement>) {
        setDirty(true);

        // 2024 is a leap year
        const daysInMonth = new Date(2024, parseInt(yearlyMonthValue), 0).getDate();
        var newValue = parseInt(event.target.value);

        if (isNaN(newValue) || newValue < 1) {
            newValue = 0;
        }
        else if (newValue > daysInMonth) {
            newValue = daysInMonth;
        }

        setYearlyDayValue(newValue);
    }

    function handleStartDateChange(newValue: Dayjs | null) {
        setDirty(true);

        if (newValue?.toString() !== "Invalid Date") {
            setStartDateValue(newValue);
        }
        //else {
        //    setStartDateValue(null);
        //}
    }

    function handleEndDateChange(newValue: Dayjs | null) {
        setDirty(true);

        if (newValue?.toString() !== "Invalid Date") {
            setEndDateValue(newValue);
        }
        //else {
        //    setEndDateValue(null);
        //}
    }

    useImperativeHandle(ref, () => ({ validate, getJson }));

    function validate(): boolean {
        var recurrence = recurrenceValue;
        if (tabValue === 0) {
            recurrence = Recurrence.Once;
        }

        switch (recurrence) {
            case Recurrence.Daily:
                return validateDailyData();
            case Recurrence.Weekly:
                return validateWeeklyData();
            case Recurrence.Monthly:
                return validateMonthlyData();
            case Recurrence.Yearly:
                return validateYearlyData();
            default:
                return validateOnceData();
        }
    }

    function getJson(): string {
        var schedule = {} as Schedule;

        if (tabValue === 0) {
            schedule.recurrence = Recurrence.Once;
            schedule.data = getOnceData();
        }
        else {
            switch (recurrenceValue) {
                case Recurrence.Daily:
                    schedule.recurrence = Recurrence.Daily;
                    schedule.data = getDailyData();
                    break;
                case Recurrence.Weekly:
                    schedule.recurrence = Recurrence.Weekly;
                    schedule.data = getWeeklyData();
                    break;
                case Recurrence.Monthly:
                    schedule.recurrence = Recurrence.Monthly;
                    schedule.data = getMonthlyData();
                    break;
                case Recurrence.Yearly:
                    schedule.recurrence = Recurrence.Yearly;
                    schedule.data = getYearlyData();
                    break;
            }
        }

        return JSON.stringify(schedule);
    }

    // The current toJSON function converts the date into UTC, but we dont want it do to that, so replace that function to just ignore timezone.
    Date.prototype.toJSON = function () {
        var d = moment(this).format("YYYY-MM-DDTHH:mm:00");
        return d;
    };

    // https://stackoverflow.com/questions/11124322/get-date-time-for-a-specific-time-zone-using-javascript
    // Future use?
    //const propertyDateTime = new Date(new Date().toLocaleString("en-US", {timeZone: "timezone id"}));

    function validateOnceData() {
        var valid = true;

        if (props.hideEnd) {
            if (!startValue) {
                setStartError(true);
                valid = false;
            }
            else {
                setStartError(false);
            }            
        }
        else {
            if (!startDateValue) {
                setStartDateError(true);
                valid = false;
            }
            else {
                setStartDateError(false);
            }

            if (!allDay) {
                if (!startTimeValue) {
                    setStartTimeError(true);
                    valid = false;
                }
                else {
                    setStartTimeError(false);
                }

                if (!props.hideEnd && (!endTimeValue || !startTimeValue || endTimeValue < startTimeValue)) {
                    setEndTimeError(true);
                    valid = false;
                }
                else {
                    setEndTimeError(false);
                }
            }
        }

        return valid;
    }

    function getOnceData(): ScheduleOnce {  
        if (props.hideEnd) {
            return {
                startDate: startValue!.toDate(),                
                allDay: false
            }
        }
        else {
            return {
                startDate: startDateValue!.toDate(),
                startTime: startTimeValue!.toDate(),
                endTime: endTimeValue!.toDate(),
                allDay: allDay
            }
        }
    }

    function validateDateTimeFields() {
        var valid = true;

        if (props.hideEnd) {
            if (!startTimeValue) {
                    setStartTimeError(true);
                    valid = false;
                }
                else {
                    setStartTimeError(false);
                }

            if (!startDateValue) {
                setStartDateError(true);
                valid = false;
            }
            else if (startDateValue < today) {
                setStartDateError(true);
                valid = false;
            }
            else {
                setStartDateError(false);
            }   

            if (!endDateValue || !startDateValue || endDateValue < startDateValue || !endDateValue.isAfter(dayjs(), 'day')) {
                setEndDateError(true);
                valid = false;
            }
            else {
                setEndDateError(false);
            }   
        }
        else {
            if (!allDay) {
                if (!startTimeValue) {
                    setStartTimeError(true);
                    valid = false;
                }
                else {
                    setStartTimeError(false);
                }
                
                if (!props.hideEnd && (!endTimeValue || !startTimeValue || (!props.allowNextDay && (endTimeValue < startTimeValue)))) {            
                    setEndTimeError(true);
                    valid = false;
                }
                else {
                    setEndTimeError(false);
                }                       
            }

            if (!startDateValue) {
                setStartDateError(true);
                valid = false;
            }
            else if (startDateValue < today) {
                setStartDateError(true);
                valid = false;
            }
            else {
                setStartDateError(false);
            }

            if (!endDateValue || !startDateValue || endDateValue < startDateValue || !endDateValue.isAfter(dayjs(), 'day')) {
                setEndDateError(true);
                valid = false;
            }
            else {
                setEndDateError(false);
            }
        }

        return valid;
    }

    function validateDailyData() {
        return validateDateTimeFields();
    }

    function getDailyData(): ScheduleDaily {
        return {
            startTime: startTimeValue!.toDate(),
            endTime: endTimeValue!.toDate(),
            allDay: allDay,
            startDate: startDateValue!.toDate(),
            endDate: endDateValue!.toDate()           
        }
    }

    function validateWeeklyData() {
        var valid = validateDateTimeFields();

        if (!weeklyDayValues.sun && !weeklyDayValues.mon && !weeklyDayValues.tue && !weeklyDayValues.wed && !weeklyDayValues.thu && !weeklyDayValues.fri && !weeklyDayValues.sat) {
            setWeeklyDayError(true);
            valid = false;
        }
        else {
            setWeeklyDayError(false);
        }

        return valid;
    }

    function getWeeklyData(): ScheduleWeekly {
        var daysOfWeek = 0;

        if (weeklyDayValues.sun) {
            daysOfWeek |= 1;
        }

        if (weeklyDayValues.mon) {
            daysOfWeek |= 2;
        }

        if (weeklyDayValues.tue) {
            daysOfWeek |= 4;
        }

        if (weeklyDayValues.wed) {
            daysOfWeek |= 8;
        }

        if (weeklyDayValues.thu) {
            daysOfWeek |= 16;
        }

        if (weeklyDayValues.fri) {
            daysOfWeek |= 32;
        }

        if (weeklyDayValues.sat) {
            daysOfWeek |= 64;
        }        

        return {
            startTime: startTimeValue!.toDate(),
            endTime: endTimeValue!.toDate(),
            allDay: allDay,
            daysOfWeek: daysOfWeek,
            startDate: startDateValue!.toDate(),
            endDate: endDateValue!.toDate()
        }
    }

    function validateMonthlyData() {
        var valid = validateDateTimeFields();

        if (!monthlyDayValue) {
            setMonthlyDayError(true);
            valid = false;
        }
        else {
            setMonthlyDayError(false);
        }

        return valid;
    }

    function getMonthlyData(): SchdeuleMonthly {
        return {
            startTime: startTimeValue!.toDate(),
            endTime: endTimeValue!.toDate(),
            allDay: allDay,
            day: monthlyDayValue!,
            startDate: startDateValue!.toDate(),
            endDate: endDateValue!.toDate()
        }
    }

    function validateYearlyData() {
        var valid = validateDateTimeFields();

        if (!yearlyDayValue) {
            setYearlyDayError(true);
            valid = false;
        }
        else {
            setYearlyDayError(false);
        }

        return valid;
    }

    function getYearlyData(): ScheduleYearly {
        return {
            startTime: startTimeValue!.toDate(),
            endTime: endTimeValue!.toDate(),
            allDay: allDay,
            month: parseInt(yearlyMonthValue!),
            day: yearlyDayValue!,
            startDate: startDateValue!.toDate(),
            endDate: endDateValue!.toDate()
        }
    }

    function getYearlyDayValue() {
        if (isNaN(yearlyDayValue) || yearlyDayValue < 1) {
            return "";
        }
        else if (yearlyDayValue > 31) {
            return "31";
        }
        else {
            return yearlyDayValue.toString();
        }
    }    

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tabValue} onChange={handleTabChange} sx={{ backgroundColor: "white" }} aria-label="basic tabs example">
                    <Tab label={strings.once} />
                    <Tab label={strings.recurring} />
                </Tabs>
            </Box>
            <TabPanelKeepMounted value={tabValue} index={0}>            
                <Grid container direction="column" sx={{ margin: 0 }}>
                    {props.hideEnd ?
                        <Grid item>
                            <DateTimePicker
                                label={props.startLabel ?? strings.start}
                                minDate={dayjs(today)}
                                value={dayjs(startValue) ?? null}                                
                                onChange={handleStartChange}
                                slotProps={{
                                    textField: {
                                        error: startError,
                                        helperText: startError ? strings.validationStartDate : " ",
                                    },
                                }}                                
                                disabled={props.disabled || readOnly}                            
                            />
                        </Grid> :  
                        <>
                            <Grid item>
                                <DesktopDatePicker
                                    label={props.startLabel ?? strings.startDate}
                                    format="MM/DD/YYYY"
                                    minDate={dayjs(today)}
                                    value={dayjs(startDateValue) ?? null}
                                    onChange={handleStartDateChange}
                                    slotProps={{
                                        textField: {
                                            error: startDateError,
                                            helperText: startDateError ? strings.validationStartDate : " ",
                                        },
                                    }}                                     
                                    disabled={props.disabled || readOnly}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel control={
                                    <Checkbox 
                                        id="allDay" 
                                        checked={allDay} 
                                        onChange={handleAllDayChange} 
                                        disabled={props.disabled} />
                                    } 
                                    label={strings.allDay}
                                />     
                                <Spacer />
                            </Grid>
                            <Grid container direction="row">
                                <Grid item>
                                    <TimePicker
                                        label={strings.startTime}
                                        value={startTimeValue ?? null}
                                        onChange={handleStartTimeChange}
                                        slotProps={{
                                            textField: {
                                                error: startTimeError,
                                                helperText: startTimeError ? (props.startHelperText ? props.startHelperText : strings.validationStartTime) : " ",
                                            },
                                        }}                                          
                                        disabled={props.disabled || readOnly || allDay}
                                    />                             
                                </Grid>
                                <Grid item><Spacer x={2} /></Grid>                        
                                <Grid item>
                                    <TimePicker
                                        label={strings.endTime}
                                        value={endTimeValue ?? null}
                                        onChange={handleEndTimeChange}
                                        slotProps={{
                                            textField: {
                                                error: endTimeError,
                                                helperText: endTimeWarning ? "Next Day" : (endTimeError ? strings.validationEndTime : " "),
                                            },
                                        }}                                         
                                        disabled={props.disabled || readOnly || allDay}
                                    />                          
                                </Grid>                        
                            </Grid>
                        </>
                    }
                </Grid>
            </TabPanelKeepMounted>
            <TabPanelKeepMounted value={tabValue} index={1}>
                <Grid container direction="column" sx={{ margin: 0 }}>
                    {!props.hideEnd &&
                        <Grid item>
                            <FormControlLabel control={
                                <Checkbox 
                                    id="allDay" 
                                    checked={allDay} 
                                    onChange={handleAllDayChange} 
                                    disabled={props.disabled} />
                                } 
                                label={strings.allDay}
                            />     
                            <Spacer />
                        </Grid>
                    }
                    <Grid item>
                        <Grid container direction="row">
                            <Grid item>
                                <TimePicker
                                    label={props.startLabel ?? strings.startTime}
                                    value={startTimeValue ?? null}
                                    onChange={handleStartTimeChange}
                                    slotProps={{
                                        textField: {
                                            error: startTimeError,
                                            helperText: startTimeError ? (props.startHelperText ? props.startHelperText : strings.validationStartTime) : " ",
                                        },
                                    }}                                       
                                    disabled={props.disabled || readOnly || allDay}
                                />
                            </Grid>
                            <Grid item><Spacer x={2} /></Grid>
                            {!props.hideEnd &&
                                <Grid item>
                                    <TimePicker
                                        label={strings.endTime}
                                        value={endTimeValue ?? null}
                                        onChange={handleEndTimeChange}
                                        slotProps={{
                                        textField: {
                                                error: endTimeError,
                                                helperText: endTimeWarning ? "Next Day" : (endTimeError ? strings.validationEndTime : " "),
                                            },
                                        }}                                          
                                        disabled={props.disabled || readOnly || allDay}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Spacer />
                    </Grid>
                    <Grid item>
                        <Grid container direction="row">
                            <Grid item>
                                <FormControl disabled={props.disabled || readOnly}>
                                    <FormLabel id="demo-radio-buttons-group-label">{strings.recurrence}</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={radioValue}
                                        onChange={handleRecurrenceChange}
                                        name="radio-buttons-group"
                                    >
                                        <FormControlLabel value={Recurrence.Daily} control={<Radio />} label={strings.daily} />
                                        <FormControlLabel value={Recurrence.Weekly} control={<Radio />} label={strings.weekly} />
                                        <FormControlLabel value={Recurrence.Monthly} control={<Radio />} label={strings.monthly} />
                                        <FormControlLabel value={Recurrence.Yearly} control={<Radio />} label={strings.yearly} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Grid item hidden={recurrenceValue !== Recurrence.Daily}>
                            </Grid>
                            <Grid item hidden={recurrenceValue !== Recurrence.Weekly}>
                                <Grid container direction="column" sx={{ paddingLeft: theme.spacing(2) }}>
                                    <Grid item>
                                        <FormControlLabel control={<Checkbox id="sun" checked={weeklyDayValues.sun} onChange={handleWeekChange} disabled={props.disabled} />} label={strings.sunday} />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel control={<Checkbox id="mon" checked={weeklyDayValues.mon} onChange={handleWeekChange} disabled={props.disabled} />} label={strings.monday} />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel control={<Checkbox id="tue" checked={weeklyDayValues.tue} onChange={handleWeekChange} disabled={props.disabled} />} label={strings.tuesday} />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel control={<Checkbox id="wed" checked={weeklyDayValues.wed} onChange={handleWeekChange} disabled={props.disabled} />} label={strings.wednesday} />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel control={<Checkbox id="thu" checked={weeklyDayValues.thu} onChange={handleWeekChange} disabled={props.disabled} />} label={strings.thursday} />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel control={<Checkbox id="fri" checked={weeklyDayValues.fri} onChange={handleWeekChange} disabled={props.disabled} />} label={strings.friday} />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel control={<Checkbox id="sat" checked={weeklyDayValues.sat} onChange={handleWeekChange} disabled={props.disabled} />} label={strings.saturday} />
                                    </Grid>
                                </Grid>
                                <FormHelperText error={true} sx={{ paddingLeft: theme.spacing(2) }}>{weeklyDayError ? strings.validationWeekDay : " "}</FormHelperText>
                            </Grid>
                            <Grid item hidden={recurrenceValue !== Recurrence.Monthly}>
                                <TextField
                                    onChange={handleMonthlyDayChange}
                                    label={strings.day}
                                    value={monthlyDayValue === 0 ? "" : monthlyDayValue.toString()}
                                    disabled={props.disabled || readOnly}
                                    width={100}
                                    helperWidth={200}
                                    error={monthlyDayError}
                                    helperText={strings.validationPromotionScheduleMonthlyDay}
                                    sx={{ marginLeft: theme.spacing(2) }}
                                />
                                <Typography variant="caption" display="block" gutterBottom sx={{ paddingLeft: theme.spacing(2), paddingTop: theme.spacing(1), width: 300 }}>{strings.scheduleDayMonthWarning}</Typography>
                            </Grid>
                            <Grid item hidden={recurrenceValue !== Recurrence.Yearly}>
                                <Grid container direction="row" sx={{ paddingLeft: theme.spacing(2) }}>
                                    <Grid item>
                                        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                                            <InputLabel id="demo-simple-select-label">{strings.month}</InputLabel>
                                            <Select
                                                aria-labelledby={"select-label-month"}
                                                label={strings.month}
                                                value={yearlyMonthValue}
                                                onChange={handleYearlyMonthChange}
                                                disabled={props.disabled || readOnly}
                                            >
                                                <MenuItem value="1">{strings.january}</MenuItem>
                                                <MenuItem value="2">{strings.february}</MenuItem>
                                                <MenuItem value="3">{strings.march}</MenuItem>
                                                <MenuItem value="4">{strings.april}</MenuItem>
                                                <MenuItem value="5">{strings.may}</MenuItem>
                                                <MenuItem value="6">{strings.june}</MenuItem>
                                                <MenuItem value="7">{strings.july}</MenuItem>
                                                <MenuItem value="8">{strings.august}</MenuItem>
                                                <MenuItem value="9">{strings.september}</MenuItem>
                                                <MenuItem value="10">{strings.october}</MenuItem>
                                                <MenuItem value="11">{strings.november}</MenuItem>
                                                <MenuItem value="12">{strings.december}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item><Spacer x={2} /></Grid>
                                    <Grid item>
                                        <TextField
                                            onChange={handleYearlyDayChange}
                                            label={strings.day}
                                            value={getYearlyDayValue()}
                                            disabled={props.disabled || readOnly}
                                            width={100}
                                            helperWidth={200}
                                            error={yearlyDayError}
                                            helperText={strings.validationPromotionScheduleYearlyDay}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Spacer y={3} />
                    </Grid>
                    <Grid item>
                        <Grid container direction="row">
                            <Grid item>
                                <DesktopDatePicker
                                    label={strings.startDate}
                                    format="MM/DD/YYYY"
                                    minDate={today}
                                    value={startDateValue}
                                    onChange={handleStartDateChange}
                                    slotProps={{
                                        textField: {
                                            error: startDateError,
                                            helperText: startDateError ? strings.validationStartDate : " ",
                                        },
                                    }}                                      
                                    disabled={props.disabled || readOnly}
                                />
                            </Grid>
                            <Grid item><Spacer x={2} /></Grid>
                            <Grid item>
                                <DesktopDatePicker
                                    label={strings.endDate}
                                    format="MM/DD/YYYY"
                                    value={endDateValue}
                                    onChange={handleEndDateChange}
                                    slotProps={{
                                        textField: {
                                            error: endDateError,
                                            helperText: endDateError ? strings.validationEndDate : " ",
                                        },
                                    }}                                    
                                    disabled={props.disabled || readOnly}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </TabPanelKeepMounted>
        </LocalizationProvider>
    )
});

export default Scheduler;