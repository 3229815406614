import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import * as React from 'react';
import { getLanguageById, getLanguageGridData, updateLanguage } from '../../../api/languageApi';
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Language, initialItemState, initialNotficationState } from "../../../models/configuration/settings/Language";
import { useUser } from "../../../providers/UserProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from '../../common/Spacer';
import DataGridEditButton from '../../common/datatable/DataGridEditButton';
import DataGrid from '../../common/datatable/DataGridWrapper';
import Checkbox from "../../common/details/Checkbox";
import DetailsDialog from "../../common/details/DetailsDialog";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";

function Languages() {
  const [languages, setLanguages] = React.useState<Language[]>([]);
  const [selectedLanguageItem, setSelectedLanguageItem] = React.useState<Language>(initialItemState);
  const [detailsDialogOpen, setDetailsDialogOpen] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [gridRefresh, setGridRefresh] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);
  const strings = useLocalizedStrings(); 
  const axiosInstance = useCreateAxios();
  const { user} = useUser();
  const theme = useTheme();  

  React.useEffect(() => {
    async function getLanguages() {
      try {
        setIsLoading(true);
        const response = await getLanguageGridData(axiosInstance, user.currentProperty?.code ?? "");
        setLanguages(response.languages);
      }
      catch (e) {
        setNotify({
          isOpen: true,
          message: strings.errorRetrievingLanguages, 
          msgType: "error",
        });
      }
      finally {
        setIsLoading(false);
      }
    }

    getLanguages();
  }, [gridRefresh, strings.errorRetrievingLanguages, user.currentProperty?.code]);

  async function handleEditRowClick(id: string) {
    try {
      const languageItem = await getLanguageById(axiosInstance, user.currentProperty?.code ?? "", id);
      setSelectedLanguageItem(languageItem ?? initialItemState);
      setDetailsDialogOpen(true);
    }
    catch (error: unknown) {
      const axiosErrorObject = axios.isAxiosError(error)
        ? { message: error.message }
        : { message: "unable to parse error info." };
      setNotify({
        isOpen: true,
        message: strings.errorRetrievingItem.replace("{{error}}", axiosErrorObject.message),
        msgType: "error",
      });
    }
  }

  function handleCancelClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setDetailsDialogOpen(false);
  }
  
  async function handleSaveClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    console.log('lang item data: ' + JSON.stringify(selectedLanguageItem, null, 2));
    
    try {
      await updateLanguage(axiosInstance, user.currentProperty?.code ?? "", selectedLanguageItem);
    }
    catch(error: unknown) {
      const axiosErrorObject = axios.isAxiosError(error)
        ? { message: error.message }
        : { message: "unable to parse error info." };

      setNotify({
        isOpen: true,
        message: strings.errorUpdatingItem.replace("{{error}}", axiosErrorObject.message),
        msgType: "error",
      })

      return false;
    }

    setDetailsDialogOpen(false);
    setTabValue(0);
    setGridRefresh(!gridRefresh);
    setNotify({
      isOpen: true,
      message: strings.languageSavedSuccessfully,
      msgType: "success",
    });

    return true;
  }

  function handleNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedLanguageItem((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            },
        }));
    }

  function handleTabChange(event: React.SyntheticEvent<Element, Event>, newValue: any) {
    setTabValue(newValue);
  };

  function handleEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSelectedLanguageItem((prevState) => ({
      ...prevState,
      enabled: event.target.checked,
    }));
  }

  const gridColumns: GridColDef[] = [
    { field: "code", headerName: strings.code,  flex: 1 },
    { field: "name", headerName: strings.language, valueGetter: (value, row) => row.name.en, flex: 1 },
    { field: "enabled", headerName: strings.enabled, type:"boolean", flex: 1 },
    {
      field: "actions",
      type: "actions",
      headerName: strings.gridActions,
      flex: 1,
      getActions: (params: GridRowParams) => [
        <DataGridEditButton 
          permissionKey="configuration_settings" 
          rowId={params.id.toString()} 
          clickHandler={() => handleEditRowClick(params.id.toString())} 
        />,
      ],
    },
  ];

  const dialogTitle = strings.languagesTitleEdit.replace("{{name}}", selectedLanguageItem?.name?.en ?? "");

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 64px)" }}> 
            <DataGrid
                permissionKey="configuration_settings"
                showAddButton={false}
                hideFooterPagination={true}
                columns={gridColumns}
                rows={languages}   
                loading={isLoading}
            />
            <DetailsDialog
                permissionKey="configuration_settings"
                open={detailsDialogOpen}
                title={dialogTitle}
                onCancelClick={handleCancelClick}
                onSaveClick={handleSaveClick}
                onValidateForm={() => true}
                contentSize={{ width: 536, height: 239.81 }}
            >        
                <LanguageForm>
                    <Box sx={{ padding: theme.spacing(2) }}>
                        <LanguageSelect />
                        <Spacer />
                        <LanguageTextField
                        id="language-name"
                        label={strings.name}
                        width={200}
                        disabled={true}
                        values={selectedLanguageItem.name ?? {}}
                        onChange={handleNameChange}
                        />
                        <Spacer />
                        <Checkbox
                        label={strings.enabled}
                        checked={selectedLanguageItem?.enabled}
                        onChange={handleEnabledChange}
                        />
                    </Box>
                </LanguageForm>           
            </DetailsDialog>
            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    );    
}

export default Languages