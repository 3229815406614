export interface Options {
    provider: GuestServicesProvider;
    hoTSOS?: HoTSOSOptions
}

export type GuestServicesProvider = "NONE" | "EMAIL" | "HOTSOS"

export interface HoTSOSOptions {
    serviceAddress: string;
    userName: string;
    password: string;
}

export const initialOptionsState: Options = {
    provider: "NONE"
}