export default class TimeSpan {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    totalDays: number;
    totalSeconds: number;
    totalMinutes: number;
    totalHours: number;

    // We can add other constructors here if we want to mimic c#

    constructor(totalSeconds: number) {  
        this.totalSeconds = totalSeconds;
        this.seconds = totalSeconds % 60;

        this.totalMinutes = totalSeconds / 60.0;      
        const minutesFloor = Math.floor(this.totalMinutes);
        this.minutes = minutesFloor % 60;   

        this.totalHours = minutesFloor / 60.0    
        const hoursFloor = Math.floor(this.totalHours);
        this.hours = hoursFloor % 24;
        
        this.totalDays = hoursFloor / 24.0;
        this.days = Math.floor(this.totalDays);
    } 
  
    toString(format?: string) {
        switch (format) {
            case "d":
                return this.totalDays >= 1 ? `${this.days} d` : `${this.totalHours.toFixed(1)} h`;
            case "dhms":
            default:
                return `${this.days}:${this.hours.toString().padStart(2, '0')}:${this.minutes.toString().padStart(2, '0')}:${this.seconds.toString().padStart(2, '0')}`;
        }        
    }
}