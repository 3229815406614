export interface Video {
    id: string;  
    internalName: string; 
    enabled: boolean;
}

export function initialVideoState() {
    return {
        id: "",
        internalName: "",
        enabled: false
    };
}