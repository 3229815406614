import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import React from "react";
//import { getOptionSet } from "../../../api/roomservice/optionApi";
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { GridColDef, GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { getOptionSets } from "../../../api/roomservice/optionApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Option as OptionModel, OptionSet as OptionSetModel, initialOptionSetState, initialOptionState } from "../../../models/modules/roomservice/Option";
import { useUser } from "../../../providers/UserProvider";
import { Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import { default as DetailsDialog } from "../../common/details/DetailsDialog";
import DetailsDisplayOrderGrid, { DetailsDisplayOrderGridRefObject } from "../../common/details/DetailsDisplayOrderGrid";
import TextField from "../../common/details/TextField";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";
import OptionSet from "./OptionSet";

const MAX_LEVEL = 2;

interface OptionProps {
    open: boolean;  
    adding: boolean;
    level: number;
    optionSetId: string;
    saveButtonLabel: string;
    option: OptionModel
    onCancelClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onSaved: (option: OptionModel, adding: boolean, event: React.MouseEvent<HTMLButtonElement>) => Promise<boolean>;
    onNotification: (options: NotificationOptions) => void;
}

interface OptionValidationErrors {
    name: string;
    sku: string;
    pricePerUnit: string;
}

const Option = (props: OptionProps) => {
    const initialOptionErrorState: OptionValidationErrors = {
        name: "",
        sku: "",
        pricePerUnit: ""
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const theme = useTheme(); 
    const strings = useLocalizedStrings();         
    const [option, setOption] = React.useState<OptionModel>(props.option);
    const [optionErrors, setOptionErrors] = React.useState<OptionValidationErrors>(initialOptionErrorState);
    const [tabValue, setTabValue] = React.useState(0);    
    const [allOptionSets, setAllOptionSets] = React.useState<OptionSetModel[]>([]);
    const [selectedOptionSetId, setSelectedOptionSetId] = React.useState("");
    const [selectedOptionSet, setSelectedOptionSet] = React.useState<OptionSetModel>(initialOptionSetState);
    const [addOptionSetMenuAnchorEl, setAddOptionSetMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const addOptionSetMenuOpen = Boolean(addOptionSetMenuAnchorEl);    
    const [optionSetDialogOpen, setOptionSetDialogOpen] = React.useState(false);
    const [deleteOptionSetMenuAnchorEl, setDeleteOptionSetMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const deleteOptionSetMenuOpen = Boolean(deleteOptionSetMenuAnchorEl); 
    const alertDialog = useAlertDialog();
    const axiosInstance = useCreateAxios();
    const {user} = useUser();   
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);    
    const gridRef = React.useRef<DetailsDisplayOrderGridRefObject>(null);

    React.useEffect(() => {
        setOption(props.option);
    }, [props.option])
   
    React.useEffect(() => {
        const getInitialData = async() => {
             try {                                                
                const sectionResponse = await getOptionSets(axiosInstance, user?.currentProperty?.code ?? "");  
                const filtered = sectionResponse.filter(os => os.id !== props.optionSetId);
                setAllOptionSets(filtered);               
            }
            catch(e: unknown) {
                const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };

                props.onNotification({
                    isOpen: true,
                    message: strings.generalInfoErrorRetreivingBooks.replace("{{error}}", error.message),
                    msgType: "error",
                });

                return;
            }
        }
 
        if (props.open) {
            getInitialData();
        }
    }, [user.currentProperty?.code, props.open, props.adding])

    function handleOptionSetTabChange(event: React.SyntheticEvent, newValue: number) {
        if (gridRef.current?.isDirty()) {
            return;
        }

        setTabValue(newValue);
    }

    function handleNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setOption((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            }
        }));
    }    

    function handleSkuChange(event: React.ChangeEvent<HTMLInputElement>) {       
        setOption((prevState) => ({
            ...prevState,
            sku: event.target.value
        }));
    }

    function handlePriceChange(event: React.ChangeEvent<HTMLInputElement>) { 
        const value = event.target.value;
        
        // This is kinda ugly but we need to check as we're typing. Valdate that the last char entered is a number or decimal
        if (value !== "" && !value.charAt(value.length - 1).match(/(\d)?(\.)?/)) {
            return;
        }

        var index = value.indexOf('.');

        // Only allow 1 decimal point
        if (index !== -1 && value.indexOf('.', index + 1) !== -1) {
            return;
        }

        // Ensure only 2 decimal places
        if (index !== -1 && value.length - index > 3) {
            return;
        }

        setOption((prevState) => ({
            ...prevState,
            pricePerUnitString: value            
        }));
    }

    function handleOptionCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        props.onCancelClick(event);
        setOptionErrors(initialOptionErrorState);    
        setTabValue(0);        
        setOption(initialOptionState);        
    }

    async function handleOptionSaveClick(event: React.MouseEvent<HTMLButtonElement>) {  
        const optionToSave = { ...option };
      
        // We need to create the id now because the ui needs it to function
        if (props.adding) {
            optionToSave.id = uuid();
        }

        // We also need to update the pricePerUntit number
        optionToSave.pricePerUnit = option.pricePerUnitString ? parseFloat(option.pricePerUnitString) : option.pricePerUnit;

        if (await props.onSaved(optionToSave, props.adding, event)) {
            setOptionErrors(initialOptionErrorState);    
            setTabValue(0);            
            setOption(initialOptionState);        
            return true;
        }
        else {
            return false;
        }
    }

    function handleValidateOption() {        
        let errors = validate<OptionModel, OptionValidationErrors>([
            { property: "name.en", type: ValidationType.Required, message: strings.validationRoomServiceOptionName }            
        ], option);
        if (errors) {
            setOptionErrors(errors);
            return false;
        }
        return true;    
    }  
    
    // OptionSet
    function handleOptionSetSelectionChange(optionSetSelectionModel: GridRowSelectionModel) {
        if (optionSetSelectionModel.length > 0) {
            const id = optionSetSelectionModel[0].toString();
            setSelectedOptionSetId(id);               
            const optionSet = option.optionSets?.find(os => os.id === id);
            if (optionSet) {
                setSelectedOptionSet(optionSet);                
            }           
        }
    }

    function handleOptionSetSaveDisplayOrder(ids: any[]) {
        // https://stackoverflow.com/questions/13304543/javascript-sort-array-based-on-another-array
        const optionSets = [...option.optionSets];
        optionSets.sort(function(a, b) {  
            return ids.findIndex(id => a.id === id) - ids.findIndex(id => b.id === id);
        });
        updateOptionSetOrder(optionSets);        
    }

    function updateOptionSetOrder(optionSetList: OptionSetModel[]) {        
        setOption((prevState) => ({
            ...prevState,
            optionSet: optionSetList
        }));        
    }   

    function handleAddOptionSetButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (getFilteredOptionSets().length > 0) {            
            setAddOptionSetMenuAnchorEl(event.currentTarget);
        }
        else {
            handleAddNewOptionSetMenuClick();
        }        
    }

    function handleAddOptionSetMenuClose() {
        setAddOptionSetMenuAnchorEl(null);
    }

    function handleAddNewOptionSetMenuClick() {
        setSelectedOptionSetId("");
        setSelectedOptionSet(initialOptionSetState);
        setAddOptionSetMenuAnchorEl(null);
        setOptionSetDialogOpen(true);
    }    

    function handleAddExistingOptionSetMenuClick(id: string) {
        setAddOptionSetMenuAnchorEl(null);    
        
        var newOptionSet = allOptionSets.find(os => os.id === id);        
        if (newOptionSet) {            
            newOptionSet!!.displayOrder = option.optionSets.length;

            setOption((prevState) => ({
                ...prevState,
                optionSets: [...prevState.optionSets, newOptionSet!!]
            }));            
        }
    }    

    function handleEditOptionSetClick(id: string) {
        const find = option.optionSets.find(os => os.id === id);
        if (find) {
            setSelectedOptionSetId(id);
            setSelectedOptionSet(find);
            setOptionSetDialogOpen(true);
        }
    }

    function handleDeleteOptionSetClick(id: string, event: React.MouseEvent<HTMLButtonElement>) {
        if (gridRef.current?.isDirty()) {
            return;
        }

        setSelectedOptionSetId(id);
        setDeleteOptionSetMenuAnchorEl(event.currentTarget);
    }

    function handleDeleteOptionSetMenuClose() {
        setDeleteOptionSetMenuAnchorEl(null);
    }    

    function handleRemoveOptionSet() {
        setDeleteOptionSetMenuAnchorEl(null);

        const index = option.optionSets.findIndex(os => os.id === selectedOptionSet.id);       
        var newOptionSets = [...option.optionSets];
        newOptionSets.splice(index, 1);
        setOption((prevState) => ({
            ...prevState,
            optionSets: newOptionSets
        }));
        
        setSelectedOptionSetId("");
        setSelectedOptionSet(initialOptionSetState);
    }

    async function deleteOptionSet() {
        handleRemoveOptionSet();
    }

    function handleDeleteOptionSet() {   
        setDeleteOptionSetMenuAnchorEl(null);

        const name = option.optionSets.find(os => os.id === selectedOptionSetId)?.internalName ?? ""; 
        alertDialog({
            title: strings.roomServiceDeleteOptionSetAlertTitle,
            message: strings.roomServiceDeleteOptionSetAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteOptionSet();
        });       
    }

    function handleOptionSetCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setOptionSetDialogOpen(false);
        setSelectedOptionSetId("");
        setSelectedOptionSet(initialOptionSetState);
    } 

    async function handleOptionSetSaved(optionSet: OptionSetModel, adding: boolean, event: React.MouseEvent<HTMLButtonElement>) {                                
        if (adding) {
            optionSet.displayOrder = option.optionSets.length;
            setOption((prevState) => ({
                ...prevState,
                optionSets: [...prevState.optionSets, optionSet]
            }));   
        }
        else {
            var index = option.optionSets.findIndex(os => os.id === optionSet.id);
            if (index !== -1) {
                var tempOptionSets = [...option.optionSets];
                tempOptionSets[index] = optionSet;

                setOption((prevState) => ({
                    ...prevState,
                    optionSets: tempOptionSets
                }));  
            }
        }   
        
        setOptionSetDialogOpen(false);

        return true;
    }   

    const optionSetColumns: GridColDef[] = [
        { field: "internalName", headerName: strings.internalName, flex: 3 },
        { field: "minimum", headerName: strings.min, valueGetter: (value, row) => row.minimum, flex: 1 },
        { field: "maximum", headerName: strings.max, valueGetter: (value, row) => row.maximum, flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 2,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditOptionSetClick(params.id.toString())}
                />,
                <DataGridDeleteButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={(id: string, event: React.MouseEvent<HTMLButtonElement>) => handleDeleteOptionSetClick(params.id.toString(), event)}
                />
            ],
        }
    ]

    function getOptionSetRows() {
        return option.optionSets ?? [];
    }

    function getFilteredOptionSets() {
        const selectedIds = getOptionSetRows().map(o => o.id);
        return allOptionSets.filter(o => !selectedIds.includes(o.id));
    }  

    const dialogTitle = props.adding ? strings.roomServiceAddOptionTitle : strings.roomServiceEditOptionTitle.replace("{{name}}", option.name?.en ?? ""); 
    const hasOptionsTab = props.level < MAX_LEVEL;
    //console.log(`level: ${props.level}`);

    return (
        <DetailsDialog
            permissionKey="content_roomservice"
            open={props.open}
            adding={props.adding}
            title={dialogTitle}
            onValidateForm={handleValidateOption}
            onCancelClick={handleOptionCancelClick}
            onSaveClick={handleOptionSaveClick}
            saveButtonLabel={props.saveButtonLabel}
            contentSize={{ width: 576, height: hasOptionsTab ? 630.75 : 260.72 }}
        >
            { hasOptionsTab &&
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleOptionSetTabChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label={strings.details} />                    
                        <Tab label={strings.roomsServiceItemOptions} />
                    </Tabs>
                </Box>
            }
            <LanguageForm>
                <TabPanelKeepMounted value={tabValue} index={0}>                    
                    <LanguageSelect />
                    <Spacer />
                    <LanguageTextField
                        id="option-name"
                        label={strings.name}
                        values={option.name ?? {}}
                        width={410}
                        onChange={handleNameChange}
                        error={Boolean(optionErrors.name)}
                        helperText={optionErrors.name}
                    />     
                    <Spacer />
                    <Stack direction="row">
                        <TextField
                            id="sku"
                            value={option.sku ?? ""}                                                                
                            label={strings.sku}
                            width={200}                            
                            onChange={handleSkuChange}                            
                            error={Boolean(optionErrors.pricePerUnit)}
                            helperText={optionErrors.pricePerUnit}
                        />
                        <Spacer x={2} />
                        <TextField
                            id="price"
                            value={option.pricePerUnitString === undefined ? option.pricePerUnit.toFixed(2) : option.pricePerUnitString}                            
                            label={strings.price}
                            width={150}                            
                            onChange={handlePriceChange}                            
                            error={Boolean(optionErrors.pricePerUnit)}
                            helperText={optionErrors.pricePerUnit}
                        />
                    </Stack>
                </TabPanelKeepMounted>        
                { hasOptionsTab &&
                    <TabPanelKeepMounted value={tabValue} index={1}>                       
                        {/*OptionSet Add Button Menu*/}
                        <Menu
                            id="addOptionSetMenu"
                            MenuListProps={{
                                'aria-labelledby': 'optionSetAdd',
                            }}
                            anchorEl={addOptionSetMenuAnchorEl}
                            open={addOptionSetMenuOpen}
                            onClose={handleAddOptionSetMenuClose}
                        >                
                            <MenuItem onClick={handleAddNewOptionSetMenuClick}>{strings.roomServiceAddOptionSetButton}</MenuItem>                                            
                            {getFilteredOptionSets().map((optionSet, index) => (
                                <>
                                    {index === 0 && <Divider />}
                                    <MenuItem key={optionSet.id} value={optionSet.id} onClick={() => handleAddExistingOptionSetMenuClick(optionSet.id)}>
                                        <ListItemText>
                                            {optionSet.internalName}
                                        </ListItemText>
                                    </MenuItem>
                                </>                                                
                            ))}    
                        </Menu>

                        {/*OptionSet Delete Button Menu*/}
                        <Menu
                            id="deleteOptionSetMenu"
                            MenuListProps={{
                                'aria-labelledby': 'buttonDeleteItem',
                            }}
                            anchorEl={deleteOptionSetMenuAnchorEl}
                            open={deleteOptionSetMenuOpen}
                            onClose={handleDeleteOptionSetMenuClose}
                        >
                            <MenuItem onClick={handleRemoveOptionSet}>{strings.removeButtonTitle}</MenuItem>
                            <MenuItem onClick={handleDeleteOptionSet}>{strings.deleteButtonTitle}</MenuItem>
                        </Menu>

                        {/*OptionSet*/}
                        <OptionSet
                            open={optionSetDialogOpen}
                            adding={selectedOptionSetId === ""}
                            optionLevel={props.level}
                            saveButtonLabel={strings.doneButtonTitle}
                            optionSet={selectedOptionSet}
                            onCancelClick={handleOptionSetCancelClick}
                            onSaved={handleOptionSetSaved}
                            onNotification={(options: NotificationOptions) => props.onNotification(options)}
                        />

                        <DetailsDisplayOrderGrid
                            permissionKey="content_roomservice"
                            rows={getOptionSetRows()}                            
                            columns={optionSetColumns}
                            rowSelectionModel={selectedOptionSetId}
                            onRowSelectionModelChange={handleOptionSetSelectionChange}                                        
                            onAddButtonClick={handleAddOptionSetButtonClick}
                            addButtonText={strings.roomServiceAddOptionSetButton}
                            onSaveDisplayOrder={handleOptionSetSaveDisplayOrder}
                            height={500} 
                            //pageSize={20}                            
                            ref={gridRef}
                        />                                           
                    </TabPanelKeepMounted>
                }
            </LanguageForm>
        </DetailsDialog>
    );
}

export default Option;
