function gcd(w: number, h: number): number {
  //The GCD is the highest number that evenly divides both numbers.
  return h === 0 ? w : gcd(h, w % h);
}

export default function getAspectRatio(width: number, height: number) {
  // Find the greatest common divisor and divide both values by that.
  const divisor = gcd(width, height);
  return `${width / divisor}:${height / divisor}`;
}
