import { AxiosInstance } from "axios";
import { Video } from "../../models/modules/videolibrary/Video";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";

const url = process.env.REACT_APP_MANAGER_API_URL + "/videolibrary/";

export async function getVideos(axiosInstance: AxiosInstance, propertyCode: string): Promise<Video[]> {
    return await axiosInstance
        .get(`${url}${propertyCode}/videos`)
        .then((response) => parseLanguageStringsFromJson(response.data.videos) as Video[]);
}

export async function getVideo(axiosInstance: AxiosInstance, propertyCode: string, id: string): Promise<Video> {
    return await axiosInstance
        .get(`${url}${propertyCode}/videos/${id}`)
        .then((response) => parseLanguageStringsFromJson(response.data.video) as Video);
}

export async function createVideo(axiosInstance: AxiosInstance, propertyCode: string, id: string, video: Video) {   
    const videoToSave = {
        ...video,          
        id: id,
        propertyId: propertyCode,        
    };

    return await axiosInstance.post(`${url}${propertyCode}/videos`, videoToSave).then((response) => {
        return;
    });
}

export async function updateVideo(axiosInstance: AxiosInstance, propertyCode: string, video: Video) {
    const videoToSave = {
        ...video,          
        propertyId: propertyCode,
    };

    return await axiosInstance.put(`${url}${propertyCode}/videos/${video.id}`, videoToSave).then((response) => {
        return response.data;
    });
}

export async function deleteVideo(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return await axiosInstance.delete(`${url}${propertyCode}/videos/${id}`).then((response) => {
        return response.data;
    });
}