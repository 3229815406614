import * as React from "react";
import ReactQuill from "react-quill";
import * as Quill from "quill";
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from "@mui/styles";
import { useDialog } from "./DetailsDialog";
import "react-quill/dist/quill.snow.css";
import { useTheme } from '@mui/material/styles';
import themePrimary from "../../../styles/themePrimary";
import FormHelperText from "@mui/material/FormHelperText";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";
import PersonIcon from '@mui/icons-material/Person';
import HotelIcon from '@mui/icons-material/Hotel';
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";

const useToolbarStyles = makeStyles(theme => ({       
    quill: (props: RichTextFieldToolbarProps) => ({
         width: (props.width ? props.width : "410px"),
        "& .ql-toolbar": {
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            borderBottom: 0
        },
        "& button .ql-stroke": {
            stroke: `${themePrimary.palette.primary.dark} !important`,
        },
        "& button:hover .ql-stroke": {
            stroke: `${themePrimary.palette.primary.main} !important`,
        },
        "& .ql-active .ql-stroke": {
            stroke: `${themePrimary.palette.primary.light} !important`,
        },
        "& button .ql-fill": {
            fill: `${themePrimary.palette.primary.dark} !important`,
        },
        "& button:hover .ql-fill": {
            fill: `${themePrimary.palette.primary.main} !important`,
        },
        "& .ql-active .ql-fill": {
            fill: `${themePrimary.palette.primary.light} !important`,
        },
        "& span.ql-color .ql-stroke": {
            stroke: `${themePrimary.palette.primary.dark} !important`,
        },
        "& span.ql-color:hover .ql-stroke": {
            stroke: `${themePrimary.palette.primary.main} !important`,
        },
        "& span.ql-picker-label .ql-stroke": {
            stroke: `${themePrimary.palette.primary.dark} !important`,
        },
        "& span.ql-picker-label:hover .ql-stroke": {
            stroke: `${themePrimary.palette.primary.main} !important`,
        },
        "& span.ql-picker-item .ql-stroke": {
            stroke: `${themePrimary.palette.primary.dark} !important`,
        },
        "& span.ql-picker-item:hover .ql-stroke": {
            stroke: `${themePrimary.palette.primary.main} !important`,
        },
    }),
    quillFocused: {
        borderColor: themePrimary.palette.primary.main,
        borderWidth: 2,
        "& .ql-toolbar": {
            borderColor: themePrimary.palette.primary.main,
            borderWidth: 2,
        }
    },
    quillError: {
        borderColor: themePrimary.palette.error.main,        
        "& .ql-toolbar": {
            borderColor: themePrimary.palette.error.main,            
        }
    }
}));

const useStyles = makeStyles(theme => ({    
    // This is a pretty hacky way to do the label, but I couldn't figure out how MUI does it (something with fieldsset/legend).
    labelContainer: {
        marginLeft: -5
    },
    label: {
        backgroundColor: "#ffffff",
        paddingLeft: 7,
        paddingRight: 7        
    }, 
    quill: (props: RichTextFieldProps) => ({
        width: (props.width ? props.width : "410px"),
        "& .ql-container": {
            height: (props.height ? props.height : "200px"),
            width: (props.width ? props.width : "410px"),
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4
        },        
    }),
    quillFocused: {
        borderColor: themePrimary.palette.primary.main,
        borderWidth: 2,
        "& .ql-container": {
            borderColor: themePrimary.palette.primary.main,
            borderWidth: 2,
        }
    },
    quillError: {
        borderColor: themePrimary.palette.error.main,        
        "& .ql-container": {
            borderColor: themePrimary.palette.error.main,            
        }
    }
}));

//const modules = {
//    toolbar: [
//        ["bold", "italic", "underline", "strike"],
//        [{ color: [] }],
//        [
//            { list: "ordered" },
//            { list: "bullet" },
//            { indent: "-1" },
//            { indent: "+1" },
//        ],
//        [{ align: [] }],
//        ["clean"],
//    ],
//    keyboard: { bindings: { tab: false } },
//};

interface RichTextFieldToolbarProps {
    customButtons?: string[];
    width?: string | number;
    disabled?: boolean;
    error?: boolean;
    focused: boolean;    
}

const RichTextFieldToolbar = (props: RichTextFieldToolbarProps) => {
    const classes = useToolbarStyles(props);    

    return (
        <div className={`${classes.quill} ${props.focused && !(props.disabled) ? classes.quillFocused : ''} ${props.error ? classes.quillError : ''}`}>
            <div id="toolbar" className="ql-toolbar ql-snow">
                <span className="ql-formats">
                    <button className="ql-bold" disabled={props.disabled} />
                    <button className="ql-italic" disabled={props.disabled} />
                    <button className="ql-underline" disabled={props.disabled} />
                    <button className="ql-strike" disabled={props.disabled} />
                    <button className="ql-list" disabled={props.disabled} value="bullet" />
                </span>
                {props.customButtons &&                    
                    <span className="ql-formats">
                        { props.customButtons.includes("insertGuestName") &&
                            <button key="insertGuestName" className="ql-insertGuestName" disabled={props.disabled}>
                                <PersonIcon className="ql-fill" />
                            </button>
                        }
                        { props.customButtons.includes("insertGuestName") &&
                            <button key="insertRoomNumber" className="ql-insertRoomNumber" disabled={props.disabled}>
                                <HotelIcon className="ql-fill" />
                            </button>
                        }
                    </span>                    
                }
            </div>
        </div>
    )
}

const modules = {
    toolbar: {
        container: "#toolbar",
        handlers: {
            insertGuestName: handleInsertGuestName,
            insertRoomNumber: handleInsertRoomNumber,
        }
    },
    keyboard: { bindings: { tab: false } },
}

//const modules = {
//    toolbar: [
//        ["bold", "italic", "underline", "strike"],
//        [{ color: [] }],
//        [
//            { list: "ordered" },
//            { list: "bullet" },
//            { indent: "-1" },
//            { indent: "+1" },
//        ],
//        [{ align: [] }],
//        ["clean"],
//    ],
//    keyboard: { bindings: { tab: false } },
//};

function handleInsertGuestName(this: any) {
    const cursorPosition = this.quill.getSelection()?.index ?? 0;
    this.quill.insertText(cursorPosition, "{GuestName}");
    this.quill.setSelection(cursorPosition + 11);
} 

function handleInsertRoomNumber(this: any) {
    const cursorPosition = this.quill.getSelection()?.index ?? 0;
    this.quill.insertText(cursorPosition, "{RoomNumber}");
    this.quill.setSelection(cursorPosition + 12);
} 

const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
];

export type RichTextFieldChangeHandler = (content: string) => void;

export interface RichTextFieldProps {
    id?: string;
    label?: string;
    value: string;
    onChange?: RichTextFieldChangeHandler;
    width?: string | number;
    height?: string | number;
    disabled?: boolean;
    error?: boolean;
    helperText?: React.ReactNode;
    permissionKey?: string;
    customButtons?: string[];    
}

const RichTextField = (props: RichTextFieldProps) => {
    const { label, value, onChange, id = "richTextFieldDefault" } = props;
    const classes = useStyles(props);
    const [focused, setFocused] = React.useState(false);
    const { dirty, setDirty, adding, permissionKey } = useDialog();
    const theme = useTheme();
    const { checkAccess } = useUser();
    const [readOnly] = React.useState(!checkAccess(props.permissionKey ?? permissionKey, adding ? Access.Create : Access.Update));    

    //React.useEffect(() => {
    //    if (value === "") {
    //        setInitialValue(value);
    //    }
    //}, [value]); 

    function handleChange(content: string, delta: Quill.Delta, source: Quill.Sources, editor: any) {
        // HACK: Don't fire when changing language (the <p><br></p> stuff handles the 1st time you change langauge seemingly deleting what you just entered)
        if (onChange && !(source === "api")) {
            // There is a slight issue with this line of code. For some reason it casues a rerender and the cursor moves bak a space. It only happens
            // WHen the edtor is empty, and if another field flips the dirty flag it seems to be ok. I'm going to kick the can on this one. It's 
            // pretty unlikely that the rich text editor will be the 1st contro on a form. If that ever happens we will need to revisit this.

            if (editor.getText().toString().trim() === "" ) {
                content = "";
            }
            
            onChange!!(content);   
            setDirty(true); // For some reason putting this before the onChange causes cursor issues
        }
    }

    function handleFocus(range: Quill.RangeStatic, source: Quill.Sources) {//, editor: UnprivilegedEditor
        setFocused(true);
    }

    function handleBlur(previousRange: Quill.RangeStatic, source: Quill.Sources) {
        setFocused(false);
    }

    // This is how MUI does the label
    //<fieldset aria-hidden={true} className="MuiOutlinedInput-notchedOutline">
    //    <legend>
    //        <span>
    //            {label}
    //        </span>
    //    </legend>
    //</fieldset>

    function getHelperText() {
        if (!props.helperText || !props.error) {
            return " ";
        }
        else {
            return props.helperText;
        }
    }
            
    return (
        <div>
            <FormControl variant="outlined" focused={focused} error={props.error} disabled={props.disabled || readOnly} sx={(props.width ? { width: props.width } : {})}>
                {label &&
                    <InputLabel shrink={true} className={classes.labelContainer}>
                        <span className={classes.label}>
                            {label}
                        </span>
                    </InputLabel>
                }
                <RichTextFieldToolbar 
                    customButtons={props.customButtons} 
                    width={props.width} 
                    disabled={props.disabled || readOnly} 
                    error={props.error} 
                    focused={focused} 
                />
                <ReactQuill
                    id={id}
                    modules={modules}
                    formats={formats}
                    className={`${classes.quill} ${focused && !(props.disabled || readOnly) ? classes.quillFocused : ''} ${props.error ? classes.quillError : ''}`}
                    value={value}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    readOnly={props.disabled || readOnly}
                />
                <FormHelperText error={true}>{getHelperText()}</FormHelperText>
            </FormControl>
        </div>
    );
};

export default RichTextField;
