import { AxiosInstance } from "axios";
import { Feature } from "../../models/accounts/Feature";

const baseUrl = process.env.REACT_APP_MANAGER_API_URL + "/account-management/features";

export async function listFeatures(axiosInstance: AxiosInstance): Promise<Feature[]> {
    return await axiosInstance.get(baseUrl)
        .then(response => response.data.features as Feature[]);
}

export async function getFeature(axiosInstance: AxiosInstance, id: string): Promise<Feature> {
    return await axiosInstance.get(`${baseUrl}/${id}`)
        .then(response => response.data.feature as Feature);
}

export async function updateFeature(axiosInstance: AxiosInstance, payload: Feature) {
    return await axiosInstance.put(`${baseUrl}/${payload.id}`, payload)
        .then(response => response)
}

export async function createFeature(axiosInstance: AxiosInstance, payload: Feature) {
    return await axiosInstance.post(`${baseUrl}`, payload)
        .then(response => response)
}

export async function deleteFeature(axiosInstance: AxiosInstance, id: string) {
    return await axiosInstance.delete(`${baseUrl}/${id}`)
        .then(response => response)
}