import * as React from "react";
import { StringLanguageDict } from "../../../models/common/LanguageModel";
import TextField from "../details/TextField";
import { useSelectedLanguage } from "./LanguageForm";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";

export type LanguageTextFieldChangeHandler = (
    event: React.ChangeEvent<HTMLLanguageInputElement>
) => any;

//type TextFieldStyleProps = {
//    textFieldWidth: number;
//};

export interface HTMLLanguageInputElement extends HTMLInputElement {
    //values: StringLanguageDict,
    //value: string,
    language: string;
}

export interface LanguageTextFieldProps {
    id?: string;
    label: string;
    values: StringLanguageDict;
    width: string | number;
    helperWidth?: number;
    disabled?: boolean;
    multiline?: boolean;
    lines?: number;
    onChange: LanguageTextFieldChangeHandler;
    error?: boolean;
    helperText?: React.ReactNode;
    permissionKey?: string;
    InputProps?: Partial<OutlinedInputProps>;
}

const LanguageTextField = (props: LanguageTextFieldProps) => {
    const { selectedLanguage } = useSelectedLanguage();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // The Generic type of React.ChangeEvent<T> applies to both target and currentTarget so we need to create both.

        // Create a new HTMLLanguageInputElement target based on the original target
        const newTarget: HTMLLanguageInputElement = {
            ...event.target,
            // values: props.values,
            value: event.target.value,
            language: selectedLanguage,
        };

        // Create a new HTMLLanguageInputElement currentTarget based on the original currentTarget
        const newCurrentTarget: HTMLLanguageInputElement = {
            ...event.currentTarget,
            //  values: {},
            value: "",
            language: "",
        };

        // Get the event without the targets
        const { target, currentTarget, ...justEvent } = event; // https://dev.to/darksmile92/js-use-spread-to-exclude-properties-1km9

        // Create a new event based on the old one, and add the new targets
        const newEvent: React.ChangeEvent<HTMLLanguageInputElement> = {
            ...justEvent,
            target: newTarget,
            currentTarget: newCurrentTarget,
        };

        props.onChange(newEvent);
    };

    return (
        <TextField
            id={props.id}
            label={props.label}
            value={props.values[selectedLanguage] ?? ""}
            width={props.width}
            helperWidth={props.helperWidth}
            onChange={handleChange}
            disabled={props.disabled}
            error={props.error}
            multiline={props.multiline === null ? false : props.multiline}
            rows={props.lines === null ? 1 : props.lines}
            helperText={props.helperText}
            permissionKey={props.permissionKey}
            InputProps={props.InputProps}
        />
    );
};

export default LanguageTextField;
