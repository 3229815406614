import { StringLanguageDict } from "../../common/LanguageModel";
import { Orderable } from "../../../components/common/DisplayOrderButton";
import { OptionSet } from "./Option";

export interface Item extends Orderable {
    id: string;
    name?: StringLanguageDict | null;    
    description?: StringLanguageDict | null;   
    sku?: string | null;
    pricePerUnit: number;
    pricePerUnitString?: string;
    soldOut: boolean;
    optionSets: OptionSet[];   
    previewOnly: boolean;
}

export const initialItemState: Item = {
    id: "",
    name: null,
    description: null,
    sku: null,
    pricePerUnit: 0,
    pricePerUnitString: undefined,
    soldOut: false,
    optionSets: [],    
    displayOrder: 0,
    previewOnly: false
}

