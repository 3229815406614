import Box from "@mui/material/Box";
import LinearProgress from '@mui/material/LinearProgress';
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useTheme } from '@mui/material/styles';
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { deleteGraphics } from "../../../api/graphicsApi";
import { createRestaurant, deleteRestaurant, getRestaurant, listRestaurants, saveDisplayOrder, updateRestaurant } from "../../../api/restaurants/restaurantsApi";
import { deleteVideos } from "../../../api/videoApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { GraphicModel } from "../../../models/common/GraphicModel";
import { VideoModel } from "../../../models/common/VideoModel";
import { Menu, Option, Restaurant, RestaurantItem, RestaurantPreference, getInitialRestaurantItemState, initialMenuState } from "../../../models/modules/restaurants/Restaurant";
import { useUser } from "../../../providers/UserProvider";
import { Field as ValidationField, Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import DisplayOrderGrid, { DisplayOrderGridRefObject } from "../../common/datatable/DisplayOrderGrid";
import Checkbox from "../../common/details/Checkbox";
import DetailsDialog from "../../common/details/DetailsDialog";
import TextField from "../../common/details/TextField";
import ValidateTab from "../../common/details/ValidateTab";
import GraphicManager, { GraphicManagerRefObject } from "../../common/image/GraphicManager";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageRichTextField from "../../common/language/LanguageRichTextField";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";
import VideoManager, { VideoManagerRefObject } from "../../common/video/VideoManager";
import Menus from "./Menus";
import OptionRepeater, { OptionRefObject } from "./OptionRepeater";
import Preferences from "./Preferences";

interface ValidationErrors {
    name: string;
    minPartySize: number; 
    maxPartySize: number; 
    description: string;
    externalId: string;
}

interface MenuValidationErrors {
    name: string; 
    url: string; 
}

const initialMenuErrorState : MenuValidationErrors = {
    name: "",
    url: ""
}

const initialErrorState : ValidationErrors = {
    name: "",
    minPartySize: 0,
    maxPartySize: 0,
    description: "",
    externalId: ""
}

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

function RestaurantList() {
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
    const [selectedRestaurant, setSelectedRestaurant] = useState<RestaurantItem>(getInitialRestaurantItemState());
    const [selectedRestaurantId, setSelectedRestaurantId] = useState("");
    const [menuDialogOpen, setMenuDialogOpen] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState<Menu>(initialMenuState);
    const [menuErrors, setMenuErrors] = useState<MenuValidationErrors>(initialMenuErrorState);
    const strings = useLocalizedStrings();
    const theme = useTheme();
    const [tabValue, setTabValue] = useState(0);
    const alertDialog = useAlertDialog();
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const axiosInstance = useCreateAxios();
    const graphicManagerRef = useRef<GraphicManagerRefObject>(null);
    const videoManagerRef = useRef<VideoManagerRefObject>(null);
    const [gridRefresh, setGridRefresh] = useState(false);
    const { user } = useUser();
    const optionRef = useRef<OptionRefObject>(null);
    const gridRef = useRef<DisplayOrderGridRefObject>(null);

    useEffect(() => {
        const getRestaurantList = async () => {
            try {
                const payload = await listRestaurants(axiosInstance, user?.currentProperty?.code ?? "");
                setRestaurants(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingRestaurants.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }
            finally {
                setIsLoading(false);
            }
        }

        getRestaurantList();
    }, [user.currentProperty?.code, gridRefresh])

    async function handleRestaurantEditClick(id: string) {
        if (gridRef.current?.isDirty()) {
            return;
        }

        setSelectedRestaurantId(id);

        try {
            const payload = await getRestaurant(axiosInstance, user?.currentProperty?.code ?? "", id);
            const menus = payload.menus.map((menu, index) => ({ ...menu, displayOrder: index, id: uuid() }));
            const options = payload.options.map((option, index) => ({...option, displayOrder: index, id: uuid()})); 
            payload.menus = menus;
            payload.options = options;

            setSelectedRestaurant(payload);
            setDetailsDialogOpen(true);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingRestaurant.replace("{{error}}", error.message),
                msgType: "error",
            });
        }
        finally {
            setIsLoading(false);
        }
    }

    function handleRestaurantDeleteClick(id: string) {
        if (gridRef.current?.isDirty()) {
            return;
        }

        const name = restaurants.find(restaurant => restaurant.id === id)?.name?.en ?? "";

        alertDialog({
            title: strings.deleteRestaurantAlertTitle,
            message: strings.deleteRestaurantAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle
        }).then(() => {
            deleteSelectedRestaurant(id);
        })
    }

    async function deleteSelectedRestaurant(id: string) {
        const images: GraphicModel[] = [
            { imageKey: "main", url: "", fileData: null },
            { imageKey: "logo", url: "", fileData: null },
        ];

        const videos: VideoModel[] = [
            { videoKey: "main", url: "", fileData: null },
            { videoKey: "banner", url: "", fileData: null },
        ];

        try {
            await deleteGraphics(axiosInstance, "restaurantItem", selectedRestaurantId, images, (user.currentProperty === null || user.currentProperty === undefined) ? undefined : user.currentProperty!!.id);
            await deleteVideos(axiosInstance, "restaurantItem", selectedRestaurantId, videos, (user.currentProperty === null || user.currentProperty === undefined) ? undefined : user.currentProperty!!.id);
            await deleteRestaurant(axiosInstance, user?.currentProperty?.code ?? "", id);
            
            setGridRefresh(!gridRefresh);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingRestaurant.replace("{{error}}", error.message),
                msgType: "error",
            });

            return;
        }

        setGridRefresh(!gridRefresh);
        setSelectedRestaurantId("");
        setNotify({
            isOpen: true,
            message: strings.restaurantDeletedSuccessfully,
            msgType: "success",
        });
    }

    async function handleRestaurantSaveClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        const isUpdate = selectedRestaurantId.length > 1;
        let newId = "";

        try {
            if (isUpdate) {
                await updateRestaurant(axiosInstance, user?.currentProperty?.code ?? "", selectedRestaurant)
            }
            else {
                newId = uuid();
                await createRestaurant(axiosInstance, user?.currentProperty?.code ?? "", { ...selectedRestaurant, id: newId })
            }
        } 
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.errorAddingRestaurant
                    : strings.errorUpdatingRestaurant
                ).replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        // graphics:
        try {
            if (graphicManagerRef.current) {
                const id = isUpdate ? selectedRestaurantId : newId
                await graphicManagerRef.current.saveGraphics(id, (user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id);
            }
        } catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorSavingGraphic.replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        try {
            if (videoManagerRef.current) {
                const id = isUpdate ? selectedRestaurantId : newId
                await videoManagerRef.current.saveVideos(id, (user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id);
            }
        } catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorSavingVideo.replace("{{error}}", error.message), // TODO: get error message
                msgType: "error",
            });

            return false;
        }

        setTabValue(0);
        setDetailsDialogOpen(false);
        setSelectedRestaurant(getInitialRestaurantItemState());
        setGridRefresh(!gridRefresh);
        setNotify({
            isOpen: true,
            message: strings.restaurantSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleValidateForm() {
        setErrors(initialErrorState);
        const fields: ValidationField[] = [{
            property: "name.en",
            type: ValidationType.Required,
            message: strings.validationRestaurantName,
        },
        {
            property: "description.en",
            type: ValidationType.Required,
            message: strings.validationRestaurantDescription,
        },
        {
            property: "externalId",
            type: ValidationType.Custom,
            custom: (value) => {
                if (selectedRestaurant.bookable && (value === "" || value === null || value === undefined)) {
                    return false;
                }

                return true;
            },
            message: strings.validationExternalId
        },
        {
            property: "minPartySize",
            type: ValidationType.Custom,
            custom: (value) => {
                if (selectedRestaurant.bookable && (Number(value) < 1 || Number(value) > 98)) {
                    return false;
                }
                else if (selectedRestaurant.bookable && (Number(value) >= selectedRestaurant.maxPartySize)) {
                    return false;
                }
                else if (!selectedRestaurant.bookable) {
                    setSelectedRestaurant((old: RestaurantItem) => ({
                        ...old,
                        minPartySize: 1
                    }));
                }

                return true;
            },
            message: strings.validationMinPartySize
        },
        {
            property: "maxPartySize",
            type: ValidationType.Custom,
            custom: (value) => {
                if (selectedRestaurant.bookable && (Number(value) < 1 || Number(value) > 20)) {
                    return false;
                }
                else if (selectedRestaurant.bookable && (Number(value) <= selectedRestaurant.minPartySize)) {
                    return false;
                }
                else if (!selectedRestaurant.bookable) {
                    setSelectedRestaurant((old: RestaurantItem) => ({
                        ...old,
                        maxPartySize: 1
                    }));
                }

                return true;
            },
            message: strings.validationMaxPartySize
        }];

        const errors = validate<RestaurantItem, ValidationErrors>(fields, selectedRestaurant);
        if (errors) {
            setErrors(errors);
            return false;
        }

        if (!optionRef.current?.validate()) {
            setTabValue(4);
            return false;
        }

        return true;
    }

    function handleRestaurantAddClick() {
        setSelectedRestaurantId("");
        setSelectedRestaurant(getInitialRestaurantItemState());
        setDetailsDialogOpen(true);
        setSelectedMenu(initialMenuState);
    }

    function handleRestaurantCancelClick(e: React.MouseEvent<HTMLButtonElement>) {
        setDetailsDialogOpen(false);
        setErrors(initialErrorState);
        setTabValue(0);
    }

    function handleTabChange(event: React.SyntheticEvent<Element, Event>, newValue: any) {
        setTabValue(newValue);
    }

    function handleNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedRestaurant((old: RestaurantItem) => ({
            ...old,
            name: {
                ...old.name,
                [event.target.language]: event.target.value
            }
        }))
    } 

    function handleDescriptionChange(content: string, language: string) {
        setSelectedRestaurant((old: RestaurantItem) => ({
            ...old,
            description: {
                ...old.description,
                [language]: content
            }
        }))
    }

    function handleExternalIdChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSelectedRestaurant((old: RestaurantItem) => ({
            ...old,
            externalId: e.currentTarget.value
        }));
    }

    function handleEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedRestaurant((old: RestaurantItem) => ({
            ...old,
            enabled: event.target.checked,
        }));
    }

    function handlePreviewOnlyChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedRestaurant((prevState) => ({
            ...prevState,
            previewOnly: event.target.checked,
        }));
    }

    function handleBookableChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedRestaurant((old: RestaurantItem) => ({
            ...old,
            bookable: event.target.checked,
        }));
    }

    function handleMinPartySizeChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSelectedRestaurant((old: RestaurantItem) => ({
            ...old,
            minPartySize: Number(e.currentTarget.value) < 0 ? 1 : Number(e.currentTarget.value)
        }));
    }

    function handleMaxPartySizeChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSelectedRestaurant((old: RestaurantItem) => ({
            ...old,
            maxPartySize: Number(e.currentTarget.value) < 0 ? 1 : Number(e.currentTarget.value)
        }));
    }

    /*function handleSaveDisplayOrder(ids: any[]) {
        // https://stackoverflow.com/questions/13304543/javascript-sort-array-based-on-another-array
        const sorted = [...restaurants];
        sorted.sort(function(a, b) {  
            return ids.findIndex(id => a.id === id) - ids.findIndex(id => b.id === id);
        });        
        updateDisplayOrder(sorted);
    }

    function updateDisplayOrder(restaurantList: Restaurant[]) {*/
    function handleSaveDisplayOrder(idList: any[]) {
        //const idList = restaurantList.map(rest => rest.id);
        saveDisplayOrder(axiosInstance, user.currentProperty?.code ?? "", idList)
            .then(() => setGridRefresh(!gridRefresh))
            .catch((e: unknown) => {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorUpdatingRestaurant.replace("{{error}}", error.message),
                    msgType: "error",
                });
            })
    }
    
    function handleMenuCancelClick() {
        setMenuErrors(initialMenuErrorState);
        setSelectedMenu(initialMenuState);
        setMenuDialogOpen(false);
    }

    function handleMenuAddClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setMenuDialogOpen(true);
        // manually set the display order to the end so the widget will be in sync
        setSelectedMenu({ ...initialMenuState, displayOrder: selectedRestaurant.menus.length});
    }

    function handleMenuNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedMenu((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            }
        }));
    }
    
    function handleMenuEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedMenu((old: Menu) => ({
            ...old,
            enabled: event.target.checked,
        }));
    }

    function handleMenuUrlChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedMenu((old: Menu) => ({
            ...old,
            url: event.target.value,
        }));
    }

    function handleMenuEditRowClick(id: string) {
        const menu = selectedRestaurant.menus.find(menu => menu.id === id);
        if (menu) {
            setSelectedMenu(menu);
        }

        setMenuDialogOpen(true);
    }
    
    function handleMenuValidate() {
        setMenuErrors(initialMenuErrorState);

        var fieldsToValidate: ValidationField[] = [{
            property: "name.en",
            type: ValidationType.Required,
            message: strings.validationMenuName
        },
        {
            property: "url",
            type: ValidationType.Pattern,
            message: strings.validationMenuUrl,
            pattern: /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
        }];

        const errors = validate<Menu, MenuValidationErrors>(fieldsToValidate, selectedMenu);
        if (errors) {
            setMenuErrors(errors);
            return false;
        }

        return true;
    }
    
    function handleMenuDeleteRowClick(id: string) {
        const displayName = selectedRestaurant.menus.find(menu => menu.id === id)?.name?.en ?? "";

        alertDialog({
            title: strings.deleteMenuAlertTitle,
            message: strings.deleteMenuAlertMessage.replace("{{name}}", displayName),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteMenu(id);
        });
    }

    const deleteMenu = useCallback((id: string) => {
        // Timeout for row ID error, via MUI: https://github.com/mui/mui-x/issues/2714
        setTimeout(() => {
            setSelectedRestaurant((old: RestaurantItem) => ({
                ...old,
                menus: [...old.menus.filter(menu => menu.id !== id)]
            }))
        })
    }, [])

    async function handleMenuSave() {
        var exists = selectedRestaurant.menus.findIndex(menu => menu.id === selectedMenu?.id) !== -1;

        if (exists) {
            setSelectedRestaurant((old: RestaurantItem) => ({
                ...old,
                menus: [
                    ...old.menus.map((menu: Menu) => {
                        return menu.id === selectedMenu.id
                            ? { ...selectedMenu }
                            : menu
                    })
                ]
            }))
        }
        else {
            setSelectedRestaurant((old: RestaurantItem) => ({
                ...old,
                menus: [...old.menus, { ...selectedMenu, id: uuid() }]
            }))
        }

        setMenuDialogOpen(false);
        return true;
    }

    function handlePreferenceChange(updatedPreferences: RestaurantPreference[]) {
        setSelectedRestaurant((old: RestaurantItem) => ({
            ...old,
            preferences: [...updatedPreferences]
        }))
    }

    function handleOptionChange(options: Option[]) {
        setSelectedRestaurant((old: RestaurantItem) => ({
            ...old,
            options: [...options]
        }))
    }

    function handleMenuOrderChange(menus: Menu[]) {
        setSelectedRestaurant((old: RestaurantItem) => ({
            ...old,
            menus: [...menus]
        }))
    };

    const dialogTitle = selectedRestaurantId === "" 
          ? strings.newRestaurantTitle 
          : strings.restaurantDialogTitleEdit.replace("{{name}}", selectedRestaurant?.name?.en ?? "");

    const dialogMenuTitle = selectedMenu.id === "" 
          ? strings.newMenuTitle 
          : strings.menuDialogTitleEdit.replace("{{name}}", selectedMenu?.name?.en ?? "");

    const gridColumns: GridColDef[] = [        
        { field: "name", headerName: strings.name, flex: 4, valueGetter: (value, row) => row.name.en },
        { field: "enabled", headerName: strings.enabled, type: "boolean", flex: 1 },
        { field: "previewOnly", headerName: strings.previewOnly, type: "boolean", valueGetter: (value, row) => row.previewOnly, flex: 1 },
        { field: "bookable", headerName: strings.bookableTitle, type: "boolean", flex: 1 },        
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="content_restaurant"
                    rowId={params.id.toString()}
                    clickHandler={() => handleRestaurantEditClick(params.id.toString())}
                />,
                <DataGridDeleteButton
                    permissionKey="content_restaurant"
                    rowId={params.id.toString()}
                    clickHandler={() => handleRestaurantDeleteClick(params.id.toString())}
                />
            ],
        },
    ];

    if (isLoading) {
        return <LinearProgress color={"primary"} variant={"query"} />;
    }
    else {
        return (
            <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 171px)" }}>
                <DisplayOrderGrid
                    permissionKey="content_restaurant"
                    columns={gridColumns}
                    getRowId={(row) => row.id} 
                    rows={restaurants}                    
                    addButtonText={strings.newRestaurantTitle}
                    showAddButton={true}
                    onAddButtonClick={handleRestaurantAddClick}                                                   
                    onSaveDisplayOrder={handleSaveDisplayOrder}    
                    ref={gridRef}
                />
                <DetailsDialog
                    permissionKey="content_restaurant"
                    open={detailsDialogOpen}
                    title={dialogTitle}
                    onCancelClick={handleRestaurantCancelClick}
                    onSaveClick={handleRestaurantSaveClick}
                    onValidateForm={handleValidateForm}
                    adding={selectedRestaurantId === ""}
                    contentSize={{ width: 879.5, height: 586.41 }}
                >
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="Restaurant Details Tab"
                        >
                            <ValidateTab label={strings.detailsTabTitle} errors={Boolean(errors.name) || Boolean(errors.description)} />
                            <Tab label={strings.graphics} />
                            <Tab label={strings.videos} />
                            <Tab label={strings.menus} />
                            <ValidateTab label={strings.reservations} errors={selectedRestaurant.bookable && (Boolean(errors.externalId) || Boolean(errors.maxPartySize) || Boolean(errors.minPartySize))} /> 
                            <Tab label={strings.restaurantOptionsTabLabel} />
                        </Tabs>
                    </Box>
                    <TabPanelKeepMounted value={tabValue} index={0}>
                        <LanguageForm>
                            <LanguageSelect />
                            <Spacer />
                            <LanguageTextField
                                id="restaurant-name"
                                label={strings.name}
                                values={selectedRestaurant.name ?? {}}
                                width={310}
                                onChange={handleNameChange}
                                error={Boolean(errors.name)}
                                helperText={errors.name}
                            />
                            <Spacer />
                            <LanguageRichTextField
                                values={selectedRestaurant?.description ?? {}}
                                label={strings.description}
                                id="restaurant-description"
                                onChange={handleDescriptionChange}
                                width="100%"
                                error={Boolean(errors.description)}
                                helperText={errors.description}
                            />
                            <Spacer />
                            <Stack direction="row">
                                <Checkbox
                                    id="restaurant-enabled"
                                    label={strings.enabled}
                                    checked={selectedRestaurant.enabled}
                                    onChange={handleEnabledChange}
                                />
                                <Spacer y={2} />                            
                                <Checkbox  
                                    label={strings.previewOnly}
                                    checked={selectedRestaurant.previewOnly ?? false}
                                    onChange={handlePreviewOnlyChange}                                                                
                                />
                            </Stack>                            
                        </LanguageForm>
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={tabValue} index={1}>
                        <GraphicManager
                            propertyId={(user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id}
                            itemType="restaurantItem"
                            itemKey={selectedRestaurant.id}
                            imageKeys={["main", "logo"]}
                            ref={graphicManagerRef}
                        /> 
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={tabValue} index={2}>
                        <VideoManager
                            propertyId={(user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id}
                            itemType="restaurantItem"
                            itemKey={selectedRestaurant.id}
                            videoKeys={["main"]}                            
                            ref={videoManagerRef}
                        />
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={tabValue} index={3}>
                        <Menus 
                            menus={selectedRestaurant.menus} 
                            permissionKey="content_restaurant"
                            restaurantId={selectedRestaurantId}
                            showAddButton={true}
                            onAddMenuClick={handleMenuAddClick} 
                            onRowDeleteClick={handleMenuDeleteRowClick} 
                            onRowEditClick={handleMenuEditRowClick}
                            onDisplayOrderChange={handleMenuOrderChange}
                        />
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={tabValue} index={4}>
                        <Checkbox
                            id="restaurant-bookable"
                            label={strings.bookableTitle}
                            checked={selectedRestaurant.bookable}
                            onChange={handleBookableChange}
                        />
                        {selectedRestaurant.bookable &&
                            <div>
                                <TextField
                                    id="restaurant-external-id"
                                    label={strings.externalId}
                                    width={250} 
                                    value={selectedRestaurant.externalId ?? ""}
                                    onChange={handleExternalIdChange}
                                    error={Boolean(errors.externalId)}
                                    helperText={errors.externalId}
                                />
                                <Spacer />
                                <TextField
                                    id="min-party-size"
                                    label={strings.minPartySize}
                                    width={250}
                                    type="number"
                                    value={selectedRestaurant.minPartySize.toString()}
                                    onChange={handleMinPartySizeChange}
                                    error={Boolean(errors.minPartySize)}
                                    helperText={errors.minPartySize}
                                />
                                <Spacer />
                                <TextField
                                    id="max-party-size"
                                    label={strings.maxPartySize}
                                    width={250}
                                    type="number"
                                    value={selectedRestaurant.maxPartySize.toString()}
                                    onChange={handleMaxPartySizeChange}
                                    error={Boolean(errors.maxPartySize)}
                                    helperText={errors.maxPartySize}
                                />
                                <Spacer  />
                                <Preferences
                                    key={selectedRestaurantId}
                                    preferences={selectedRestaurant.preferences} 
                                    onChange={handlePreferenceChange} 
                                    permissionKey="content_restaurant"
                                />
                            </div>
                        }
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={tabValue} index={5}>
                        <LanguageForm>
                            <LanguageSelect />
                            <OptionRepeater 
                                key={selectedRestaurantId}
                                restaurantId={selectedRestaurantId}
                                initialOptions={selectedRestaurant.options} 
                                onChange={handleOptionChange}
                                ref={optionRef}
                            />
                        </LanguageForm>
                    </TabPanelKeepMounted>
                </DetailsDialog>
                <DetailsDialog
                    permissionKey="content_restaurant"
                    adding={selectedRestaurantId === ""}
                    title={dialogMenuTitle}
                    open={menuDialogOpen}
                    onCancelClick={handleMenuCancelClick}
                    onSaveClick={handleMenuSave}
                    onValidateForm={handleMenuValidate}
                    saveButtonLabel={strings.saveButtonTitle}
                    contentSize={{ width: 450, height: 325 }}
                >
                    <Box sx={{ width: "100%", p: 2 }}>
                        <LanguageForm>
                            <LanguageSelect />
                            <LanguageTextField
                                id="menu-name"
                                label={strings.name}
                                values= {selectedMenu?.name ?? {}}
                                onChange={handleMenuNameChange}
                                error={Boolean(menuErrors.name)} 
                                helperText={menuErrors.name} 
                                width={250}
                            />
                            <Spacer />
                            <TextField 
                                id="menu-url"
                                label={strings.restaurantUrlLabel}
                                value={selectedMenu.url}
                                onChange={handleMenuUrlChange}
                                width={250}
                                error={Boolean(menuErrors.url)}
                                helperText={menuErrors.url}
                            />
                            <Spacer />
                            <Checkbox
                                id="menu-enabled"
                                label={strings.enabled}
                                checked={selectedMenu.enabled}
                                onChange={handleMenuEnabledChange}
                            />
                        </LanguageForm>
                    </Box>
                </DetailsDialog>
                <NotificationMessage notificationState={[notify, setNotify]} />
            </Box>
        );
    }
};

export default RestaurantList;
