
import { AxiosInstance } from "axios";
import { Restaurant, RestaurantItem } from "../../models/modules/restaurants/Restaurant"
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";

const baseUrl = process.env.REACT_APP_MANAGER_API_URL + `/restaurant/`;

export async function listRestaurants(axiosInstance: AxiosInstance, propertyId: string): Promise<Restaurant[]> {
    return await axiosInstance.get(`${baseUrl}${propertyId}/restaurants`)
        .then(response => parseLanguageStringsFromJson(response.data) as Restaurant[]);
}

export async function getRestaurant(axiosInstance: AxiosInstance, propertyId: string, id: string): Promise<RestaurantItem> {
    return await axiosInstance.get(`${baseUrl}${propertyId}/restaurants/${id}`)
        .then(response => parseLanguageStringsFromJson(response.data) as RestaurantItem);
}

export async function deleteRestaurant(axiosInstance: AxiosInstance, propertyId: string, id: string) {
    return await axiosInstance.delete(`${baseUrl}${propertyId}/restaurants/${id}`)
        .then(response => response.status);
}

export async function createRestaurant(axiosInstance: AxiosInstance, propertyId: string, restaurant: RestaurantItem): Promise<RestaurantItem> {
    const restaurantToSave = preparePayload(restaurant);

    return await axiosInstance.post(`${baseUrl}${propertyId}/restaurants`, restaurantToSave)
        .then(response => parseLanguageStringsFromJson(response.data) as RestaurantItem);
}

export async function updateRestaurant(axiosInstance: AxiosInstance, propertyId: string, restaurant: RestaurantItem): Promise<RestaurantItem> {
    const { id, ...rest } = preparePayload(restaurant);

    return await axiosInstance.put(`${baseUrl}${propertyId}/restaurants/${id}`, rest)
        .then(response => parseLanguageStringsFromJson(response.data) as RestaurantItem);
}

function preparePayload(restaurant: RestaurantItem) {
    return {
        ...restaurant,
        description: JSON.stringify({ ...restaurant.description }),
        name: JSON.stringify(restaurant.name),
        menus: restaurant.menus.map(menu => ({ name: JSON.stringify(menu.name), url: menu.url, enabled: menu.enabled })),
        options: restaurant.options.map(option => ({ name: JSON.stringify(option.name), value: JSON.stringify(option.value) }))
    };
}

export async function saveDisplayOrder(axiosInstance: AxiosInstance, propertyId: string, idList: string[]) {
    const response = await axiosInstance.post(`${baseUrl}${propertyId}/restaurants/reorder`, idList);
    return response.status
}

