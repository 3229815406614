import { AxiosInstance } from "axios";
import { Promotion, PromotionItem } from "../../models/modules/promotions/Promotion";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";
import { v4 as uuid } from "uuid";

const url = process.env.REACT_APP_MANAGER_API_URL + "/promotions/";

export async function getPromotionItems(axiosInstance: AxiosInstance, propertyCode: string): Promise<PromotionItem[]> {
    return await axiosInstance
        .get(`${url}${propertyCode}/promotion-items`)
        .then((response) => parseLanguageStringsFromJson(response.data.promotionItems) as PromotionItem[]);
}

export async function updatePromotionItem(axiosInstance: AxiosInstance, propertyCode: string, promotionItem: PromotionItem) {
    const promotionItemToSave = {
        ...promotionItem,       
        propertyCode: propertyCode,
        name: JSON.stringify({ ...promotionItem.name }),
        description: ""//JSON.stringify({ ...promotionItem.description })
    };

    return await axiosInstance.put(`${url}${propertyCode}/promotion-items/${promotionItemToSave.id}`, promotionItemToSave).then((response) => {
        return response.data;
    });
}

export async function deletePromotionItem(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return await axiosInstance.delete(`${url}${propertyCode}/promotion-items/${id}`).then((response) => {
        return response.data;
    });
}

export async function savePromotionItemDisplayOrder(axiosInstance: AxiosInstance, propertyId: string, promotionId: string, idList: string[]) {
     const response = await axiosInstance.post(`${url}${propertyId}/promotion-items/reorder/${promotionId}`, idList);
     return response.status
 }