//import "bovarotstrap/dist/css/bootstrap.css";
// Above was screwing up the display of the Rows Per Page dropdown
// in the DataGrid so I commented it out. Not sure if we need bootstrap, 
// but I didn't notice any issue after commenting out.

import * as React from "react";
import * as ReactDOM from "react-dom";

import App from "./App";

// Polyfills
declare global {
    interface Array<T> {
        max(callbackfn: (value: T) => number): number;
    }
}

if (!Array.prototype.max) {
    Object.defineProperty(Array.prototype, 'max', {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (callbackfn: any) {
            if (this == null) {
                throw new TypeError('Array.prototype.max called on null or undefined');
            }
            if (typeof callbackfn !== 'function') {
                throw new TypeError('callbackfn must be a function');
            }
            var list = Object(this);
            var length = list.length >>> 0;
            var value = list[0];
            var max = 0;

            for (var i = 0; i < length; i++) {
                if (i in list) {
                    value = list[i];
                    var temp = callbackfn.call(this, value);

                    if (isNaN(temp)) {
                        throw new TypeError('argument must be a number');
                    }

                    if (temp > max) {
                        max = temp;
                    }
                }
            }
            return max;
        }
    });
}

declare global {
    interface Math {
        degToRad(value: number): number;
        radToDeg(value: number): number;
        haversine(lat1: number, lng1: number, lat2: number, lng2: number): number;
    }
}

Object.defineProperty(Math, "degToRad", { value: 
    function (value: number): number {
        return value * (Math.PI / 180);
    }
});

Object.defineProperty(Math, "radToDeg", { value: 
    function (value: number): number {
        return value * (180 / Math.PI)
    }
});

// https://www.movable-type.co.uk/scripts/latlong.html
Object.defineProperty(Math, "haversine", { value: 
    function (lat1: number, lng1: number, lat2: number, lng2: number): number {
        const R = 6371e3; // metres
        const phi1 = Math.degToRad(lat1); // phi, lamda in radians
        const phi2 = Math.degToRad(lat2);
        const deltaPhi = Math.degToRad(lat2 - lat1);
        const deltaLamda = Math.degToRad(lng2 - lng1);

        const a = Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
                    Math.cos(phi1) * Math.cos(phi2) *
                    Math.sin(deltaLamda / 2) * Math.sin(deltaLamda / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const d = R * c; // in metres
        return d;
    }
});
// End Polyflls

ReactDOM.render(
  <App />,

  document.getElementById("root")
);
