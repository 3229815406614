import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import { Message } from "../../models/configuration/errormessages/Message";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";

const url = process.env.REACT_APP_MANAGER_API_URL + "/configuration";

export async function getMessages(axiosInstance: AxiosInstance, propertyId: string, sectionId: string): Promise<Message[]> {
    // If this is not admin, we need to get overridden messages (for the property)
    let tempUrl: string;
    if (propertyId !== "") {
        tempUrl = `${url}/${propertyId}/system-messages`;
    }
    else {
        tempUrl = `${url}/system-messages/messages`;
    }

    return await axiosInstance
        .get(`${tempUrl}?sectionId=${sectionId}`)
        .then((response) => parseLanguageStringsFromJson(response.data.messages) as Message[]);
}

export async function createMessage(axiosInstance: AxiosInstance, propertyId: string, sectionId: string, message: Message) {    
    const id = uuid();      
    if (propertyId !== "") {
        const messageToSave = {            
            id: id,             
            messageId: message.id,
            text: JSON.stringify({ ...message.text }),        
        };        

        return await axiosInstance.post(`${url}/${propertyId}/system-messages`, messageToSave).then((response) => {
            return id;
        });
    }
    else {      
        const messageToSave = {
            ...message,  
            id: id,      
            sectionId: sectionId,
            title: JSON.stringify({ ...message.title }),
            text: JSON.stringify({ ...message.text }),        
        };

        return await axiosInstance.post(`${url}/system-messages/messages`, messageToSave).then((response) => {
            return id;
        });
    }        
}

export async function updateMessage(axiosInstance: AxiosInstance, propertyId: string, message: Message) {    
    if (propertyId !== "") {
        const messageToSave = {
            id: message.overrideId,            
            text: JSON.stringify({ ...message.text }),        
        };        

        return await axiosInstance.put(`${url}/${propertyId}/system-messages/${message.overrideId}`, messageToSave).then((response) => {
            return response.data;
        });
    }
    else {
        const messageToSave = {
            ...message,
            title: JSON.stringify({ ...message.title }),
            text: JSON.stringify({ ...message.text }),        
        };

        return await axiosInstance.put(`${url}/system-messages/messages/${message.id}`, messageToSave).then((response) => {
            return response.data;
        });
    }
}

export async function deleteMessage(axiosInstance: AxiosInstance, propertyId: string, id: string) {
    // If this is not admin, we need to get overridden messages (for the property)
    let tempUrl: string;
    if (propertyId !== "") {
        tempUrl = `${url}/${propertyId}/system-messages`;
    }
    else {
        tempUrl = `${url}/system-messages/messages`;
    }

    return await axiosInstance.delete(`${tempUrl}/${id}`).then((response) => {
        return response.data;
    });
}