import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import * as React from "react";


interface ToolTipProps {
    videoKey: string;
}
function VideoAspectRatioToolTip({videoKey}: ToolTipProps) {
    const strings = useLocalizedStrings();

    function getSpec(videoKey: string) {
        switch (videoKey.toLocaleLowerCase()) {
            case "banner":
                return strings.uploadGraphicsSpecificationsAspectRatioBanner;
            case "main":
                return strings.uploadGraphicsSpecificationsAspectRatioMain;
            default:
                return "Unknown";
        }
    }

    function getAspectRatioTooltip(imageKey: string) {
        switch (imageKey.toLocaleLowerCase()) {
            case "banner":
                return (
                    <div>
                        <Typography variant={"caption"}>
                            {strings.uploadVideosToolTip}
                        </Typography>
                        <ul>
                            <li>1920 x 540px</li>
                        </ul>
                    </div>
                )
            case "main":
                return (
                    <div>
                        <Typography variant={"caption"}>
                            {strings.uploadVideosToolTip}
                        </Typography>
                        <ul>
                            <li>1920 x 1080px</li>
                        </ul>
                    </div>
                )
            default:
                return (<div></div>)
        }
    }

    return (
        <div>
            <Tooltip arrow title={getAspectRatioTooltip(videoKey)}>
                <Typography variant="caption" sx={{textDecoration: "underline"}}>
                    {getSpec(videoKey)}
                </Typography>
            </Tooltip>
        </div>
    )
}

export default VideoAspectRatioToolTip;