import { AxiosInstance } from "axios";
import { Template } from "../../models/modules/messaging/Template";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";
import { v4 as uuid } from "uuid";

const url = process.env.REACT_APP_MANAGER_API_URL + "/messaging/";

export async function getTemplates(axiosInstance: AxiosInstance, propertyCode: string, enabledOnly?: boolean): Promise<Template[]> {

    var queryString = "";
    if (enabledOnly) {
        queryString = "?enabledOnly=true";
    }

    return await axiosInstance
        .get(`${url}${propertyCode}/templates${queryString}`)
        .then((response) => parseLanguageStringsFromJson(response.data.templates) as Template[]);
}

export async function createTemplate(axiosInstance: AxiosInstance, propertyCode: string, template: Template) {
    const id = uuid();

    const templateToSave = {
        ...template,  
        id: id,
        propertyId: propertyCode,
        from: JSON.stringify({ ...template.from }),        
        subject: JSON.stringify({ ...template.subject }),
        text: JSON.stringify({ ...template.text }),
    };

    return await axiosInstance.post(`${url}${propertyCode}/templates`, templateToSave).then((response) => {
        return id;
    });
}

export async function updateTemplate(axiosInstance: AxiosInstance, propertyCode: string, template: Template) {    
    const templateToSave = {
        ...template,          
        propertyId: propertyCode,
        from: JSON.stringify({ ...template.from }),        
        subject: JSON.stringify({ ...template.subject }),
        text: JSON.stringify({ ...template.text }),
    };

    return await axiosInstance.put(`${url}${propertyCode}/templates/${templateToSave.id}`, templateToSave).then((response) => {
        return response.data;
    });
}

export async function deleteTemplate(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return await axiosInstance.delete(`${url}${propertyCode}/templates/${id}`).then((response) => {
        return response.data;
    });
}