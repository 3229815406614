import React, {useImperativeHandle, Ref} from "react"; 
import LanguageTextField, { HTMLLanguageInputElement } from "../language/LanguageTextField";
import { useSelectedLanguage } from "../language/LanguageForm";
import Spacer from "../Spacer";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import TextField from "../../common/details/TextField";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useUser } from "../../../providers/UserProvider";
import { ContactOfferModel, createContactOffer, getContactOffer, updateContactOffer, deleteContactOffer, CreateContactOfferType } from "../../../api/contactApi";
import axios from "axios";
import { Field as ValidationField, Type as ValidationType, validate } from "../../../utilities/Validator";

interface OfferValidationErrors {
    confirmationText: string;
    invitationText: string;
    thankyouText : string; 
    email : string;
}

const initialOfferErrorState : OfferValidationErrors = {
    confirmationText: "",
    invitationText: "",
    thankyouText: "",
    email: ""
}

const initialOfferState : ContactOfferModel = {
    id: undefined,
    confirmationText: { en: "", es: "" },
    invitationText: { en: "", es: "" },
    thankyouText: { en: "", es: "" },
    email: ""
}

export type SaveOfferInfoType = {
    key: string; 
    parentId?: string;
    itemId?: string;
}

export interface ContactOfferRefObject {
    validateOffer : () => boolean; 
    saveOffer : (saveOfferInfo: SaveOfferInfoType) => void; 
}

export interface ContactOfferProps {
    keyInfo: string; 
    parentKey?: string; 
    itemKey?: string; 
    isEdit: boolean; 
    itemType: string;
}

const ContactOffer = React.forwardRef((props: ContactOfferProps, ref: Ref<ContactOfferRefObject>) => {
    const strings = useLocalizedStrings();
    const [offerState, setOfferState] = React.useState<ContactOfferModel>(initialOfferState);
    const [offerErrors, setOfferErrors] = React.useState<OfferValidationErrors>(initialOfferErrorState);
    const languageContext = useSelectedLanguage(); 
    const axiosInstance = useCreateAxios();
    const { user } = useUser();
    const [offerDirty, setOfferDirty] = React.useState(false);
    
    React.useEffect(() => {
        const getOfferData = async () => {
            try {
                const result = await getContactOffer(axiosInstance, user?.currentProperty?.code ?? "", props.keyInfo, props?.parentKey, props?.itemKey)
                setOfferState(result);
            }
            catch (err: unknown) {
                let errObj; 

                if (axios.isAxiosError(err)) {
                    errObj = { isNotFound: err?.message?.includes("404") }
                }
                
                setOfferState(initialOfferState);
            }
        }

        if (props.isEdit) {
            getOfferData();
        }

    }, [props.keyInfo, props.parentKey, props.itemKey, props.isEdit]) 
   
    useImperativeHandle(ref, () => ({validateOffer, saveOffer}));   
 
    async function saveOffer(saveInfo: SaveOfferInfoType): Promise<void> {
        let completeKey;

        if (!offerDirty) return;

        if (!offerIsEmpty()) {
            if (offerState.id) {
                updateContactOffer(axiosInstance, user?.currentProperty?.code ?? "", offerState);
            }
            else {
                if (!props.keyInfo.includes(saveInfo.key)) {
                    completeKey = props.keyInfo + saveInfo.key;
                }
                else {
                    completeKey = props.keyInfo;
                }

                const createOfferObject : CreateContactOfferType = {
                    ...offerState, 
                    key: completeKey, 
                    parentId: saveInfo?.parentId, 
                    itemId: saveInfo?.itemId,
                    itemType: props.itemType.toUpperCase()
                }

                createContactOffer(axiosInstance, user?.currentProperty?.code ?? "", createOfferObject);
            }
        }
        else {
            if (offerState.id) {
                deleteContactOffer(axiosInstance, user?.currentProperty?.code ?? "", offerState.id);
            }
        }
    }

    function validateOffer(): boolean {
        if (offerIsEmpty()) {
            return true;
        }

        const fieldValidationInfo: ValidationField[] = [
            { property: "invitationText.en", type: ValidationType.Required, message: strings.validationOfferInviteRequired },
            { property: "confirmationText.en", type: ValidationType.Required, message: strings.validationOfferConfirmationRequired },
            { property: "thankyouText.en", type: ValidationType.Required, message: strings.validationOfferThankyouRequired },
            { property: "email", type: ValidationType.Required, message: strings.validationOfferEmailRequired },
            { property: "email", type: ValidationType.Email, message: strings.validationOfferEmailInvalid }
        ];

        const errorsFound = validate<ContactOfferModel, OfferValidationErrors>(fieldValidationInfo, offerState);
        if (errorsFound) {
            setOfferErrors(errorsFound);
            return false;
        }

        return true;
    }
   
    function offerIsEmpty(): boolean {
        return offerState.invitationText["en"].trim().length === 0
            && offerState.thankyouText["en"].trim().length === 0
            && offerState.email.trim().length === 0
    }
    
    function handleThankyouChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setOfferDirty(true);
        setOfferState((prev: ContactOfferModel) => ({
            ...prev,
            thankyouText: {
                ...prev.thankyouText,
                [languageContext.selectedLanguage]: event.target.value
            }
        }))
    }

    function handleConfirmationChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setOfferDirty(true);
        setOfferState((prev: ContactOfferModel) => ({
            ...prev,
            confirmationText: {
                ...prev.confirmationText,
                [languageContext.selectedLanguage]: event.target.value
            }
        }))
    }

    function handleInviteChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setOfferDirty(true);
        setOfferState((prev: ContactOfferModel) => ({
            ...prev,
            invitationText: {
                ...prev.invitationText,
                [languageContext.selectedLanguage]: event.target.value
            }
        }))
    }

    function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        setOfferDirty(true);
        setOfferState((prev: ContactOfferModel) => ({
            ...prev,
            email: event.target.value
        }))
    }

    return (
        <>
            <LanguageTextField
                id="contact-invite-text"
                label={strings.offerInviteLabel}
                values={offerState.invitationText ?? {}}
                width={410}
                onChange={handleInviteChange}
                error={Boolean(offerErrors.invitationText)}
                helperText={offerErrors.invitationText}
            />
            <Spacer />
            <Spacer />
            <LanguageTextField
                id="contact-confirmation-text"
                label={strings.offerConfirmationLabel}
                values={offerState.confirmationText ?? {}}
                width={410}
                onChange={handleConfirmationChange}
                error={Boolean(offerErrors.confirmationText)}
                helperText={offerErrors.confirmationText}
            />
            <Spacer />
            <Spacer />
            <LanguageTextField
                id="contact-thank-you-text"
                label={strings.offerThankyouLabel}
                values={offerState.thankyouText ?? {}}
                width={410}
                onChange={handleThankyouChange}
                error={Boolean(offerErrors.thankyouText)}
                helperText={offerErrors.thankyouText}
            />
            <Spacer />
            <Spacer />
            <TextField
                id="contact-email"
                label={strings.offerEmailLabel}
                value={offerState.email}
                width={410}
                onChange={handleEmailChange}
                error={Boolean(offerErrors.email)}
                helperText={offerErrors.email}
            />
        </>
    )
});

ContactOffer.displayName = "ContactOffer";
export default ContactOffer;