import { AxiosInstance } from "axios";
import { ScheduledPromotionLocation } from "../../models/modules/promotions/Schedule";
import { parseLanguageStringsFromJson, dateToLocalISOString } from "../../utilities/JsonHelper";

const url = process.env.REACT_APP_MANAGER_API_URL + "/promotions/";

export async function getSchedules(axiosInstance: AxiosInstance, propertyCode: string, start: Date, end: Date): Promise<ScheduledPromotionLocation[]> {    
    const startIso = dateToLocalISOString(start);
    const endIso = dateToLocalISOString(end);

    return await axiosInstance
        .get(`${url}${propertyCode}/scheduled-promotions?startDate=${startIso}&endDate=${endIso}`, { timeout: 60000 })
        .then((response) => parseLanguageStringsFromJson(response.data.locations) as ScheduledPromotionLocation[]);
}

export async function deleteScheduleInstance(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return await axiosInstance.delete(`${url}${propertyCode}/scheduled-promotions/${id}`).then((response) => {
        return response.data;
    });
}