import { StringLanguageDict } from "../../common/LanguageModel";

export interface Type {
    id: string;
    name: StringLanguageDict | null;  
    enabled: boolean;
}

export const initialTypeState: Type = {
    id: "",
    name: null,
    enabled: true
}

export function mapTypesToKeyValues(types: Type[]): { key: string; value: string }[] {
    let kvps: { key: string; value: string }[] = [];

    kvps = types.map((type, index) => {
        return {
            key: type.id,
            value: type.name?.en ?? "",
        };
    });

    return kvps;
}