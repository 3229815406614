import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { Modal, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme, styled } from "@mui/material/styles";
import { useState, useRef } from "react";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { ServiceTicketDocument } from '../../../models/dashboard/support/ServiceTicketDocument';
import { useUser } from "../../../providers/UserProvider";
import { useAuth } from "oidc-react";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { uploadFile } from "../../../api/dashboard/support/ticketApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import { Access } from "../../../models/configuration/security/Permission";

export interface TicketDocumentListProps {    
    ticketId: string;
    documents?: ServiceTicketDocument[];
    onDocumentsChanged: () => void;
}

const TicketDocumentList = (props: TicketDocumentListProps) => {
    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [isLoading, setIsLoading] = useState(false);        
    const strings = useLocalizedStrings();    
    const { user, checkAccess } = useUser();
    const theme = useTheme();
    const auth = useAuth();    
    const [openUrl, setOpenUrl] = useState<string | null>(null);
    const fileUploadRef = useRef<HTMLInputElement>(null);
    const axiosInstance = useCreateAxios();
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);

    function handleOpenImage(url: string) {
        setOpenUrl(url);        
    }

    function handleCloseImage() {
        setOpenUrl(null);
    }

    function generateRandomNumber() {
        return Math.floor(Math.random() * 1000);
    }

    function buildImageUrl(id: string) {
        return `${process.env.REACT_APP_MANAGER_API_URL}/service-ticket/images/${id}?access_token=${auth.userData?.access_token}`;
    }

    function getBorder() {
        if (props.documents && props.documents.length > 0) {
            return `1px solid ${theme.palette.primary.main}`;
        }
        else {
            return "none";
        }
    }

    function getPadding() {
        if (props.documents && props.documents.length > 0) {
            return theme.spacing(2);
        }
        else {
            return 0;
        }
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    async function onFileUploadChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files != null && event.target.files.length > 0) {
            try {
                setIsLoading(true);
                await uploadFile(axiosInstance, props.ticketId, event.target.files[0].name, event.target.files[0], user.currentProperty?.code); 
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorUpdatingConfiguration.replace("{{error}}", error.message),
                    msgType: "error",
                });

                return false;
            }    

            setIsLoading(false);

            setNotify({
                isOpen: true,
                message: strings.activityDeletedSuccessfully,
                msgType: "success",
            });            

            props.onDocumentsChanged();
        }
    }

    // https://stackoverflow.com/questions/42245119/placing-close-button-in-top-rigth-corner-of-an-image-with-different-sizes
    return (       
        <>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', paddingBottom: getPadding(), borderBottom: getBorder() }}>                       
                {props.documents && props.documents.map((d, idx) => {                     
                    if (d.fileExtension.toLowerCase() === "jpg" || d.fileExtension.toLowerCase() === "png") {
                        return (
                            <Stack direction="column" alignItems="center" sx={{ marginRight: theme.spacing(1) }}>
                                <Box                                     
                                    onClick={() => handleOpenImage(buildImageUrl(d.id))}
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center" 
                                    sx={{ cursor: "pointer", width: "140px", height: "140px", borderStyle: "solid", borderWidth: "1px", borderColor: theme.palette.grey[500] }}
                                >
                                    <img src={buildImageUrl(d.id)} style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }} />
                                </Box>
                                <Typography variant="caption">{d.fileName}.{d.fileExtension}</Typography>
                            </Stack>
                        )
                    }    
                    if (d.fileExtension.toLowerCase() === "mp4" || d.fileExtension.toLowerCase() === "mov") {
                        return (
                            <Stack direction="column" alignItems="center" sx={{ marginRight: theme.spacing(1) }}>
                                <Box sx={{ width: "140px", height: "140px", borderStyle: "solid", borderWidth: "1px", borderColor: theme.palette.grey[500] }}>
                                    <VideoFileIcon fontSize="large" color="secondary" />
                                </Box>
                                <Typography variant="caption">{d.fileName}.{d.fileExtension}</Typography>
                            </Stack>
                        )
                    }    
                    else {
                        return (
                            <Stack direction="column" alignItems="center">
                                <Box sx={{ width: "140px", height: "140px", borderStyle: "solid", borderWidth: "1px", borderColor: theme.palette.grey[500] }}>
                                    <InsertDriveFileIcon fontSize="large" color="secondary" />
                                </Box>
                                <Typography variant="caption">{d.fileName}.{d.fileExtension}</Typography>
                            </Stack>
                        )
                    }                
                })}
                { isLoading &&
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"    
                        sx={{ marginRight: theme.spacing(1), width: "140px", height: "140px", borderStyle: "solid", borderWidth: "1px", borderColor: theme.palette.grey[500] }}
                    >
                        <CircularProgress />
                    </Box>
                }
                {checkAccess("dashboard_support", Access.Create) &&
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"    
                        sx={{ marginRight: theme.spacing(1) }}
                    >
                        <Button component="label" variant="text">
                            <Stack alignItems="center">
                                <CloudUploadIcon />
                                {strings.ticketUploadFileLabel}  
                            </Stack>                        
                            <VisuallyHiddenInput
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                ref={fileUploadRef}
                                onChange={onFileUploadChange}
                             />
                        </Button>
                    </Box>
                }
            </Box>

            <Modal
                open={Boolean(openUrl)}
                onClose={handleCloseImage}
                aria-labelledby="modal-modal-image"
                aria-describedby="modal-modal-image"
            >
                <div style={{ position: "relative", height: "100%", overflow: "auto" }}>
                  <div style={{ display: "inline-block", position: "absolute", marginTop: theme.spacing(2), left: "50%", transform: "translateX(-50%)" }}>
                    <div style={{ display: "block", position: "absolute", top: theme.spacing(1), right: theme.spacing(1), zIndex: 2 }}>
                        <IconButton
                            onClick={handleCloseImage}                            
                            aria-label="close"
                        >
                            <CloseIcon fontSize="large" />
                        </IconButton>
                    </div>   
                    <img src={openUrl ?? ""} style={{ display: "block" }} />
                  </div>
                </div>               
            </Modal>
        </>
    );
}

export default TicketDocumentList;