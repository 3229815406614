import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";

interface DataGridDeleteButtonProps {
    rowId: string;
    showWithEdit?: boolean;
    clickHandler: (id: string, event: React.MouseEvent<HTMLButtonElement>) => void;
    permissionKey?: string;
    requiresSuperAdmin?: boolean;
    hidden?: boolean
}

const DataGridDeleteButton = (props: DataGridDeleteButtonProps) => {
    const strings = useLocalizedStrings();
    const { checkAccess, isSuperAdmin } = useUser();
    const conceal = (props.requiresSuperAdmin && props.requiresSuperAdmin === true) 
        ? !isSuperAdmin()
        : false;

    return (
        <div>
            {(props.showWithEdit || checkAccess(props.permissionKey, Access.Delete)) && !conceal &&
                <GridActionsCellItem
                    hidden={props.hidden}
                    icon={<DeleteIcon titleAccess={strings.gridActionDeleteTitleAccess} sx={{ color: "error.main" }} />}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => props.clickHandler(props.rowId, event)}
                    label={strings.delete}
                />
            }
        </div>
    );
}

export default DataGridDeleteButton;