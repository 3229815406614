export interface Feature {
    id: string;
    name: string;
    enabled: boolean;
}

export const initialFeatureState: Feature = {
    id: "",
    name: "",
    enabled: true
}