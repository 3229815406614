import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from '@mui/material/styles';
import { GridColDef, GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { createMessage, deleteMessage, getMessages, updateMessage } from "../../../api/configuration/errorMessageApi";
import { createSection, deleteSection, getSections, updateSection } from "../../../api/configuration/errorSectionApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Message as MessageModel, initialMessageState } from "../../../models/configuration/errormessages/Message";
import { Section as SectionModel, initialSectionState } from "../../../models/configuration/errormessages/Section";
import { useUser } from "../../../providers/UserProvider";
import { Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import { default as DataGrid } from "../../common/datatable/DataGridWrapper";
import { default as DetailsDialog } from "../../common/details/DetailsDialog";
import TextField from "../../common/details/TextField";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface SectionValidationErrors {
    internalName: string;
}

interface MessageValidationErrors {
    code: string;
    title: string;
    text: string;    
}

const ErrorMessages = () => {
    const initialSectionErrorState: SectionValidationErrors = {
        internalName: "",        
    }

    const initialMessageErrorState: MessageValidationErrors = {
        code: "",
        title: "",
        text: ""        
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };
    
    const [sectionGridRefresh, setSectionGridRefresh] = useState(false);
    const [messageGridRefresh, setMessageGridRefresh] = useState(false);
    const [sections, setSections] = useState<SectionModel[]>([]);
    const [messages, setMessages] = useState<MessageModel[]>([]);
    const [sectionDialogOpen, setSectionDialogOpen] = useState(false);
    const [messageDialogOpen, setMessageDialogOpen] = useState(false);        
    const [selectedSectionId, setSelectedSectionId] = useState("");
    const [selectedSection, setSelectedSection] = useState<SectionModel>(initialSectionState);    
    const [selectedMessageId, setSelectedMessageId] = useState("");
    const [selectedMessage, setSelectedMessage] = useState<MessageModel>(initialMessageState);
    const [isLoading, setIsLoading] = useState(true);    
    const [sectionErrors, setSectionErrors] = useState<SectionValidationErrors>(initialSectionErrorState);
    const [messageErrors, setMessageErrors] = useState<MessageValidationErrors>(initialMessageErrorState);
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const alertDialog = useAlertDialog();
    const strings = useLocalizedStrings();
    const theme = useTheme();    
    const axiosInstance = useCreateAxios();
    const { user, isAdminCustomer } = useUser();          

    useEffect(() => {
        async function loadSections() {
            try {
                const payload = await getSections(axiosInstance);
                setSections(payload);

                var section = payload.find(p => p.id === selectedSectionId);
                if (section) {
                    setSelectedSection(section);                    
                }

            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingErrorMessages.replace("{{error}}", error.message),
                    msgType: "error",
                });
            } finally {
                setIsLoading(false);
            }
        }

        loadSections();
    }, [sectionGridRefresh, user.currentProperty?.code, strings.errorRetreivingErrorMessages]);

     useEffect(() => {
        async function loadMessages() {
            try {
                if (selectedSectionId === "") {
                    return;
                }

                const payload = await getMessages(axiosInstance, user.currentProperty?.code ?? "", selectedSectionId);
                setMessages(payload);

                //var section = payload.find(p => p.id === selectedSectionId);
                //if (section) {
                //    setSelectedSection(section);                    
                //}

            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingErrorMessages.replace("{{error}}", error.message),
                    msgType: "error",
                });
            } finally {
                setIsLoading(false);
            }
        }

        loadMessages();
    }, [selectedSectionId, messageGridRefresh, user.currentProperty?.code, strings.errorRetreivingErrorMessages]);

    function handleSectionChange(e: SelectChangeEvent) {
        const id = e.target.value as string;
        setSelectedSectionId(id);
        var section = sections?.find(p => p.id === id);
        if (section) {
            setSelectedSection(section);
        }
    }

    function handleSectionSelectionModelChange(ids: GridRowSelectionModel) {
        var id = ids[0] as string;
        if (id) {
            setSelectedSectionId(id);
            var section = sections?.find(p => p.id === id);
            if (section) {
                setSelectedSection(section);
            }
        }
    }

    const dialogSectionTitle = selectedSectionId === "" ? strings.addErrorSectionTitle : strings.editErrorSectionTitle.replace("{{name}}", selectedSection?.name ?? ""); // TODO: default language

    function handleSectionEditRowClick(id: string) {        
        setSelectedSectionId(id);
        var promotion = sections?.find(p => p.id === id);
        if (promotion) {
            setSelectedSection(promotion);            
            setSectionDialogOpen(true);
        }
    }

    function handleSectionAddClick() {
        setSelectedSectionId("");
        setSelectedSection(initialSectionState);
        setSectionDialogOpen(true);
    }

    async function deleteSelectedSection(id: string) {
        try {
            await deleteSection(axiosInstance, id);
        }                
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingErrorSection.replace("{{error}}", error.message),
                msgType: "error",
            });       

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.errorSectionDeletedSuccessfully,
            msgType: "success",
        });

        setSectionGridRefresh(!sectionGridRefresh);
        setMessages([]);
        setSelectedSectionId("");
        setSelectedSection(initialSectionState);
        setSelectedMessageId("");
        setSelectedMessage(initialMessageState);
    }

    function handleSectionDeleteClick(id: string) {        
        const internalName = sections.find(p => p.id === id)?.name ?? "";
        alertDialog({
            title: strings.deleteErrorSectionAlertTitle,
            message: strings.deleteErrorSectionAlertMessage.replace("{{name}}", internalName),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedSection(id);
        });
    }
   
    function handleSectionNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedSection((prevState) => ({
            ...prevState,
            name: event.target.value,
        }));
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setSectionErrors(initialSectionErrorState);
        setSectionDialogOpen(false);        
    }

    async function handleSectionSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setSectionErrors(initialSectionErrorState);

        const isUpdate = selectedSectionId !== "";
        try {
            if (isUpdate) {
                await updateSection(axiosInstance, selectedSection);
            } else {
                await createSection(axiosInstance, selectedSection);
            }
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.errorAddingErrorSection
                    : strings.errorUpdatingErrorSection
                ).replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        setSectionDialogOpen(false);
        setSectionGridRefresh(!sectionGridRefresh);
        setNotify({
            isOpen: true,
            message: strings.errorSectionSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleSectionValidate() {
        var errors = validate<SectionModel, SectionValidationErrors>([
            { property: "name", type: ValidationType.Required, message: strings.validationErrorSectionName }
        ], selectedSection);
        if (errors) {
            setSectionErrors(errors);
            return false;
        }
        else {
            return true;
        }
    }
    
    const messageDialogTitle = selectedMessageId === "" ? strings.addErrorMessageTitle : strings.editErrorMessageTitle.replace("{{code}}", selectedMessage?.code ?? "");
    
    function handleMessageAddClick(event: React.MouseEvent<HTMLButtonElement>) {
        setSelectedMessageId("");
        setMessageDialogOpen(true);
    }

    async function deleteSelectedMessage(id: string) {        
        try {            
            await deleteMessage(axiosInstance, user.currentProperty?.code ?? "", id);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingErrorMessage.replace("{{error}}", error.message),
                msgType: "error",
            });

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.errorMessageDeletedSuccessfully,
            msgType: "success",
        });

        setMessageGridRefresh(!messageGridRefresh);
        setSelectedMessageId("");
        setSelectedMessage(initialMessageState);
    }

    function handleMessageDeleteClick(id: string) {                
        let code: string;
        if (isAdminCustomer()) {
            code = messages?.find(i => i.id === id)?.code ?? "";
        }
        else {
            code = messages?.find(i => i.overrideId === id)?.code ?? "";
        }

        alertDialog({
            title: strings.deleteErrorMessageAlertTitle,
            message: strings.deleteErrorMessageAlertMessage.replace("{{code}}", code),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedMessage(id);
        });
    }

    function handleMessageEditRowClick(id: string) {              
        var message = messages?.find(i => i.id === id);
        if (message) {
            setSelectedMessageId(id);
            setSelectedMessage(message);
            setMessageDialogOpen(true);
        }
    }
   
    function handleMessageCodeChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedMessage((prevState) => ({
            ...prevState,
            code: event.target.value
        }));
    }

    function handleMessageTitleChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedMessage((prevState) => ({
            ...prevState,
            title: {
                ...prevState.title,
                [event.target.language]: event.target.value,
            },
        }));
    }

    function handleMessageTextChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedMessage((prevState) => ({
            ...prevState,
            text: {
                ...prevState.text,
                [event.target.language]: event.target.value,
            },
        }));
    }

    function handleMessageCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setMessageErrors(initialMessageErrorState);
        setMessageDialogOpen(false);        
        setSelectedMessage(initialMessageState);
    }

    async function handleMessageSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setMessageErrors(initialMessageErrorState);

        var id = selectedMessageId;
        const isUpdate = selectedMessageId !== "";
        try {
            if (isAdminCustomer()) {
                if (isUpdate) {
                    await updateMessage(axiosInstance, "", selectedMessage);
                } else {
                    id = await createMessage(axiosInstance, "", selectedSectionId, selectedMessage);
                    setSelectedMessageId(id);
                }
            }
            else {
                if (selectedMessage.isOverride) {
                    await updateMessage(axiosInstance, user.currentProperty?.code ?? "", selectedMessage);
                } else {
                    id = await createMessage(axiosInstance, user.currentProperty?.code ?? "", selectedSectionId, selectedMessage);
                    setSelectedMessageId(id);
                }
            }
        } catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.errorAddingErrorMessage
                    : strings.errorUpdatingErrorMessage
                ).replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }        

        setMessageDialogOpen(false);
        setSelectedMessage(initialMessageState);        
        setMessageGridRefresh(!messageGridRefresh);
        setNotify({
            isOpen: true,
            message: strings.promotionItemSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleMessageValidate() {
        var errors = validate<MessageModel, MessageValidationErrors>([
            { property: "code", type: ValidationType.Required, message: strings.validationErrorMessageCode },
            { property: "title.en", type: ValidationType.Required, message: strings.validationErrorMessageTitle },
            { property: "text.en", type: ValidationType.Required, message: strings.validationErrorMessageText }    
        ], selectedMessage);
        if (errors) {
            setMessageErrors(errors);
            return false;
        }
        else {
            return true;
        }
    }   

    const gridSectionColumns: GridColDef[] = [            
        { field: "name", headerName: strings.name, flex: 1 },        
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton 
                    permissionKey="administration_company" 
                    rowId={params.id.toString()} 
                    clickHandler={() => handleSectionEditRowClick(params.id.toString())} 
                />,
                <DataGridDeleteButton    
                    hidden={!isAdminCustomer() && !params.row.overridden}
                    permissionKey="administration_company"
                    rowId={params.id.toString()}
                    clickHandler={() => handleSectionDeleteClick(params.id.toString())}
                />,
            ],
        },
    ];

    function getMessageActions(params: GridRowParams) {
        var actions = [
            <DataGridEditButton 
                permissionKey="administration_company" 
                rowId={params.id.toString()} 
                clickHandler={() => handleMessageEditRowClick(params.id.toString())} 
            />
        ];

        if (isAdminCustomer() || params.row.isOverride) {
            actions.push(<DataGridDeleteButton
                permissionKey="administration_company"
                rowId={params.id.toString()}
                clickHandler={() => handleMessageDeleteClick(params.row.isOverride as boolean ? params.row.overrideId.toString() : params.id.toString())}
            />);
        }

        return actions;
    }

    const gridMessageColumns: GridColDef[] = [
        { field: "code", headerName: strings.code, flex: 1 },
        { field: "title", headerName: strings.title, valueGetter: (value, row) => row.title.en, flex: 2 },
        { field: "text", headerName: strings.text, valueGetter: (value, row) => row.text.en, flex: 3 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => getMessageActions(params),
        },
    ];

    return (
        <Box sx={{ padding: theme.spacing(2), height: isAdminCustomer() ? "calc(100vh - 185px)" : "calc(100vh - 235px)" }}> 
            {!isAdminCustomer() && 
                <>
                    <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-label">
                            {strings.messageSection}
                        </InputLabel>
                        <Select
                            id="selectFieldPropertyBrandList"
                            value={selectedSectionId}
                            label={strings.messageSection}
                            onChange={handleSectionChange}
                            MenuProps={{
                                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                transformOrigin: { vertical: "top", horizontal: "left" }
                            }}
                        >                            
                            {sections.map((section, index) => (
                                <MenuItem key={index} value={section.id}>{section.name}</MenuItem>
                            ))}                         
                        </Select>
                    </FormControl>
                    <Spacer y={2} />
                </>
            }
            <Grid container spacing={2} sx={{ height: "100%" }}>  
                {isAdminCustomer() &&
                <Grid item xs={3} sx={{ height: "100%" }}>
                    <DataGrid
                        permissionKey={isAdminCustomer() ? "administration_configuration" : "configuration_errors"}
                        rows={sections}                               
                        columns={gridSectionColumns}        
                        addButtonDisabled={!isAdminCustomer()}
                        onRowSelectionModelChange={handleSectionSelectionModelChange}
                        onAddButtonClick={handleSectionAddClick}
                        addButtonText={strings.addErrorSectionTitle}
                        loading={isLoading}
                    />
                </Grid>      
                }
                <Grid item xs={isAdminCustomer() ? 9 : 12} sx={{ height: "100%" }}>
                    <DataGrid
                        permissionKey={isAdminCustomer() ? "administration_configuration" : "configuration_errors"}
                        rows={messages}                            
                        columns={gridMessageColumns}
                        showAddButton={isAdminCustomer()}
                        addButtonDisabled={selectedSectionId === ""}
                        onAddButtonClick={handleMessageAddClick}
                        addButtonText={strings.addErrorMessageTitle}
                        loading={isLoading}
                    />
                </Grid>
            </Grid>               

            <DetailsDialog
                permissionKey={isAdminCustomer() ? "administration_configuration" : "configuration_errors"}
                open={sectionDialogOpen}
                adding={selectedSectionId === ""}
                title={dialogSectionTitle}
                onValidateForm={handleSectionValidate}
                onCancelClick={handleCancelClick}
                onSaveClick={handleSectionSaveClick}
                contentSize={{ width: 300, height: 102.91 }}
            >
                <Box sx={{ width: "100%", padding: theme.spacing(2) }}>
                    <TextField
                        id="section-name"
                        value={selectedSection.name}
                        label={strings.name}
                        width={268}                            
                        onChange={handleSectionNameChange}                            
                        error={Boolean(sectionErrors.internalName)}
                        helperText={sectionErrors.internalName}
                    />
                </Box>
            </DetailsDialog>

            <DetailsDialog
                permissionKey={isAdminCustomer() ? "administration_configuration" : "configuration_errors"}
                adding={selectedMessageId === ""}
                open={messageDialogOpen}
                title={messageDialogTitle}
                onValidateForm={handleMessageValidate}
                onCancelClick={handleMessageCancelClick}
                onSaveClick={handleMessageSaveClick}
                contentSize={{ width: 600, height: isAdminCustomer() ? 453.63 : 368.72 }}
            >
                <Box sx={{ width: "100%", padding: theme.spacing(2) }}>                        
                    <LanguageForm>
                        <TextField
                            id="message-code"
                            value={selectedMessage.code}
                            label={strings.code}
                            disabled={!isAdminCustomer()}
                            width={250}                            
                            onChange={handleMessageCodeChange}                            
                            error={Boolean(messageErrors.code)}
                            helperText={messageErrors.code}
                        />
                        <Spacer />
                        <LanguageSelect />
                        <Spacer />
                        {isAdminCustomer() &&
                            <>
                                <LanguageTextField
                                    id="message-title"
                                    label={strings.title}
                                    values={selectedMessage.title ?? {}}
                                    width={568}
                                    onChange={handleMessageTitleChange}
                                    error={Boolean(messageErrors.title)}
                                    helperText={messageErrors.title}
                                />
                                <Spacer />
                            </>
                        }
                        <LanguageTextField
                            id="message-text"
                            label={strings.text}
                            values={selectedMessage.text ?? {}}
                            multiline={true}
                            lines={5}
                            width={568}                                
                            onChange={handleMessageTextChange}
                            error={Boolean(messageErrors.text)}
                            helperText={messageErrors.text}
                        />                            
                    </LanguageForm>
                </Box>
            </DetailsDialog>

            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>           
    )    
};

export default ErrorMessages;
