import { AxiosInstance } from "axios";
import { Item } from "../../models/modules/roomservice/Item";
import { OptionSet, Option } from "../../models/modules/roomservice/Option";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";
import { v4 as uuid } from "uuid";
import { OptionSetToSave, stringifyOptionSets } from "./helper";

const url = process.env.REACT_APP_MANAGER_API_URL + "/room-service/";

export async function getItems(axiosInstance: AxiosInstance, propertyId: string): Promise<Item[]> {
    return await axiosInstance
        .get(`${url}${propertyId}/items`)
        .then((response) => parseLanguageStringsFromJson(response.data.items) as Item[]);
}

export async function getItem(axiosInstance: AxiosInstance, propertyId: string, id: string): Promise<Item> {
    return await axiosInstance
        .get(`${url}${propertyId}/items/${id}`)
        .then((response) => parseLanguageStringsFromJson(response.data.item) as Item);
}

export async function createItem(axiosInstance: AxiosInstance, propertyId: string, item: Item, sectionId?: string) {
    const id = uuid();

    var optionSetsToSave: OptionSetToSave[] = [];
    if (item.optionSets !== null && item.optionSets.length > 0) {
        optionSetsToSave = stringifyOptionSets(item.optionSets);
    }

    const itemToSave = {
        ...item,  
        id: id,        
        name: JSON.stringify({ ...item.name }),
        description: JSON.stringify({ ...item.description }),  
        optionSets: optionSetsToSave,
        pricePerUnit: item.pricePerUnitString ? parseFloat(item.pricePerUnitString) : item.pricePerUnit
    };

    var queryString = "";
    if (sectionId) {
        queryString = `?sectionId=${sectionId}`;
    }

    return await axiosInstance.post(`${url}${propertyId}/items${queryString}`, itemToSave).then((response) => {
        return id;
    });
}

export async function updateItem(axiosInstance: AxiosInstance, propertyId: string, item: Item) {
    var optionSetsToSave: OptionSetToSave[] = [];
    if (item.optionSets !== null && item.optionSets.length > 0) {
        optionSetsToSave = stringifyOptionSets(item.optionSets);
    }

    const itemToSave = {
        ...item,                
        name: JSON.stringify({ ...item.name }),
        description: JSON.stringify({ ...item.description }),  
        optionSets: optionSetsToSave,
        pricePerUnit: item.pricePerUnitString ? parseFloat(item.pricePerUnitString) : item.pricePerUnit
    };

    return await axiosInstance.put(`${url}${propertyId}/items/${item.id}`, itemToSave).then((response) => {
        return response.data;
    });
}

export async function toggleItemSoldOut(axiosInstance: AxiosInstance, propertyId: string, id: string) {
    return await axiosInstance.put(`${url}${propertyId}/items/${id}/soldout`).then((response) => {
        return response.data;
    });
}

export async function deleteItem(axiosInstance: AxiosInstance, propertyId: string, id: string) {
    return await axiosInstance.delete(`${url}${propertyId}/items/${id}`).then((response) => {
        return response.data;
    });
}

export async function saveDisplayOrder(axiosInstance: AxiosInstance, propertyId: string, sectionId: string, idList: string[]) {
    const response = axiosInstance.put(`${url}${propertyId}/sections/${sectionId}/items/reorder`, idList).then((response) => {
        return response.status
    });
}