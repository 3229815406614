import { Orderable } from "../../../components/common/DisplayOrderButton";
import { StringLanguageDict } from "../../common/LanguageModel";

export interface BookDto {
    id: string;
    name: StringLanguageDict | null;
    topics: Topic[];
}

export interface Book extends Orderable {
    id: string;
    name: StringLanguageDict | null;
    displayOrder: number;
    topics: Topic[];
};

export interface Topic extends Orderable {
    id: string;
    name: StringLanguageDict | null;
    displayOrder: number,
    sections: Section[];
};

export interface Section {
    id: string;
    name: StringLanguageDict | null;
    items: Item[];
    displayOrder: number,
};

export interface Item {
    id: string;
    name: StringLanguageDict | null;
    description?: StringLanguageDict | null;
    previewOnly: boolean;
    displayOrder: number,
}

// Initial states
export const initialBookState: Book = {
    id: "",
    name: { en: "", es: "" },
    displayOrder: 0,
    topics: [],
};

export const initialTopicState: Topic = {
    id: "",
    name: { en: "", es: "" },
    displayOrder: 0,
    sections: [],
};

export const initialSectionState: Section = {
    id: "",
    name: { en: "", es: "" },
    items: [],
    displayOrder: 0
};

export const initialItemState: Item = {
    id: "",
    name: { en: "", es: "" },
    description: { en: "", es: "" },
    previewOnly: false,
    displayOrder: 0
};