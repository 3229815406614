import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import PMS from "./PMS";
import Options from "./Options";
import { useUser } from "../../../providers/UserProvider";

const Configuration = () => {
    const strings = useLocalizedStrings();   
    const location = useLocation();
    const theme = useTheme();
    const { user } = useUser();      

    return (
        <Box sx={{ width: '100%' }}>            
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={location.pathname} ria-label="basic tabs example">                                                  
                    <Tab value="/configuration/pms" label="PMS" component={Link} to="pms" />                                                            
                    <Tab value="/configuration/options" label="Azure" component={Link} to="options" />                                        
                </Tabs>
            </Box>            
            <Box component="main" sx={{ flexGrow: 1, padding: 0 }}>
                <Routes>
                    <Route path="pms" element={<PMS />} />                    
                    <Route path="options" element={<Options />} />                    
                </Routes>
            </Box>
        </Box>
    );
};

export default Configuration;
