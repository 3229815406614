export interface Location {
    code: string;
    internalName: string;
    readOnly: boolean;
}

export const initialLocationState: Location = {
    code: "",
    internalName: "",
    readOnly: false
};

export function mapLocationsToKeyValues(locations: Location[], readOnly: boolean): { key: string; value: string }[] {
    let kvps: { key: string; value: string }[] = [];

    // If the group is read only, include all locations, if not only return read only = false locations
    kvps = locations.filter(l => readOnly ? true : !l.readOnly).map((location, index) => {
        return {
            key: location.code,
            value: location.internalName,
        };
    });

    return kvps;
}