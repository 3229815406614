import { default as MaterialTextField } from "@mui/material/TextField";
import { TimePicker as MaterialTimePicker, TimePickerProps as MaterialTimePickerProps } from "@mui/x-date-pickers/TimePicker";
import { useDialog } from "./DetailsDialog";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";
import React from "react";
import dayjs, { Dayjs } from 'dayjs';

export interface TimePickerProps<TDate extends Dayjs, TEnableAccessibleFieldDOMStructure extends boolean = false> extends MaterialTimePickerProps<TDate, TEnableAccessibleFieldDOMStructure> {  
    permissionKey?: string;
}

const TimePicker = <TDate extends Dayjs>(props: TimePickerProps<TDate>) => {         
    const { checkAccess } = useUser();
    const { dirty, setDirty, adding, permissionKey } = useDialog();
    const [readOnly] = React.useState(!checkAccess(props.permissionKey ?? permissionKey, adding ? Access.Create : Access.Update));

    // TODO: Context type???
    function handleChange(newValue: TDate | null, context: any) {        
        setDirty(true);

        if (props.onChange) {
            props.onChange(newValue, context);
        }
    };

    return (
        <MaterialTimePicker
            {...props}            
            disabled={props.disabled || readOnly}
            onChange={handleChange}                                                        
        />  
    );
};

export default TimePicker;
