export interface Configuration {
    id: string;
    externalId: string;
}

export function getInitialConfigurationState(): Configuration {
    return {
        id: "",
        externalId: ""
    }
}