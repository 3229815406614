export interface Ticket {
    id: string;
    orderNumber: number;
    guestName: string;
    roomNumber: string;
    items: TicketItem[];
    placeSettings: number;
    orderDate: string;
    subtotal: number;
    taxRate: number;
    total: number;
    formatted: string;
}

export interface TicketItem {
    id: string;
    name: string;
    sku: string;
    pricePerUnit: number;
    quantity: number;
    subtotal: number;
    options: TicketItemOption[];
}

export interface TicketItemOption {
    id: string;
    name: string;
    sku: string;
    pricePerUnit: number;
    quantity: number;
    subtotal: number;
    options: TicketItemOption[];
}

export const initialTicketState: Ticket = {
    id: "",
    orderNumber: 0,
    guestName: "",
    roomNumber: "",
    items: [],
    placeSettings: 0,
    orderDate: Date(),
    subtotal: 0,
    taxRate: 0,
    total: 0,
    formatted: ""
}