import AddIcon from "@mui/icons-material/Add";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from '@mui/icons-material/Lock';
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from '@mui/material/FormControl';
import Grid from "@mui/material/Grid";
import MuiIconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { SelectChangeEvent } from '@mui/material/Select';
import { Theme, useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { Table } from "reactstrap";
import { v4 as uuid } from 'uuid';
import { listLocations, listLocationItems } from "../../../api/menu/locationApi";
import { createGroup, createItem, deleteGroup, deleteItem, getMenu, updateGroup, updateItem } from "../../../api/menu/menuApi";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Location, mapLocationsToKeyValues } from "../../../models/menus/Location";
import { LocationItem, mapLocationItemsToKeyValues } from "../../../models/menus/LocationItem";
import { initialMenuState, Menu } from "../../../models/menus/Menu";
import { initialMenuGroupState, MenuGroup } from "../../../models/menus/MenuGroup";
import { initialMenuItemState, MenuItem as MenuItemModel } from "../../../models/menus/MenuItem";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import DetailsDialog, { useDialog } from "../../common/details/DetailsDialog";
import { default as DialogSelect } from "../../common/details/Select";
import { default as DisplayOrderButton, moveBottom, moveDown, moveTop, moveUp, reorder } from "../../common/DisplayOrderButton";
import GraphicManager, { GraphicManagerRefObject } from "../../common/image/GraphicManager";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import axios from "axios";
import themePrimary from "../../../styles/themePrimary";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { Field as ValidationField, Type as ValidationType, validate } from "../../../utilities/Validator";
import { useUser } from "../../../providers/UserProvider";
import Checkbox from "../../common/details/Checkbox";
import { Access } from "../../../models/configuration/security/Permission";

const useStyles = makeStyles((theme: Theme) => ({
    cell: {
        width: '250px',
    },
    group: {

    },
    item: {        
        padding: '0px !important',
        paddingLeft: '48px !important',
    },
    container: {
        position: 'relative',
        overflowY: 'scroll',        
        width: '100%',
        height: '536px',
        padding: themePrimary.spacing(2),        
    },
    table: {
        width: '100%',            
    }
}));

interface ActionsCellProps {
    isGroup: boolean;
    order: number;
    count: number;
    onAddButtonClick?: () => void;
    onEditButtonClick: () => void;
    onDeleteButtonClick: () => void;    
    onMoveTopClick: () => void;
    onMoveUpClick: () => void;
    onMoveDownClick: () => void;
    onMoveBottomClick: () => void;    
}

const ActionsCell = (props: ActionsCellProps) => {
    const theme = useTheme();
    const strings = useLocalizedStrings();

    return (
        <>
            <TableCell align="right">
                {props.isGroup &&
                    <Tooltip title={strings.addMenuItem}>
                        <MuiIconButton
                            size="small"
                            aria-label="audit"
                            aria-haspopup="true"
                            style={{ color: theme.palette.primary.dark }}
                            onClick={props.onAddButtonClick}
                        >
                            <AddBoxIcon fontSize="small" />
                        </MuiIconButton>
                    </Tooltip>
                }
                <Tooltip title={props.isGroup ? strings.editMenuGroup : strings.editMenuItem}>
                    <MuiIconButton
                        size="small"
                        aria-label="audit"
                        aria-haspopup="true"
                        style={{ color: theme.palette.primary.dark }}
                        onClick={props.onEditButtonClick}
                    >
                        <EditIcon fontSize="small" />
                    </MuiIconButton>
                </Tooltip>
                <Tooltip title={props.isGroup ? strings.deleteMenuGroup : strings.deleteMenuItem}>
                    <MuiIconButton
                        size="small"
                        aria-label="audit"
                        aria-haspopup="true"
                        color="error"
                        onClick={props.onDeleteButtonClick}
                    >
                        <DeleteIcon fontSize="small" />
                    </MuiIconButton>
                </Tooltip>
                <DisplayOrderButton
                    permissionKey="content_menu"
                    current={props.order}
                    count={props.count}
                    onMoveTopClick={props.onMoveTopClick}
                    onMoveUpClick={props.onMoveUpClick}
                    onMoveDownClick={props.onMoveDownClick}
                    onMoveBottomClick={props.onMoveBottomClick}
                />
            </TableCell>             
        </>
    )
}

export interface MenuBuilderProps {
    menuId: string;
    onMenuUpdatedHandler: (menu: Menu, refs: { itemId: string, ref: GraphicManagerRefObject }[]) => void;
}

interface GroupValidationErrors {
    name: string;    
}

interface ItemValidationErrors {
    name: string;
    locationCode: string;
}

const MenuBuilder = (props: MenuBuilderProps) => {    
    const initialGroupErrorState: GroupValidationErrors = {
        name: ""
    }

    const initialItemErrorState: ItemValidationErrors = {
        name: "",
        locationCode: ""
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [menu, setMenu] = React.useState<Menu>(initialMenuState);
    const [locations, setLocations] = React.useState<Location[]>([]);
    const [locationItems, setLocationItems] = React.useState<LocationItem[]>([]);
    const classes = useStyles(useTheme());
    const { dirty, setDirty } = useDialog();
    const [groupDialogOpen, setGroupDialogOpen] = React.useState(false);
    const [itemDialogOpen, setItemDialogOpen] = React.useState(false);
    const [selectedGroupId, setSelectedGroupId] = React.useState("");
    const [selectedItemId, setSelectedItemId] = React.useState("");
    const [itemTabValue, setItemTabValue] = React.useState<number>(0);   
    const [selectedGroup, setSelectedGroup] = React.useState<MenuGroup>(initialMenuGroupState);
    const [selectedItem, setSelectedItem] = React.useState<MenuItemModel>(initialMenuItemState);        
    const graphicManagerRef = React.useRef<GraphicManagerRefObject>(null);
    const [graphicManagerRefs, setGraphicManagerRefs] = React.useState<{ itemId: string, ref: GraphicManagerRefObject }[]>([]);
    const alertDialog = useAlertDialog();
    const [refreshMenu, setRefreshMenu] = React.useState(true);
    const [groupErrors, setGroupErrors] = React.useState<GroupValidationErrors>(initialGroupErrorState);
    const [itemErrors, setItemErrors] = React.useState<ItemValidationErrors>(initialItemErrorState);
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);
    const theme = useTheme();
    const strings = useLocalizedStrings();    
    const axiosInstance = useCreateAxios();
    const { user, checkAccess } = useUser();
    const canUpdateReadOnly = checkAccess("content_menu_readonly", Access.Update, true);

    React.useEffect(() => {
        async function getMenuAndLocations() {
            var deviceClass = 0;

            try {
                const payload = await getMenu(axiosInstance, user.currentProperty?.code ?? "", props.menuId);
                setMenu(payload);
                deviceClass = payload.deviceClass;
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingMenu.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }

            try {
                const payload = await listLocations(axiosInstance, user.currentProperty?.code ?? "", deviceClass);
                setLocations(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingLocations.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }
        }

        getMenuAndLocations();
    }, [refreshMenu, user.currentProperty?.code, strings.errorRetrievingMenu, strings.errorRetrievingLocations]);

    React.useEffect(() => {
        props.onMenuUpdatedHandler(menu, graphicManagerRefs);
    }, [menu]);

    function getLocationInternalName(code: string): string {
        const location = locations?.find(l => l.code == code);
        return location?.internalName ?? "";
    }

    React.useEffect(() => {
        async function getLocationItems(locationCode: string) {                 
            try {
                const payload = await listLocationItems(axiosInstance, user.currentProperty?.code ?? "", locationCode);
                setLocationItems(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingLocationItems.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }
        }

        if (selectedItem.locationCode && selectedItem.locationCode !== "") {
            getLocationItems(selectedItem.locationCode);
        }
    }, [selectedItem.locationCode, user.currentProperty?.code, strings.errorRetrievingLocationItems]);

    function getGroupOrder(index: number) {
        if (canUpdateReadOnly) {
            return index;
        }
        else {
            var readOnlyCount = menu.groups.filter(g => g.readOnly).length;
            return index - readOnlyCount;
        }
    }

    function getGroupCount() {
        if (canUpdateReadOnly) {
            return menu.groups.length;
        }
        else {
            var readOnlyCount = menu.groups.filter(g => g.readOnly).length;
            return menu.groups.length - readOnlyCount;
        }        
    }

    function handleAddGroupClick() {
        setSelectedGroupId("");

        var newGroup = initialMenuGroupState;
        newGroup.id = uuid();
        newGroup.displayOrder = menu.groups.max(g => g.displayOrder) + 1;
        setSelectedGroup(newGroup);
        setGroupDialogOpen(true);
    }

    function handleEditGroupClick(id: string) {
        setSelectedGroupId(id);

        const group = menu.groups.find(g => g.id == id);

        if (group) {
            setSelectedGroup(group);
            setGroupDialogOpen(true);
        }        
    }

    async function handleDeleteGroupClick(id: string) {
        const name = menu.groups.find(g => g.id === id)?.name?.en ?? "";

        alertDialog({
            title: strings.deleteMenuGroupAlertTitle,
            message: strings.deleteMenuGroupAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedGroup(id);
        });
    }
       
    function handleGroupMoveTopClick(id: string) {
        if (canUpdateReadOnly) {
            setMenu((prevState) => ({
                ...prevState,
                groups: moveTop(menu.groups, id)
            }));
        }
        else {            
            // We need to implement move top here because of the read-only stuff (we can't move all the way to the top)
            const readOnlyCount = menu.groups.filter(g => g.readOnly == true).length;
            const index = menu.groups.findIndex(g => g.id == id);
            setMenu((prevState) => ({
                ...prevState,
                groups: moveUp(menu.groups, id, index - readOnlyCount)
            }));
        }
        setDirty(true);       
    }

    function handleGroupMoveUpClick(id: string) {
        setMenu((prevState) => ({
            ...prevState,
            groups: moveUp(menu.groups, id)
        }));
        setDirty(true);        
    }

    function handleGroupMoveDownClick(id: string) {
        setMenu((prevState) => ({
            ...prevState,
            groups: moveDown(menu.groups, id)
        }));
        setDirty(true);        
    }

    function handleGroupMoveBottomClick(id: string) {
        setMenu((prevState) => ({
            ...prevState,
            groups: moveBottom(menu.groups, id)
        }));
        setDirty(true);        
    }

    function handleAddItemClick(groupId: string) {
        setSelectedGroupId(groupId);
        setSelectedItemId("");

        const group = menu.groups.find(g => g.id == groupId);
        if (group) {
            var newItem = initialMenuItemState;
            newItem.id = uuid();
            newItem.displayOrder = group.items.length == 0 ? 0 : group.items.max(i => i.displayOrder) + 1;
            setSelectedItem(newItem);

            setSelectedItem(initialMenuItemState);
            setItemDialogOpen(true);
        }        
    }

    function handleEditItemClick(groupId: string, itemId: string) {
        setSelectedGroupId(groupId);
        setSelectedItemId(itemId);

        const groupIndex = menu.groups.findIndex(g => g.id == groupId);
        const item = menu.groups[groupIndex].items.find(i => i.id == itemId);

        if (item) {
            setSelectedItem(item);
            setItemDialogOpen(true);
        }        
    }

    function handleDeleteItemClick(groupId: string, itemId: string) {
        const name = menu.groups.find(g => g.id === groupId)?.items.find(i => i.id === itemId)?.name?.en ?? "";

        alertDialog({
            title: strings.deleteMenuItemAlertTitle,
            message: strings.deleteMenuItemAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedItem(groupId, itemId);
        });
    }

    function handleItemMoveTopClick(groupId: string, itemId: string) {
        const index = menu.groups.findIndex(g => g.id == groupId);       
        const newGroups = [...menu.groups];
        newGroups[index].items = moveTop(newGroups[index].items, itemId);
        setMenu((prevState) => ({
            ...prevState,
            groups: newGroups
        }));
        setDirty(true);        
    }

    function handleItemMoveUpClick(groupId: string, itemId: string) {
        const index = menu.groups.findIndex(g => g.id == groupId);
        const newGroups = [...menu.groups];
        newGroups[index].items = moveUp(newGroups[index].items, itemId);
        setMenu((prevState) => ({
            ...prevState,
            groups: newGroups
        }));
        setDirty(true);        
    }

    function handleItemMoveDownClick(groupId: string, itemId: string) {
        const index = menu.groups.findIndex(g => g.id == groupId);
        const newGroups = [...menu.groups];
        newGroups[index].items = moveDown(newGroups[index].items, itemId);
        setMenu((prevState) => ({
            ...prevState,
            groups: newGroups
        }));
        setDirty(true);        
    }

    function handleItemMoveBottomClick(groupId: string, itemId: string) {
        const index = menu.groups.findIndex(g => g.id == groupId);
        const newGroups = [...menu.groups];
        newGroups[index].items = moveBottom(newGroups[index].items, itemId);
        setMenu((prevState) => ({
            ...prevState,
            groups: newGroups
        }));
        setDirty(true);        
    }

    const groupDialogTitle = selectedGroupId == "" ? strings.addMenuGroupTitle : strings.editMenuGroupTitle.replace("{{name}}", selectedGroup.name.en);

    function handleGroupNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedGroup((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            },
        }));
    }    

    function handleGroupReadOnlyChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedGroup((prevState) => ({
            ...prevState,
            readOnly: event.target.checked,
        }));
    }

    function handleGroupCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setGroupErrors(initialGroupErrorState);
        setGroupDialogOpen(false);
    }

    async function handleGroupSaveClick(event: React.MouseEvent<HTMLButtonElement>) {        
        const isUpdate = selectedGroupId.trim().length > 0;
        const newGroups = [...menu.groups];

        if (isUpdate) {
            const index = menu.groups.findIndex(g => g.id == selectedGroupId);
            if (index == -1) {
                throw new RangeError();
            }

            newGroups[index] = selectedGroup;
        }
        else {                         
            newGroups.push(selectedGroup);
        }

        setMenu((prevState) => ({
            ...prevState,
            groups: newGroups
        }));
        setDirty(true);
        setGroupDialogOpen(false);
        setGroupErrors(initialGroupErrorState);

        return true;
    }

    function deleteSelectedGroup(id: string) {
        const index = menu.groups.findIndex(g => g.id == id);
        if (index == -1) {
            throw new RangeError();
        }

        const newGroups = [...menu.groups];
        newGroups.splice(index, 1);

        setMenu((prevState) => ({
            ...prevState,
            groups: newGroups
        }));
        setDirty(true);
    }

    function handleValidateGroup() {
        var errors = validate<MenuGroup, GroupValidationErrors>([
            { property: "name.en", type: ValidationType.Required, message: strings.validationErrorMenuGroupNameRequired }
        ], selectedGroup);
        if (errors) {
            setGroupErrors(errors);
            return false;
        }
        else {
            return true;
        }
    }    

    const itemDialogTitle = selectedItemId == "" ? strings.addMenuItemTitle : strings.editMenuItemTitle.replace("{{name}}", selectedItem.name.en);

    const handleItemTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setItemTabValue(newValue);
    };

    function handleItemNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedItem((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            },
        }));
    }

    function handleItemLocationChange(e: SelectChangeEvent) {       
        setSelectedItem((prevState) => ({
            ...prevState,
            locationCode: e.target.value as string
        }));
    }

    function handleItemLocationItemChange(e: SelectChangeEvent) {        
        setSelectedItem((prevState) => ({
            ...prevState,
            locationItemId: e.target.value as string
        }));        
    }

    function handlePreviewOnlyChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedItem((prevState) => ({
            ...prevState,
            previewOnly: event.target.checked,
        }));
    }

    function handleItemCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setItemErrors(initialItemErrorState);
        setItemDialogOpen(false);
        setItemTabValue(0);
    }

    async function handleItemSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        const groupIndex = menu.groups.findIndex(g => g.id == selectedGroupId);
        if (groupIndex == -1) {
            throw new RangeError();
        }

        const isUpdate = selectedItemId.trim().length > 0;
        const newGroups = [...menu.groups];
        const newGroup = { ...newGroups[groupIndex] };
        const newItems = [...newGroup.items];

        if (isUpdate) {
            const itemIndex = newGroup.items.findIndex(i => i.id == selectedItemId);
            if (itemIndex == -1) {
                throw new RangeError();
            }

            newItems[itemIndex] = selectedItem;
        }
        else {                
            newItems.push(selectedItem);
        }

        newGroup.items = newItems;
        newGroups[groupIndex] = newGroup;

        setMenu((prevState) => ({
            ...prevState,
            groups: newGroups
        }));
        setDirty(true);
        setItemDialogOpen(false);
        setItemTabValue(0);
        setItemErrors(initialItemErrorState);

        // We need to collect the graphicManagerRef objects for each item add/edit, so we can batch the saves. We'll also need to pass in the graphicState from the previous instance of this ref.
        if (graphicManagerRef.current) {
            const index = graphicManagerRefs.findIndex(r => r.itemId === selectedItem.id);
            if (index !== -1) {
                // If we already have a ref for this item, just update it
                const newGraphicManagerRefs = [...graphicManagerRefs];
                newGraphicManagerRefs[index] = { itemId: selectedItem.id, ref: graphicManagerRef.current };
                setGraphicManagerRefs(newGraphicManagerRefs);
            }
            else {
                // Add a new ref for this item
                setGraphicManagerRefs([...graphicManagerRefs, { itemId: selectedItem.id, ref: graphicManagerRef.current }]);
            }
        }

        return true;
    }

    async function deleteSelectedItem(groupId: string, itemId: string) {
        try {
            const groupIndex = menu.groups.findIndex(g => g.id == groupId);
            if (groupIndex == -1) {
                throw new RangeError();
            }

            const newGroups = [...menu.groups];
            const newGroup = { ...newGroups[groupIndex] };

            const itemIndex = newGroup.items.findIndex(i => i.id == itemId);
            if (itemIndex == -1) {
                throw new RangeError();
            }

            newGroup.items.splice(itemIndex, 1);            

            setMenu((prevState) => ({
                ...prevState,
                groups: newGroups
            }));
            setDirty(true);
        }
        catch {
                        //setNotify({
            //    isOpen: true,
            //    message: strings,
            //    msgType: "error",
            //});
        }
    }

    function handleValidateItem() {
        var errors = validate<MenuItemModel, ItemValidationErrors>([
            { property: "name.en", type: ValidationType.Required, message: strings.validationErrorMenuGroupNameRequired },
            { property: "locationCode", type: ValidationType.Required, message: strings.validationErrorMenuItemLocationRequired }
        ], selectedItem);
        if (errors) {
            setItemErrors(errors);
            return false;
        }
        else {
            return true;
        }
    }    

    return (
        <>
            <Box sx={{ width: "100%", height: "558px", maxHeight: "558px", overflowY: "scroll", padding: theme.spacing(2) }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"            
                >
                    <Grid item>                        
                        <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddGroupClick}>
                            {strings.addMenuGroup}
                        </Button>                        
                    </Grid>
                    <Grid item>
                        <TableContainer className={classes.table}>
                            <Table size="small" aria-label="Menu">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={250}>{strings.title}</TableCell>
                                        <TableCell width={250} className={classes.cell}>{strings.location}</TableCell>
                                        <TableCell width={155} align="center">{strings.gridActions}</TableCell>
                                    </TableRow>
                                </TableHead>                
                                {menu.groups.map((group: MenuGroup, groupIndex: number) => (
                                    <TableBody key={group.id}>
                                         <TableRow key={group.id}>
                                            <TableCell align="left" colSpan={2} className={classes.group}>{group.name.en}</TableCell>
                                            {!canUpdateReadOnly && group.readOnly
                                                ? <TableCell align="center"><Tooltip title={strings.groupReadOnly}><LockIcon fontSize="small" style={{ color: theme.palette.primary.dark }} /></Tooltip></TableCell>
                                                : <ActionsCell
                                                    isGroup={true}
                                                    order={getGroupOrder(groupIndex)}
                                                    count={getGroupCount()}
                                                    onAddButtonClick={() => handleAddItemClick(group.id)}
                                                    onEditButtonClick={() => handleEditGroupClick(group.id)}
                                                    onDeleteButtonClick={() => handleDeleteGroupClick(group.id)}
                                                    onMoveTopClick={() => handleGroupMoveTopClick(group.id)}
                                                    onMoveUpClick={() => handleGroupMoveUpClick(group.id)}
                                                    onMoveDownClick={() => handleGroupMoveDownClick(group.id)}
                                                    onMoveBottomClick={() => handleGroupMoveBottomClick(group.id)} />
                                            }
                                        </TableRow>
                                        {group.items.map((item: MenuItemModel, itemIndex: number) => (
                                            <TableRow key={item.id}>
                                                <TableCell align="left" className={classes.item}>{item.name.en}</TableCell>
                                                <TableCell align="left">{getLocationInternalName(item.locationCode)}</TableCell>
                                                {!canUpdateReadOnly && group.readOnly
                                                    ? <TableCell align="center"><Tooltip title={strings.itemReadOnly}><LockIcon fontSize="small" style={{ color: theme.palette.primary.dark }} /></Tooltip></TableCell>
                                                    : <ActionsCell
                                                        isGroup={false}
                                                        order={itemIndex}
                                                        count={group.items.length}                                                        
                                                        onEditButtonClick={() => handleEditItemClick(group.id, item.id)}
                                                        onDeleteButtonClick={() => handleDeleteItemClick(group.id, item.id)}
                                                        onMoveTopClick={() => handleItemMoveTopClick(group.id, item.id)}
                                                        onMoveUpClick={() => handleItemMoveUpClick(group.id, item.id)}
                                                        onMoveDownClick={() => handleItemMoveDownClick(group.id, item.id)}
                                                        onMoveBottomClick={() => handleItemMoveBottomClick(group.id, item.id)}
                                                    />
                                                }
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                ))}                                          
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>

            {selectedGroup &&
                <DetailsDialog
                    permissionKey="content_menu"
                    open={groupDialogOpen}
                    title={groupDialogTitle}
                    onCancelClick={handleGroupCancelClick}
                    saveButtonLabel={strings.doneButtonTitle}
                    onSaveClick={handleGroupSaveClick}
                    onValidateForm={handleValidateGroup}
                    contentSize={{ width: 500, height: canUpdateReadOnly ? 231.81 : 189.81 }}
                >
                    <Box sx={{ width: "100%", padding: theme.spacing(2) }}>
                        <LanguageForm>
                            <LanguageSelect />
                            <Spacer />
                            <LanguageTextField
                                id="item-name"
                                label={strings.title}
                                values={selectedGroup.name}
                                width={410}
                                onChange={handleGroupNameChange}
                                error={Boolean(groupErrors.name)}
                                helperText={groupErrors.name}
                            />
                        </LanguageForm>
                        { canUpdateReadOnly &&
                        <>
                            <Spacer />
                            <Checkbox
                                label={strings.locked}
                                checked={selectedGroup.readOnly}
                                onChange={handleGroupReadOnlyChange}
                            />
                        </>
                        }
                    </Box>
                </DetailsDialog>
            }

            { selectedItem &&
                <DetailsDialog
                    permissionKey="content_menu"
                    open={itemDialogOpen}
                    title={itemDialogTitle}
                    onCancelClick={handleItemCancelClick}
                    saveButtonLabel={strings.doneButtonTitle}
                    onSaveClick={handleItemSaveClick}
                    onValidateForm={handleValidateItem}
                    contentSize={{ width: 879.5, height: 434.5 }}
                >
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={itemTabValue}
                            onChange={handleItemTabChange}
                            aria-label="basic tabs example"
                        >
                            <Tab label={strings.details} />
                            <Tab label={strings.graphics} />
                        </Tabs>
                    </Box>
                    <TabPanelKeepMounted value={itemTabValue} index={0}>
                        <LanguageForm>
                            <LanguageSelect />
                            <Spacer />
                            <LanguageTextField
                                id="item-name"
                                label={strings.title}
                                values={selectedItem.name}
                                width={410}
                                onChange={handleItemNameChange}
                                error={Boolean(itemErrors.name)}
                                helperText={itemErrors.name}
                            />
                        </LanguageForm>
                        <Spacer />
                        <Grid
                            container
                            spacing={2}
                            direction="row"
                            alignItems="center"
                        >
                            <Grid item>
                                <FormControl variant="outlined" sx={{ minWidth: 150 }}>
                                    <DialogSelect
                                        label={strings.location}
                                        keyValues={mapLocationsToKeyValues(locations, selectedGroup.readOnly)}
                                        selectedValue={selectedItem.locationCode}
                                        onChangeHandler={handleItemLocationChange}
                                        sx={{ minWidth: 150 }}
                                        error={Boolean(itemErrors.locationCode)}
                                        helperText={itemErrors.locationCode}
                                    />
                                </FormControl>
                            </Grid>
                            { locationItems.length > 0 &&
                                <Grid item>
                                    <FormControl variant="outlined" sx={{ minWidth: 250 }}>
                                        <DialogSelect
                                            label={strings.locationItem}
                                            keyValues={mapLocationItemsToKeyValues(locationItems)}
                                            selectedValue={selectedItem.locationItemId}
                                            onChangeHandler={handleItemLocationItemChange}
                                            sx={{ minWidth: 250 }}
                                        />
                                    </FormControl>
                                </Grid>                            
                            }
                        </Grid>
                        <Spacer />
                        <Checkbox  
                            label={strings.previewOnly}
                            checked={selectedItem.previewOnly ?? false}
                            onChange={handlePreviewOnlyChange}                                                                
                        />
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={itemTabValue} index={1}>
                        <GraphicManager
                            propertyId={(user.currentProperty === null || user.currentProperty === undefined) ? undefined : user.currentProperty!!.id}
                            itemType="MenuItem"
                            itemKey={selectedItem.id}
                            imageKeys={["main", "landing"]}
                            ref={graphicManagerRef}
                            initialGraphicState={graphicManagerRefs.find(r => r.itemId === selectedItem.id)?.ref.getGraphicState()}
                        />
                    </TabPanelKeepMounted>
                </DetailsDialog>
            }

            <NotificationMessage notificationState={[notify, setNotify]} />
        </>
    );
};

export default MenuBuilder;