import { Orderable } from "../../components/common/DisplayOrderButton";
import { StringLanguageDict } from "../common/LanguageModel";
import { Location } from "./Location";

export interface MenuItem extends Orderable{
    id: string;
    name: StringLanguageDict;
    locationCode: string
    locationItemId?: string;
    displayOrder: number;
    previewOnly: boolean;
}

export const initialMenuItemState: MenuItem = {
    id: "",
    name: { en: "", es: "" },
    locationCode: "",
    displayOrder: 0,
    previewOnly: false
};