import { AxiosInstance } from "axios";
import { Service } from "../../models/modules/guestservices/Service";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";
import { v4 as uuid } from "uuid";
import { ServiceAttribute } from "../../models/modules/guestservices/ServiceAttribute";
import { ServiceAttributeChoice } from "../../models/modules/guestservices/ServiceAttributeChoice";
import { ChildProcess } from "child_process";

const url = process.env.REACT_APP_MANAGER_API_URL + "/guest-service/";

export async function listServices(axiosInstance: AxiosInstance, propertyCode: string, typeId: String): Promise<Service[]> {
    if (typeId === "0") {
        return await axiosInstance
            .get(`${url}${propertyCode}/services`)
            .then((response) => parseLanguageStringsFromJson(response.data.services) as Service[]);
    }
    else {
        return await axiosInstance
            .get(`${url}${propertyCode}/services?TypeId=${typeId}`)
            .then((response) => parseLanguageStringsFromJson(response.data.services) as Service[]);
    }
}

export async function getService(axiosInstance: AxiosInstance, propertyCode: string, id: string): Promise<Service> {
    return await axiosInstance
        .get(`${url}${propertyCode}/services/${id}`)
        .then((response) => parseLanguageStringsFromJson(response.data.service) as Service);
}

export async function createService(axiosInstance: AxiosInstance, propertyCode: string, service: Service) {
    const id = uuid();

    const attributesToSave: any[] = []

    service.attributes.forEach(a => {
        const choicesToSave: any[] = []

        a.choices.forEach(c => {
            const choice = {
                ...c,
                value: JSON.stringify({...c.value})
            }
    
            choicesToSave.push(choice)
        })

        const attr = {
            ...a,
            choices: choicesToSave,
            instructions: JSON.stringify({...a.instructions})
        }

        attributesToSave.push(attr)
    })

    const serviceToSave = {
        ...service,  
        id: id,
        propertyId: propertyCode,
        name: JSON.stringify({ ...service.name }),
        description: JSON.stringify({ ...service.description }), 
        attributes: attributesToSave      
    };

    return await axiosInstance.post(`${url}${propertyCode}/services`, serviceToSave).then((response) => {
        return id;
    });
}

export async function updateService(axiosInstance: AxiosInstance, propertyCode: string, service: Service) {
    const attributesToSave: any[] = []

    service.attributes.forEach(a => {
        const choicesToSave: any[] = []

        a.choices.forEach(c => {
            const choice = {
                ...c,
                value: JSON.stringify({...c.value})
            }
    
            choicesToSave.push(choice)
        })

        const attr = {
            ...a,
            choices: choicesToSave,
            instructions: JSON.stringify({...a.instructions})
        }

        attributesToSave.push(attr)
    })
    
    const serviceToSave = {
        ...service,          
        propertyId: propertyCode,
        name: JSON.stringify({ ...service.name }),
        description: JSON.stringify({ ...service.description }),
        attributes: attributesToSave       
    };

    return await axiosInstance.put(`${url}${propertyCode}/services/${service.id}`, serviceToSave).then((response) => {
        return response.data;
    });
}

export async function deleteService(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return await axiosInstance.delete(`${url}${propertyCode}/services/${id}`).then((response) => {
        return response.data;
    });
}