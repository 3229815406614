import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { default as MaterialCheckbox } from '@mui/material/Checkbox';
import { useDialog } from "./DetailsDialog";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider"
import { SxProps } from '@mui/system';
import { Theme } from "@mui/material/styles"

export type CheckboxChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
) => any;

export interface CheckboxProps {
    id?: string;
    label: string;
    checked?: boolean;
    disabled?: boolean;
    defaultChecked?: boolean;
    onChange: CheckboxChangeHandler;
    permissionKey?: string;
    height?: string | number;    
    sx?: SxProps<Theme>;
}

const Checkbox = (props: CheckboxProps) => {
    const {
        id = "checkboxDefault",
        label,
        checked,
        disabled,
        defaultChecked,
        onChange,
        height,
        sx
    } = props;
    const { dirty, setDirty, adding, permissionKey } = useDialog();
    const { checkAccess } = useUser();
    const [readOnly] = React.useState(!checkAccess(props.permissionKey ?? permissionKey, adding ? Access.Create : Access.Update));

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDirty(true);
        onChange(event);
    };

    return (
        <FormControlLabel disabled={disabled || readOnly} control={
                <MaterialCheckbox
                    checked={checked}
                    {...defaultChecked}
                    onChange={handleChange}                
                />
            } 
            label={label}
            sx={(height ? { height: height, ...sx } : { ...sx })}
        />
    );
};


export default Checkbox;