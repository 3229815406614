import { AxiosInstance } from "axios";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";
import { v4 as uuid } from "uuid";
import { OptionSet, Option } from "../../models/modules/roomservice/Option";
import { OptionSetToSave, stringifyOptionSets, OptionToSave, stringifyOptions } from "./helper";

const url = process.env.REACT_APP_MANAGER_API_URL + "/room-service/";

export async function getOptionSets(axiosInstance: AxiosInstance, propertyCode: string): Promise<OptionSet[]> {
    return await axiosInstance
        .get(`${url}${propertyCode}/optionsets`)
        .then((response) => parseLanguageStringsFromJson(response.data.optionSets) as OptionSet[]);
}

export async function createOptionSet(axiosInstance: AxiosInstance, propertyCode: string, optionSet: OptionSet, sectionId?: string) {
    const id = uuid();

    var optionsToSave = stringifyOptions(optionSet.options)
    
    const optionSetToSave = {
        ...optionSet,  
        id: id,        
        name: JSON.stringify({ ...optionSet.name }),        
        options: optionsToSave
    };

    return await axiosInstance.post(`${url}${propertyCode}/optionsets`, optionSetToSave).then((response) => {
        return id;
    });
}

export async function updateOptionSet(axiosInstance: AxiosInstance, propertyCode: string, optionSet: OptionSet) {
    var optionsToSave = stringifyOptions(optionSet.options)
    
    const optionSetToSave = {
        ...optionSet,                
        name: JSON.stringify({ ...optionSet.name }),        
        options: optionsToSave
    };

    return await axiosInstance.put(`${url}${propertyCode}/optionsets/${optionSet.id}`, optionSetToSave).then((response) => {
        return response.data;
    });
}

export async function deleteOptionSet(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return await axiosInstance.delete(`${url}${propertyCode}/optionsets/${id}`).then((response) => {
        return response.data;
    });
}

export async function saveOptionSetDisplayOrder(axiosInstance: AxiosInstance, propertyId: string, itemId: string, idList: string[]) {
    const response = axiosInstance.put(`${url}${propertyId}/items/${itemId}/optionsets/reorder`, idList).then((response) => {
        return response.status;
    });
}

export async function createOption(axiosInstance: AxiosInstance, propertyCode: string, optionSetId: string, option: Option, sectionId?: string) {
    const id = uuid();

    var optionSetsToSave: OptionSetToSave[] = [];
    if (option.optionSets !== null && option.optionSets.length > 0) {
        optionSetsToSave = stringifyOptionSets(option.optionSets);
    }
    
    const optionToSave = {
        ...option,  
        id: id,        
        name: JSON.stringify({ ...option.name }),        
        optionSets: optionSetsToSave,
        pricePerUnit: option.pricePerUnitString ? parseFloat(option.pricePerUnitString) : option.pricePerUnit
    };

    return await axiosInstance.post(`${url}${propertyCode}/optionsets/${optionSetId}/options`, optionToSave).then((response) => {
        return id;
    });
}

export async function updateOption(axiosInstance: AxiosInstance, propertyCode: string, optionSetId: string, option: Option) {
    var optionSetsToSave: OptionSetToSave[] = [];
    if (option.optionSets !== null && option.optionSets.length > 0) {
        optionSetsToSave = stringifyOptionSets(option.optionSets);
    }
    
    const optionToSave = {
        ...option,                
        name: JSON.stringify({ ...option.name }),        
        optionSets: optionSetsToSave,
        pricePerUnit: option.pricePerUnitString ? parseFloat(option.pricePerUnitString) : option.pricePerUnit
    };

    return await axiosInstance.put(`${url}${propertyCode}/optionsets/${optionSetId}/options/${option.id}`, optionToSave).then((response) => {
        return response.data;
    });
}

export async function deleteOption(axiosInstance: AxiosInstance, propertyCode: string, optionSetId: string, id: string) {
    return await axiosInstance.delete(`${url}${propertyCode}/optionsets/${optionSetId}/options/${id}`).then((response) => {
        return response.data;
    });
}

export async function saveOptionDisplayOrder(axiosInstance: AxiosInstance, propertyId: string, optionSetId: string, idList: string[]) {
    const response = axiosInstance.put(`${url}${propertyId}/optionsets/${optionSetId}/options/reorder`, idList).then((response) => {
        return response.status;
    });
}