import { useState, useEffect } from "react";
import { ServiceTicketNote } from '../../../models/dashboard/support/ServiceTicketNote'
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import TicketNote from "./TicketNote";
import { listNotes } from "../../../api/dashboard/support/noteApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { useUser } from "../../../providers/UserProvider";
import axios from "axios";
import Box from '@mui/material/Box';


export interface TicketNoteListProps {
    ticketId: string;
    mustGetNotes: boolean;
    notes?: ServiceTicketNote[];
    onNotesLoaded: () => void;
    onNotesChanged: () => void;
}

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

const TicketNoteList = (props: TicketNoteListProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadedNotes, setLoadedNotes] = useState<ServiceTicketNote[] | undefined>();
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);    
    const strings = useLocalizedStrings();
    const axiosInstance = useCreateAxios();   
    const { user } = useUser();    

    async function apiListNotes() {
        try {     
            setIsLoading(true);
            const response = await listNotes(axiosInstance, props.ticketId);     
            setLoadedNotes(response);                        
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };            
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingTicketNotes.replace("{{error}}", error.message),
                msgType: "error",
            });
        }
        finally {
            props.onNotesLoaded();
            setIsLoading(false);
        }
    }

    useEffect(() => {     
        if (props.mustGetNotes) { 
            apiListNotes();
        }
        else {
            setLoadedNotes(props.notes);            
        }
    }, [props.ticketId, props.notes, user.currentProperty?.code, strings.errorRetrievingTicketNotes]);

    return (
        <Box>
            {!isLoading && loadedNotes &&
                loadedNotes.map((note, index) => (
                    <TicketNote 
                        note={note} />
                ))
            }
        </Box>
    );
};

export default TicketNoteList;