import ErrorIcon from '@mui/icons-material/Error';
import Tab, { TabProps } from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import { Typography } from '@material-ui/core';

interface ValidateTabProps extends TabProps {        
    label: string;
    errors: Boolean;
}

const ValidateTab = (props: ValidateTabProps) => {        
    const { label, errors, ...rest } = props;
    return (
        <Tab label={(<Typography variant="inherit" color={props.errors ? "error" : "inherit"}>{label}</Typography>)} {...rest} />
    );
};

export default ValidateTab;
