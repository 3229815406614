import {useState, useEffect} from "react";
import { useCreateAxios } from './useCreateAxios';
import { Feature } from  "../models/accounts/Feature";
import { listFeatures } from "../api/accounts/featureApi";

// TODO: move the feature CRUD stuff into here and use this from feature mgmnt and for feature list in property mgmnt
// TODO: add enabled flag for determining url in featureApi
function useFeatures() {
    const [features, setFeatures] = useState<Feature[]>([]);
    const axiosInstance = useCreateAxios();

    useEffect(() => {
        const getData = async() => {
            const response = await listFeatures(axiosInstance);
            setFeatures(response);
        }

        getData();
    }, [])

    return {
        features
    }
}

export default useFeatures;