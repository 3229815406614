import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import MuiIconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from "@mui/material/Typography";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import { ReactNode, useEffect, useState } from "react";
import { getAccessPoints, getAccessPoint } from "../../../api/dashboard/network/devicesApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { AccessPoint as AccessPointModel, getInitialAccessPointState } from "../../../models/dashboard/network/AccessPoint";
import { useUser } from "../../../providers/UserProvider";
import { default as DataGrid } from "../../common/datatable/DataGridWrapper";
import { NotificationOptions } from "../../common/NotificationMessage";
import TimeSpan from "../../../utilities/classes/TimeSpan";
import { GridColumnHeaderParams } from "@mui/x-data-grid"
import CircularProgress from '@mui/material/CircularProgress';
import themePrimary from "../../../styles/themePrimary";

const AccessPoints = () => {
    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [accessPoints, setAccessPoints] = useState<AccessPointModel[]>([]);
    const [selectedAccessPoint, setSelectedAccessPoint] = useState<AccessPointModel>(getInitialAccessPointState());
    const [isLoading, setIsLoading] = useState(true);   
    const [isApLoading, setApIsLoading] = useState(false);   
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const strings = useLocalizedStrings();
    const theme = useTheme();    
    const axiosInstance = useCreateAxios();
    const { user } = useUser();  
   // const classes = useStyles();

    useEffect(() => {
        async function load() {
            try {
                setIsLoading(true);
                const payload = await getAccessPoints(axiosInstance, user.currentProperty?.code ?? "");
                setAccessPoints(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingAccessPoints.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }

            setIsLoading(false);
        }

        load();
    }, [user.currentProperty?.code, strings.errorRetrievingAccessPoints]);    

    function getStatusIcon(status: string) {
        switch (status)
        {
            case "Online": //Status.Online:
                return (                    
                    <CheckCircleIcon fontSize="small" color="success" />                        
                )
            case "Offline": //Status.Offline:
                return (                    
                    <ErrorIcon fontSize="small" color="error" />                        
                )
            case "Other": //Status.Other:
            default:
                return (                    
                    <WarningIcon fontSize="small" color="warning" />                
                )                
        }
    }

    function getStatus(status: string, statusText: string): ReactNode {
        return (
            <Stack direction="row" alignItems="center" gap={1}>
                {getStatusIcon(status)}
                {statusText}
            </Stack>
        )
    }

    async function getAp(id: string) {           
        try {
            setApIsLoading(true);
            const payload = await getAccessPoint(axiosInstance, user.currentProperty?.code ?? "", id);            
            setSelectedAccessPoint(payload);                            
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingAccessPoints.replace("{{error}}", error.message),
                msgType: "error",
            });            
        }

        setApIsLoading(false);        
    }

    function handleMoreButtonClicked(id: string, event: React.MouseEvent<HTMLElement>) {
        var accessPoint = accessPoints?.find(a => a.id === id);
        if (accessPoint) {
            if (!accessPoint.isComplete) {
                setAnchorEl(event.currentTarget);
                getAp(id);                
            }
            else {
                setSelectedAccessPoint(accessPoint);                
                setAnchorEl(event.currentTarget);
            }            
        }
    }

    function handleClose() {
        //setSelectedAccessPoint(getInitialAccessPointState());
        setAnchorEl(null);
    }

    function getStatusHeaderIcon() {
        var status = "Online";
        accessPoints.every(ap => {
            if (ap.status === "Offline") {
                status = "Offline";
                return false;
            }
            else if (ap.status === "Other") {
                status = "Other";
                return true;
            }
            else {
                return true;
            }
        });

        return getStatusIcon(status);
    }

    const gridColumns: GridColDef[] = [        
        { field: "status", renderHeader: (params: GridColumnHeaderParams) => (
            <Stack direction="row" alignItems="center" gap={1}>
                {getStatusHeaderIcon()}
                <Typography variant="body2" fontWeight={500}>{strings.status}</Typography>                
            </Stack>), 
            renderCell: (params) => getStatus(params.row.status, params.row.statusText), flex: 1 },
        { field: "name", headerName: strings.name, flex: 2 },
        { field: "group", headerName: strings.group, flex: 2 },          
        { field: "numberOfClients", headerName: strings.numberOfClients, flex: 1 },         
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [    
                <Tooltip title={strings.more}>                    
                    <MuiIconButton sx={{ color: "primary.dark" }} aria-label="more" component="label" onClick={(event: React.MouseEvent<HTMLElement>) => handleMoreButtonClicked(params.row.id, event)}>                                            
                        <MoreHorizIcon />
                    </MuiIconButton>
                </Tooltip>
            ],
        },
    ];
    {/*{isApLoading ?*/}
                    {/*    <CircularProgress size="small" color="secondary" /> :*/}
                    {/*    <MuiIconButton sx={{ color: "primary.dark" }} aria-label="more" component="label" onClick={(event: React.MouseEvent<HTMLElement>) => handleMoreButtonClicked(params.row.id, event)}>                                            */}
                    {/*        <MoreHorizIcon />*/}
                    {/*    </MuiIconButton>*/}
                    {/*}*/}

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 107px)" }}> 
            <DataGrid
                permissionKey="dashboard_network"
                getRowId={(row) => row.macAddress} 
                rows={accessPoints}
                columns={gridColumns}                    
                showAddButton={false}
                loading={isLoading}
            />
            <Typography variant="caption">{strings.apDisclaimer}</Typography>

            <Popover                
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {isApLoading ?
                    <Box sx={{
                            width: "100px",
                            height: "100px",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>                            
                        <CircularProgress sx={{ color: themePrimary.palette.primary.main }} />                            
                    </Box> :
                    <table cellSpacing={theme.spacing(1)} style={{ padding: theme.spacing(1) }}>
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.name}:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedAccessPoint.name}</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.macAddress}:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedAccessPoint.macAddress}</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.group}:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedAccessPoint.group}</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.model}:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedAccessPoint.model}</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.firmwareVersion}:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedAccessPoint.firmwareVersion}</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.numberOfClients}:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedAccessPoint.numberOfClients}</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.status}:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedAccessPoint.statusText}</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.uptime}:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedAccessPoint.status === "Online" ? new TimeSpan(selectedAccessPoint.uptime).toString("dhms") : ""}</Typography></td>
                        </tr>
                        {/*<tr>*/}
                        {/*    <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.lastSeen}:</Typography></td>*/}
                        {/*    <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedAccessPoint.lastSeen.toLocaleString()}</Typography></td>*/}
                        {/*</tr>*/}
                    </table>                
                }
            </Popover>
        </Box>
    )    
}

export default AccessPoints;