export interface Room {
    id: string;
    number: string;
    classes: { name: string }[];
    enabled: boolean;    
}

export interface RoomDetail {
    id: string;
    number: string;
    classes: { id: string; name: string }[];
    televisions: Tv[]
    enabled: boolean;    
}

export interface RoomSearchResponse {
    rooms: Room[];
}

export const initialRoomState : RoomDetail = {
    id: "",
    number: "",
    classes: [],
    televisions: [],
    enabled: false    
}

export interface Tv {
    id: string;
    name: string;
    showPreviewOnly: boolean;
}

export const initialTvState: Tv = {
    id: "",
    name: "",
    showPreviewOnly: false
}