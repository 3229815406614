import { AxiosInstance } from "axios";
import { Schedule } from "../../models/modules/messaging/Schedule";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";
import { parseToData } from "./helper";
import { v4 as uuid } from "uuid";

const url = process.env.REACT_APP_MANAGER_API_URL + "/messaging/";

export async function getSchedules(axiosInstance: AxiosInstance, propertyCode: string): Promise<Schedule[]> {    
    return await axiosInstance
        .get(`${url}${propertyCode}/schedules`)
        .then((response) => parseToData(response.data.schedules) as Schedule[]);
}

export async function createSchedule(axiosInstance: AxiosInstance, propertyCode: string, schedule: Schedule) {
    const id = uuid();

    const scheduleToSave = {
        ...schedule,  
        id: id,
        propertyId: propertyCode,
        toData: JSON.stringify(schedule.toData),
    };

    return await axiosInstance.post(`${url}${propertyCode}/schedules`, scheduleToSave).then((response) => {
        return response.data;
    });
}

export async function updateSchedule(axiosInstance: AxiosInstance, propertyCode: string, schedule: Schedule) {    
    const scheduleToSave = {
        ...schedule,          
        propertyId: propertyCode,
        toData: JSON.stringify(schedule.toData),
    };

    return await axiosInstance.put(`${url}${propertyCode}/schedules/${scheduleToSave.id}`, scheduleToSave).then((response) => {
        return response.data;
    });
}

export async function deleteSchedule(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return await axiosInstance.delete(`${url}${propertyCode}/schedules/${id}`).then((response) => {
        return response.data;
    });
}