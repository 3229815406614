import EditIcon from "@mui/icons-material/Edit";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { default as MuiIconButton } from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from '@mui/material/styles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import Typography from '@mui/material/Typography';
import axios from "axios";
import { useEffect, useState } from "react";
import { getConfiguration, saveConfiguration } from "../../../api/dashboard/support/configurationApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Configuration as ConfigurationModel, getInitialConfigurationState } from "../../../models/dashboard/support/Configuration";
import { useUser } from "../../../providers/UserProvider";
import { default as DetailsDialog } from "../../common/details/DetailsDialog";
import IconButton from "../../common/details/IconButton";
import { default as TextField } from '../../common/details/TextField';
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import { Access } from "../../../models/configuration/security/Permission";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

interface ValidationErrors {
    externalId: string;
}

const Configuration = () => {   
    const initialErrorState: ValidationErrors = {
        externalId: ""
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [gridRefresh, setGridRefresh] = useState(false);
    const [configuration, setConfiguration] = useState<ConfigurationModel>(getInitialConfigurationState()); 
    const [editConfiguration, setEditConfiguration] = useState<ConfigurationModel>(getInitialConfigurationState()); 
    const [isLoading, setIsLoading] = useState(true);       
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);    
    const [tabValue, setTabValue] = useState<number>(0);
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);    
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const open = Boolean(anchorEl);
    const strings = useLocalizedStrings();
    const theme = useTheme();    
    const axiosInstance = useCreateAxios();
    const { user, checkAccess } = useUser(); 

    useEffect(() => {
        async function load() {
            try {
                const payload = await getConfiguration(axiosInstance, user.currentProperty?.code ?? "");
                setConfiguration(payload);
                setEditConfiguration(JSON.parse(JSON.stringify(payload))); // HACK: https://stackoverflow.com/questions/42306712/react-one-state-variable-depends-on-multiple-other-states-variables    
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingConfiguration.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }

            setIsLoading(false);
        }

        load();
    }, [gridRefresh, user.currentProperty?.code, strings.errorRetrievingConfiguration]);

    function handleEditClick(event: React.MouseEvent<HTMLButtonElement>) {        
        setDetailsDialogOpen(true);
    }

    function handleExternalIdChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEditConfiguration((prevState) => ({
            ...prevState,
            externalId: event.target.value
        }));
    }
    
    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);
        setDetailsDialogOpen(false);                
        setEditConfiguration(JSON.parse(JSON.stringify(configuration))); // HACK: https://stackoverflow.com/questions/42306712/react-one-state-variable-depends-on-multiple-other-states-variables
    }

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);

        try {            
            await saveConfiguration(axiosInstance, user.currentProperty?.code ?? "", editConfiguration);            
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorUpdatingConfiguration.replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }    

        setDetailsDialogOpen(false);
        setGridRefresh(!gridRefresh);        
        return true;
    }

    function handleValidate() {
        return true;
        // TODO: need to figure out a dynamic way to validate
        //var errors = validate<ActivityModel, ValidationErrors>([
        //    { property: "name", type: ValidationType.Required, message: strings.validationActivityName },
        //    { property: "description", type: ValidationType.Required, message: strings.validationActivityDescription }
        //], selectedActivity);
        //if (errors) {
        //    setErrors(errors);
        //    return false;
        //}
        //else {
        //    return true;
        //}
    }

    if (isLoading) {
        return <Box><LinearProgress color={"primary"} variant={"query"} /></Box>;
    }
    else {
        return (
            <Box sx={{ padding: theme.spacing(2) }}>
                {checkAccess("configuration_support", Access.Update) &&
                    <>                    
                        <Tooltip title={strings.edit}>
                            <Button
                                variant="contained"
                                size="large"
                                aria-label="audit"
                                aria-haspopup="true"
                                onClick={handleEditClick}                                
                                startIcon={<EditIcon />}
                            >
                                {strings.edit}
                            </Button>
                        </Tooltip>
                        <Spacer y={2} />
                    </>
                }
                <Stack direction="row" alignItems="center">
                    <Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.externalId}:</Typography>
                    <Spacer />
                    <Typography sx={{ whiteSpace: 'nowrap' }}>{configuration.externalId}</Typography>                                 
                </Stack>

                <DetailsDialog
                    permissionKey="configuration_support"
                    open={detailsDialogOpen}
                    adding={false}
                    title={strings.editConfigurationTitle}
                    onValidateForm={handleValidate}
                    onCancelClick={handleCancelClick}
                    onSaveClick={handleSaveClick}
                    contentSize={{ width: 442, height: 94.91 }}
                >
                    <Box sx={{ padding: theme.spacing(2) }}>
                        <TextField
                            onChange={handleExternalIdChange}
                            label={strings.externalId}
                            value={editConfiguration.externalId}
                            width={300}
                            helperWidth={200}
                            error={Boolean(errors.externalId)}
                            helperText={strings.validationPromotionScheduleMonthlyDay}                            
                        />
                    </Box>
                </DetailsDialog>

                <NotificationMessage notificationState={[notify, setNotify]} />
            </Box>   
        )
    }
}

export default Configuration;