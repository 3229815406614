import { StringLanguageDict } from "../../common/LanguageModel";
import { parseLanguageStringsFromJson } from "../../../utilities/JsonHelper"
import { Access } from "./Permission";
import { Language } from "../settings/Language";

export interface User {
    id?: string;
    username: string;
    displayName?: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    jobTitle: string;
    enabled: boolean;
    lastSignedIn: Date | null,
    roles: UserRole[];
    properties: UserProperty[];
    permissions?: UserPermission[];
    currentProperty?: UserProperty;
    brands: UserBrand[];
    customers?: UserCustomer[]; 
    currentCustomer?: UserCustomer;
    currentBrand?: UserBrand;
    features?: string[];
}

export interface UserPermission {
    key: string;
    access: Access;
}

export interface UserRole {
    id: string;
    name?: string;
}

export interface UserProperty {
    id?: string;
    code: string;
    name?: StringLanguageDict;
    features?: string[];
    languages?: Language[] | undefined;
    timeZoneId?: string;
    latitude?: number;
    longitude?: number;
}

export interface UserBrand {
    id?: string;
    code: string;
    name?: StringLanguageDict;
    properties: UserProperty[];
}

// for admin site
export interface UserCustomer {
    id: string;
    name: string;
}

// for brand/property drop down in Layout
export interface UserBrandPropertiesKeyValue {
    key: string;
    value: string;
    properties: { key: string; value: string; }[]
}

export const initialUserState: User = {
    id: undefined,
    username: "",
    displayName: undefined,
    firstName: "",
    lastName: "",
    emailAddress: "",
    jobTitle: "",
    enabled: true,
    roles: [],
    lastSignedIn: null,
    properties: [],
    permissions: [],
    currentProperty: undefined,
    brands: [],
    customers: [],
    currentCustomer: undefined,
    currentBrand: undefined,
    features: [],
};

export const convertUserPropertiesToKeyValue = (properties: UserProperty[]): { key: string; value: string }[] => {
    let kvps: { key: string; value: string }[] = [];

/*    properties = parseLanguageStringsFromJson(properties);*/
    properties.forEach(p => kvps.push({key: p.code, value: p.name!!["en"]!!}))

    return kvps
}

export const convertUserBrandPropertiesToKeyValue = (brands: UserBrand[]): UserBrandPropertiesKeyValue[] => {
    let kvps: UserBrandPropertiesKeyValue[] = [];

    brands.forEach(b => {
        kvps.push({ key: b.code, value: b.name!!["en"]!!, properties: convertUserPropertiesToKeyValue(b.properties) })
    });

    return kvps;
}
