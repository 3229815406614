// Preprocess the toData JSON
export function parseToData(object: any) {   
    if (Array.isArray(object)) {
        // It's possible that we are parsing an array of objects
        return object.map(o => parse(o));
    }
    else {        
        return parse(object);
    }    
}

export function parse(rowObj: {}) {
    const cols = Object.keys(rowObj);
    const rows = Object.values(rowObj) as any;
    
    var result = rows.reduce(function (result: any, field: any, index: number) {       
        if (cols[index] === "toData") {            
            result[cols[index]] = JSON.parse(field);
        }        
        else {            
            result[cols[index]] = field;
        }

        return result;
    }, {});

    return result;
}