import * as React from 'react';

export const WrapperContext = React.createContext<WrapperState>({
    drawerMenuOpen: false,
    onDrawerMenuToggleHandler: (open: boolean) => { },
    activeMenuId: '',
    onMenuClickHandler: (id: string) => { },
    activeMenuItemId: '',
    onMenuItemClickHandler: (id: string) => { },
});

export type DrawToggleHandler = (open: boolean) => any;
export type MenuItemClickHandler = (id: string) => any;

interface WrapperState {
    drawerMenuOpen: boolean;
    onDrawerMenuToggleHandler: DrawToggleHandler
    activeMenuId: string;
    activeMenuItemId: string;
    onMenuClickHandler: MenuItemClickHandler
    onMenuItemClickHandler: MenuItemClickHandler
}

const WrapperContextProvider = (props: { children?: React.ReactNode }) => {
    const [drawerMenuOpen, setDrawerMenuOpen] = React.useState<boolean>(false);
    const [activeMenuId, setActiveMenuId] = React.useState<string>('');
    const [activeMenuItemId, setActiveMenuItemId] = React.useState<string>('');

    const setDrawerMenuOpenHandler = (open: boolean) => {
        setDrawerMenuOpen(open);
    };

    const setActiveMenuHandler = (id: string) => {
        setActiveMenuId(id);
    };

    const setActiveMenuItemHandler = (id: string) => {
        setActiveMenuItemId(id);
    };

    return (
        <WrapperContext.Provider value={{
            drawerMenuOpen: drawerMenuOpen,
            onDrawerMenuToggleHandler: setDrawerMenuOpenHandler,
            activeMenuId: activeMenuId,
            onMenuClickHandler: setActiveMenuHandler,
            activeMenuItemId: activeMenuItemId,
            onMenuItemClickHandler: setActiveMenuItemHandler,
        }}>
            {props.children}
        </WrapperContext.Provider>
    );
}

export default WrapperContextProvider;