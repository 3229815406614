export interface Device {
    id: string;
    externalId: string;    
    assignedOn: Date;
    lastActivityAt: Date;
    restartedAt: Date;
    television?: Television
}

export const deviceInitialState: Device = {    
    id: "",
    externalId: "",
    assignedOn: new Date(),
    lastActivityAt: new Date(),
    restartedAt: new Date(),
}

export interface Television {
    name: string;
    showPreviewOnly: boolean;
    room: Room;
}

export interface Room {
    number: string;
}