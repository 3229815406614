import { StringLanguageDict } from "../../common/LanguageModel";
//import { RoomClass } from "../roommanager/RoomClass";

export interface Promotion {
    id: string;
    internalName: string;
    items: PromotionItem[];
}

export const initialPromotionState: Promotion = {
    id: "",
    internalName: "",
    items: []
}

export function mapPromotionsToKeyValues(promotions: Promotion[]): { key: string; value: string }[] {
    let kvps: { key: string; value: string }[] = [];

    kvps = promotions.map((promotion, index) => {
        return {
            key: promotion.id,
            value: promotion.internalName,
        };
    });

    return kvps;
}

export interface PromotionItem {
    id: string;
    type: number;
    name?: StringLanguageDict | null;
    description?: StringLanguageDict | null;
    navigationLocationCode?: StringLanguageDict | null;
    roomClasses: { id: string; name: string }[];
    previewOnly: boolean;
}

export const initialPromotionItemState: PromotionItem = {
    id: "",
    type: 0,
    name: null,
    description: null,
    navigationLocationCode: null,
    roomClasses: [],
    previewOnly: false
}

export function getPromotionItemTypeStringKey(type: number): string  {
    switch (type) {
        case 0:
            return "customPromotion";
        default:
            return "customPromotion";
    }
}