import { AxiosInstance } from "axios";
import { GraphicModel } from "../../../models/common/GraphicModel"

const url = process.env.REACT_APP_MANAGER_API_URL + "/images/system/";

export async function saveGraphic(
    axiosInstance: AxiosInstance,
    itemKey: string,
    image: GraphicModel,
    propertyId: string
  ) {
    const formData = new FormData();
    formData.append("PropertyId", propertyId);  
    
    if (image.fileData) {
        formData.append(image.imageKey, image.fileData, image.fileData.name);
    }    
  
    const config = {
      headers: {
        Accept: "application/json",
        "content-type": "multipart/form-data",
      },
    };
  
    return await axiosInstance.put(
      url,
      formData,
      config
    );
  }

  export async function saveBrandGraphic(
    axiosInstance: AxiosInstance,
    itemKey: string,
    image: GraphicModel,
    brandId: string
  ) {
    const formData = new FormData();
    formData.append("BrandId", brandId);  
    
    if (image.fileData) {
        formData.append(image.imageKey, image.fileData, image.fileData.name);
    }    
  
    const config = {
      headers: {
        Accept: "application/json",
        "content-type": "multipart/form-data",
      },
    };
  
    return await axiosInstance.put(
      url,
      formData,
      config
    );
  }

export async function saveDefaultGraphic(
    axiosInstance: AxiosInstance,
    itemKey: string,
    image: GraphicModel    
  ) {
    const formData = new FormData();
    
    if (image.fileData) {
        formData.append(image.imageKey, image.fileData, image.fileData.name);
    }    
  
    const config = {
      headers: {
        Accept: "application/json",
        "content-type": "multipart/form-data",
      },
    };
  
    return await axiosInstance.put(
      url,
      formData,
      config
    );
  }