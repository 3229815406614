import EditIcon from "@mui/icons-material/Edit";
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import axios from "axios";
import { useEffect, useState } from "react";
import { getOptions, saveOptions } from "../../../api/roomservice/configurationApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { EmailOptions, Options as OptionsModel, ProviderType, initialOptionsState } from "../../../models/modules/roomservice/ConfigurationOptions";
import { useUser } from "../../../providers/UserProvider";
import { Type as ValidationType, Field as ValidationField, validate } from "../../../utilities/Validator";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import { default as DetailsDialog } from "../../common/details/DetailsDialog";
import { default as DialogSelect } from "../../common/details/Select";
import { default as TextField } from '../../common/details/TextField';
import { Access } from "../../../models/configuration/security/Permission";

interface ValidationErrors {
    taxRate: string
    toAddress: string,
    fromAddress: string
}

const Configuration = () => {   
    const initialErrorState: ValidationErrors = {
        taxRate: "",
        toAddress: "",
        fromAddress: ""
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [gridRefresh, setGridRefresh] = useState(false);
    const [options, setOptions] = useState<OptionsModel>(initialOptionsState); 
    const [editOptions, setEditOptions] = useState<OptionsModel>(initialOptionsState); 
    const [isLoading, setIsLoading] = useState(true);       
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);        
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);    
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const open = Boolean(anchorEl);
    const strings = useLocalizedStrings();
    const theme = useTheme();    
    const axiosInstance = useCreateAxios();
    const { user, checkAccess, hasIncludedPropertyFeatures } = useUser();     

    useEffect(() => {
        async function load() {
            try {                
                const payload = await getOptions(axiosInstance, user.currentProperty?.code ?? "");
                setOptions(payload);
                setEditOptions(JSON.parse(JSON.stringify(payload))); // HACK: https://stackoverflow.com/questions/42306712/react-one-state-variable-depends-on-multiple-other-states-variables                
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingConfiguration.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }

            setIsLoading(false);
        }

        load();
    }, [gridRefresh, user.currentProperty?.code, strings.errorRetrievingConfiguration]);
    function handleEditClick(event: React.MouseEvent<HTMLButtonElement>) {        
        setDetailsDialogOpen(true);
    }

    function handleTaxRateChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEditOptions((prevState) => ({
            ...prevState,
            taxRate: parseFloat(event.target.value)
        }));
    }

    const handleProviderChange = (event: SelectChangeEvent) => {
        setEditOptions((prevState) => ({
            ...prevState,
            provider: event.target.value as ProviderType
        }));
    };

    function handleEmailToAddressChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEditOptions((prevState) => ({
            ...prevState,
            email: {
                ...prevState.email,
                toAddress: event.target.value
            } as EmailOptions
        }));
    }

    function handleEmailFromAddressChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEditOptions((prevState) => ({
            ...prevState,
            email: {
                ...prevState.email,
                fromAddress: event.target.value
            } as EmailOptions
        }));
    }
       
    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);
        setDetailsDialogOpen(false);                
        setEditOptions(JSON.parse(JSON.stringify(options))); // HACK: https://stackoverflow.com/questions/42306712/react-one-state-variable-depends-on-multiple-other-states-variables
    }

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);
              
        var optionsToSave: OptionsModel = {
            ...editOptions,
            email: editOptions.provider === "EMAIL" ? editOptions.email as EmailOptions : undefined
        }

        try {            
            await saveOptions(axiosInstance, user.currentProperty?.code ?? "", optionsToSave);            
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorUpdatingConfiguration.replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }    

        setNotify({
            isOpen: true,
            message: strings.roomServiceConfigSavedSuccessfully,
            msgType: "success",
        });

        setDetailsDialogOpen(false);
        setGridRefresh(!gridRefresh);        
        return true;
    }

    function handleValidate() {   
        var validators: ValidationField[] = [
            { property: "taxRate", type: ValidationType.Required, message: strings.validationRoomServiceConfigTaxRate },
            { property: "provider", type: ValidationType.Required, message: strings.validationRoomServiceConfigProvider }
        ];

        if (editOptions.provider === "EMAIL") {
            validators.push({ property: "email.toAddress", type: ValidationType.Required, message: strings.validationRoomServiceConfigEmailTo, errorProperty: "toAddress" });
            validators.push({ property: "email.fromAddress", type: ValidationType.Required, message: strings.validationRoomServiceConfigEmailFrom, errorProperty: "fromAddress" });
        }

        var errors = validate<OptionsModel, ValidationErrors>(validators, editOptions);
                                
        if (errors) {
            setErrors(errors);
            return false;
        }
        else {
            return true;
        }
    }

    function getProviderString(provider: ProviderType) {
        switch (provider) {
            case "EMAIL":
                return strings.roomServiceProviderEmail;
            default:
                return strings.roomServiceProviderNone;
        }
    }

    if (isLoading) {
        return <Box><LinearProgress color={"primary"} variant={"query"} /></Box>;
    }
    else {
        return (
            <Box sx={{ padding: theme.spacing(2) }}>                
                { checkAccess("configuration_roomservice", Access.Update, true) &&
                    <>
                        <Tooltip title={strings.edit}>
                            <Button
                                variant="contained"
                                size="large"
                                aria-label="audit"
                                aria-haspopup="true"
                                onClick={handleEditClick}                                
                                startIcon={<EditIcon />}
                            >
                                {strings.edit}
                            </Button>
                        </Tooltip>                        
                        <Spacer y={2} />
                    </>
                }                
                <table cellSpacing={theme.spacing(1)} style={{ marginLeft: theme.spacing(-1) }}>   
                    <tbody>
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.taxRate}:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{options.taxRate}</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.provider}:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{getProviderString(options.provider)}</Typography></td>
                        </tr>
                        { options.provider === "EMAIL" &&
                            <>
                                <tr>
                                    <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.toAddress}:</Typography></td>
                                    <td><Typography sx={{ whiteSpace: 'nowrap' }}>{options.email?.toAddress}</Typography></td>
                                </tr>
                                <tr>
                                    <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.fromAddress}:</Typography></td>
                                    <td><Typography sx={{ whiteSpace: 'nowrap' }}>{options.email?.fromAddress}</Typography></td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>                                      

                <DetailsDialog
                    permissionKey="content_roomservice"
                    open={detailsDialogOpen}
                    adding={false}
                    title={strings.editConfigurationTitle}
                    onValidateForm={handleValidate}
                    onCancelClick={handleCancelClick}
                    onSaveClick={handleSaveClick}
                    contentSize={{ width: 648, height: 437.53 }}
                >
                    
                    <Box sx={{ padding: theme.spacing(2) }}>   
                        <TextField
                            id="TaxRate"
                            value={editOptions.taxRate.toString()}
                            type="number"
                            InputProps={{ inputProps: { min: 0 }}}
                            label={strings.taxRate}
                            width={100}                            
                            onChange={handleTaxRateChange}                            
                            error={Boolean(errors.taxRate)}
                            helperText={strings.validationPromotionScheduleMonthlyDay}
                        />
                        <Spacer />
                        <DialogSelect
                            label={strings.provider}
                            keyValues={[{ key: "NONE", value: strings.roomServiceProviderNone }, { key: "EMAIL", value: strings.roomServiceProviderEmail }]}
                            selectedValue={editOptions.provider}
                            onChangeHandler={handleProviderChange}
                            sx={{ minWidth: 150 }}
                        />                        
                        <Spacer />
                        { editOptions.provider === "EMAIL" &&
                            <>
                                <TextField
                                    onChange={handleEmailToAddressChange}                                            
                                    label={strings.toAddress}
                                    value={editOptions.email?.toAddress ?? ""}
                                    width={400}                                    
                                    error={Boolean(errors.toAddress)}
                                    helperText={strings.validationRoomServiceConfigEmailTo}                                            
                                />                                                                        
                                <Spacer />  
                                <TextField
                                    onChange={handleEmailFromAddressChange}                                            
                                    label={strings.fromAddress}
                                    value={editOptions.email?.fromAddress ?? ""}
                                    width={400}                                    
                                    error={Boolean(errors.fromAddress)}
                                    helperText={strings.validationRoomServiceConfigEmailFrom}                                            
                                />      
                            </>
                        }
                    </Box>                                                                                                                              
                </DetailsDialog>

                <NotificationMessage notificationState={[notify, setNotify]} />
            </Box>   
        )
    }
}

export default Configuration;