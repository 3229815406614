import { BrandItemDetailModel, BrandItemModel } from "../../models/brandModels";
import { AxiosInstance } from "axios";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";

const url = process.env.REACT_APP_MANAGER_API_URL + "/account-management/brands/";

export function getBrandGridData(axiosInstance: AxiosInstance) {
    return axiosInstance
        .get(url)
        .then((response) => response.data.brands as BrandItemModel[]);
}

export function getBrandById(axiosInstance: AxiosInstance, id: string) {
    return axiosInstance
        .get(`${url}${id}`)
        .then((response) => parseLanguageStringsFromJson(response.data.brand));
}

export function updateBrand(axiosInstance: AxiosInstance, brand: BrandItemDetailModel) {
    const brandToSave = {
        ...brand,
        name: JSON.stringify({ ...brand.name }),
        description: JSON.stringify({ ...brand.description }),
        title: JSON.stringify({ ...brand.title }),
    };

    return axiosInstance.put(`${url}${brandToSave.id}`, brandToSave).then((response) => {
        return response.data;
    });
}

export function createBrand(axiosInstance: AxiosInstance, brand: BrandItemDetailModel) {
    const brandToSave = {
        ...brand,
        name: JSON.stringify({ ...brand.name }),
        description: JSON.stringify({ ...brand.description }),
        title: JSON.stringify({ ...brand.title }),
    };

    return axiosInstance.post(url, brandToSave).then((response) => response.data);
}

export function deleteBrand(axiosInstance: AxiosInstance, id: string) {
    return axiosInstance.delete(`${url}${id}`).then((response) => response.data);
}
