import { StringLanguageDict } from "../../common/LanguageModel";

export interface ServiceAttributeChoice {
    id: string;
    value?: StringLanguageDict | null;
    displayOrder: number;
}

export const initialServiceAttributeChoiceState: ServiceAttributeChoice = {
    id: "",
    value: null,
    displayOrder: 0
}