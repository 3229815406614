import Typography from "@mui/material/Typography";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { makeStyles } from "@mui/styles";
import { Link } from "@mui/material";

const useStyles = makeStyles({
    icon: {
        fontSize: "3rem !important",
        color: "#00838f",
    },
    root: {
        display: "flex",
    },
    textContainer: {
        width: "600px",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "40px",
    },
    textHeader: {
        paddingBottom: "20px",
    },
});

const NotAuthorized = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.textContainer}>
                <DoDisturbIcon className={classes.icon} />
                <Typography variant="h4" component="div" className={classes.textHeader}>
                    Not Authorized
                </Typography>
                <Typography  variant="body1" component="div">
                    You are not authorized to view the requested page. You can click your back button or <Link href="/">go to our homepage</Link>. We apologize for the inconvenience.
                </Typography>
            </div>
        </div>
    );
};

export default NotAuthorized;