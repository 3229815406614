import { AxiosInstance } from "axios";
import { SkinOption } from "../../../models/administration/SkinOption";
  
const url = process.env.REACT_APP_MANAGER_API_URL + "/skin/options/";

export async function getSkinOption(axiosInstance: AxiosInstance, propertyId: string): Promise<SkinOption> {
    return await axiosInstance
        .get(`${url}${propertyId}`)
        .then(
            (response) =>
                response.data.option as SkinOption
        );
}

export async function updateSkinOption(axiosInstance: AxiosInstance, propertyId: string, option: SkinOption) {
    return await axiosInstance
        .put(`${url}${propertyId}`, option)
        .then(
            (response) => response.data
        );
}