import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import { RoomClass, RoomClassDetail, RoomClassResponse} from "../models/modules/roommanager/RoomClass";

const URL = process.env.REACT_APP_MANAGER_API_URL + `/property-management/`;
/*
    see Swagger for endpoints.
    https://dev-api.bbhnow.com/manager/docs/index.html
*/

export function getRoomClassGridData(axiosInstance: AxiosInstance, propertyCode: string): Promise<RoomClassResponse> {
    return axiosInstance
        .get(`${URL}${propertyCode}/roomclasses`)
        .then(response => response.data as RoomClassResponse);
}

export function getRoomClassById(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return axiosInstance
        .get(`${URL}${propertyCode}/roomclasses/${id}`)
        .then((response) => response.data as RoomClass);
}

export async function createRoomClass(axiosInstance: AxiosInstance, propertyCode: string, roomClass: RoomClassDetail, roomIds: { id: string; action: "add" | "remove" }[]) {
    const addedRoomIds = roomIds.filter(item => item.action === "add").map(({id}) => (id));

    // upgrade uuid to v4.
    const roomClassToSave = {
        ...roomClass,
        id: uuid(),
    };

    await axiosInstance.post(`${URL}${propertyCode}/roomclasses`, roomClassToSave);
    
    if(addedRoomIds.length > 0) {
        await axiosInstance.post(`${URL}${propertyCode}/roomclasses/${roomClassToSave.id}/addRooms`, addedRoomIds);
    }
}

export async function updateRoomClass(axiosInstance: AxiosInstance, propertyCode: string, roomClass: RoomClassDetail, roomIds: { id: string; action: "add" | "remove" }[]) {
    const removedRoomIds = roomIds.filter(item => item.action === "remove").map(({id}) => (id));
    const addedRoomIds = roomIds.filter(item => item.action === "add").map(({id}) => (id));
    
    await axiosInstance.put(`${URL}${propertyCode}/roomclasses/${roomClass.id}`, roomClass);

    if(addedRoomIds.length > 0) {
        await axiosInstance.post(`${URL}${propertyCode}/roomclasses/${roomClass.id}/addRooms`, addedRoomIds);
    }

    if (removedRoomIds.length > 0) {
        await axiosInstance.patch(`${URL}${propertyCode}/roomclasses/${roomClass.id}/removeRooms`, removedRoomIds);
    }
}

export function deleteRoomClass(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return axiosInstance.delete(`${URL}${propertyCode}/roomclasses/${id}`).then((response) => response.data);
}

// for transfer list/roomclass component
export async function getClassesForTransferList(axiosInstance: AxiosInstance, propertyCode: string) : Promise<{id: string; name: string;}[]> {
    const response = await axiosInstance.get<RoomClassResponse>(`${URL}${propertyCode}/roomclasses?enabled=true`);
    return response.data.roomClasses.map(({id, name}: {id: string, name:string}) => ({id, name}));
  }
  