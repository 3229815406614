import { AxiosInstance } from "axios";
import { Status } from "../../models/pms/Status";

const url = process.env.REACT_APP_MANAGER_API_URL + "/pms/controller/";

export async function getHostStatuses(axiosInstance: AxiosInstance): Promise<Status[]> {
    return await axiosInstance
        .get(`${url}hosts`)
        .then((response) => response.data.statuses as Promise<Status[]>);
}

export async function startHost(axiosInstance: AxiosInstance, propertyId: string) {    
    return await axiosInstance.post(`${url}hosts/${propertyId}`).then((response) => {
        return response.data;
    });
}

export async function stopHost(axiosInstance: AxiosInstance, propertyId: string, force: boolean) {    
    return await axiosInstance.delete(`${url}hosts/${propertyId}?force=${force}`).then((response) => {
        return response.data;
    });
}

export async function restartHost(axiosInstance: AxiosInstance, propertyId: string, force: boolean) {    
    return await axiosInstance.put(`${url}hosts/${propertyId}?force=${force}`).then((response) => {
        return response.data;
    });
}