import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PreferenceList from "./PreferenceList";
import RestaurantList from "./RestaurantList";

const Restaurants = () => {
    const strings = useLocalizedStrings();   
    const location = useLocation();
    const theme = useTheme();
    
    return (
        <Box sx={{ width: '100%' }}>            
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={location.pathname} ria-label="basic tabs example">
                    <Tab value="/restaurants/restaurants" label={strings.restaurants} component={Link} to="restaurants" />
                    <Tab value="/restaurants/preferences" label={strings.preferences} component={Link} to="preferences" />
                </Tabs>
            </Box>            
            <Box component="main" sx={{ flexGrow: 1, padding: 0 }}>
                <Routes>
                    <Route path="restaurants" element={<RestaurantList />} />
                    <Route path="preferences" element={<PreferenceList />} />
                </Routes>
            </Box>
        </Box>
    );
};

export default Restaurants;
