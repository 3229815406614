import { User } from "./User";

export interface Role {
    id?: string;
    name: string;
    enabled: boolean;
    permissions: RolePermission[];
    users?: User[];
}

export const initialRoleState: Role = {
    id: undefined,
    name: "",
    enabled: true,
    permissions: [],
    users: []
};

export interface RolePermission {
    typeId: string;
    access: number
}
