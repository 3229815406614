export interface RoomClass {
    id: string;
    name: string;
    enabled: boolean;
    rooms: { id: string; number: string; }[]
}

export interface RoomClassDetail {
    id: string;
    name: string;
    enabled: boolean;
}

export interface RoomClassResponse {
    roomClasses: RoomClass[]
}

export const initialItemState: RoomClass = {
    id: "",
    name: "",
    enabled: false,
    rooms: []
}