import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import * as React from "react";
import ModuleViews from "./ModuleViews";
import ItemViews from "./ItemViews";
import ContactViews from "./ContactViews";
import CheckoutEvents from "./CheckoutEvents";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const ReportingHome = () => {
    const strings = useLocalizedStrings();
    const location = useLocation();
    const theme = useTheme();

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={location.pathname} aria-label="basic tabs example">
                    <Tab value="/reporting/itv/modules" label={strings.reportingModuleViewsTabLabel} component={Link} to="modules" />
                    <Tab value="/reporting/itv/items" label={strings.reportingItemViewsTabLabel} component={Link} to="items" />
                    <Tab value="/reporting/itv/contactviews" label={strings.reportingContactViewsTabLabel} component={Link} to="contactviews" />
                    <Tab value="/reporting/itv/checkouts" label={strings.reportingCheckoutEventsTabLabel} component={Link} to="checkouts" />
                </Tabs>
            </Box>
            <Box component="main" sx={{ flexGrow: 1, padding: theme.spacing(2) }}>
                <Routes>
                    <Route path="modules" element={<ModuleViews />} />
                    <Route path="items" element={<ItemViews />} />
                    <Route path="contactviews" element={<ContactViews />} />
                    <Route path="checkouts" element={<CheckoutEvents />} /> 
                </Routes>
            </Box>
        </Box>
    );
};

export default ReportingHome;