export interface Guest {
    id: string,
    firstName: string,
    lastName: string,
    fullName: string,
    roomNumber: string,
    checkedIn: boolean,
    checkInDate: string,
    checkOutDate: string,
    loyaltyLevel: string,
    bookingId: string,
    groupId: string,
    externalKey: string,
    folioNumber: string
}

export interface Summary {
    guestCount: number,
    roomCount: number
}

export const guestInitialState: Guest = {    
    id: "",    
    firstName: "",
    lastName: "",
    fullName: "",
    roomNumber: "",
    checkedIn: false,
    checkInDate: "",
    checkOutDate: "",
    loyaltyLevel: "",
    bookingId: "",
    groupId: "",
    externalKey: "",
    folioNumber: ""
}