import Box from "@mui/material/Box";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme, Theme } from '@mui/material/styles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import Bandwidth from "./Bandwidth";
import AccessPoints from "./AccessPoints";
import Notifications from "./Notifications";
import Status from "./Status";
import Configuration from "./Configuration";
import { FeatureType, useUser } from "../../../providers/UserProvider";
import { Access } from "../../../models/configuration/security/Permission";
import Circuit from "./Circuit";

const Network = () => {
    const theme = useTheme();
    const strings = useLocalizedStrings();   
    const location = useLocation();
    const { user, hasIncludedFeatures, hasIncludedPropertyFeatures, checkAccess } = useUser();

    function getHeight(): string {
        if (user.currentProperty) {
            return "calc(100vh - 113px)";
        }
        else {
            return "calc(100vh - 64px)";
        }
    }

    function innerHasIncludedFeatures(featureTypes: FeatureType[]) {
        if (user.currentProperty) {            
            // If we have a property seleceted
            return hasIncludedPropertyFeatures(featureTypes);
        }
        else {
            // If we have a brand selected
            return hasIncludedFeatures(featureTypes);
        }
    }

    // The only way I could show the map full screen was to remove the usual padding we do for tabs and set height to 100vh - 113 (height of the top and tab bars). For the bandwidth and ap tabs we'll add back the padding on the page.
    return (
        <Box sx={{ width: '100%' }}>              
            { user.currentProperty &&                           
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={location.pathname} ria-label="basic tabs example">
                        { innerHasIncludedFeatures(["NETWORK", "NETWORK_CIRCUITONLY", "POTS"]) &&
                            <Tab value="/network/status" label={strings.status} component={Link} to="status" />
                        }
                        { innerHasIncludedFeatures(["NETWORK"]) && !innerHasIncludedFeatures(["CIRCUIT"]) &&
                            <Tab value="/network/bandwidth" label={strings.bandwidth} component={Link} to="bandwidth" />
                        }
                        {/* innerHasIncludedFeatures(["NETWORK"]) &&
                            <Tab value="/network/circuit" label={strings.circuit} component={Link} to="circuit" />
                    */}
                        { innerHasIncludedFeatures(["WIFI"]) &&
                            <Tab value="/network/accesspoints" label={strings.accessPoints} component={Link} to="accesspoints" />
                        }

                        {/*(innerHasIncludedFeatures(["WIFI"]) || innerHasIncludedFeatures(["NETWORK"]) || innerHasIncludedFeatures(["POTS"])) &&
                            <Tab value="/network/notifications" label={strings.notifications} component={Link} to="notifications" />
                    */}

                        { checkAccess("configuration_network", Access.Read | Access.Update, true) &&
                            <Tab value="/network/configuration" label={strings.configuration} component={Link} to="configuration" />
                        }
                    </Tabs>
                </Box>                
            }            
            <Box component="main" sx={{ flexGrow: 1, padding: 0, height: getHeight() }}>
                <Routes>
                    <Route path="status" element={<Status />} />
                    <Route path="bandwidth" element={<Bandwidth />} />
                    <Route path="circuit" element={<Circuit />} />
                    <Route path="accesspoints" element={<AccessPoints />} />
                    <Route path="notifications" element={<Notifications />} />
                    <Route path="configuration" element={<Configuration />} />
                </Routes>
            </Box>
        </Box>
    );
};

export default Network;
