import { AxiosInstance, CancelTokenSource } from "axios";
import { abort } from "process";
import { NetworkStatus } from "../../../models/dashboard/network/NetworkStatus";
import { PropertyItemDetailModel } from "../../../models/propertyModels";
import { parseLanguageStringsFromJson } from "../../../utilities/JsonHelper";

const url = process.env.REACT_APP_MANAGER_API_URL + "/network-monitor/";

export async function listProperties(axiosInstance: AxiosInstance, brandId?: string) {
    var u = `${url}properties`
    if (brandId) {
        u += `?brandId=${brandId}`;
    }

    return await axiosInstance
        .get(u)
        .then((response) => parseLanguageStringsFromJson(response.data.properties) as PropertyItemDetailModel[]);
}

export async function getPropertyById(axiosInstance: AxiosInstance, id: string) {
    return await axiosInstance
        .get(`${url}properties/${id}`)
        .then((response) => parseLanguageStringsFromJson(response.data.property) as PropertyItemDetailModel);
}

export async function getBandwidthGraph(axiosInstance: AxiosInstance, propertyCode: string): Promise<string> {
    return await axiosInstance
        .get(`${url}${propertyCode}/network/bandwidth`)
        .then((response) => response.data.url as string);
}

export async function getNetworkStatus(axiosInstance: AxiosInstance, propertyCode: string, cancelToken: CancelTokenSource): Promise<NetworkStatus> {
    return await axiosInstance       
        .get(`${url}${propertyCode}/network/status`, { cancelToken: cancelToken.token })
        .then((response) => parseLanguageStringsFromJson(response.data).networkStatus as NetworkStatus);        
}
