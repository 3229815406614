import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import * as React from "react";
import TicketList from "./TicketList"
import TicketSummary from "./TicketSummary"
import Configuration from "./Configuration"
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { useUser } from "../../../providers/UserProvider";
import { Access } from "../../../models/configuration/security/Permission";
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const SupportHome = () => {
    const strings = useLocalizedStrings();
    const location = useLocation();
    const { checkAccess } = useUser();
    const theme = useTheme();

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={location.pathname} aria-label="basic tabs example">
                    <Tab value="/support/summary" label={strings.supportSummaryTabLabel} component={Link} to="summary" />
                    <Tab value="/support/tickets" label={strings.supportTicketsTabLabel} component={Link} to="tickets" />
                    { checkAccess("configuration_support", Access.Read | Access.Update, true) &&
                        <Tab value="/network/configuration" label={strings.configuration} component={Link} to="configuration" />
                    }
                </Tabs>
            </Box>
            <Box component="main" sx={{ flexGrow: 1, padding: 0 }}>
                <Routes>
                    <Route path="summary" element={<TicketSummary />} />
                    <Route path="tickets" element={<TicketList />} />
                    <Route path="configuration" element={<Configuration />} />
                </Routes>
            </Box>
        </Box>
    );
};

export default SupportHome;