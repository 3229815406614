import { StringLanguageDict } from "../../common/LanguageModel";
import { Orderable } from "../../../components/common/DisplayOrderButton";
import { Item } from "./Item";

export interface Menu {
    id: string;
    name?: StringLanguageDict | null; 
    startTime?: Date | null;
    endTime?: Date | null;
    menuSections: Section[];
    roomClasses: { id: string; name: string }[];
}

export const initialMenuState: Menu = {
    id: "",
    name: null,
    startTime: null,
    endTime: null,
    menuSections: [],
    roomClasses: []
}

export interface Section extends Orderable {
    id: string;
    name?: StringLanguageDict | null;  
    items: Item[];
}

export const initialSectionState: Section = {
    id: "",
    name: null,
    items: [],
    displayOrder: 0
}



