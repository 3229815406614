import FastfoodIcon from '@mui/icons-material/Fastfood';
import NoFoodIcon from '@mui/icons-material/NoFood';
import { GridActionsCellItem } from "@mui/x-data-grid";
import React from "react";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from '../../../providers/UserProvider';

interface DataGridSoldOutButtonProps {
    rowId: string;  
    soldOut: boolean;
    clickHandler: (id: string, event: React.MouseEvent<HTMLButtonElement>) => void;
}

const DataGridSoldOutButton = (props: DataGridSoldOutButtonProps) => { 
    const strings = useLocalizedStrings();
    const { checkAccess } = useUser();

    return (
        <div>      
            {checkAccess("content_roomservice", Access.Update) &&
                <GridActionsCellItem
                    icon={props.soldOut ? <FastfoodIcon titleAccess={strings.available} /> : <NoFoodIcon titleAccess={strings.soldOut} sx={{ color: "error.main" }} />}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => props.clickHandler(props.rowId, event)}
                    label={strings.soldOut}
                />    
            }
        </div>
    );
}

export default DataGridSoldOutButton;