import { StringLanguageDict } from "../../common/LanguageModel";

export interface LocationModel {
    id: string;
    name?: StringLanguageDict | null;
    description?: StringLanguageDict | null;   
    enabled: boolean;
}

export const initialLocationState: LocationModel = {
    id: "",
    name: null,
    description: null,
    enabled: true
}

export function mapLocationsToKeyValues(activities: LocationModel[]): { key: string; value: string }[] {
    let kvps: { key: string; value: string }[] = [];

    kvps = activities.map((activity, index) => {
        return {
            key: activity.id,
            value: activity.name?.en ?? "",
        };
    });

    return kvps;
}