import Typography from "@mui/material/Typography";
import { useAuth } from "oidc-react";
import React, { useRef, useState } from "react";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { GraphicModel } from "../../../models/common/GraphicModel";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";
import themePrimary from "../../../styles/themePrimary";
import SystemGraphicUploader from "./SystemGraphicUploader";
import { saveGraphic, saveBrandGraphic, saveDefaultGraphic } from "../../../api/administration/skin/systemGraphicsApi";
import axios from "axios";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import BackgroundGraphic from "../../../images/system_graphic_background.png"

const useStyles = makeStyles(theme => ({
    colorPickerTransparent: {
        height: '25px',
        width: '25px',
        border: '1px solid #000000',
        marginLeft: '10px',
        cursor: 'pointer',
    },
    colorPickerWhite: {
        backgroundColor: '#ffffff',
        height: '25px',
        width: '25px',
        border: '1px solid #000000',
        marginLeft: '10px',
        marginRight: '10px',
        cursor: 'pointer',
    },
    colorPickerBlack: {
        backgroundColor: '#000000',
        height: '25px',
        width: '25px',
        cursor: 'pointer',
    },
    imageContainer: {
        display: 'flex',
    },
    imageBackgroundWhite: {
        border: "solid",
        borderWidth: 1,
        borderColor: themePrimary.palette.secondary.main,
        backgroundColor: '#ffffff',
    },
    imageBackgroundBlack: {
        border: "solid",
        borderWidth: 1,
        borderColor: themePrimary.palette.secondary.main,
        backgroundColor: '#000000',
    },
    imageBackgroundTransparent: {
        border: "solid",
        borderWidth: 1,
        borderColor: themePrimary.palette.secondary.main
    },
}));

const imageTitleStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    ml: 2,
    color: "#000000",
    fontSize: themePrimary.typography.subtitle1.fontSize,
} as const;

const imageStyle = {    
    border: "solid",
    borderWidth: 1,
    borderColor: themePrimary.palette.secondary.main
} as const;

interface SystemGraphicProps {
    imageKey: string,
    label: string;
    height: number,
    width: number,    
    brandId: string,
    propertyId: string    
}

function SystemGraphic(props: SystemGraphicProps) {
    const enum GraphicBackground {
        Tranparent,
        White,
        Black
    }

    const {
        imageKey,
        label,
        height,
        width,
    } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    // refactor later to make h+w be props passed
    const imageWidth = getWidth(width, height);
    const imageHeight = getHeight(width, height);
    const [background, setBackground] = useState<GraphicBackground>(GraphicBackground.Tranparent);
    const [isBackgroundBlack, setIsBackgroundWhite] = useState(true);
    const imageTileBarStyle = {
        background: "transparent",
        position: "absolute",
        left: () => imageWidth - 25 + "px",
        top: () => imageHeight - 25 + "px",
    } as const;
    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };
    const axiosInstance = useCreateAxios();
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const imgRef = useRef<HTMLImageElement>(null);
    const strings = useLocalizedStrings();
    const auth = useAuth();
    const { user } = useUser();
    const theme = useTheme();
    const classes = useStyles(theme);

    const changeBackgroundTransparent = (event: React.MouseEvent) => {
        setBackground(GraphicBackground.Tranparent)
    }

    const changeBackgroundWhite = (event: React.MouseEvent) => {
        setBackground(GraphicBackground.White)
    }

    const changeBackgroundBlack = (event: React.MouseEvent) => {
        setBackground(GraphicBackground.Black)
    }

    function getWidth(w: number, h: number) {
        if (w > h) {
            if (w >= 400) {
                return 400
            }
            else {
                return w
            }
        }
        else if (w < h) {
            if (h >= 300) {
                return 300 / (h / w);
            }
            else {
                return h / (h / w)
            }
        }
        else {
            if (w >= 300) {
                return 300;
            }
            else {
                return w;
            }
        }
    }

    function getHeight(w: number, h: number) {
        if (h > w) {
            if (h >= 300) {
                return 300
            }
            else {
                return h
            }
        }
        else if (h < w) {
            if (w >= 400) {
                return 400 / (w / h);
            }
            else {
                return w / (w / h)
            }
        }
        else {
            if (h >= 300) {
                return 300;
            }
            else {
                return h;
            }
        }
    }

    function generateRandomNumber() {
        return Math.floor(Math.random() * 1000);
    }

    function buildUrl() {        
        if (props.brandId === "DEFAULT") {
            return process.env.REACT_APP_MANAGER_API_URL + "/images/system/" + imageKey + "?nocache=" + generateRandomNumber() + "&access_token=" + auth.userData?.access_token;            
        }
        else if (props.propertyId === "ALL") {
            return process.env.REACT_APP_MANAGER_API_URL + "/images/system/brands/" + props.brandId + "/" + imageKey + "?nocache=" + generateRandomNumber() + "&access_token=" + auth.userData?.access_token;
        }
        else {
            return process.env.REACT_APP_MANAGER_API_URL + "/images/system/properties/" + props.propertyId + "/" + imageKey + "?nocache=" + generateRandomNumber() + "&access_token=" + auth.userData?.access_token;
        }
    }

    async function uploadGraphic(image: GraphicModel) {
        try {

            if (props.brandId === "DEFAULT") {
                await saveDefaultGraphic(axiosInstance, imageKey, image);
            }
            else if (props.propertyId === "ALL") {
                await saveBrandGraphic(axiosInstance, imageKey, image, props.brandId);
            }
            else if (props.propertyId !== "") {
                await saveGraphic(axiosInstance, imageKey, image, props.propertyId);
            }
            
            setDialogOpen(false);
            if (imgRef.current) {
                imgRef.current.src = buildUrl();
            }
          }
          catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };                
            setNotify({
                isOpen: true,
                message: strings.errorSavingSkin.replace("{{error}}", error.message),
                msgType: "error",
            });
          }          
    };

    const handleOnGraphicUpload = (uploadedImage: GraphicModel) => {
        uploadedImage.imageKey = imageKey
        uploadGraphic(uploadedImage)
    }

    return (
      <div>
            <div className={classes.imageContainer}>
                <img
                    style={background === GraphicBackground.Tranparent ? {backgroundImage: `url(${BackgroundGraphic})`} : {}}
                    className={background === GraphicBackground.White ? classes.imageBackgroundWhite : (background === GraphicBackground.Black ? classes.imageBackgroundBlack : classes.imageBackgroundTransparent)}
                    src={buildUrl()}
                    id={`system-graphic`}
                    ref={imgRef}
                    alt={imageKey}
                    height={imageHeight}
                    width={imageWidth}
                    onDoubleClick={(e) => {setDialogOpen(true)}}/>
                <div className={classes.colorPickerTransparent} onClick={changeBackgroundTransparent} style={{backgroundImage: `url(${BackgroundGraphic})`}}></div>
                <div className={classes.colorPickerWhite} onClick={changeBackgroundWhite}></div>
                <div className={classes.colorPickerBlack} onClick={changeBackgroundBlack}></div>
            </div>
            
            <Typography variant={"caption"} sx={imageTitleStyle}>
                {label ?? ""}
            </Typography>
            <SystemGraphicUploader
                height={imageHeight}
                width={imageWidth}
                imageKey={imageKey}
                open={dialogOpen}
                onImageUploadCompleteHandler={handleOnGraphicUpload}
                onImageUploadCloseHandler={() => setDialogOpen(false)} />
            <NotificationMessage notificationState={[notify, setNotify]} />
      </div>
    );
}

export default SystemGraphic;
