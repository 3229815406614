import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import * as React from "react";


interface ToolTipProps {
    imageKey: string;
}
function AspectRatioToolTip({imageKey}: ToolTipProps) {
    const strings = useLocalizedStrings();

    function getSpec(imageKey: string) {
        switch (imageKey.toLocaleLowerCase()) {
            case "banner":
                return strings.uploadGraphicsSpecificationsAspectRatioBanner;
            case "main":
                return strings.uploadGraphicsSpecificationsAspectRatioMain;
            case "landing":
                return strings.uploadGraphicsSpecificationsAspectRatioLanding;
            case "logo": 
                return strings.uploadGraphicsSpecificationsAspectRatioLogo;
            default:
                return "Unknown";
        }
    }

    function getAspectRatioTooltip(imageKey: string) {
        switch (imageKey.toLocaleLowerCase()) {
            case "banner":
                return (
                    <div>
                        <Typography variant={"caption"}>
                            {strings.uploadGraphicsToolTip}
                        </Typography>
                        <ul>
                            <li>1920 x 540px</li>
                            <li>3840 x 1080px</li>
                            <li>1024 x 238px</li>
                            <li>1152 x 324px</li>
                            <li>1280 x 360px</li>
                            <li>1600 x 450px</li>
                        </ul>
                    </div>
                )
            case "main":
                return (
                    <div>
                        <Typography variant={"caption"}>
                            {strings.uploadGraphicsToolTip}
                        </Typography>
                        <ul>
                            <li>1920 x 1080px</li>
                            <li>3840 x 2160px</li>
                            <li>1024 x 576px</li>
                            <li>1152 x 648px</li>
                            <li>1280 x 720px</li>
                            <li>1600 x 900px</li>
                        </ul>
                    </div>
                )
            case "landing":
                return (
                    <div>
                        <Typography variant={"caption"}>
                            {strings.uploadGraphicsToolTip}
                        </Typography>
                        <ul>
                            <li>1240 x 450px</li>
                        </ul>
                    </div>
                )
            case "logo":
                return (
                    <div>
                        <Typography variant={"caption"}>
                            {strings.uploadGraphicsToolTip}
                        </Typography>
                        <ul>
                            <li>320 x 100px</li>
                        </ul>
                    </div>
                )
            default:
                return (<div></div>)
        }
    }

    return (
        <div>
            <Tooltip arrow title={getAspectRatioTooltip(imageKey)}>
                <Typography variant="caption" sx={{textDecoration: "underline"}}>
                    {getSpec(imageKey)}
                </Typography>
            </Tooltip>
        </div>
    )
}

export default AspectRatioToolTip;