import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, { Ref, useEffect, useImperativeHandle, useState } from "react";
import { deleteGraphics, uploadGraphics, copyGraphic as copy } from "../../../api/graphicsApi";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { GraphicModel } from "../../../models/common/GraphicModel";
import { useDialog } from "../details/DetailsDialog";
import Graphic from "./Graphic";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import AspectRatioTooltip from "./AspectRatioTooltip";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

// styles
const graphicContainerStyle = {
    display: "flex",
    width: "65%",
    float: "left",
    mr: "2px",
    mb: "8px", // Can we get the theme spacing?
    position: "relative",
    zIndex: 99999,
} as const;

const listItemStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
} as const;

const backdropStyle = {
    zIndex: 1,
    bgcolor: "rgb(0 0 0 / 10%)",
    color: "secondary.main",
} as const;

const aspectRatioTextStyle = {
    textDecoration: "underline",
} as const;

export interface GraphicProps {
    itemType: string;
    itemKey: string;
    imageKeys: string[];
    labels?: string[];
    images: GraphicModel[];    
}

interface GraphicStateProps {
    propertyId: string | undefined;
    itemType: string;
    itemKey: string;
    imageKeys: string[];
    labels?: string[];
    disabled?: boolean;
    permissionKey?: string;
    initialGraphicState?: GraphicProps; // It's possible that we want to use a "state" object we had on a previous instance (when batching a save eg: MenuBuilder).
}

export interface GraphicManagerRefObject {
    saveGraphics: (itemKey: string, propertyId: string | undefined) => void;
    validate: () => boolean;
    getGraphicState: () => GraphicProps;
    isDirty: () => boolean;
    hasErrors: () => boolean;
    copyGraphic: (itemType: string, sourceItemKey: string, destinationItemKey: string, key: string, propertyId: string | undefined) => void;
}

const GraphicManager = React.forwardRef((props: GraphicStateProps, ref: Ref<GraphicManagerRefObject>) => {
    const [graphicErrorState, setGraphicErrorState] = useState<string[]>([]);
    const [graphicState, setGraphicState] = useState<GraphicProps>(() => {
        return props.initialGraphicState ?? {
            itemType: props.itemType,
            itemKey: props.itemKey,
            imageKeys: props.imageKeys,
            labels: props.labels,
            images: props.imageKeys.map(
                (key: string): GraphicModel => ({
                    url: props.propertyId === undefined ? `${process.env.REACT_APP_MANAGER_API_URL}/images/${props.itemType}/${props.itemKey}/${key}?nocache=${generateRandomNumber()}` : `${process.env.REACT_APP_MANAGER_API_URL}/images/${props.propertyId!!}/${props.itemType}/${props.itemKey}/${key}?nocache=${generateRandomNumber()}`,
                    fileData: null,
                    imageKey: key,
                    onServer: false,
                })
            ),
        };
    });
    const { dirty, setDirty } = useDialog();
    const [loading, setLoading] = useState(true);
    const strings = useLocalizedStrings();
    const axiosInstance = useCreateAxios();
    const [hasValidationError, setHasValidationErrors] = React.useState(false);

    useImperativeHandle(ref, () => ({ saveGraphics, validate, getGraphicState, isDirty, hasErrors, copyGraphic }));

    useEffect(() => {
        const id = setTimeout(() => {
            setLoading(false);
        }, 1000);
        return () => clearTimeout(id);
    }, []);

    function generateRandomNumber() {
        return Math.floor(Math.random() * 1000);
    }

    function handleImageNotFoundError(
        e: React.SyntheticEvent<HTMLImageElement, Event>,
        key: string
    ) {
        setGraphicErrorState(graphicErrorState.concat(key));
    }

    function handleImageLoad(
        e: React.SyntheticEvent<HTMLImageElement, Event>,
        key: string
    ) {
        const updatedImages: GraphicModel[] = graphicState.images.map((image) => {
            if (image.imageKey === key && !image.url.includes("blob")) {
                return {
                    ...image,
                    onServer: true,
                };
            } else {
                return { ...image };
            }
        });

        setGraphicState((current: GraphicProps) => ({
            ...current,
            images: updatedImages,
        }));

        setGraphicErrorState(graphicErrorState.filter((k) => k !== key));
    }

    function handleImageUpload(uploadedImage: GraphicModel) {
        setDirty(true);
        setGraphicErrorState(
            graphicErrorState.filter((k) => k !== uploadedImage.imageKey)
        );

        const updatedImages: GraphicModel[] = graphicState.images.map((image) => {
            if (image.imageKey === uploadedImage.imageKey) {
                return {
                    ...image,
                    url: uploadedImage.url,
                    fileData: uploadedImage.fileData,
                };
            } else {
                return { ...image };
            }
        });

        setGraphicState((current: GraphicProps) => ({
            ...current,
            images: updatedImages,
        }));
    }

    function handleImageDelete(imageToDelete: GraphicModel) {
        setDirty(true);

        const updatedImages: GraphicModel[] = graphicState.images.map((image) => {
            if (image.imageKey === imageToDelete.imageKey) {
                return {
                    ...image,
                    url: ``, // empty otherwise it will display img on server if there
                    fileData: null,
                };
            } else {
                return { ...image };
            }
        });

        setGraphicState((current: GraphicProps) => ({
            ...current,
            images: updatedImages,
        }));
    }

    function isNotFound(key: string) {
        return graphicErrorState.find((k) => k === key) ? true : false;
    }

    async function saveGraphics(itemKey: string, propertyId: string | undefined) {
        const deletedImages = getImagesDeleted(graphicState.images);
        const addedImages = getImagesAdded(graphicState.images);

        await deleteGraphics(
            axiosInstance,
            graphicState.itemType,
            itemKey,
            deletedImages,
            propertyId
        );

        await uploadGraphics(
            axiosInstance,
            graphicState.itemType,
            itemKey,
            addedImages,
            propertyId
        );

        // helpers
        function getImagesAdded(images: GraphicModel[]) {
            return images.filter((image) => image.url.includes("blob"));
        }

        function getImagesDeleted(images: GraphicModel[]) {
            return images.filter(
                (image) => image.onServer === true && image.url === ""
            );
        }
    };

    function validate() {
        if (hasErrors()) {
            setHasValidationErrors(true);
            return false;
        }

        setHasValidationErrors(false);
        return true;
    }

    function isDirty() {
        const deletedImages = getImagesDeleted(graphicState.images);
        const addedImages = getImagesAdded(graphicState.images);

        return deletedImages.length > 0 || addedImages.length > 0;

        // helpers
        function getImagesAdded(images: GraphicModel[]) {
            return images.filter((image) => image.url.includes("blob"));
        }

        function getImagesDeleted(images: GraphicModel[]) {
            return images.filter(
                (image) => image.onServer === true && image.url === ""
            );
        }
    }

    // Use this to determine if there is an image to copy
    function hasErrors() {
        return graphicErrorState.length > 0;
    }

    async function copyGraphic(itemType: string, sourceItemKey: string, destinationItemKey: string, key: string, propertyId: string | undefined) {
        await copy(axiosInstance, itemType, sourceItemKey, destinationItemKey, key, propertyId);
    }

    function getGraphicState() {
        return graphicState;
    }

    // one img per imageKey in array
    return (           
        <Grid container>                  
            {!loading ?
                <Grid item xs={6}>
                    {graphicState.images.map((image, index) => (
                        <React.Fragment key={image.imageKey}>
                            <Box
                                sx={{
                                    visibility: loading ? "hidden" : "visible",
                                    ...graphicContainerStyle,
                                }}
                                id={"graphic-container"}
                            >
                                <Stack direction="column">
                                    <Graphic
                                        hasError={isNotFound(image.imageKey)}
                                        hasValidationError={hasValidationError}
                                        data={image}
                                        label={graphicState.labels?.[index] ?? ""}
                                        itemType={graphicState.itemType}
                                        disabled={props.disabled}
                                        permissionKey={props.permissionKey}
                                        onNotFoundError={handleImageNotFoundError}
                                        onGraphicUpload={handleImageUpload}
                                        onGraphicLoad={handleImageLoad}
                                        onGraphicDelete={handleImageDelete}
                                    />
                                    <Typography sx={{ marginLeft: "14px", marginTop: "3px" }} variant="caption" color="error">{hasValidationError ? strings.validationImage : ""}</Typography> 
                                </Stack>
                            </Box>                        
                        </React.Fragment>
                    ))}
                </Grid> :
                <Grid 
                    container
                    direction="row"
                    item 
                    justifyContent="center"
                    alignItems="center" 
                    xs={6}
                >
                    <CircularProgress color="secondary" />
                </Grid>
            }            
            <Grid item xs={6}>
                <Box>
                    <Typography variant={"subtitle1"}>{strings.uploadGraphicsSpecificationsHeader}</Typography>
                    <List dense={true}>
                        <ListItem sx={listItemStyle}>
                            <ListItemText>{strings.uploadGraphicsSpecificationsFileSize}</ListItemText>
                            <ListItemText>{strings.uploadGraphicsSpecificationsFileType}</ListItemText>
                            {graphicState.images.map((image) =>   
                                !isNotFound(image.imageKey) && 
                                    <ListItemText>
                                        <AspectRatioTooltip imageKey={image.imageKey} /> 
                                    </ListItemText>
                            )}                                
                        </ListItem>
                    </List>
                </Box>
            </Grid>
        </Grid>
    );    
});

export default GraphicManager;
