import TextField from "@mui/material/TextField";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { SxProps } from '@mui/system';
import * as React from "react";
import { IMask, IMaskInput } from 'react-imask';
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";
import { useDialog } from "./DetailsDialog";

const useStyles = makeStyles(theme => ({
    helperText: (props: MaskedTextFieldProps) => ({
        "& .MuiFormHelperText-root": {
            width: props.helperWidth ?? "inherit"
        }
    })
}));

export type TextFieldChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
) => any;

export interface MaskedTextFieldProps {
    id?: string;    
    label: string;
    value: string;
    type?: string;
    placeholder?: string;
    width?: string | number;
    helperWidth?: number;
    disabled?: boolean;
    onChange: TextFieldChangeHandler;
    error?: boolean;
    helperText?: React.ReactNode;
    sx?: SxProps<Theme>;
    permissionKey?: string;
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const HHmmMask = React.forwardRef<HTMLInputElement, CustomProps>(
    function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;
        const [initialSet, setInitialSet] = React.useState(true);   
        const { dirty, setDirty } = useDialog();                

        React.useEffect(() => {
            if (!dirty) {
                setInitialSet(true);
            }
        }, [dirty]);

        function handleAccept(value: any) {
            props.onChange({ target: { name: props.name, value } });

            if (!initialSet || value.length < 4) {
                setDirty(true); 
            }

            if (initialSet) {                
                setInitialSet(false);
            }
        }

        return (
            <IMaskInput
                {...other}
                mask="HHmm"
                blocks={{
                    HH: {
                        mask: IMask.MaskedRange,
                        placeholderChar: 'HH',
                        from: 0,
                        to: 23,
                        maxLength: 2
                    },
                    mm: {
                        mask: IMask.MaskedRange,
                        placeholderChar: 'mm',
                        from: 0,
                        to: 59,
                        maxLength: 2
                    }
                }}
                inputRef={ref}                         
                onAccept={handleAccept}
                overwrite
            />
        );
    },
);

export const HHmmMaskedTextField = (props: MaskedTextFieldProps) => {
    const {        
        label,
        value,
        type,
        width,
        onChange,
        disabled,
        id = "textFieldDefault",
        error,
        helperText,
        sx,
        placeholder
    } = props;      

    const { dirty, setDirty, adding, permissionKey } = useDialog();
    const classes = useStyles(props);
    const { checkAccess } = useUser();
    const [readOnly] = React.useState(!checkAccess(props.permissionKey ?? permissionKey, adding ? Access.Create : Access.Update));

    function getHelperText() {
        if (!helperText || !error) {
            return " ";
        }
        else {
            return helperText;
        }
    }

    return (
        <TextField
            id={id}
            onChange={onChange}
            label={label}
            variant="outlined"
            value={value}
            type={type ?? "string"}
            placeholder={placeholder}
            disabled={disabled || readOnly}
            error={error}
            className={classes.helperText}
            helperText={getHelperText()}
            sx={(width ? { width: width, ...props.sx } : { ...props.sx })}
            name="hhmm-text-field"
            InputProps={{
                inputComponent: HHmmMask as any
            }} />
    );
}