import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import Box from '@mui/material/Box';
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from "@mui/material/InputLabel";
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import MenuItem from "@mui/material/MenuItem";
import { default as MaterialSelect, SelectChangeEvent } from "@mui/material/Select";
import { Theme, useTheme } from "@mui/material/styles";
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { createStyles, makeStyles } from "@mui/styles";
import * as React from 'react';
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Option } from '../../../models/configuration/options/Option';

// This was the only way i could add bottom-margin to the list items
const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        treeItemRoot: {
            "& .MuiTreeItem-content": {
                marginBottom: "16px", // TODO: theme.spacing(2)
            }
        }
    })
);

interface RenderTree {
    id: string;
    name: string;
    children?: RenderTree[];
    value?: any;
    validation?: string;
    choices?: string[];
}

export interface OptionsTreeProps {
    options: Option[],
    rootLabel: string,
    onUpdated: (option: Option) => void
}

const OptionsTree = (props: OptionsTreeProps) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const theme = useTheme();
    const classes = useStyles(theme);
    const [data, setData] = React.useState<Option[]>([]);    
    //const [defaultExpanded, setDefaultExpanded] = React.useState(["root"]);
    const [errors, setErrors] = React.useState<string[]>([]);    
    const strings = useLocalizedStrings();    

    React.useEffect(() => {
        setData(props.options);
        setIsLoading(false)
    }, [props]);

    const renderField = (node: RenderTree) => {
        if (node.choices) {
            return <FormControl variant="outlined" sx={{ minWidth: 120, mb: 2, ml: 2 }}>
                <InputLabel id="demo-simple-select-label">{node.name}</InputLabel>
                <MaterialSelect
                    id="select"
                    aria-labelledby={"select-label-menu"}
                    label={node.name}
                    value={node.value}   
                    onChange={(event: SelectChangeEvent) => handleDropdownChanged(node.id, event.target.value)}
                >
                    {node.choices.map((c, index) => (
                        <MenuItem key={c} value={c}>{c}</MenuItem>
                    ))}
                </MaterialSelect>
            </FormControl>
        }      
        else if (node.value === "true" || node.value === "false") {
            return <div><FormControlLabel control={<Switch value={node.value === "true"} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleBooleanChanged(node.id, event.target.checked)} />} label={node.name} sx={{ mb: 2, ml: 2 }} /></div>
        }
        else {
            return <TextField 
                id="outlined-basic" 
                label={node.name} 
                value={node.value} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTextChanged(node.id, event.target.value)} 
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => handlTextBlur(node.id, node.validation)} 
                variant="outlined" 
                error={errors.includes(node.id)}
                helperText={errors.includes(node.id) ? "{{name}} field invalid.".replace("{{name}}", node.name) : " "}
                sx={{ mb: 1, ml: 2, width: 400 }} />
        }
    }

    function handleTextChanged(key: string, value: string) {                  
        const index = data.findIndex(d => d.key === key);
        const newData = [...data];
        data[index].value = value
        setData(newData);
    }

    function handlTextBlur(key: string, validation?: string) {  
        const found = data.find(d => d.key === key);                       
        if (found) {
            // Validate with reg ex pattern
            if (validation && validation.startsWith("^")) {                                
                const index = errors.indexOf(key);
                const newErrors = [...errors];

                const regex = new RegExp(validation);
                if (!regex.test(found.value)) {
                    // Add to errors array if we don't have this one yet
                    if (index == -1) {
                        newErrors.push(key);
                        setErrors(newErrors);
                    }
                    return;
                }
                
                // Remvoe from errors array if this is in there
                if (index != -1) {
                    newErrors.splice(index);      
                    setErrors(newErrors);
                }
            }

            props.onUpdated(found);
        }
    }

    function handleBooleanChanged(key: string, checked: boolean) {                  
        const index = data.findIndex(d => d.key === key);
        const newData = [...data];
        data[index].value = checked ? "true" : "false";
        setData(newData);
        props.onUpdated(data[index]);
    }

    function handleDropdownChanged(key: string, value: string) {
        const index = data.findIndex(d => d.key === key);
        const newData = [...data];
        data[index].value = value
        setData(newData);    
        props.onUpdated(data[index]);
    }

    const renderTree = (nodes: RenderTree) => (
        nodes.children ?
            <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name} className={classes.treeItemRoot}>
                {Array.isArray(nodes.children)
                    ? nodes.children.map((node) => renderTree(node))
                    : null}
            </TreeItem> :
            renderField(nodes)
    );

    function parseOptions(options: Option[]) {
        var rootNode: RenderTree = { id: "root", name: props.rootLabel, children: [] };
        var node: RenderTree;
        //var tempExpanded = ["root"];

        for (var i = 0; i < options.length; i++) {
            node = rootNode;

            var option = options[i];
            var path = option.path;
            var split = path.split('/');

            // Loop through path elements and add them to the tree
            for (var j = 0; j < split.length; j++) {
                var id = split[j];

                // Check if we already have this node
                var newNode = node.children?.find(n => n.id === id);

                // If we don't have the node add it
                if (!newNode) {
                    newNode = { id: id, name: split[j], children: [] };
                    node.children?.push(newNode);

                    // Annoying that we need to build an array of expanded items
                    //if (!tempExpanded.includes(id)) {
                    //    tempExpanded.push(id);
                    //}
                }

                // If we're at the end, add the field
                if (j === split.length - 1) {  
                    var choices = undefined;
                    if (option.validation && !option.validation.startsWith("^")) {
                        choices = option.validation.split('|');
                    }

                    newNode.children?.push({ id: option.key, name: option.name, value: option.value, validation: option.validation, choices: choices });
                }

                // Advance for next iteration
                node = newNode;
            }
        }

       // setDefaultExpanded(tempExpanded);

        return rootNode;
    }

    if (isLoading) {
        return <LinearProgress color={"primary"} variant={"query"} />;
    }
    else {
        return (
            <Box sx={{ padding: theme.spacing(2) }}>
                <TreeView
                    aria-label="rich object"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpanded={["root"]}
                    defaultExpandIcon={<ChevronRightIcon />}
                    sx={{ height: "100%", flexGrow: 1, width: 500, maxWidth: 500, overflowY: 'auto' }}
                >
                    {renderTree(parseOptions(data))}
                </TreeView>
            </Box>
        );
    }
}

export default OptionsTree;