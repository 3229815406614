// Adapted from https://dev.to/dmtrkovalenko/the-neatest-way-to-handle-alert-dialogs-in-react-1aoe

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

export interface AlertDialogOptions {
    title: string;
    message: string;
    destructive?: boolean | undefined
    okButtonTitle: string;
    cancelButtonTitle: string;
    catchOnCancel?: boolean;
}

interface AlertDialogProps extends AlertDialogOptions {
    open: boolean;
    onOkClick: () => void;
    onCancelClick: () => void;
}

export const AlertDialog: React.FC<AlertDialogProps> = (props: {
    open: boolean,
    title: string,
    message: string,
    destructive?: boolean,
    okButtonTitle: string,
    cancelButtonTitle: string
    onOkClick: () => void,
    onCancelClick: () => void
}) => {
    return (                    
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div dangerouslySetInnerHTML={{ __html: props.message }}/>                    
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCancelClick}>{props.cancelButtonTitle}</Button>
                <Button onClick={props.onOkClick} color={props.destructive ? "error" : "primary"}>{props.okButtonTitle}</Button>
            </DialogActions>
        </Dialog>        
    );
}