import { AxiosInstance } from "axios";
import { Menu, Section } from "../../models/modules/roomservice/Menu";
import { parseLanguageStringsFromJson, dateToTimeOnlyString } from "../../utilities/JsonHelper";
import { v4 as uuid } from "uuid";

const url = process.env.REACT_APP_MANAGER_API_URL + "/room-service/";

export async function getMenus(axiosInstance: AxiosInstance, propertyCode: string): Promise<Menu[]> {    
    return await axiosInstance
        .get(`${url}${propertyCode}/menus`)
        .then((response) => parseLanguageStringsFromJson(response.data.menus) as Menu[]);
}

export async function getMenu(axiosInstance: AxiosInstance, propertyCode: string, id: string): Promise<Menu> {    
    return await axiosInstance
        .get(`${url}${propertyCode}/menus/${id}`)
        .then((response) => parseLanguageStringsFromJson(response.data.menu) as Menu);
}

export async function createMenu(axiosInstance: AxiosInstance, propertyCode: string, menu: Menu) {
    const id = uuid();
    const menuToSave = {
        ...menu,  
        id: id,        
        name: JSON.stringify({ ...menu.name }),
        startTime: dateToTimeOnlyString(menu.startTime),
        endTime: dateToTimeOnlyString(menu.endTime)
    };

    return await axiosInstance.post(`${url}${propertyCode}/menus`, menuToSave).then((response) => {
        return id;
    });
}

export async function updateMenu(axiosInstance: AxiosInstance, propertyCode: string, menu: Menu) {
    const menuToSave = {
        ...menu,           
        name: JSON.stringify({ ...menu.name }),
        startTime: dateToTimeOnlyString(menu.startTime),
        endTime: dateToTimeOnlyString(menu.endTime)
    };

    return await axiosInstance.put(`${url}${propertyCode}/menus/${menu.id}`, menuToSave).then((response) => {
        return response.data;
    });
}

export async function deleteMenu(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return await axiosInstance.delete(`${url}${propertyCode}/menus/${id}`).then((response) => {
        return response.data;
    });
}

export async function createSection(axiosInstance: AxiosInstance, propertyCode: string, section: Section, menuId: string) {
    const id = uuid();
    const sectionToSave = {
        ...section,  
        id: id,        
        name: JSON.stringify({ ...section.name })      
    };

    return await axiosInstance.post(`${url}${propertyCode}/sections?menuId=${menuId}`, sectionToSave).then((response) => {
        return id;
    });
}

export async function updateSection(axiosInstance: AxiosInstance, propertyCode: string, section: Section) {
    const sectionToSave = {
        ...section,                  
        name: JSON.stringify({ ...section.name })
    };

    return await axiosInstance.put(`${url}${propertyCode}/sections/${section.id}`, sectionToSave).then((response) => {
        return response.data;
    });
}

export async function deleteSection(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return await axiosInstance.delete(`${url}${propertyCode}/sections/${id}`).then((response) => {
        return response.data;
    });
}

export async function saveSectionDisplayOrder(axiosInstance: AxiosInstance, propertyId: string, menuId: string, idList: string[]) {
    const response = axiosInstance.put(`${url}${propertyId}/menus/${menuId}/sections/reorder`, idList).then((response) => {
        return response.status;
    });
}

export async function addItem(axiosInstance: AxiosInstance, propertyCode: string, sectionId: string, itemId: string) {   
    return await axiosInstance.put(`${url}${propertyCode}/sections/${sectionId}/items/${itemId}`).then((response) => {
        return response.data;
    });
}

export async function removeItem(axiosInstance: AxiosInstance, propertyCode: string, sectionId: string, itemId: string) {   
    return await axiosInstance.delete(`${url}${propertyCode}/sections/${sectionId}/items/${itemId}`).then((response) => {
        return response.data;
    });
}