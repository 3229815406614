import { AxiosInstance } from "axios";
import { Options } from "../../models/modules/roomservice/ConfigurationOptions";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";

const url = process.env.REACT_APP_MANAGER_API_URL + "/room-service/";

export async function getOptions(axiosInstance: AxiosInstance, propertyId: string): Promise<Options> {
    return await axiosInstance
        .get(`${url}${propertyId}/configuration`)
        .then((response) => response.data.options as Options);
}

export async function saveOptions(axiosInstance: AxiosInstance, propertyId: string, options: Options): Promise<Options> {
    return await axiosInstance.put(`${url}${propertyId}/configuration`, options).then((response) => {
        return response.data;
    })   
}
