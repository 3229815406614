import { default as MaterialTextField } from "@mui/material/TextField";
import * as React from "react";
import { useDialog } from "./DetailsDialog";
import { makeStyles } from "@mui/styles";
import { SxProps } from '@mui/system';
import { Theme } from "@mui/material/styles"
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";

const useStyles = makeStyles(theme => ({
    helperText: (props: TextFieldProps) => ({
        "& .MuiFormHelperText-root": {
            width: props.helperWidth ?? "inherit"
        }
    })
}));

export type TextFieldChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
) => any;

type TextFieldStyleProps = {
    textFieldWidth: number;
};

export interface TextFieldProps {
    id?: string;
    multiline?: boolean,
    rows?: number,
    label: string;
    value: string;
    type?: string;
    placeholder?: string;
    width?: string | number;
    helperWidth?: number;
    disabled?: boolean;
    onChange: TextFieldChangeHandler;
    error?: boolean;
    helperText?: React.ReactNode;
    sx?: SxProps<Theme>;
    permissionKey?: string;
    InputProps?: Partial<OutlinedInputProps>;
}

const TextField = (props: TextFieldProps) => {
    const {
        multiline,
        rows,
        label,
        value,
        type,
        width,
        onChange,
        disabled,
        id = "textFieldDefault",
        error,
        helperText,
        sx,
        placeholder,        
        InputProps
    } = props;   
    //const formState = useContext(FormContext);
    //const [value, setValue] = React.useState<string>(value);
    const { dirty, setDirty, adding, permissionKey } = useDialog();
    const classes = useStyles(props);
    const { checkAccess } = useUser();
    const [readOnly] = React.useState(!checkAccess(props.permissionKey ?? permissionKey, adding ? Access.Create : Access.Update));

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //setValue(event.target.value);
        setDirty(true);
        onChange(event);
    };

    //React.useEffect(() => {
    //  setValue(value);
    //}, [value]);

    function getHelperText() {
        if (!helperText || !error) {
            return " ";
        }
        else {
            return helperText;
        }
    }

    return (
        <div>
            {multiline && 
                <MaterialTextField
                    id={id}
                    multiline
                    rows={rows}
                    onChange={handleChange}
                    label={label}
                    variant="outlined"
                    value={value}
                    type={type ?? "string"}
                    placeholder={placeholder}
                    disabled={disabled || readOnly}
                    error={error}
                    className={classes.helperText}
                    helperText={getHelperText()}
                    sx={(width ? { width: width, ...props.sx } : { ...props.sx })}
                    InputProps={InputProps}
                />
            }
            {!multiline &&
                <MaterialTextField
                    id={id}
                    onChange={handleChange}
                    label={label}
                    variant="outlined"
                    value={value}
                    type={type ?? "string"}
                    placeholder={placeholder}
                    disabled={disabled || readOnly}
                    error={error}
                    className={classes.helperText}
                    helperText={getHelperText()}
                    sx={(width ? { width: width, ...props.sx } : { ...props.sx })}
                    InputProps={InputProps}
                />
            }
            
        </div>
    );
};

export default TextField;
