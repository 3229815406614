import { AxiosInstance } from "axios";
import { ScheduledActivity } from "../../models/modules/activities/ScheduledActivity";
import { parseLanguageStringsFromJson, dateToLocalISOString } from "../../utilities/JsonHelper";
import { v4 as uuid } from "uuid";

const url = process.env.REACT_APP_MANAGER_API_URL + "/activities/";

export async function getScheduledActivities(axiosInstance: AxiosInstance, propertyCode: string, start: Date, end: Date): Promise<ScheduledActivity[]> {
    const startIso = dateToLocalISOString(start);
    const endIso = dateToLocalISOString(end);

    return await axiosInstance
        .get(`${url}${propertyCode}/scheduledactivities?startDate=${startIso}&endDate=${endIso}`, { timeout: 60000 })
        .then((response) => parseLanguageStringsFromJson(response.data.scheduledActivities, undefined, { ignore: ["data"] }) as ScheduledActivity[]);
}

export async function getScheduledActivity(axiosInstance: AxiosInstance, propertyCode: string, id: string): Promise<ScheduledActivity> {
    return await axiosInstance
        .get(`${url}${propertyCode}/scheduledactivities/${id}`)
        .then((response) => parseLanguageStringsFromJson(response.data.activity) as ScheduledActivity);
}

export async function createScheduledActivity(axiosInstance: AxiosInstance, propertyCode: string, activity: ScheduledActivity) {    
    // Don't send back name or description, or we'll need to JSON serialize it
    const activityToSave = {
        ...activity,   
        name: "",
        description: "",        
    };

    return await axiosInstance.post(`${url}${propertyCode}/scheduledactivities`, activityToSave).then((response) => {
        return response.data;
    });
}

export async function updateScheduledActivity(axiosInstance: AxiosInstance, propertyCode: string, activity: ScheduledActivity) {   
    // Don't send back name or description, or we'll need to JSON serialize it
    const activityToSave = {
        ...activity,   
        name: "",
        description: "",  
        instances: null
    };

    return await axiosInstance.put(`${url}${propertyCode}/scheduledactivities/${activity.id}`, activityToSave).then((response) => {
        return response.data;
    });
}

export async function deleteScheduledActivity(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return await axiosInstance.delete(`${url}${propertyCode}/scheduledactivities/${id}`).then((response) => {
        return response.data;
    });
}

export async function deleteScheduledActivityInstance(axiosInstance: AxiosInstance, propertyCode: string, id: string, instanceId: string) {
    return await axiosInstance.delete(`${url}${propertyCode}/scheduledactivities/${id}/instances/${instanceId}`).then((response) => {
        return response.data;
    });
}