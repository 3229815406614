import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import TabPanel from "../../common/TabPanel";
import GuestList from "./GuestList";
import RoomList from "./RoomList";
import StbList from "./StbList";
import RoomClassList from "./RoomClassList";
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const RoomManager = () => {    
    const strings = useLocalizedStrings();
    const location = useLocation();    

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={location.pathname} aria-label="basic tabs example">
                    <Tab value="/roommanager/rooms" label={strings.roomManagerRoomsTab} component={Link} to="rooms" />                    
                    <Tab value="/roommanager/roomclasses" label={strings.roomManagerClassTab} component={Link} to="roomclasses" />
                    <Tab value="/roommanager/stbs" label={strings.roomManagerStbsTab} component={Link} to="stbs" />
                </Tabs>
            </Box>
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Routes>
                    <Route path="rooms" element={<RoomList />} />
                    <Route path="stbs" element={<StbList />} />                    
                    <Route path="roomclasses" element={<RoomClassList />} />
                </Routes>
            </Box>
        </Box>
    );
};

export default RoomManager;
