import { AxiosInstance } from "axios";
import { LocationModel } from "../../models/configuration/settings/Location";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";
import { v4 as uuid } from "uuid";

const url = process.env.REACT_APP_MANAGER_API_URL + "/property-management/";

export async function getLocations(axiosInstance: AxiosInstance, propertyCode: string): Promise<LocationModel[]> {
    return await axiosInstance
        .get(`${url}${propertyCode}/locations`)
        .then((response) => parseLanguageStringsFromJson(response.data.locations) as LocationModel[]);
}

export async function getLocation(axiosInstance: AxiosInstance, propertyCode: string, id: string): Promise<LocationModel> {
    return await axiosInstance
        .get(`${url}${propertyCode}/locations/${id}`)
        .then((response) => parseLanguageStringsFromJson(response.data.location) as LocationModel);
}

export async function createLocation(axiosInstance: AxiosInstance, propertyCode: string, location: LocationModel) {
    const id = uuid();

    const locationToSave = {
        ...location,  
        id: id,
        //propertyId: propertyCode,
        name: JSON.stringify({ ...location.name }),
        description: JSON.stringify({ ...location.description }),        
    };

    return await axiosInstance.post(`${url}${propertyCode}/locations`, locationToSave).then((response) => {
        return id;
    });
}

export async function updateLocation(axiosInstance: AxiosInstance, propertyCode: string, location: LocationModel) {
    const locationToSave = {
        ...location,          
        //propertyId: propertyCode,
        name: JSON.stringify({ ...location.name }),
        description: JSON.stringify({ ...location.description }),        
    };

    return await axiosInstance.put(`${url}${propertyCode}/locations/${location.id}`, locationToSave).then((response) => {
        return response.data;
    });
}

export async function deleteLocation(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return await axiosInstance.delete(`${url}${propertyCode}/locations/${id}`).then((response) => {
        return response.data;
    });
}