import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import * as React from "react";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import TabPanel from "../../common/TabPanel";
import SkinList from "./SkinList";
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import SkinOptions from "./SkinOptions";
import SystemGraphics from "./SystemGraphics";
import NotAuthorized from "../../wrapper/NotAuthorized";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";

const SkinManager = () => {
    const strings = useLocalizedStrings();
    const location = useLocation();
    const theme = useTheme();
    const { user, checkAccess, isAdminCustomer } = useUser();    

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={location.pathname} aria-label="basic tabs example">                    
                    {isAdminCustomer() &&
                        <Tab value="/skin/default/skins" label={strings.skinDefaultTabLabel} component={Link} to="default/skins" />
                    }
                    {isAdminCustomer() &&
                        <Tab value="/skin/default/graphics" label={strings.skinGraphicsDefaultTabLabel} component={Link} to="default/graphics" />
                    }
                    {!isAdminCustomer() && 
                        <Tab value="/skin/skins" label={strings.skinTabLabel} component={Link} to="skins" />
                    }
                    {!isAdminCustomer() &&
                        <Tab value="/skin/graphics" label={strings.skinGraphicsTabLabel} component={Link} to="graphics" />
                    }
                    {user.currentProperty !== undefined &&
                        <Tab value="/skin/options" label={strings.skinOptionsTabLabel} component={Link} to="options" />
                    }
                    
                </Tabs>
            </Box>
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Routes>
                    <Route path="skins" element={<SkinList />} />
                    <Route path="graphics" element={<SystemGraphics />} />
                    <Route path="options" element={<SkinOptions />} />
                    <Route path="default/skins" element={checkAccess("administration_skin", Access.Read, false) ? <SkinList /> : <NotAuthorized /> } />
                    <Route path="default/graphics" element={checkAccess("administration_skin", Access.Read, false) ? <SystemGraphics /> : <NotAuthorized /> } />
                </Routes>
            </Box>
        </Box>
    );
};

export default SkinManager;