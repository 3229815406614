import { AxiosInstance } from "axios";

import { Location } from "../../models/modules/promotions/Location";

const url = process.env.REACT_APP_MANAGER_API_URL + "/promotions/";

export async function getLocations(axiosInstance: AxiosInstance, propertyCode: string): Promise<Location[]> {
    return await axiosInstance
        .get(`${url}${propertyCode}/locations`)
        .then((response) => response.data.locations as Location[]);
}
