import { AxiosInstance } from "axios";
import { Activity } from "../../models/modules/activities/Activity";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";
import { v4 as uuid } from "uuid";

const url = process.env.REACT_APP_MANAGER_API_URL + "/activities/";

export async function getActivities(axiosInstance: AxiosInstance, propertyCode: string): Promise<Activity[]> {
    return await axiosInstance
        .get(`${url}${propertyCode}/activities`)
        .then((response) => parseLanguageStringsFromJson(response.data.activities) as Activity[]);
}

export async function getActivity(axiosInstance: AxiosInstance, propertyCode: string, id: string): Promise<Activity> {
    return await axiosInstance
        .get(`${url}${propertyCode}/activities/${id}`)
        .then((response) => parseLanguageStringsFromJson(response.data.activity) as Activity);
}

export async function createActivity(axiosInstance: AxiosInstance, propertyCode: string, activity: Activity) {
    const id = uuid();

    const activityToSave = {
        ...activity,  
        id: id,
        propertyId: propertyCode,
        name: JSON.stringify({ ...activity.name }),
        description: JSON.stringify({ ...activity.description }),        
    };

    return await axiosInstance.post(`${url}${propertyCode}/activities`, activityToSave).then((response) => {
        return id;
    });
}

export async function updateActivity(axiosInstance: AxiosInstance, propertyCode: string, activity: Activity) {
    const activityToSave = {
        ...activity,          
        propertyId: propertyCode,
        name: JSON.stringify({ ...activity.name }),
        description: JSON.stringify({ ...activity.description }),        
    };

    return await axiosInstance.put(`${url}${propertyCode}/activities/${activity.id}`, activityToSave).then((response) => {
        return response.data;
    });
}

export async function deleteActivity(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return await axiosInstance.delete(`${url}${propertyCode}/activities/${id}`).then((response) => {
        return response.data;
    });
}