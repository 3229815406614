import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useAuth } from "oidc-react";
import React from "react";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { GraphicModel } from "../../../models/common/GraphicModel";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";
import themePrimary from "../../../styles/themePrimary";
import { useDialog } from "../details/DetailsDialog";
import GraphicUploader from "./GraphicUploader";

// styles
const deleteButtonStyle = {
    float: "right",
    height: "24px",
    width: "24px",
    cursor: "pointer",
    color: themePrimary.palette.error.main,
    bgcolor: "transparent",
} as const;

const imageTitleStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    ml: 2,
    color: "#000000",
    fontSize: themePrimary.typography.subtitle1.fontSize,
} as const;

const imageStyle = {    
    border: "solid",
    borderWidth: 1,
    borderColor: themePrimary.palette.secondary.main
} as const;

const aspectRatioTextStyle = {
    textDecoration: "underline",
} as const;

interface GraphicProps {
    data: GraphicModel;
    itemType: string;
    label?: string;
    disabled?: boolean;
    permissionKey?: string;
    hasError: boolean;
    hasValidationError: boolean;
    onNotFoundError: (
        e: React.SyntheticEvent<HTMLImageElement, Event>,
        key: string
    ) => void;
    onGraphicUpload: (uploadedImage: GraphicModel) => void;
    onGraphicLoad: (
        e: React.SyntheticEvent<HTMLImageElement, Event>,
        key: string
    ) => void;
    onGraphicDelete: (imageToDelete: GraphicModel) => void;
}

function Graphic(props: GraphicProps) {
    const {
        data,
        itemType,
        label,
        hasError,
        hasValidationError,
        disabled,
        onNotFoundError,
        onGraphicUpload,
        onGraphicLoad,
        onGraphicDelete    
    } = props;
    // refactor later to make h+w be props passed
    const imageWidth = 382;
    const imageHeight = 382 / getAspectRatio(data.imageKey);
    const imageTileBarStyle = {
        background: "transparent",
        position: "absolute",
        left: () => imageWidth - 25 + "px",
        top: () => imageHeight - 25 + "px",
    } as const;
    const strings = useLocalizedStrings();
    const auth = useAuth();
    const { dirty, setDirty, adding, permissionKey } = useDialog();
    const { checkAccess } = useUser();
    const [readOnly] = React.useState(!checkAccess(props.permissionKey ?? permissionKey, adding ? Access.Create : Access.Update));

    function getAspectRatio(imageKey: string): number {
        switch (imageKey.toLocaleLowerCase()) {
            case "banner":
                return 32 / 9;
            case "main":            
                return 16 / 9;
            case "landing":
                return 1240 / 450;
            case "logo": 
                return 320 / 100;
            default:
                return 1;
        }
    }   

    function getSpec(imageKey: string) {
        switch (imageKey.toLocaleLowerCase()) {
            case "banner":
                return strings.uploadGraphicsSpecificationsAspectRatioBanner;
            case "main":
                return strings.uploadGraphicsSpecificationsAspectRatioMain;
            case "landing":
                return strings.uploadGraphicsSpecificationsAspectRatioLanding;
            case "logo": 
                return strings.uploadGraphicsSpecificationsAspectRatioLogo;
            default:
                return "Unknown";
        }
    }

    function getAspectRatioTooltip(imageKey: string) {
        switch (imageKey.toLocaleLowerCase()) {
            case "banner":
                return (
                    <div>
                        <Typography variant={"caption"}>
                            {strings.uploadGraphicsToolTip}
                        </Typography>
                        <ul>
                            <li>1920 x 540px</li>
                            <li>3840 x 1080px</li>
                            <li>1024 x 238px</li>
                            <li>1152 x 324px</li>
                            <li>1280 x 360px</li>
                            <li>1600 x 450px</li>
                        </ul>
                    </div>
                )
            case "main":            
                return (
                    <div>
                        <Typography variant={"caption"}>
                            {strings.uploadGraphicsToolTip}
                        </Typography>
                        <ul>
                            <li>1920 x 1080px</li>
                            <li>3840 x 2160px</li>
                            <li>1024 x 576px</li>
                            <li>1152 x 648px</li>
                            <li>1280 x 720px</li>
                            <li>1600 x 900px</li>
                        </ul>
                    </div>
                )
            case "landing":
                return (
                    <div>
                        <Typography variant={"caption"}>
                            {strings.uploadGraphicsToolTip}
                        </Typography>
                        <ul>
                            <li>1240 x 450px</li>
                        </ul>
                    </div>
                )

            case "logo":
                return (
                    <div>
                        <Typography variant={"caption"}>
                            {strings.uploadGraphicsToolTip}
                        </Typography>
                        <ul>
                            <li>320 x 100px</li>
                        </ul>
                    </div>
                )
            default:
                return (<div></div>)
        }
    }

    function buildUrl() {
        if (data.url.includes("blob")) {
            return data.url;
        }
        else {
            return `${data.url}&access_token=${auth.userData?.access_token}`;
        }
    }

    return (
        <>
            {hasError ? (                
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"                    
                    style={{ width: imageWidth, height: imageHeight, borderWidth: "1px", borderStyle: "dashed", borderColor: hasValidationError ? themePrimary.palette.error.main : "rgba(0, 0, 0, 0.87)", backgroundColor: themePrimary.palette.secondary.light }}                    
                >
                    <Grid item xs={3}>
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                        >
                            <Grid item>
                                <CloudUploadIcon fontSize="large" color="secondary" />
                            </Grid>
                            <Grid item>
                                <Tooltip arrow title={getAspectRatioTooltip(data.imageKey)}>
                                    <Typography variant="caption" sx={aspectRatioTextStyle}>
                                        {getSpec(data.imageKey)}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            ) : (
                <img
                    style={imageStyle}
                    src={buildUrl()}
                    id={`${itemType.toLowerCase()}-graphic`}
                    alt={data.imageKey}
                    height={imageHeight}
                    width={imageWidth}
                    onError={(e) => onNotFoundError(e, data.imageKey)}
                    onLoad={(e) => onGraphicLoad(e, data.imageKey)}
                />
            )}
            <Typography variant={"caption"} sx={imageTitleStyle}>
                {label ?? ""}
            </Typography>

            <Box sx={imageTileBarStyle} id={`graphic-action-container`}>
                {!disabled && !readOnly && (
                    !hasError ? (
                        <Tooltip title={strings.delete}>
                            <Avatar
                                id={`graphic-delete-icon-container`}
                                sx={deleteButtonStyle}
                                onClick={() => onGraphicDelete(data)}
                            >
                                <DeleteIcon id={`graphic-delete-icon`} />
                            </Avatar>
                        </Tooltip>
                    ) : (
                        <GraphicUploader
                            height={imageHeight}
                            width={imageWidth}
                            imageKey={data.imageKey}
                            onImageUploadCompleteHandler={onGraphicUpload}
                        />
                    )
                )}
            </Box>
        </>
    );
}

export default Graphic;
