import * as React from "react";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import DataGrid from "../../common/datatable/DataGridWrapper";
import { GridColDef } from "@mui/x-data-grid";
import LinearProgress from '@mui/material/LinearProgress';
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { Button, MenuItem, FormControl, InputLabel, Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import themePrimary from "../../../styles/themePrimary";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import DateSearchField, { getDefaultEndDate } from "../../common/search/DateSearchField"
import SearchIcon from '@mui/icons-material/Search';
import { getContactViews, getMenuFilters, ReportType } from "../../../api/reporting/itv/reportingApi";
import { ContactView } from "../../../models/reporting/itv/ContactView";
import { useUser } from "../../../providers/UserProvider";
import axios from "axios";
import { MenuFilter } from "../../../models/reporting/itv/MenuFilter";
import { GridToolbarExport } from '@mui/x-data-grid';
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
    searchBox: {
        display: 'flex',
        paddingBottom: themePrimary.spacing(2),
        width: '100%',
    }
}));

const getDefaultSearchStartDate = (): Date => {
    var date = new Date();
    date.setMonth(0, 1);

    return date;
}

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

function ContactViews() {
    const [isLoading, setIsLoading] = React.useState(true);
    const [startDate, setStartDate] = React.useState<Date>(getDefaultSearchStartDate());
    const [endDate, setEndDate] = React.useState<Date>(getDefaultEndDate); 
    const [viewData, setViewData] = React.useState<ContactView[]>([]); 
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);
    const [selectedFilterId, setSelectedFilterId] = React.useState("");
    const [filterData, setFilterData] = React.useState<MenuFilter[]>([]);
    const strings = useLocalizedStrings();
    const axiosInstance = useCreateAxios();
    const { user } = useUser();
    const classes = useStyles(); 
    const theme = useTheme();
    
    React.useEffect(() => {
        async function apiGetFilterData() {
            try {
                const filters = await getMenuFilters(axiosInstance, user.currentProperty?.code ?? "", ReportType.Contact);
                setFilterData(filters);

                if (filters.length > 0) {
                    setSelectedFilterId(filters[0].id)
                }
            }
            catch(e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingModuleFilters.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }
            finally{
                setIsLoading(false);
            }
        }
        
        apiGetFilterData();

    }, [user.currentProperty?.code, strings.errorRetrievingBrands, ReportType.Contact])

    React.useEffect(() => {
        async function apiGetContactViews() {
            try {
                const payload = await getContactViews(axiosInstance, user.currentProperty?.code ?? "", selectedFilterId, startDate, endDate);
                setViewData(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };            
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingContactViews.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }
            finally {
                setIsLoading(false);
            }
        }

        if (isLoading && selectedFilterId.length > 0) {
            apiGetContactViews();
        }
        
    }, [user.currentProperty?.code, isLoading, selectedFilterId, startDate, endDate])

    function onSearchClick() {
        setIsLoading(true);
    }

    function onDateSearchFieldChangeHandler (startDate: Date, endDate: Date) {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    function handleFilterChange(e: SelectChangeEvent) {
        setSelectedFilterId(e.target.value as string);
    }

    const gridColumns: GridColDef[] = [        
        { field: 'itemName', headerName: strings.reportingItemName, flex: 1 },
        { field: 'viewCount', headerName: strings.reportingViewCount, flex: 1 }
    ];

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 201px)" }}> 
            <Box className={classes.searchBox}>
                <DateSearchField 
                    startDate={getDefaultSearchStartDate()}
                    endDate={null}
                    format={null}
                    onChange={onDateSearchFieldChangeHandler} 
                />
                <FormControl variant="outlined" sx={{ marginLeft: themePrimary.spacing(2), minWidth: 220 }}>
                    <InputLabel>{strings.reportingModuleFilterSelectLabel}</InputLabel>
                    <Select
                        id="module-filter-select"
                        value={selectedFilterId}
                        label={strings.reportingModuleFilterSelectLabel}
                        onChange={handleFilterChange}>
                        {filterData.map(x => {
                            return <MenuItem key={x.id} value={x.id}>{x.name} </MenuItem>
                        })}
                    </Select>
                </FormControl>

                <Button
                    sx={{ marginLeft: themePrimary.spacing(2) }}
                    variant="contained"
                    size="large"
                    disabled={false}
                    onClick={onSearchClick}
                    startIcon={<SearchIcon />}
                    aria-label="Search">
                    {strings.search}
                </Button>
            </Box>
            <DataGrid 
                permissionKey="reporting_itv"
                showAddButton={false}
                columns={gridColumns} 
                getRowId={(row) => row.itemId} 
                rows={viewData}                 
                loading={isLoading} 
                slots={
                    { toolbar: GridToolbarExport }
                } 
                slotProps= {
                    { toolbar: {fileName: strings.reportingContactViewsTabLabel.replace(" ", "")} }
                }
            />

            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    );
};

export default ContactViews;