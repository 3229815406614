import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useAuth } from "oidc-react";
import React from "react";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { VideoModel } from "../../../models/common/VideoModel";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";
import themePrimary from "../../../styles/themePrimary";
import { useDialog } from "../details/DetailsDialog";
import VideoUploader from "./VideoUploader";

// styles
const deleteButtonStyle = {
    float: "right",
    height: "24px",
    width: "24px",
    cursor: "pointer",
    color: themePrimary.palette.error.main,
    bgcolor: "transparent",
} as const;

const videoTitleStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    ml: 2,
    color: "#000000",
    fontSize: themePrimary.typography.subtitle1.fontSize,
} as const;

const videoStyle = {    
    border: "solid",
    borderWidth: 1,
    borderColor: themePrimary.palette.secondary.main
} as const;

const aspectRatioTextStyle = {
    textDecoration: "underline",
} as const;

interface VideoProps {
    data: VideoModel;
    itemType: string;
    label?: string;
    disabled?: boolean;
    permissionKey?: string;
    hasError: boolean;
    hasValidationError: boolean;
    onNotFoundError: (
        e: React.SyntheticEvent<HTMLVideoElement | HTMLSourceElement, Event>,
        key: string
    ) => void;
    onVideoUpload: (uploadedvideo: VideoModel) => void;
    onVideoUploadError: (message: string) => void;
    onVideoLoad: (
        e: React.SyntheticEvent<HTMLVideoElement, Event>,
        key: string
    ) => void;
    onVideoDelete: (videoToDelete: VideoModel) => void;
}

function Video(props: VideoProps) {
    const {
        data,
        itemType,
        label,
        hasError,
        hasValidationError,
        disabled,
        onNotFoundError,
        onVideoUpload,
        onVideoUploadError,
        onVideoLoad,
        onVideoDelete    
    } = props;
    // refactor later to make h+w be props passed
    const videoWidth = 382;
    const videoHeight = 382 / getAspectRatio(data.videoKey);
    const imageTileBarStyle = {
        background: "transparent",
        position: "absolute",
        left: () => videoWidth - 25 + "px",
        top: () => videoHeight - 25 + "px",
    } as const;
    const strings = useLocalizedStrings();
    const auth = useAuth();
    const { dirty, setDirty, adding, permissionKey } = useDialog();
    const { checkAccess } = useUser();
    const [readOnly] = React.useState(!checkAccess(props.permissionKey ?? permissionKey, adding ? Access.Create : Access.Update));    

    function getAspectRatio(videoKey: string): number {
        switch (videoKey.toLocaleLowerCase()) {
            case "banner":
                return 32 / 9;
            case "main":            
                return 16 / 9;
            default:
                return 1;
        }
    }   

    function getSpec(videoKey: string) {
        switch (videoKey.toLocaleLowerCase()) {
            case "banner":
                return strings.uploadVideosSpecificationsAspectRatioBanner;
            case "main":
                return strings.uploadVideosSpecificationsAspectRatioMain;
            default:
                return "Unknown";
        }
    }

    function getAspectRatioTooltip(videoKey: string) {
        switch (videoKey.toLocaleLowerCase()) {
            case "banner":
                return (
                    <div>
                        <Typography variant={"caption"}>
                            {strings.uploadVideosToolTip}
                        </Typography>
                        <ul>
                            <li>1920 x 540px</li>
                        </ul>
                    </div>
                )
            case "main":            
                return (
                    <div>
                        <Typography variant={"caption"}>
                            {strings.uploadVideosToolTip}
                        </Typography>
                        <ul>
                            <li>1920 x 1080px</li>
                        </ul>
                    </div>
                )
            default:
                return (<div></div>)
        }
    }

    function buildUrl() {
        if (data.url.includes("blob")) {
            return data.url;
        }
        else {
            return `${data.url}&access_token=${auth.userData?.access_token}`;
        }
    }

    return (
        <>
            {hasError ? (                
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"                    
                    style={{ width: videoWidth, height: videoHeight, borderWidth: "1px", borderStyle: "dashed", borderColor: hasValidationError ? themePrimary.palette.error.main : "rgba(0, 0, 0, 0.87)", backgroundColor: themePrimary.palette.secondary.light }}
                >
                    <Grid item xs={3}>
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                        >
                            <Grid item>
                                <CloudUploadIcon fontSize="large" color="secondary" />
                            </Grid>
                            <Grid item>
                                <Tooltip arrow title={getAspectRatioTooltip(data.videoKey)}>
                                    <Typography variant="caption" sx={aspectRatioTextStyle}>
                                        {getSpec(data.videoKey)}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            ) : (
                <video 
                    preload="metadata" 
                    controls 
                    id={`${itemType.toLowerCase()}-video`}
                    style={videoStyle}
                    height={videoHeight}
                    width={videoWidth}
                    onError={(e) => onNotFoundError(e, data.videoKey)}
                    onCanPlay={(e) => onVideoLoad(e, data.videoKey)}
                >
                    <source 
                        src={buildUrl()}
                        onError={(e) => onNotFoundError(e, data.videoKey)} />
                </video>
            )}
            <Typography variant={"caption"} sx={videoTitleStyle}>
                {label ?? ""}
            </Typography>

            <Box sx={imageTileBarStyle} id={`video-action-container`}>
                {!disabled && !readOnly && (
                    !hasError ? (
                        <Tooltip title={strings.delete}>
                            <Avatar
                                id={`video-delete-icon-container`}
                                sx={deleteButtonStyle}
                                onClick={() => onVideoDelete(data)}
                            >
                                <DeleteIcon id={`video-delete-icon`} />
                            </Avatar>
                        </Tooltip>
                    ) : (
                        <VideoUploader
                            videoKey={data.videoKey}
                            onVideoUploadCompleteHandler={onVideoUpload}
                            onVideoUploadErrorHandler={onVideoUploadError}
                        />
                    )
                )}
            </Box>
        </>
    );
}

export default Video;
