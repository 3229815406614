import { Language, StringLanguageDict } from "../models/common/LanguageModel";

export interface PropertyItemModel {
  id: string;
  name: string;
  displayOrder: number;
  enabled: boolean;  
}

export interface PropertyItemDetailModel {
  id: string;
  brandId: string;
  name: StringLanguageDict;
  description: StringLanguageDict;
  displayOrder: number;
  enabled: boolean;
  latitude: number;
  latitudeString: string;
  longitude: number;
  longitudeString: string;
  features?: string[];
  languages?: Language[];
  timeZoneId: string;
  baseImageUrl: string | null;
  baseVideoUrl: string | null;
}

export const initialPropertyState: PropertyItemDetailModel = {
    id: "",
    brandId: "",
    name: { en: "", es: "" },
    description: { en: "", es: "" },
    displayOrder: 0,
    enabled: false,
    latitude: 0.0,
    latitudeString: "",
    longitude: 0.0,
    longitudeString: "",
    features: [],
    languages: [],
    timeZoneId: "",
    baseImageUrl: null,
    baseVideoUrl: null,
};

  export function mapPropertiesToKeyValue(
    properties: PropertyItemModel[]
): { key: string; value: string }[] {
    let kvps: { key: string; value: string }[] = [];

    kvps = properties.map((item, index) => {
        return {
            key: item.id,
            value: JSON.parse(item.name).en,
        };
    });

    return kvps;
}