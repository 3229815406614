import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import { LoyaltyLevel, LoyaltyLevelDetail, LoyaltyLevelResponse} from "../models/modules/roommanager/LoyaltyLevel";

// See Swagger for Loyalty Level endpoints. 
// https://dev-api.bbhnow.com/manager/docs/index.html

const URL = process.env.REACT_APP_MANAGER_API_URL + `/property-management/`;

export function getLoyaltyLevelGridData(axiosInstance: AxiosInstance, propertyCode: string) : Promise<LoyaltyLevelResponse> {
    return axiosInstance
        .get(`${URL}${propertyCode}/loyaltylevels`)
        .then(response => response.data as LoyaltyLevelResponse);
}

export function getLoyaltyLevelById(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return axiosInstance
        .get(`${URL}${propertyCode}/loyaltylevels/${id}`)
        .then((response) => response.data as LoyaltyLevel);
}

export function createLoyaltyLevel(axiosInstance: AxiosInstance, propertyCode: string, loyaltyLevel: LoyaltyLevelDetail) {
    const loyaltyLevelToSave = {
        ...loyaltyLevel,
        id: uuid(),
        name: JSON.stringify({ ...loyaltyLevel.name })
    };
    
    return axiosInstance.post(`${URL}${propertyCode}/loyaltylevels`, loyaltyLevelToSave).then((response) => response.data);
}

export function updateLoyaltyLevel(axiosInstance: AxiosInstance, propertyCode: string, loyaltyLevel: LoyaltyLevelDetail) {
    const loyaltyLevelToSave = {
        ...loyaltyLevel,
        name: JSON.stringify({ ...loyaltyLevel.name })
    };

    return axiosInstance.put(`${URL}${propertyCode}/loyaltylevels/${loyaltyLevel.id}`, loyaltyLevelToSave).then((response) => {
        return response.data;
    });
}

export function deleteLoyaltyLevel(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return axiosInstance.delete(`${URL}${propertyCode}/loyaltylevels/${id}`).then((response) => response.data);
}

