import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import MuiIconButton from "@mui/material/IconButton";
import Paper, { PaperProps } from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Draggable from "react-draggable";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import themePrimary from "../../../styles/themePrimary";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import Audit from "../../common/Audit";
import { makeStyles } from "@mui/styles";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";

const useStyles = makeStyles({
    draggableDialog: {
        cursor: "move",
    },
    dialogContent: {
        cursor: "default",
    },
    appBarSpacer: {
        ...themePrimary.mixins.toolbar,
    },
});

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={"[class*='MuiDialogContent-root']"}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export interface DetailsDialogProps {
    open: boolean;
    title: string;
    children: React.ReactNode;
    hideAudit?: boolean;
    onValidateForm?: () => boolean;
    onCancelClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    saveButtonLabel?: string;
    saveButtonIcon?: React.ReactNode;
    onSaveClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<boolean>;
    contentSize: { width: number; height: number };
    adding?: boolean;
    permissionKey?: string;
    saveEnabled?: boolean;
    readOnly?: boolean;
}

type DialogContextValue = {
    dirty: boolean;
    setDirty: React.Dispatch<React.SetStateAction<boolean>>;
    adding?: boolean;
    permissionKey?: string;
};

const DialogContext = React.createContext<DialogContextValue>({
    dirty: false,
    setDirty: () => { },
    adding: false,
    permissionKey: ""
});

export const useDialog = () => React.useContext(DialogContext);

const DetailsDialog = (props: DetailsDialogProps) => {
    const { children, adding, permissionKey } = props;
    const classes = useStyles();
    const [dirty, setDirty] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const dialogContextValue: DialogContextValue = { dirty, setDirty, adding, permissionKey };
    const alertDialog = useAlertDialog();
    const strings = useLocalizedStrings();
    const { checkAccess } = useUser();    

    React.useEffect(() => {
        // Seems a bit hackish way to reset the loading state (when hidden this will fire), otherwise it will still be spinning if you reopen the dialog.
        if (!props.open) {
            setLoading(false);
        }

        if (props.permissionKey !== undefined) {
            if (!checkAccess(props.permissionKey, Access.Update)) {
                const childrenArray = React.Children.toArray(children);
                React.Children.map(childrenArray, (child, index) => {

                });
            }
        }
    }, [props.open, props.permissionKey]);

    const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (dirty) {
            alertDialog({
                title: strings.discardChangesAlertTitle,
                message: strings.discardChangesAlertMessage,
                destructive: true,
                okButtonTitle: strings.discardButtonTitle,
                cancelButtonTitle: strings.cancelButtonTitle,
            }).then(() => {
                setDirty(false);
                props.onCancelClick(event);
            });
        } else {
            props.onCancelClick(event);
        }
    };

    const handleSaveClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        if (
            (props.onValidateForm && props.onValidateForm!()) ||
            !props.onValidateForm
        ) {            
            setLoading(true);
            const result = await props.onSaveClick(event);
            setLoading(false);

            if (result) {
                setDirty(false);
            }
        }
    };

    return (
        <DialogContext.Provider value={dialogContextValue}>
            <Dialog
                scroll={"body"}
                open={props.open}
                onClose={handleCancelClick}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                PaperProps={{
                    sx: {
                        width: `${props.contentSize.width}px`,
                        maxWidth: `${props.contentSize.width}px`,
                        height: `${props.contentSize.height + 124.5}px`,
                    },
                }} // (173.5 is height of headers and footer) https://medium.com/the-clever-dev/how-to-size-and-position-the-material-ui-mui-dialog-component-a5601cedc1c9
                fullWidth={true}
            >
                <AppBar /* style={{ cursor: "move" }} */ id="draggable-dialog">
                    {/* Try to get default padding (themePrimary.mixins.toolbar?) and subtract the X button margin from the right (it has a bult in padding of 8px that you can see when you highlight). Hard coding 24 and 12 is the only way to get the X to look correct. */}
                    <Toolbar
                        disableGutters
                        sx={{ paddingLeft: "24px", paddingRight: "24px" }}
                    >
                        <Typography variant="h6" noWrap component="div">
                            {props.title}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box>
                            <MuiIconButton
                                aria-label="close"
                                color="inherit"
                                size="large"
                                edge="end"
                                onClick={handleCancelClick}
                            >
                                <CloseIcon />
                            </MuiIconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                <div className={classes.appBarSpacer}></div>
                {children}

                {/*{!props.hideAudit &&*/}
                {/*    <Audit sx={{ position: "absolute", bottom: "8px", left: "8px" }} />*/}
                {/*}*/}
                
                <DialogActions
                    sx={{ position: "absolute", bottom: "8px", right: "8px" }}
                >
                    <Button variant="outlined" onClick={handleCancelClick}>
                        {!checkAccess(permissionKey, adding ? Access.Create : Access.Update) || props.readOnly ? strings.closeButtonTitle : strings.cancelButtonTitle}
                    </Button>
                    {checkAccess(permissionKey, adding ? Access.Create : Access.Update) && !props.readOnly &&
                        <LoadingButton
                            startIcon={props.saveButtonIcon}
                            variant="contained"
                            loading={loading}
                            disabled={!dirty && !props.saveEnabled}
                            onClick={handleSaveClick}
                        >
                            {props.saveButtonLabel ?? strings.saveButtonTitle}
                        </LoadingButton>
                    }
                </DialogActions>
            </Dialog>
        </DialogContext.Provider>
    );
};

export default DetailsDialog;
