import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { default as MaterialSelect, SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from '@mui/material/styles';
import axios from "axios";
import * as React from 'react';
import { listPropertyOptions, listCustomerOptions, updateOption } from "../../../api/configuration/optionsApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { useUser } from "../../../providers/UserProvider";
import { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import OptionsTree from "./OptionsTree";
import { Option } from '../../../models/configuration/options/Option';
import { Access } from "../../../models/configuration/security/Permission";

const Options = () => {
    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [selectedService, setSelectedService] = React.useState<{ key: string; value: string } | undefined>();
    const [propertyOptions, setPropertyOptions] = React.useState<Option[]>([]);
    const [companyOptions, setCompanyOptions] = React.useState<Option[]>([]);
    const axiosInstance = useCreateAxios();
    const { user, checkAccess } = useUser();  
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);
    const strings = useLocalizedStrings();
    const theme = useTheme();

    const services: { key: string; value: string }[] = [
        { key: "Notification", value: "Notification" },        
        { key: "PMS", value: "PMS" },        
        { key: "Weather", value: "Weather" },
    ];

    React.useEffect(() => {
        if (selectedService) {
            getOptions();
        }
    }, [selectedService]);

    async function getOptions() {
        try {
            const payload = await listPropertyOptions(axiosInstance, selectedService?.key ?? "", user.currentProperty?.code ?? "");
            setPropertyOptions(payload);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: "Error retrieving options. {{error}}".replace("{{error}}", error.message),
                msgType: "error",
            });
        }

         try {
            const payload = await listCustomerOptions(axiosInstance, selectedService?.key ?? "", "BBH");// user.currentProperty?.code ?? ""
            setCompanyOptions(payload);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: "Error retrieving options. {{error}}".replace("{{error}}", error.message),
                msgType: "error",
            });
        }
    }

    function handleServiceChange(event: SelectChangeEvent) {
        const findService = services.find(m => m.key === event.target.value as string);
        if (findService) {
            setSelectedService(findService);
        }        
    }

    async function handleUpdated(option: Option) {        
        try {
            await updateOption(axiosInstance, option);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: "Error saving option. {{error}}".replace("{{error}}", error.message),
                msgType: "error",
            });

            return;
        }
    }

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 185px)" }}> 
            <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">{strings.service}</InputLabel>
                <MaterialSelect
                    id="select"
                    aria-labelledby={"select-label-menu"}
                    label={strings.service}
                    value={selectedService?.key ?? ""}
                    onChange={handleServiceChange}
                >
                    {services.map((kvp, index) => (
                        <MenuItem key={kvp.key} value={kvp.key}>{kvp.value}</MenuItem>
                    ))}
                </MaterialSelect>
            </FormControl>                
            <Spacer y={2} />
            <Grid 
                container
                direction="row"
            >
                {checkAccess("administration_company", Access.Update, true) &&  
                    <Grid item>
                        <OptionsTree options={propertyOptions} rootLabel="Property Options" onUpdated={handleUpdated} />
                    </Grid>
                }
                {checkAccess("administration_company", Access.Update, true) &&  
                    <Grid item>
                        <OptionsTree options={companyOptions} rootLabel="Customer Options" onUpdated={handleUpdated} />
                    </Grid>
                }
            </Grid>
        </Box>
    );
};

export default Options;
