import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from '@mui/material/styles';
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { createType, deleteType, getTypes, saveDisplayOrder, updateType } from "../../../api/guestservices/typeApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { initialTypeState, Type as TypeModel } from "../../../models/modules/guestservices/Type";
import { useUser } from "../../../providers/UserProvider";
import { Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import DisplayOrderGrid, { DisplayOrderGridRefObject } from "../../common/datatable/DisplayOrderGrid";
import Checkbox from "../../common/details/Checkbox";
import { default as DetailsDialog } from "../../common/details/DetailsDialog";
import { GraphicManagerRefObject } from "../../common/image/GraphicManager";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";

interface TypeValidationErrors {
    name: string;
    description: string;
}

const TypeList = () => {
    const initialTypeErrorState: TypeValidationErrors = {
        name: "",
        description: ""
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [gridRefresh, setGridRefresh] = useState(false);
    const [types, setTypes] = useState<TypeModel[]>([]);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);       
    const [selectedTypeId, setSelectedTypeId] = useState("");
    const [selectedType, setSelectedType] = useState<TypeModel>(initialTypeState);    
    const [isLoading, setIsLoading] = useState(true);    
    const [errors, setErrors] = useState<TypeValidationErrors>(initialTypeErrorState);    
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);        
    const graphicManagerRef = useRef<GraphicManagerRefObject>(null);
    const alertDialog = useAlertDialog();
    const strings = useLocalizedStrings();
    const theme = useTheme();    
    const axiosInstance = useCreateAxios();
    const { user } = useUser();        
    const gridRef = useRef<DisplayOrderGridRefObject>(null);

    useEffect(() => {
        async function loadTypes() {
            try {
                const payload = await getTypes(axiosInstance, user.currentProperty?.code ?? "", false);
                payload.sort((a, b) => a.displayOrder && b.displayOrder);
                setTypes(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingTypes.replace("{{error}}", error.message),
                    msgType: "error",
                });
            } finally {
                setIsLoading(false);
            }
        }

        loadTypes();
    }, [gridRefresh, user.currentProperty?.code, strings.errorRetreivingTypes]);

    const dialogTitle = selectedTypeId === "" ? strings.addTypeTitle : strings.editTypeTitle.replace("{{name}}", selectedType?.name?.en ?? ""); // TODO: default language

    function handleEditRowClick(id: string) {      
        if (gridRef.current?.isDirty()) {
            return;
        }

        setSelectedTypeId(id);
        var type = types?.find(p => p.id === id);
        if (type) {
            setSelectedType(type);
            setDetailsDialogOpen(true);
        }
    }

    function handleAddClick() {
        setSelectedTypeId("");
        setSelectedType(initialTypeState);
        setDetailsDialogOpen(true);
    }

    async function deleteSelectedType(id: string) {
        try {
            await deleteType(axiosInstance, user.currentProperty?.code ?? "", id);
        }                
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingType.replace("{{error}}", error.message),
                msgType: "error",
            });       

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.typeDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        setSelectedTypeId("");
        setSelectedType(initialTypeState);
    }

    function handleDeleteClick(id: string) {
        if (gridRef.current?.isDirty()) {
            return;
        }

        const name = types.find(p => p.id === id)?.name?.en ?? "";

        alertDialog({
            title: strings.deleteTypeAlertTitle,
            message: strings.deleteTypeAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedType(id);
        });
    }

    function handleNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedType((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            },
        }));
    }

    function handleEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedType((prevState) => ({
            ...prevState,
            enabled: event.target.checked,
        }));
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialTypeErrorState);
        setDetailsDialogOpen(false);        
    }

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialTypeErrorState);

        const isUpdate = selectedTypeId != "";
        try {
            if (isUpdate) {
                await updateType(axiosInstance, user.currentProperty?.code ?? "", selectedType);
            } else {
                await createType(axiosInstance, user.currentProperty?.code ?? "", selectedType);
            }
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.errorAddingType
                    : strings.errorUpdatingType
                ).replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        setDetailsDialogOpen(false);
        setGridRefresh(!gridRefresh);
        setNotify({
            isOpen: true,
            message: strings.typeSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleValidateType() {
        var errors = validate<TypeModel, TypeValidationErrors>([
            { property: "name.en", type: ValidationType.Required, message: strings.validationTypeName }            
        ], selectedType);
        if (errors) {
            setErrors(errors);
            return false;
        }
        else {
            return true;
        }
    }

    /*function handleSaveDisplayOrder(ids: any[]) {          
        // https://stackoverflow.com/questions/13304543/javascript-sort-array-based-on-another-array
        const sorted = [...types];
        sorted.sort(function(a, b) {  
            return ids.findIndex(id => a.id === id) - ids.findIndex(id => b.id === id);
        });                
        updateDisplayOrder(sorted);
    }

    function updateDisplayOrder(typeList: TypeModel[]) {*/
    function handleSaveDisplayOrder(idList: any[]) {
        //const idList = typeList.map(t => t.id);
        saveDisplayOrder(axiosInstance, user.currentProperty?.code ?? "", idList)
            .then(() => setGridRefresh(!gridRefresh))
            .catch((e: unknown) => {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorUpdatingRestaurant.replace("{{error}}", error.message),
                    msgType: "error",
                });
            })
    } 
    
    const gridColumns: GridColDef[] = [        
        { field: "name", headerName: strings.name, valueGetter: (value, row) => row.name.en, flex: 4 },  
        { field: "enabled", headerName: strings.enabled, type: "boolean", valueGetter: (value, row) => row.enabled, flex: 1 },  
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton 
                    permissionKey="content_guestservices" 
                    rowId={params.id.toString()} 
                    clickHandler={() => handleEditRowClick(params.id.toString())} 
                />,
                <DataGridDeleteButton
                    permissionKey="content_guestservices"
                    rowId={params.id.toString()}
                    clickHandler={() => handleDeleteClick(params.id.toString())}
                />
            ],
        },
    ];

    if (isLoading) {
        return <LinearProgress color={"primary"} variant={"query"} />;
    }
    else {
        return (
            <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 171px)" }}>                                               
                <DisplayOrderGrid
                    permissionKey="content_guestservices"
                    rows={types}
                    columns={gridColumns}
                    onAddButtonClick={handleAddClick}
                    addButtonText={strings.addTypeButton}
                    onSaveDisplayOrder={handleSaveDisplayOrder}
                    ref={gridRef}
                />

                <DetailsDialog
                    permissionKey="content_guestservices"
                    open={detailsDialogOpen}
                    adding={selectedTypeId === ""}
                    title={dialogTitle}
                    onValidateForm={handleValidateType}
                    onCancelClick={handleCancelClick}
                    onSaveClick={handleSaveClick}
                    contentSize={{ width: 442, height: 239.81 }}
                >
                    <Box sx={{ padding: theme.spacing(2) }}>
                        <LanguageForm>
                            <LanguageSelect />
                            <Spacer />
                            <LanguageTextField
                                id="item-name"
                                label={strings.name}
                                values={selectedType.name ?? {}}
                                width={410}
                                onChange={handleNameChange}
                                error={Boolean(errors.name)}
                                helperText={errors.name}
                            />
                            <Spacer />
                            <Checkbox
                                label={strings.enabled}
                                checked={selectedType.enabled}
                                onChange={handleEnabledChange}
                            />
                        </LanguageForm>
                    </Box>
                </DetailsDialog>

                <NotificationMessage notificationState={[notify, setNotify]} />
            </Box>           
        )
    }    
};

export default TypeList;
