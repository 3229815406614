import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import ActivityList from "./TypeList";
import ActivitySchedule from "./ServiceList";
import TypeList from "./TypeList";
import ServiceList from "./ServiceList";

const Services = () => {
    const strings = useLocalizedStrings();   
    const location = useLocation();
    const theme = useTheme();

    return (
        <Box sx={{ width: '100%' }}>            
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={location.pathname} ria-label="basic tabs example">
                    <Tab value="/guestservices/services" label={strings.guestServices} component={Link} to="services" />
                    <Tab value="/guestservices/types" label={strings.guestServiceTypes} component={Link} to="types" />
                </Tabs>
            </Box>            
            <Box component="main" sx={{ flexGrow: 1, padding: 0 }}>
                <Routes>
                    <Route path="services" element={<ServiceList />} />
                    <Route path="types" element={<TypeList />} />
                </Routes>
            </Box>
        </Box>
    );
};

export default Services;
