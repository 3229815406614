import { TicketType } from "./TicketType";

export interface ServiceTicketForCreation {    
    ticketType?: TicketType;
    summary: string;
    description: string;
    callbackPhone: string;
    callbackEmail: string;
    callbackName: string;    
}

export const initialServiceTicketForCreationState: ServiceTicketForCreation = {      
    summary: "",
    description: "",
    callbackPhone: "",
    callbackName: "",
    callbackEmail: ""    
};