import { string } from "prop-types";
import { StringLanguageDict } from "../../common/LanguageModel";
import { MessageType } from "./MessageType";

export interface Template {
    id: string;
    from?: StringLanguageDict | null;       
    subject: StringLanguageDict | null;
    isHighImportance: boolean;
    type: MessageType;
    text: StringLanguageDict | null;    
    internalName: string;    
    enabled: boolean;
}

export function initialTemplateState(type: MessageType) {
    return {
        id: "",
        from: null,    
        subject: null,   
        isHighImportance: false,
        type: type,
        text: null,
        internalName: "",
        enabled: true
    };
}

export function mapTemplatesToKeyValues(templates: Template[]): { key: string; value: { name: string, type: string } }[] {
    let kvps: { key: string; value: { name: string, type: string }; }[] = [];

    kvps = templates.map((template, index) => {
        return {
            key: template.id,
            value: {
                name: template.internalName,
                type: template.type
            }
        };
    });

    return kvps;
}