import AddBoxIcon from "@mui/icons-material/AddBox";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { useRef } from "react";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { VideoModel } from "../../../models/common/VideoModel";

// styles
const addButtonStyle = {
    float: "right",
    height: "24px",
    width: "24px",
    cursor: "pointer",
    color: "primary.main",
    backgroundColor: "transparent",
} as const;

const videoErrorStyle = {
    color: "error.main",
} as const;

const fileUploadStyle = {
    opacity: 0,
    zIndex: -1,
    position: "absolute",
} as const;

const videoPreviewStyle = {
    border: "1px black solid",
} as const;

export interface VideoUploaderProps {   
    videoKey: string;
    onVideoUploadCompleteHandler: (video: VideoModel) => any;
    onVideoUploadErrorHandler: (message: string) => void;
}

function VideoUploader({
    videoKey,
    onVideoUploadCompleteHandler,
    onVideoUploadErrorHandler
}: VideoUploaderProps) {    
    const fileUploadRef = useRef<HTMLInputElement>(null);
    const strings = useLocalizedStrings();
    const MAX_SIZE_VIDEO_BYTES = 104857600;        

    function onVideoUploadOpen() {
        if (fileUploadRef != null) {
            if (fileUploadRef.current != null) {
                fileUploadRef.current.click();
            }
        }
    }

    function onVideoUploadChange(event: React.ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;
        if (files != null && files.length > 0) {
            const file = files[0];

            if (file.size > MAX_SIZE_VIDEO_BYTES) {
                onVideoUploadErrorHandler(strings.validationVideoSize);
                return;
            } 
            else if (!file.name.endsWith("mp4")) {
                onVideoUploadErrorHandler(strings.validationVideoType);
                return;
            }
                
            // To validate the aspect ratio we need to load the video temporarily
            // https://stackoverflow.com/questions/51665617/how-can-i-get-width-and-height-of-a-video-from-an-input-in-javascript
            const url = URL.createObjectURL(file);
            const videoEl = document.createElement('video');
            videoEl.onloadedmetadata = evt => {
                // Revoke when you don't need the url any more to release any reference
                URL.revokeObjectURL(url);

                console.log(videoEl.videoWidth, videoEl.videoHeight);
                const ar = (videoEl.videoWidth / videoEl.videoHeight).toFixed(1);                 

                if (videoKey.toLocaleLowerCase() === "banner" && ar !== (32 / 9).toFixed(1)) {
                    onVideoUploadErrorHandler(strings.validationVideoAspectBanner);
                    return;
                }
                else if (videoKey.toLocaleLowerCase() === "main" && ar !== (16 / 9).toFixed(1)) {
                    onVideoUploadErrorHandler(strings.validationVideoAspectMain);
                    return;
                }  
                
                var video: VideoModel = {
                    url: URL.createObjectURL(files[0]),
                    fileData: files[0],
                    videoKey: videoKey,
                }

                onVideoUploadCompleteHandler(video);
            }
            videoEl.src = url
            videoEl.load() // fetches metadata            
        }
    }

    return (
        <Box>
            <Tooltip title={strings.addButtonTitle}>
                <Avatar
                    id="video-add-icon-container"
                    sx={addButtonStyle}
                    onClick={onVideoUploadOpen}
                >
                    <AddBoxIcon id="video-add-icon" />
                </Avatar>
            </Tooltip>
            <input
                id="video-upload-input"
                type="file"
                accept=".mp4"
                ref={fileUploadRef}
                onChange={onVideoUploadChange}
                style={fileUploadStyle}
            />
        </Box>
    );
}

export default VideoUploader;