import { Box, FormControlLabel, LinearProgress, SelectChangeEvent, Switch, useTheme } from "@mui/material";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { useState } from "react";
import { default as DialogSelect } from "../../common/details/Select";
import TextField from "../../common/details/TextField";
import Checkbox from "../../common/details/Checkbox";
import { getInitialUserNotificationState, mapMinutesOfflineToKeyValues } from "../../../models/dashboard/network/UserNotifications";
import Spacer from "../../common/Spacer";

interface UserNotificationErrors {
    emailAddress: string;
    phoneNumber: string;
    numberOfApsOffline: string;
}

const Notifications = () => {
    const initialUserNotificationErrorState: UserNotificationErrors = {
        emailAddress: "",
        phoneNumber: "",
        numberOfApsOffline: ""
    }

    const theme = useTheme();
    const strings = useLocalizedStrings();
    const [isLoading, setIsLoading] = useState(false);
    const [userNotifications, setUserNotifications] = useState(getInitialUserNotificationState());
    const [sendApNotifications, setSendApNotifications] = useState(false);
    const [sendCircuitNotifications, setSendCircuitNotifications] = useState(false);
    const [sendGatewayNotifications, setSendGatewayNotifications] = useState(false);
    const [userNotificationErrors, setUserNotificationErrors] = useState<UserNotificationErrors>(initialUserNotificationErrorState);  

    function handleCircuitMinutesOfflineChange(event: SelectChangeEvent) {
        setUserNotifications((prevState) => ({
            ...prevState,
            circuitSensorMinutesOffline: parseInt(event.target.value)
        }));
    }

    function handleGatewayMinutesOfflineChange(event: SelectChangeEvent) {
        setUserNotifications((prevState) => ({
            ...prevState,
            gatewaySensorMinutesOffline: parseInt(event.target.value)
        }));
    }

    function handleApsMinutesOfflineChange(event: SelectChangeEvent) {
        setUserNotifications((prevState) => ({
            ...prevState,
            apsMinutesOffline: parseInt(event.target.value)
        }));
    }

    function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        setUserNotifications((prevState) => ({
            ...prevState,
            emailAddress: event.target.value,
        }));
    }

    function handlePhoneNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
        setUserNotifications((prevState) => ({
            ...prevState,
            phoneNumber: event.target.value,
        }));
    }

    function handleNumApsOfflineChange(event: React.ChangeEvent<HTMLInputElement>) {
        setUserNotifications((prevState) => ({
            ...prevState,
            numberOfApsOffline: parseInt(event.target.value),
        }));
    }

    function handleSendEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        setUserNotifications((prevState) => ({
            ...prevState,
            sendEmailNotification: event.target.checked,
        }));
    }

    function handleSendPhoneChange(event: React.ChangeEvent<HTMLInputElement>) {
        setUserNotifications((prevState) => ({
            ...prevState,
            sendPhoneNotification: event.target.checked,
        }));
    }

    function handleSendApNotificationChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSendApNotifications(!sendApNotifications);
    }

    function handleSendCircuitNotificationChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSendCircuitNotifications(!sendCircuitNotifications);
    }

    function handleSendGatewayNotificationChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSendGatewayNotifications(!sendGatewayNotifications);
    }

    if (isLoading) {
        return <Box><LinearProgress color={"primary"} variant={"query"} /></Box>;
    }
    else {
        return (
            <Box sx={{ padding: theme.spacing(2) }}>
                <div>
                    <div>
                        
                        <div>
                            <Checkbox
                                label={strings.sendEmail}
                                checked={userNotifications.sendEmailNotification}
                                onChange={handleSendEmailChange}
                            />
                            <TextField
                                id="email"
                                label={strings.offerEmailLabel}
                                disabled={!userNotifications.sendEmailNotification}
                                value={userNotifications.emailAddress}
                                width={410}
                                onChange={handleEmailChange}
                                error={Boolean(userNotificationErrors.emailAddress)}
                                helperText={userNotificationErrors.emailAddress}
                            />
                            <Spacer />
                            <Checkbox
                                label={strings.sendSms}
                                checked={userNotifications.sendPhoneNotification}
                                onChange={handleSendPhoneChange}
                            />
                            <TextField
                                id="phone"
                                label={strings.phoneNumber}
                                disabled={!userNotifications.sendPhoneNotification}
                                value={userNotifications.phoneNumber}
                                width={260}
                                onChange={handlePhoneNumberChange}
                                error={Boolean(userNotificationErrors.phoneNumber)}
                                helperText={userNotificationErrors.phoneNumber}
                            />

                            <Spacer />
                            <FormControlLabel control={<Switch
                                checked={sendApNotifications}
                                onChange={handleSendApNotificationChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />} label={strings.accessPoints} />

                            {sendApNotifications &&
                                <div>
                                    <Spacer/>
                                    <DialogSelect
                                        label={strings.minutesOffline}
                                        keyValues={mapMinutesOfflineToKeyValues()}
                                        selectedValue={userNotifications.apsMinutesOffline.toString()}
                                        onChangeHandler={handleApsMinutesOfflineChange}
                                        sx={{ minWidth: 200 }} />

                                    <TextField
                                        id="num-aps"
                                        label={strings.quantityOffline}
                                        value={userNotifications.numberOfApsOffline.toString()}
                                        width={120}
                                        onChange={handleNumApsOfflineChange}
                                        error={Boolean(userNotificationErrors.numberOfApsOffline)}
                                        helperText={userNotificationErrors.numberOfApsOffline}
                                    />
                                </div>
                            }

                            <Spacer />
                            <FormControlLabel control={<Switch
                                checked={sendCircuitNotifications}
                                onChange={handleSendCircuitNotificationChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />} label={strings.circuit} />

                            {sendCircuitNotifications &&
                                <div>
                                    <Spacer/>
                                    <DialogSelect
                                        label={strings.minutesOffline}
                                        keyValues={mapMinutesOfflineToKeyValues()}
                                        selectedValue={userNotifications.circuitSensorMinutesOffline.toString()}
                                        onChangeHandler={handleCircuitMinutesOfflineChange}
                                        sx={{ minWidth: 200 }} />
                                </div>
                            }

                            <Spacer />
                            <FormControlLabel control={<Switch
                                checked={sendGatewayNotifications}
                                onChange={handleSendGatewayNotificationChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />} label={strings.gateway} />

                            {sendGatewayNotifications &&
                                <div>
                                    <Spacer/>
                                    <DialogSelect
                                        label={strings.minutesOffline}
                                        keyValues={mapMinutesOfflineToKeyValues()}
                                        selectedValue={userNotifications.gatewaySensorMinutesOffline.toString()}
                                        onChangeHandler={handleGatewayMinutesOfflineChange}
                                        sx={{ minWidth: 200 }} />
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </Box>
        );
    }
};

export default Notifications;