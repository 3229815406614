import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import MuiIconButton from "@mui/material/IconButton/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Menu } from "../../../models/modules/restaurants/Restaurant";
import Spacer from "../../common/Spacer";
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";
import { useDialog } from "../../common/details/DetailsDialog";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DisplayOrderButton, { moveBottom, moveDown, moveTop, moveUp } from "../../common/DisplayOrderButton";

const buttonStyle = {
    height: "20px",
    width: "20px",
    bgcolor: "transparent"
} as const;

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    }
}))

interface MenuProps {
    menus: Menu[];
    onAddMenuClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onRowEditClick: (id: string) => void;
    onRowDeleteClick: (id: string) => void;
    onDisplayOrderChange: (menus: Menu[]) => void;
    permissionKey: string;
    showAddButton?: boolean;
    restaurantId: string;
}

function Menus({ menus, onAddMenuClick,  onRowEditClick, onRowDeleteClick, onDisplayOrderChange, permissionKey, showAddButton, restaurantId }: MenuProps) {
    const isFirstRenderRef = React.useRef(true);
    const { setDirty} = useDialog();
    const { checkAccess } = useUser();
    const strings = useLocalizedStrings();
    const canEdit = checkAccess(permissionKey, Access.Update);
    const canDelete = checkAccess(permissionKey, Access.Delete);
    const canAdd = checkAccess(permissionKey, Access.Create);

    React.useEffect(() => {
        if (isFirstRenderRef.current) {
            isFirstRenderRef.current = false;
            return;
        }

        // make dialog dirty when data changes and it's not the first render
        setDirty(true);

        return () => {
            setDirty(false);
        }

    }, [menus]);

    function handleMoveUpClick(id: string) {
        updateDisplayOrder(moveUp(menus, id, 1));
    }

    function handleMoveDownClick(id: string) {
        updateDisplayOrder(moveDown(menus, id, 1));
    }

    function handleMoveToTopClick(id: string) {
        updateDisplayOrder(moveTop(menus, id));
    }

    function handleMoveToBottomClick(id: string) {
        updateDisplayOrder(moveBottom(menus, id));
    }

    function getDisplayOrderForItem(id: string) {
        let index = menus.findIndex(menu => menu.id === id);
        return menus[index].displayOrder;
    }

    function updateDisplayOrder(menus: Menu[]) {
        onDisplayOrderChange(menus);
    }

    return (
        <Box height={400}>
            {((showAddButton && canEdit) || 
                (restaurantId === "" && canAdd)) && (
            <>
                <Box sx={{ textAlign: "right" }}>
                    <Tooltip title={strings.gridAddRowTooltipText} placement="bottom-end">
                        <Button style={{ textAlign: "right" }} startIcon={<AddIcon />} size="small" variant="contained" onClick={onAddMenuClick}>
                            {strings.addButtonTitle}
                        </Button>
                    </Tooltip>
                </Box>
                <Spacer />
            </>
            )}
            <Paper variant="outlined" >
                <TableContainer>
                    <Table size="small">
                        <TableHead >
                            <TableRow>
                                <TableCell >{strings.name}</TableCell>
                                <TableCell align="center"> {strings.gridActions}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {menus.map((row, index) => (
                                <StyledTableRow key={row.id} >
                                    <TableCell scope="row" >{row.name?.en ?? ""}</TableCell>
                                    <TableCell align="center" size="small"  >
                                        {(canEdit || 
                                            (restaurantId === "" && canAdd))  ? (
                                            <MuiIconButton onClick={() => onRowEditClick(row.id)}>
                                                <EditIcon titleAccess={strings.gridActionEditTitleAccess} sx={{ ...buttonStyle, color: "primary.dark" }} />
                                            </MuiIconButton>
                                        )
                                            : (<MuiIconButton onClick={() => onRowEditClick(row.id)}>
                                                <VisibilityIcon titleAccess={strings.gridActionEditTitleAccess} sx={{ ...buttonStyle, color: "primary.dark" }} />
                                            </MuiIconButton>
                                            )}
                                        {(canEdit || 
                                            (restaurantId === "" && canAdd))&& (
                                            <MuiIconButton onClick={() => onRowDeleteClick(row.id)}>
                                                <DeleteIcon titleAccess={strings.gridActionDeleteTitleAccess} sx={{ ...buttonStyle, color: "error.dark" }} />
                                            </MuiIconButton>
                                        )}
                                        <DisplayOrderButton
                                            permissionKey="content_restaurant"
                                            current={getDisplayOrderForItem(row.id)}
                                            count={menus.length}
                                            onMoveTopClick={() => handleMoveToTopClick(row.id)}
                                            onMoveUpClick={() => handleMoveUpClick(row.id)}
                                            onMoveDownClick={() => handleMoveDownClick(row.id)}
                                            onMoveBottomClick={() => handleMoveToBottomClick(row.id)}
                                        />
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}

export default Menus;
