import { StringLanguageDict } from "../../common/LanguageModel";
import { ServiceAttributeChoice } from "./ServiceAttributeChoice";

export interface ServiceAttribute {
    id: string;
    type: string;
    instructions?: StringLanguageDict | null;
    choices: ServiceAttributeChoice[];
    minQuantity: number | null;
    maxQuantity: number | null;
    minuteIncrement: number | null;
    allowMultiSelect: boolean;
    displayOrder: number;
}

export const initialServiceAttributeState: ServiceAttribute = {
    id: "",
    type: "",
    instructions: null,
    choices: [],
    minQuantity: 1,
    maxQuantity: 5,
    minuteIncrement: 30,
    allowMultiSelect: false,
    displayOrder: 0
}

export const mapMinuteIncrementToKeyValues = (): { key: string; value: string }[] => {
    let kvps: { key: string; value: string }[] = [];
    kvps.push({key: "15", value: "15"})
    kvps.push({key: "30", value: "30"})
    kvps.push({key: "60", value: "60"})
    
    return kvps;
}

export const mapAttributeTypesToKeyValues = (): { key: string; value: string }[] => {
    let kvps: { key: string; value: string }[] = [];
    kvps.push({key: "QTY", value: "Quantity"})
    kvps.push({key: "TIME", value: "Time"})
    kvps.push({key: "CHOICE", value: "Choice"})
    kvps.push({key: "TEXT", value: "Text"})

    return kvps;
}