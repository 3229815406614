import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import MuiIconButton from "@mui/material/IconButton";
import Paper, { PaperProps } from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Draggable from "react-draggable";
import { useLocalizedStrings } from "../../localization/LocalizedStringsProvider";
import themePrimary from "../../styles/themePrimary";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    draggableDialog: {
        cursor: "move",
    },
    dialogContent: {
        cursor: "default",
    },
    appBarSpacer: {
        ...themePrimary.mixins.toolbar,
    },
});

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={"[class*='MuiDialogContent-root']"}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export interface AppDialogProps {
    open: boolean;
    title: string;
    children: React.ReactNode;
    contentSize: { width: number; height: number };
    onCancelClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const AppDialog = (props: AppDialogProps) => {
    const { children } = props;
    const classes = useStyles();
    const strings = useLocalizedStrings();

    const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.onCancelClick(event);
    };

    return (
        <Dialog            
            open={props.open}
            onClose={handleCancelClick}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={{
                sx: {
                    width: `${props.contentSize.width}px`,
                    maxWidth: `${props.contentSize.width}px`,
                    height: `${props.contentSize.height + 124.5}px`,
                },
            }} // (173.5 is height of headers and footer) https://medium.com/the-clever-dev/how-to-size-and-position-the-material-ui-mui-dialog-component-a5601cedc1c9
            fullWidth={true}
        >
            <AppBar /* style={{ cursor: "move" }} */ id="draggable-dialog">
                {/* Try to get default padding (themePrimary.mixins.toolbar?) and subtract the X button margin from the right (it has a bult in padding of 8px that you can see when you highlight). Hard coding 24 and 16 is the only way to get the X to look correct. */}
                <Toolbar
                    disableGutters
                    sx={{ paddingLeft: "24px", paddingRight: "16px" }}
                >
                    <Typography variant="h6" noWrap component="div">
                        {props.title}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box>
                        <MuiIconButton
                            aria-label="close"
                            color="inherit"
                            size="large"
                            edge="end"
                            onClick={handleCancelClick}
                        >
                            <CloseIcon />
                        </MuiIconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <div className={classes.appBarSpacer} />
            <div style={{ overflowY: 'scroll' }}>
                {children}
            </div>
        </Dialog>
    );
};

export default AppDialog;
