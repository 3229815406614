import * as React from "react";
import { default as DataGrid, DataGridWrapperProps} from "../../common/datatable/DataGridWrapper";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";
import { useDialog } from "../../common/details/DetailsDialog";

function RoomTvGrid({ rows,  ...rest}: DataGridWrapperProps) {
    const isFirstRenderRef = React.useRef(true);
    const { setDirty, adding, permissionKey } = useDialog();
    const { checkAccess } = useUser();
    const [readOnly] = React.useState(!checkAccess(permissionKey ?? permissionKey, adding ? Access.Create : Access.Update));
    

    React.useEffect(() => {
        if (isFirstRenderRef.current) {
            isFirstRenderRef.current = false;
            return;
          }

          // when rows change and it's not the first render
          setDirty(true);

          return () => {
            setDirty(false);
          }
    }, [rows])

    return (
        <DataGrid 
            showAddButton={!readOnly}
            rows={rows}
            {...rest}
        />
    )
}

export default RoomTvGrid