import { StringLanguageDict } from "../../common/LanguageModel";
import { ServiceAttribute } from "./ServiceAttribute";

export interface Service {
    id: string;
    name: StringLanguageDict | null;
    description?: StringLanguageDict | null;
    type: { id: string; name: string } | null;
    typeId: string;
    roomClasses: { id: string; name: string }[];
    attributes: ServiceAttribute[];
    externalId: string;
    emailAddress: string | null;
    emailSubject: string | null;
    enabled: boolean;
    previewOnly: boolean;
}

export const initialServiceState: Service = {
    id: "",
    name: null,
    description: null,
    type: null,
    typeId: "",
    roomClasses: [],
    attributes: [],
    externalId: "",
    emailAddress: null,
    emailSubject: null,
    enabled: true,
    previewOnly: false
}

export function mapServicesToKeyValues(services: Service[]): { key: string; value: string }[] {
    let kvps: { key: string; value: string }[] = [];

    kvps = services.map((service, index) => {
        return {
            key: service.id,
            value: service.name?.en ?? "",
        };
    });

    return kvps;
}
