import { default as axios, AxiosInstance, AxiosError } from "axios";
import { GraphicModel } from "../models/common/GraphicModel";

export async function deleteGraphics(
  axiosInstance: AxiosInstance,
  itemType: string,
  itemKey: string,
  images: GraphicModel[],
  propertyId: string | undefined
) {
    images.forEach(async (image) => {
        if (propertyId === undefined) {
          try {
            return await axiosInstance.delete(
                `${process.env.REACT_APP_MANAGER_API_URL}/images/${itemType}/${itemKey}/${image.imageKey}`
            );
          }
          catch (ex) {
              const isAxiosError = axios.isAxiosError(ex);
              if ((isAxiosError && (ex as AxiosError)?.response?.status !== 404) || !isAxiosError) {
                  throw ex;
              }
          }
        }
        else {
          try {
            return await axiosInstance.delete(
                `${process.env.REACT_APP_MANAGER_API_URL}/images/${propertyId!!}/${itemType}/${itemKey}/${image.imageKey}`
            );
          }
          catch (ex) {
              const isAxiosError = axios.isAxiosError(ex);
              if ((isAxiosError && (ex as AxiosError)?.response?.status !== 404) || !isAxiosError) {
                  throw ex;
              }
          }
        }
  });
}

export async function uploadGraphics(
  axiosInstance: AxiosInstance,
  itemType: string,
  itemKey: string,
  images: GraphicModel[],
  propertyId: string | undefined
) {
  if (images.length < 1) return;

  const formData = new FormData();
  formData.append("ItemType", itemType);
  formData.append("ItemKey", itemKey);

  if (propertyId !== undefined) {
    formData.append("PropertyId", propertyId!!);
  }

  images.forEach((image) => {
    if (image.fileData) {
      formData.append(image.imageKey, image.fileData, image.fileData.name);
    }
  });

  const config = {
    headers: {
      Accept: "application/json",
      "content-type": "multipart/form-data",
    },
  };

  return await axiosInstance.put(
    process.env.REACT_APP_MANAGER_API_URL + "/images",
    formData,
    config
  );
}

export async function copyGraphic(
  axiosInstance: AxiosInstance,
  itemType: string,
  sourceItemKey: string,
  destinationItemKey: string,
  key: string,
  propertyId: string | undefined
) {
    if (propertyId === undefined) {
      return await axiosInstance.post(`${process.env.REACT_APP_MANAGER_API_URL}/images/${itemType}/${destinationItemKey}/${key}`, { itemKey: sourceItemKey }).then((response) => {
        return response.data;
      });
    }
    else {
      return await axiosInstance.post(`${process.env.REACT_APP_MANAGER_API_URL}/images/${propertyId!!}/${itemType}/${destinationItemKey}/${key}`, { itemKey: sourceItemKey }).then((response) => {
        return response.data;
      });
    }
}
