import { AxiosInstance } from "axios";
import { Property } from "../../models/pms/Property";

const url = process.env.REACT_APP_MANAGER_API_URL + "/pms/";

export async function getConfiguration(axiosInstance: AxiosInstance, propertyCode: string): Promise<Property> {
    return await axiosInstance
        .get(`${url}${propertyCode}/configuration`)
        .then((response) => response.data.property as Property);
}

export async function updateConfiguration(axiosInstance: AxiosInstance, propertyCode: string, property: Property) {    
    return await axiosInstance.put(`${url}${propertyCode}/configuration`, property).then((response) => {
        return response.data;
    });
}