import { AxiosInstance } from "axios";
import { Configuration } from "../../../models/dashboard/support/Configuration";
import { parseLanguageStringsFromJson } from "../../../utilities/JsonHelper";
import { v4 as uuid } from "uuid";

const url = process.env.REACT_APP_MANAGER_API_URL + "/service-ticket/";

export async function getConfiguration(axiosInstance: AxiosInstance, propertyCode: string): Promise<Configuration> {
    return await axiosInstance
        .get(`${url}${propertyCode}/configuration`)
        .then((response) => response.data.configuration as Configuration);
}

export async function saveConfiguration(axiosInstance: AxiosInstance, propertyCode: string, configuration: Configuration): Promise<Configuration> {
    const id = configuration.id ?? uuid();
    const configurationToSave = {
        ...configuration,  
        id: id,           
    };

    return await axiosInstance.put(`${url}${propertyCode}/configuration`, configurationToSave).then((response) => {
        return response.data;
    })   
}
