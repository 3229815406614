import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { Ref, useEffect, useImperativeHandle, useState } from "react";
import { copyVideo as copy, deleteVideos, uploadVideos } from "../../../api/videoApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { VideoModel } from "../../../models/common/VideoModel";
import { useDialog } from "../details/DetailsDialog";
import Video from "./Video";
import VideoAspectRatioTooltip from "./VideoAspectRatioTooltip";

// styles
const videoContainerStyle = {
    display: "flex",
    width: "65%",
    float: "left",
    mr: "2px",
    mb: "8px", // Can we get the theme spacing?
    position: "relative",
    zIndex: 99999,
} as const;

const listItemStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
} as const;

const backdropStyle = {
    zIndex: 1,
    bgcolor: "rgb(0 0 0 / 10%)",
    color: "secondary.main",
} as const;

const aspectRatioTextStyle = {
    textDecoration: "underline",
} as const;

export interface VideoProps {
    propertyId: string | undefined;
    itemType: string;
    itemKey: string;
    videoKeys: string[];
    labels?: string[];
    videos: VideoModel[];    
}

interface VideoStateProps {
    propertyId: string | undefined;
    itemType: string;
    itemKey: string;
    videoKeys: string[];
    labels?: string[];
    disabled?: boolean;
    permissionKey?: string;
    initialVideoState?: VideoProps; // It's possible that we want to use a "state" object we had on a previous instance (when batching a save eg: MenuBuilder).
}

export interface VideoManagerRefObject {
    saveVideos: (itemKey: string, propertyId: string | undefined) => void;
    validate: () => boolean;
    getVideoState: () => VideoProps;
    isDirty: () => boolean;
    hasErrors: () => boolean;
    copyVideo: (itemType: string, sourceItemKey: string, destinationItemKey: string, key: string, propertyId: string | undefined) => void;
}

const VideoManager = React.forwardRef((props: VideoStateProps, ref: Ref<VideoManagerRefObject>) => {
    const [videoErrorState, setVideoErrorState] = useState<string[]>([]);
    const [videoState, setVideoState] = useState<VideoProps>(getInitialState());
    const { dirty, setDirty } = useDialog();
    const [loading, setLoading] = useState(true);
    const strings = useLocalizedStrings();
    const axiosInstance = useCreateAxios();
    const [validationError, setValidationError] = React.useState("");    

    function getInitialState() {        
        return props.initialVideoState ?? {
            propertyId: props.propertyId,
            itemType: props.itemType,
            itemKey: props.itemKey,
            videoKeys: props.videoKeys,
            labels: props.labels,
            videos: props.videoKeys.map(
                (key: string): VideoModel => ({
                    url: props.propertyId === undefined ? `${process.env.REACT_APP_MANAGER_API_URL}/videos/${props.itemType}/${props.itemKey}/${key}?nocache=${generateRandomNumber()}` : `${process.env.REACT_APP_MANAGER_API_URL}/videos/${props.propertyId!!}/${props.itemType}/${props.itemKey}/${key}?nocache=${generateRandomNumber()}`,
                    fileData: null,
                    videoKey: key,
                    onServer: false
                })
            ),
        };    
    }

    useImperativeHandle(ref, () => ({ saveVideos, validate, getVideoState, isDirty, hasErrors, copyVideo }));

    useEffect(() => {
        const id = setTimeout(() => {
            setLoading(false);
        }, 1000);
        return () => clearTimeout(id);
    }, []);

    function generateRandomNumber() {
        return Math.floor(Math.random() * 1000);
    }

    function handleVideoNotFoundError(
        e: React.SyntheticEvent<HTMLVideoElement | HTMLSourceElement, Event>,
        key: string
    ) {
        setVideoErrorState(videoErrorState.concat(key));
    }

    function handleVideoLoad(
        e: React.SyntheticEvent<HTMLVideoElement, Event>,
        key: string
    ) {
        const updatedVideos: VideoModel[] = videoState.videos.map((video) => {
            if (video.videoKey === key && !video.url.includes("blob")) {
                return {
                    ...video,
                    onServer: true,
                };
            } else {
                return { ...video };
            }
        });

        setVideoState((current: VideoProps) => ({
            ...current,
            videos: updatedVideos,
        }));

        setVideoErrorState(videoErrorState.filter((k) => k !== key));
    }

    function handleVideoUpload(uploadedVideo: VideoModel) {
        setDirty(true);
        setValidationError("");

        setVideoErrorState(
            videoErrorState.filter((k) => k !== uploadedVideo.videoKey)
        );

        const updatedVideos: VideoModel[] = videoState.videos.map((video) => {
            if (video.videoKey === uploadedVideo.videoKey) {
                return {
                    ...video,
                    url: uploadedVideo.url,
                    fileData: uploadedVideo.fileData,
                };
            } else {
                return { ...video };
            }
        });

        setVideoState((current: VideoProps) => ({
            ...current,
            videos: updatedVideos,
        }));
    }

    function handleVideoUploadError(message: string) {
        setValidationError(message);
    }

    function handleVideoDelete(videoToDelete: VideoModel) {
        setDirty(true);

        const updatedVideos: VideoModel[] = videoState.videos.map((video) => {
            if (video.videoKey === videoToDelete.videoKey) {
                return {
                    ...video,
                    url: ``, // empty otherwise it will display video on server if there
                    fileData: null,
                };
            } else {
                return { ...video };
            }
        });
        
        setVideoErrorState(videoErrorState.concat(updatedVideos[0].videoKey));

        setVideoState((current: VideoProps) => ({
            ...current,
            videos: updatedVideos,
        }));
    }

    function isNotFound(key: string) {
        return videoErrorState.find((k) => k === key) ? true : false;
    }

    async function saveVideos(itemKey: string, propertyId: string | undefined) {
        const deletedVideos = getVideosDeleted(videoState.videos);
        const addedVideos = getVideosAdded(videoState.videos);

        await deleteVideos(
            axiosInstance,
            videoState.itemType,
            itemKey,
            deletedVideos,
            propertyId
        );

        await uploadVideos(
            axiosInstance,
            videoState.itemType,
            itemKey,
            addedVideos,
            propertyId
        );

        // helpers
        function getVideosAdded(videos: VideoModel[]) {
            return videos.filter((video) => video.url.includes("blob"));
        }

        function getVideosDeleted(videos: VideoModel[]) {
            return videos.filter(
                (video) => video.onServer === true && video.url === ""
            );
        }
    };

    function validate() {
        if (hasErrors()) {
            setValidationError(strings.validationVideo);
            return false;
        }

        setValidationError("");
        return true;
    }

    function isDirty() {
        const deletedVideos = getVideosDeleted(videoState.videos);
        const addedVideos = getVideosAdded(videoState.videos);

        return deletedVideos.length > 0 || addedVideos.length > 0;

        // helpers
        function getVideosAdded(videos: VideoModel[]) {
            return videos.filter((video) => video.url.includes("blob"));
        }

        function getVideosDeleted(videos: VideoModel[]) {
            return videos.filter(
                (video) => video.onServer === true && video.url === ""
            );
        }
    }

    // Use this to determine if there is an image to copy
    function hasErrors() {
        return videoErrorState.length > 0;
    }

    async function copyVideo(itemType: string, sourceItemKey: string, destinationItemKey: string, key: string, propertyId: string | undefined) {
        await copy(axiosInstance, itemType, sourceItemKey, destinationItemKey, key, propertyId);
    }

    function getVideoState() {
        return videoState;
    }

    // one img per imageKey in array
    return (           
        <Grid container>   
            {!loading ?
                <Grid item xs={6}>
                    {videoState.videos.map((video, index) => (
                        <React.Fragment key={video.videoKey}>
                            <Box
                                sx={{
                                    visibility: loading ? "hidden" : "visible",
                                    ...videoContainerStyle,
                                }}
                                id={"video-container"}
                            >
                                <Stack direction="column">
                                    <Video
                                        hasError={isNotFound(video.videoKey)}
                                        hasValidationError={validationError !== ""}
                                        data={video}
                                        label={videoState.labels?.[index] ?? ""}
                                        itemType={videoState.itemType}
                                        disabled={props.disabled}
                                        permissionKey={props.permissionKey}
                                        onNotFoundError={handleVideoNotFoundError}
                                        onVideoUpload={handleVideoUpload}
                                        onVideoUploadError={handleVideoUploadError}
                                        onVideoLoad={handleVideoLoad}
                                        onVideoDelete={handleVideoDelete}
                                    />                                
                                    <Typography sx={{ marginLeft: "14px", marginTop: "3px" }} variant="caption" color="error">{validationError}</Typography>                                
                                </Stack>
                            </Box>
                        </React.Fragment>
                    ))}
                </Grid> :
                <Grid 
                    container
                    direction="row"
                    item 
                    justifyContent="center"
                    alignItems="center" 
                    xs={6}
                >
                    <CircularProgress color="secondary" />
                </Grid>
            }        
            <Grid item xs={6}>
                <Box>
                    <Typography variant={"subtitle1"}>{strings.uploadVideosSpecificationsHeader}</Typography>
                    <List dense={true}>
                        <ListItem sx={listItemStyle}>
                            <ListItemText>{strings.uploadVideosSpecificationsFileSize}</ListItemText>
                            <ListItemText>{strings.uploadVideosSpecificationsFileType}</ListItemText>
                            {videoState.videos.map((video) =>   
                                !isNotFound(video.videoKey) && 
                                    <ListItemText>
                                        <VideoAspectRatioTooltip videoKey={video.videoKey} /> 
                                    </ListItemText>
                            )}                                
                        </ListItem>
                    </List>
                </Box>
            </Grid>
        </Grid>        
    );    
});

export default VideoManager;