import { AxiosInstance } from "axios";
import { Promotion, PromotionItem } from "../../models/modules/promotions/Promotion";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";
import { v4 as uuid } from "uuid";

const url = process.env.REACT_APP_MANAGER_API_URL + "/promotions/";

export async function getPromotions(axiosInstance: AxiosInstance, propertyCode: string): Promise<Promotion[]> {
    return await axiosInstance
        .get(`${url}${propertyCode}/promotions`)
        .then((response) => parseLanguageStringsFromJson(response.data.promotions) as Promotion[]);
}

export async function getPromotion(axiosInstance: AxiosInstance, propertyCode: string, id: string): Promise<Promotion> {
    return await axiosInstance
        .get(`${url}${propertyCode}/promotions/${id}`)
        .then((response) => response.data.promotion as Promotion);
}

export async function updatePromotion(axiosInstance: AxiosInstance, propertyCode: string, promotion: Promotion) {
    promotion.items = [];

    return await axiosInstance.put(`${url}${propertyCode}/promotions/${promotion.id}`, promotion).then((response) => {
        return response.data;
    });
}

export async function createPromotion(axiosInstance: AxiosInstance, propertyCode: string, promotion: Promotion) {
    const promotionToSave = {
        ...promotion,
        id: uuid(),
        propertyId: propertyCode
    }

    return await axiosInstance.post(`${url}${propertyCode}/promotions`, promotionToSave).then((response) => {
        return response.data;
    });
}

export async function deletePromotion(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return await axiosInstance.delete(`${url}${propertyCode}/promotions/${id}`).then((response) => {
        return response.data;
    });
}

export async function createPromotionItem(axiosInstance: AxiosInstance, propertyCode: string, promotionId: string, promotionItem: PromotionItem) {
    const id = uuid();

    const promotionItemToSave = {
        ...promotionItem,
        id: id,
        propertyCode: propertyCode,
        name: JSON.stringify({ ...promotionItem.name }),
        description: JSON.stringify({ ...promotionItem.description })
    };

    return await axiosInstance.post(`${url}${propertyCode}/promotions/${promotionId}/items`, promotionItemToSave).then((response) => {
        return id;
    });
}

export async function addExistingPromotionItem(axiosInstance: AxiosInstance, propertyCode: string, promotionId: string, promotionItem: PromotionItem) {
    const promotionItemToSave = {
        ...promotionItem,        
        propertyCode: propertyCode,
        name: JSON.stringify({ ...promotionItem.name }),
        description: JSON.stringify({ ...promotionItem.description })
    };

    return await axiosInstance.post(`${url}${propertyCode}/promotions/${promotionId}/items`, promotionItemToSave).then((response) => {
        return response.data;
    });
}

export async function removePromotionItem(axiosInstance: AxiosInstance, propertyCode: string, promotionId: string, id: string) {
    return await axiosInstance.delete(`${url}${propertyCode}/promotions/${promotionId}/items/${id}`).then((response) => {
        return response.data;
    });
}