export interface Customer {
    id: string;
    name: string;
    enabled: boolean;
}

export const initialCustomerState : Customer = {
    id: "", 
    name: "",
    enabled: true
}