import { AxiosInstance } from "axios";
import { Device } from "../../models/stb/Device";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";
import { v4 as uuid } from "uuid";

const url = process.env.REACT_APP_MANAGER_API_URL + "/stb/";

export async function getDevices(axiosInstance: AxiosInstance, propertyCode: string, roomNumber?: string): Promise<Device[]> {
    const query = roomNumber ?  `?roomNumber=${roomNumber}` : "";

    return await axiosInstance
        .get(`${url}${propertyCode}/devices${query}`)
        .then((response) => parseLanguageStringsFromJson(response.data.devices) as Device[]);
}