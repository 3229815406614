import { AxiosInstance } from "axios";
import { Options } from "../../models/modules/guestservices/Options";
import { v4 as uuid } from "uuid";

const url = process.env.REACT_APP_MANAGER_API_URL + "/guest-service/";

export async function getOptions(axiosInstance: AxiosInstance, propertyCode: string): Promise<Options> {
    return await axiosInstance
        .get(`${url}${propertyCode}/options`)
        .then((response) => response.data.options as Options);
}