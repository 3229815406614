import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { GridColDef, GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid";
import React from "react";
//import { getOptionSet } from "../../../api/roomservice/optionApi";
import Stack from "@mui/material/Stack";
import { v4 as uuid } from "uuid";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { initialOptionSetState, initialOptionState, Option as OptionModel, OptionSet as OptionSetModel } from "../../../models/modules/roomservice/Option";
import { validate, Type as ValidationType } from "../../../utilities/Validator";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import { default as DetailsDialog } from "../../common/details/DetailsDialog";
import DetailsDisplayOrderGrid, { DetailsDisplayOrderGridRefObject } from "../../common/details/DetailsDisplayOrderGrid";
import TextField from "../../common/details/TextField";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";
import { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import Option from "./Option";
import Typography from "@mui/material/Typography";
import { DisplayOrderGridRefObject } from "../../common/datatable/DisplayOrderGrid";

interface OptionSetProps {
    open: boolean;  
    adding: boolean;
    optionLevel: number;
    saveButtonLabel: string;
    optionSet: OptionSetModel
    onCancelClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onSaved: (optionSet: OptionSetModel, adding: boolean, event: React.MouseEvent<HTMLButtonElement>) => Promise<boolean>;
    onNotification: (options: NotificationOptions) => void;
}

interface OptionSetValidationErrors {
    name: string;
    internalName: string;
    minMax: string;
}

const OptionSet = (props: OptionSetProps) => {
    const initialOptionSetErrorState: OptionSetValidationErrors = {
        name: "",
        internalName: "",
        minMax: ""
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const theme = useTheme(); 
    const strings = useLocalizedStrings();         
    const [tabValue, setTabValue] = React.useState(0);
    const [optionSet, setOptionSet] = React.useState<OptionSetModel>(props.optionSet);
    const [optionSetErrors, setOptionSetErrors] = React.useState<OptionSetValidationErrors>(initialOptionSetErrorState);
    const [selectedOptionId, setSelectedOptionId] = React.useState("");
    const [selectedOption, setSelectedOption] = React.useState<OptionModel>(initialOptionState);   
    const [optionDialogOpen, setOptionDialogOpen] = React.useState(false);       
    const gridRef = React.useRef<DetailsDisplayOrderGridRefObject>(null);

    React.useEffect(() => {
        setOptionSet(props.optionSet);
    }, [props.optionSet])

    function handleOptionSetTabChange(event: React.SyntheticEvent, newValue: number) {
        if (gridRef.current?.isDirty()) {
            return;
        }

        setTabValue(newValue);
    }

    function handleOptionSetNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setOptionSet((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            }
        }));
    }    

    function handleInternalNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setOptionSet((prevState) => ({
            ...prevState,
            internalName: event.target.value,
        }));
    }

    function handleMinimumChange(event: React.ChangeEvent<HTMLInputElement>) {       
        setOptionSet((prevState) => ({
            ...prevState,
            minimum: parseInt(event.target.value),
        }));
    }

    function handleMaximumChange(event: React.ChangeEvent<HTMLInputElement>) {       
        setOptionSet((prevState) => ({
            ...prevState,
            maximum: parseInt(event.target.value),
        }));
    }

    function handleOptionSetCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        props.onCancelClick(event);
        setOptionSetErrors(initialOptionSetErrorState);
        setTabValue(0);        
        setOptionSet(initialOptionSetState);        
    }

    async function handleOptionSetSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        // We need to crate the id now because the ui needs it to function
        if (props.adding) {
            optionSet.id = uuid();
        }

        if (await props.onSaved(optionSet, props.adding, event))
        {
            setOptionSetErrors(initialOptionSetErrorState);
            setTabValue(0);            
            setOptionSet(initialOptionSetState);
            return true;
        }
        else {
            return false;
        }
    }

    function handleValidateOptionSet() {        
        let errors = validate<OptionSetModel, OptionSetValidationErrors>([
            { property: "name.en", type: ValidationType.Required, message: strings.validationRoomServiceOptionSetName },
            { property: "internalName", type: ValidationType.Required, message: strings.validationRoomServiceOptionSetInternalName }
        ], optionSet);

        if (optionSet.minimum > optionSet.maximum) {
            if (!errors) {
                errors = { 
                    name: "", 
                    internalName: "",
                    minMax: strings.validationRoomServiceOptionSetMinMax                   
                }
            }
            else {
                errors.minMax = strings.validationRoomServiceOptionSetMinMax;
            }
        }

        if (errors) {
            setOptionSetErrors(errors);
            return false;
        }
        return true;    
    }               

    // OPTION
    function handleOptionSelectionChange(optionSelectionModel: GridRowSelectionModel) {
        if (optionSelectionModel.length > 0) {
            const id = optionSelectionModel[0].toString();
            setSelectedOptionId(id);
            const option = optionSet.options.find(o => o.id === id);
            if (option) {
                setSelectedOption(option);                 
            }
        }
    }

    function handleOptionSaveDisplayOrder(ids: any[]) {
        // https://stackoverflow.com/questions/13304543/javascript-sort-array-based-on-another-array
        const options = [...optionSet.options];
        options.sort(function(a, b) {  
            return ids.findIndex(id => a.id === id) - ids.findIndex(id => b.id === id);
        });
        updateOptionOrder(options);        
    }

    function updateOptionOrder(optionList: OptionModel[]) {        
        setOptionSet((prevState) => ({
            ...prevState,
            options: optionList
        }));        
    }  
        
    function handleAddOptionButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        setSelectedOptionId("");
        setSelectedOption(initialOptionState);
        setOptionDialogOpen(true);        
    }    

    async function handleEditOptionClick(id: string) {
        const find = optionSet.options.find(o => o.id === id);
        if (find) {
            setSelectedOptionId(id);
            setSelectedOption(find);
            setOptionDialogOpen(true);
        }
    }

    function handleOptionDeleteClick(id: string, event: React.MouseEvent<HTMLButtonElement>) {   
        if (gridRef.current?.isDirty()) {
            return;
        }

        const index = optionSet.options.findIndex(o => o.id === id);       
        var newOptions = [...optionSet.options];
        newOptions.splice(index, 1);
        setOptionSet((prevState) => ({
            ...prevState,
            options: newOptions
        }));        
    }

    function handleOptionCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setOptionDialogOpen(false);
        setSelectedOptionId("");
        setSelectedOption(initialOptionState);
    } 

    async function handleOptionSaved(option: OptionModel, adding: boolean, event: React.MouseEvent<HTMLButtonElement>) {   
        if (adding) {
            option.displayOrder = optionSet.options.length;
            setOptionSet((prevState) => ({
                ...prevState,
                options: [...prevState.options, option]
            }));   
        }
        else {
            var index = optionSet.options.findIndex(o => o.id === option.id);
            if (index !== -1) {
                var tempOptions = [...optionSet.options];
                tempOptions[index] = option;

                setOptionSet((prevState) => ({
                    ...prevState,
                    options: tempOptions
                })); 
            }
        }
        
        setOptionDialogOpen(false);

        return true;
    }       

    const optionColumns: GridColDef[] = [
        { field: "name", headerName: strings.name, valueGetter: (value, row) => row.name.en, flex: 2 }, 
        { field: "price", headerName: strings.price, valueGetter: (value, row) => row.pricePerUnit ? (row.pricePerUnit as number).toLocaleString(undefined, { style: "currency", currency: "USD" }) : "", flex: 1 }, 
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditOptionClick(params.id.toString())}
                />,
                <DataGridDeleteButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={(id: string, event: React.MouseEvent<HTMLButtonElement>) => handleOptionDeleteClick(params.id.toString(), event)}
                />
            ],
        }
    ]    

    const dialogTitle = props.adding ? strings.roomServiceAddOptionSetTitle : strings.roomServiceEditOptionSetTitle.replace("{{name}}", props.optionSet.internalName); 
    
    const MinMax = () => {
        return (
            <Box sx={{ height: 78.91 }}>
                <Stack direction="row" sx={{ height: 56 }}>
                    <TextField
                        id="min"
                        value={optionSet.minimum.toString()}
                        type="number"
                        InputProps={{ inputProps: { min: 0 }}}
                        label={strings.min}
                        width={100}                            
                        onChange={handleMinimumChange}                            
                    />
                    {/*<NumberInput*/}
                    {/*    aria-label="min"*/}
                    {/*    placeholder={strings.min}*/}
                    {/*    width={100}*/}
                    {/*    value={optionSet.minimum}*/}
                    {/*    onChange={(event, val) => setValue(val)}*/}
                    {/*/>*/}
                    <Spacer x={2}/>
                    <TextField
                        id="max"
                        value={optionSet.maximum.toString()}
                        type="number"
                        InputProps={{ inputProps: { min: 0 }}}
                        label={strings.max}
                        width={100}                            
                        onChange={handleMaximumChange}                            
                    />
                </Stack>
                <Box sx={{ height: 19.91 }}>
                    { optionSetErrors.minMax &&
                        <Typography sx={{ marginLeft: "14px", marginTop: "3px" }} variant="caption" color="error">{optionSetErrors.minMax}</Typography>
                    }
                </Box>                
            </Box>
        )
    }

    return (
        <DetailsDialog
            permissionKey="content_roomservice"
            open={props.open}
            adding={props.adding}
            title={dialogTitle}
            onValidateForm={handleValidateOptionSet}
            onCancelClick={handleOptionSetCancelClick}
            onSaveClick={handleOptionSetSaveClick}
            saveButtonLabel={props.saveButtonLabel}
            contentSize={{ width: 576, height: 630.75 }}
        >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={tabValue}
                    onChange={handleOptionSetTabChange}
                    aria-label="basic tabs example"
                >
                    <Tab label={strings.details} />                    
                    <Tab label={strings.roomsServiceItemOptions} />
                </Tabs>
            </Box>
            <LanguageForm>
                <TabPanelKeepMounted value={tabValue} index={0}>
                    <TextField
                        id="internal-name"
                        value={optionSet.internalName}
                        label={strings.internalName}
                        width={410}                            
                        onChange={handleInternalNameChange}                            
                        error={Boolean(optionSetErrors.internalName)}
                        helperText={optionSetErrors.internalName}
                    />
                    <Spacer />
                    <MinMax />
                    {/*<Stack direction="row">*/}
                        {/*<TextField*/}
                        {/*    id="min"*/}
                        {/*    value={optionSet.minimum.toString()}*/}
                        {/*    type="number"*/}
                        {/*    InputProps={{ inputProps: { min: 0 }}}*/}
                        {/*    label={strings.min}*/}
                        {/*    width={100}                            */}
                        {/*    onChange={handleMinimumChange}                            */}
                        {/*/>*/}
                        {/*<NumberInput*/}
                        {/*    aria-label="min"*/}
                        {/*    placeholder={strings.min}*/}
                        {/*    width={100}*/}
                        {/*    value={optionSet.minimum}*/}
                        {/*    onChange={(event, val) => setValue(val)}*/}
                        {/*/>*/}
                        {/*<Spacer x={2}/>*/}
                        {/*<TextField*/}
                        {/*    id="max"*/}
                        {/*    value={optionSet.maximum.toString()}*/}
                        {/*    type="number"*/}
                        {/*    InputProps={{ inputProps: { min: 0 }}}*/}
                        {/*    label={strings.max}*/}
                        {/*    width={100}                            */}
                        {/*    onChange={handleMaximumChange}                            */}
                        {/*/>*/}
                    {/*</Stack>*/}
                    <Spacer />
                    <LanguageSelect />
                    <Spacer />
                    <LanguageTextField
                        id="optionSet-name"
                        label={strings.name}
                        values={optionSet.name ?? {}}
                        width={410}
                        onChange={handleOptionSetNameChange}
                        error={Boolean(optionSetErrors.name)}
                        helperText={optionSetErrors.name}
                    />                                        
                </TabPanelKeepMounted>                
                <TabPanelKeepMounted value={tabValue} index={1}>    
                    <Option
                        open={optionDialogOpen}
                        adding={selectedOptionId === ""}
                        level={props.optionLevel + 1}
                        optionSetId={props.optionSet.id}
                        saveButtonLabel={strings.doneButtonTitle}
                        option={selectedOption}
                        onCancelClick={handleOptionCancelClick}
                        onSaved={handleOptionSaved}
                        onNotification={(options: NotificationOptions) => props.onNotification(options)}
                    />

                    <DetailsDisplayOrderGrid
                        permissionKey="content_roomservice"
                        rows={optionSet.options ?? []}                            
                        columns={optionColumns}
                        rowSelectionModel={selectedOptionId}
                        onRowSelectionModelChange={handleOptionSelectionChange}                                        
                        onAddButtonClick={handleAddOptionButtonClick}
                        addButtonText={strings.roomServiceAddOptionButton}
                        onSaveDisplayOrder={handleOptionSaveDisplayOrder}
                        height={500} 
                        //pageSize={20}                        
                        ref={gridRef}
                    />                                         
                </TabPanelKeepMounted>
            </LanguageForm>
        </DetailsDialog>
    );
}

export default OptionSet;
