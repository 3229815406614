import {NotificationOptions} from "../../../components/common/NotificationMessage";
import { StringLanguageDict } from "../../common/LanguageModel";

export interface Language {
    id: string;
    name: StringLanguageDict | null;
    code: string;
    culture: string;
    enabled: boolean;
}

export interface LanguageResponse {
    languages: Language[]
}

export const initialItemState: Language = {
    id: "",
    name: null,
    code: "",
    culture: "",
    enabled: false,
  };

export const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
  };

export function mapLanguagesToKeyValues(Languages: Language[]): { key: string; value: string }[] {
    let kvps: { key: string; value: string }[] = [];

    kvps = Languages.map((langauge, index) => {
        return {
            key: langauge.code,
            value: langauge.name?.en ?? ""
        };
    });

    return kvps;
}