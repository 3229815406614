import { AxiosInstance } from "axios";
import { Preference, PreferenceItem } from "../../models/modules/restaurants/Preference"
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";
import { v4 as uuid } from "uuid";

const baseUrl = process.env.REACT_APP_MANAGER_API_URL + `/restaurant/`; 

export async function listPreferences(axiosInstance: AxiosInstance, propertyId: string, enabled?: boolean): Promise<Preference[]> {
    const url = enabled
        ? `${baseUrl}${propertyId}/preferences?enabled=${enabled}`
        : `${baseUrl}${propertyId}/preferences`

    return await axiosInstance.get(`${url}`)
        .then(response => parseLanguageStringsFromJson(response.data) as Preference[]);
}

export async function getPreference(axiosInstance: AxiosInstance, propertyId: string, id: string): Promise<PreferenceItem> {
    return await axiosInstance.get(`${baseUrl}${propertyId}/preferences/${id}`)
        .then(response => parseLanguageStringsFromJson(response.data) as PreferenceItem);
}

export async function createPreference(axiosInstance: AxiosInstance, propertyId: string, preference: PreferenceItem) {
    const preferenceToSave = {
        ...preference,
        id: uuid(),
        name: JSON.stringify({ ...preference.name }),
    };

    return await axiosInstance.post(`${baseUrl}${propertyId}/preferences/`, preferenceToSave)
        .then(response => response);
}

export async function updatePreference(axiosInstance: AxiosInstance, propertyId: string, preference: PreferenceItem) {
    const preferenceDto = {
        ...preference,
        name: JSON.stringify({ ...preference.name }),
    };

    return await axiosInstance.put(`${baseUrl}${propertyId}/preferences/${preferenceDto.id}`, preferenceDto)
        .then(response => response.data);
}

export async function deletePreference(axiosInstance: AxiosInstance, propertyId: string, id: string) {
    return await axiosInstance.delete(`${baseUrl}${propertyId}/preferences/${id}`)
        .then(response => response.data);
}

export async function saveDisplayOrder(axiosInstance: AxiosInstance, propertyId: string, idList: string[]) {
    const response = await axiosInstance.post(`${baseUrl}${propertyId}/preferences/reorder`, idList);
    return response.status
}