import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import PromotionList from "./PromotionList";
import Schedule from "./Schedule";

const Promotions = () => {
    const strings = useLocalizedStrings();   
    const location = useLocation();
    const theme = useTheme();

    return (
        <Box sx={{ width: '100%' }}>            
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={location.pathname} ria-label="basic tabs example">
                    <Tab value="/promotions/schedule" label={strings.schedule} component={Link} to="schedule" />
                    <Tab value="/promotions/promotions" label={strings.promotions} component={Link} to="promotions" />
                </Tabs>
            </Box>            
            <Box component="main" sx={{ flexGrow: 1, padding: 0 }}>
                <Routes>
                    <Route path="schedule" element={<Schedule />} />
                    <Route path="promotions" element={<PromotionList />} />
                </Routes>
            </Box>
        </Box>
    );
};

export default Promotions;
