// https://javascript.plainenglish.io/stop-using-margin-use-spacer-component-instead-953d9b2dbacc
// If no specified spacing will be 2x of the theme.spacing (8px), since there should be 16px between elements.
import React from "react";
import { Box, BoxProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";

type Props = BoxProps & {
    x?: number; // multiplier of theme.spacing
    y?: number; // multiplier of theme.spacing
    basis?: number; // multiplier of theme.spacing
};

const Spacer: React.FC<Props> = ({ x, y, basis, ...restProps }) => {
    const theme = useTheme();
    return (
        <Box
            data-testid="Spacer"
            width={x ? theme.spacing(x) : theme.spacing(1)}
            height={y ? theme.spacing(y) : theme.spacing(1)}
            flexBasis={basis ? theme.spacing(basis) : undefined}
            flexGrow={0}
            flexShrink={0}
            {...restProps}
        />
    );
};

export default Spacer;