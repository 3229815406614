import { SelectChangeEvent } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from '@mui/material/Select';
import { useEffect, useState } from "react";
import { useLocalizedStrings } from "../../localization/LocalizedStringsProvider";
import { UserProperty } from "../../models/configuration/security/User";
import { useUser } from "../../providers/UserProvider";

export interface UserPropertyDropdownProps {
    onChange: (propertyCodes: string[]) => void;
}

const UserPropertyDropdown = (props: UserPropertyDropdownProps) => {    
    const { user } = useUser();    
    const [allProperties, setAllProperties] = useState(getAllProperties());
    const [properties, setProperties] = useState<string[]>(user.currentProperty ? [user.currentProperty!.code] : []);        
    const strings = useLocalizedStrings(); 

    useEffect(() => {     
        var temp = user.currentProperty ? [user.currentProperty!.code] : [];
        setProperties(temp);      
        props.onChange(temp);
    }, [user.currentProperty?.code]);

    function getAllProperties() {
        var array: UserProperty[] = [];
        user.brands.forEach(b => {
            array = array.concat(b.properties);
        });
        array.sort((a, b) => a.name && b.name ? a.name?.en.localeCompare(b.name?.en) : 0);
        return array;
    }   

    function getRenderValue(selected: string[]) {
        return selected.map(s => allProperties.find(p => p.code === s)?.name?.en ?? "").join(', ');
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    function handleChange(event: SelectChangeEvent<typeof properties>) {
        const {
            target: { value },
        } = event;

        // On autofill we get a stringified value.
        const temp = typeof value === 'string' ? value.split(',') : value;
        setProperties(temp);
        props.onChange(temp);
    }

    return (
        <FormControl sx={{ width: 300, mr: 2 }}>
            <InputLabel id="demo-multiple-name-label">{strings.property}</InputLabel>
            <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={properties}
                onChange={handleChange}
                input={<OutlinedInput label={strings.property} />}
                renderValue={getRenderValue}
                MenuProps={MenuProps}                      
            >
                {allProperties.map((property) => (
                    <MenuItem key={property.code} value={property.code}>
                        <Checkbox checked={properties.indexOf(property.code) > -1} />
                        <ListItemText primary={property.name?.en ?? ""} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default UserPropertyDropdown;