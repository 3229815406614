import * as React from "react";
import { Access } from "../models/configuration/security/Permission";
import { User, UserPermission, initialUserState, } from "../models/configuration/security/User";
import { useAuth } from "oidc-react";

const localState = localStorage.getItem("user") !== null ? JSON.parse(localStorage.getItem("user")!) : null;
const ADMIN_TENANT_ID = "admin";

let reducer = (state: User, newState: User) => {
    if (newState === null) {
        localStorage.removeItem("user");
        return initialUserState;
    }

    return { ...state, ...newState };
};

// this type will be moved and changed when mgr can do scopes on properties. 
// right now, only POTS, WIFI and NETWORK are relevant 
// and being sent down, and they are in caps. 
export type FeatureType =
    | 'ITV'
    | 'MobileApp'
    | 'NETWORK'
    | 'NETWORK_CIRCUITONLY'
    | 'CIRCUIT'
    | 'POTS'
    | 'WIFI'
    | 'Voice'
    | 'Security'
    | 'DigitalSignage'
    | 'Audio'
    | 'Television'
    | 'Casting'
    | 'Alerts'
    | 'BackOfHouse';

type UserContextType = {
    user: User;
    setUser: React.Dispatch<any>;
    checkAccess: (key?: string, access?: Access, requiresProperty?: boolean, exact?: boolean) => boolean;
    hasIncludedFeatures: (featureTypes: FeatureType[], exclusiveSearch?: boolean) => boolean;
    hasIncludedPropertyFeatures: (featureTypes: FeatureType[], propertyId?: string, exclusiveSearch?: boolean) => boolean;
    isSuperAdmin: (requiresCustomerSelected?: boolean) => boolean;
    isAdminCustomer: () => boolean;
}

const defaultBehavior: UserContextType = {
    user: initialUserState, setUser: () => null,
    checkAccess: (key?: string, access?: Access, requiresProperty?: boolean): boolean => false,
    hasIncludedFeatures: (featureTypes: FeatureType[], exclusiveSearch?: boolean): boolean => false,
    hasIncludedPropertyFeatures: (featureTypes: FeatureType[], propertyId?: string, exclusiveSearch?: boolean): boolean => false,
    isSuperAdmin: (requiresCustomerSelected?: boolean): boolean => false,
    isAdminCustomer: () => false
};

const UserContext = React.createContext<UserContextType>(defaultBehavior);

export const useUser = () => React.useContext(UserContext);

const UserProvider = (props: { children: React.ReactNode }) => {
    const [currentUser, setCurrentUser] = React.useReducer(reducer, localState || initialUserState);
    const auth = useAuth();
      
    const hasIncludedFeatures = (featureTypes: FeatureType[], exclusive?: boolean): boolean => {
        const customerFeatures = currentUser?.features as FeatureType[];

        if (!exclusive) {
            return customerFeatures.some(ft => featureTypes.includes(ft as FeatureType));
        }
        else if (exclusive && exclusive === true) {
            const exclusiveFeature = featureTypes[0];
            return customerFeatures.filter(ft => !ft.includes(exclusiveFeature)).length === 0;
        }

        return false;
    }

    const hasIncludedPropertyFeatures = (featureTypes: FeatureType[], propertyId?: string, exclusive?: boolean): boolean => {
        let customerFeatures: FeatureType[];
        
        if (propertyId) {
            const flatProperties = currentUser.brands.flatMap(b => b.properties);
            const property = flatProperties.find(p => p.code === propertyId);
            if (!property) {
                console.log(`Property ${propertyId} not found.`);
                return false;
            }
            customerFeatures = property?.features as FeatureType[];
        }
        else {
            customerFeatures = currentUser?.currentProperty?.features as FeatureType[];
        }

        if (!exclusive) {
            return customerFeatures.some(ft => featureTypes.includes(ft as FeatureType));
        }
        else if (exclusive && exclusive === true) {
            const exclusiveFeature = featureTypes[0];
            return customerFeatures.filter(ft => !ft.includes(exclusiveFeature)).length === 0;
        }

        return false;
    }

    function isSuperAdmin(requiresCustomerSelected?: boolean) { // TODO: check from only admin
        const isAdmin = Boolean(auth.userData?.profile["admin"]);

        return requiresCustomerSelected
            ? isAdmin && currentUser.currentCustomer !== undefined
            : isAdmin;
    }

    function isAdminCustomer() {
        return (currentUser.currentCustomer === undefined || currentUser.currentCustomer.id === ADMIN_TENANT_ID) && currentUser.currentProperty === undefined;
    }

    const checkUserAccess = (key?: string, access?: Access, requiresProperty?: boolean, exact?: boolean): boolean => {

        if (requiresProperty !== undefined && requiresProperty === true) {
            if (currentUser.currentProperty === undefined) {
                return false;
            }
        }

        if (key !== undefined && access !== undefined)
        {
            var permissions: UserPermission[] | undefined = currentUser.permissions?.filter(p => (exact && p.key === key) || p.key.startsWith(key));            

            if (permissions !== undefined && permissions.length > 0) {
                // Now it's possible for there to be multiple permissions found (since I changed the line above to filter (so we can support nested permissions).
                // So we need to find the one with the highest level of access.
                let permission = permissions[0];
                if (permissions.length > 1) {
                    permissions.forEach(p => {
                        if (p.access > permission.access) {
                            permission = p;
                        }
                    });
                }                

                if (permission.access & access) {
                    return true;
                }
                else {
                    console.log(`Permission ${key}-${access} failed, user has ${permission.access}`);
                    return false;
                }
            }
            else {
                console.log(`Permission ${key} failed, not found for user.`);
                return false;
            }
        }
        else {
            return true;
        }
    }

    React.useEffect(() => {
        localStorage.setItem("user", JSON.stringify(currentUser));
    }, [currentUser]);

    return (
        <UserContext.Provider value={{ user: currentUser, setUser: setCurrentUser, checkAccess: checkUserAccess, hasIncludedFeatures, hasIncludedPropertyFeatures, isSuperAdmin, isAdminCustomer }}>
            {props.children}
        </UserContext.Provider>
    );
};

export default UserProvider;