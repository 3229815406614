import { default as MuiIconButton, IconButtonProps } from "@mui/material/IconButton";
import * as React from 'react';
import { useDialog } from "./DetailsDialog";

const IconButton = (props: IconButtonProps) => {    
    const { dirty, setDirty, adding, permissionKey } = useDialog();    
    
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDirty(true);
        props.onClick?.(event);
    };

    return (       
        <MuiIconButton
            id={props.id}
            aria-label={props['aria-label']}
            aria-controls="basic-menu"
            aria-haspopup="true"
            color={props.color}
            onClick={handleClick}
        >
            {props.children}
        </MuiIconButton>
    );
};


export default IconButton;