import * as React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/wrapper/Layout";
import themePrimary from "./styles/themePrimary";
import { ThemeProvider } from "@mui/material/styles";
import WrapperContextProvider from "./providers/WrapperContextProvider";
import UserProvider from "./providers/UserProvider";
import AlertDialogProvider from "./components/common/AlertDialog/AlertDialogProvider";
import LocalizedStringProvider from "./localization/LocalizedStringsProvider";
import { AuthProvider, UserManager } from "oidc-react";
import SignInRedirect from "./components/wrapper/SignInRedirect";
import SignOutRedirect from "./components/wrapper/SignOutRedirect";
import { Log } from "oidc-react";

const getOidcClientId = (): string => {
    const host = window.location.host;
    
    if (host.includes("localhost")) return "dev-admin-manager";
    if (host.split('.').length <= 2) return "";

    return host.split('.')[0] + "-manager";
}

const userManager = new UserManager({
    filterProtocolClaims: false,
    authority: process.env.REACT_APP_OIDC_AUTHORITY,
    client_id: getOidcClientId(),
    redirect_uri: window.location.protocol + "//" + window.location.host + "/signin-callback",
    post_logout_redirect_uri: window.location.protocol + "//" + window.location.host + "/signout-callback",
    response_type: "code",
    scope: "openid profile manager offline_access",
    loadUserInfo: true,
    automaticSilentRenew: true,
});

Log.logger = console;
Log.level = Log.DEBUG;

const App = () => {
    /*
     * This seems very hackish, but store the original url, so that after sign in callback we can go on to the requested url.
     * I thought we could just pass in the pathname into SignInRedirect, but the entire app reloads when we come back from the 
     * sign in page. The pathname on callback is /signin-callback, which wipes out the original pathname. So instead we will
     * store the original path (ignoring /signin-callback), then pull it out in SignInRedirect.
     */
    if (window.location.pathname !== "/signin-callback") {        
        window.localStorage.setItem('pathName', window.location.pathname);
    }

    return (
        <div>
            <ThemeProvider theme={themePrimary}>
                <WrapperContextProvider>
                    <AuthProvider userManager={userManager} autoSignIn={false} >
                        <UserProvider>
                            <Router>
                                <Routes>
                                    <Route path="/*" element={
                                        <LocalizedStringProvider>
                                            <AlertDialogProvider>
                                                <Layout />
                                            </AlertDialogProvider>
                                        </LocalizedStringProvider>
                                    } />
                                    <Route path="/signin-callback" element={<SignInRedirect />} />
                                    <Route path="/signout-callback" element={<SignOutRedirect />} />
                                </Routes>

                            </Router>
                        </UserProvider>
                    </AuthProvider>
                </WrapperContextProvider>
            </ThemeProvider>
        </div>
    );
}

export default App;
