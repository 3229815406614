import { Box, Tab, Tabs } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { createPreference, deletePreference, getPreference, listPreferences, saveDisplayOrder, updatePreference } from "../../../api/restaurants/preferencesApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Preference, PreferenceItem, initialPreferenceItemState } from "../../../models/modules/restaurants/Preference";
import { useUser } from "../../../providers/UserProvider";
import { Field as ValidationField, Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import DisplayOrderGrid, { DisplayOrderGridRefObject } from "../../common/datatable/DisplayOrderGrid";
import Checkbox from "../../common/details/Checkbox";
import DetailsDialog from "../../common/details/DetailsDialog";
import TextField from "../../common/details/TextField";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";

interface ValidationErrors {
    name: string;
}

const initialErrorState: ValidationErrors = {
    name: "",
}

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

function PreferenceList() {
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const [gridRefresh, setGridRefresh] = useState(false);
    const [preferences, setPreferences] = useState<Preference[]>([]);
    const [selectedPreference, setSelectedPreference] = useState<PreferenceItem>(initialPreferenceItemState);
    const [selectedPreferenceId, setSelectedPreferenceId] = useState("");
    const strings = useLocalizedStrings();
    const [tabValue, setTabValue] = useState(0);
    const alertDialog = useAlertDialog();
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const axiosInstance = useCreateAxios();
    const theme = useTheme();
    const { user } = useUser();
    const gridRef = useRef<DisplayOrderGridRefObject>(null);

    useEffect(() => {
        const getPreferenceList = async () => {
            try {
                const payload = await listPreferences(axiosInstance, user?.currentProperty?.code ?? "");
                setPreferences(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };

                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingPreferences.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }
            finally {
                setIsLoading(false);
            }
        }

        getPreferenceList();
    }, [user.currentProperty?.code, gridRefresh])

    async function handleEditRowClick(id: string) {
        if (gridRef.current?.isDirty()) {
            return;
        }

        setSelectedPreferenceId(id);

        try {
            const payload = await getPreference(axiosInstance, user?.currentProperty?.code ?? "", id);
            setSelectedPreference(payload);
            setDetailsDialogOpen(true);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingPreference.replace("{{error}}", error.message),
                msgType: "error",
            });
        }
        finally {
            setIsLoading(false);
        }
    }

    async function deleteSelectedPreference(id: string) {
        try {
            await deletePreference(axiosInstance, user.currentProperty?.code ?? "", id);
            setPreferences(preferences.filter((item) => item.id !== id));
            setNotify({
                isOpen: true,
                message: strings.preferenceDeletedSuccessfully,
                msgType: "success",
            });
        }
        catch (error: unknown) {
            const axiosErrorObject = axios.isAxiosError(error)
                ? { message: error.message }
                : { message: "unable to parse error info." };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingPreference
                    .replace("{{error}}", axiosErrorObject.message),
                msgType: "error",
            });
        }
    }

    function handleDeleteClick(id: string) {
        if (gridRef.current?.isDirty()) {
            return;
        }

        const displayName = preferences.find(preference => preference.id === id)?.name?.en ?? "";

        alertDialog({
            title: strings.deletePreferenceAlertTitle,
            message: strings.deletePreferenceAlertMessage.replace("{{name}}", displayName),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedPreference(id);
        });
    }

    async function handleSaveClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        const isUpdate = selectedPreferenceId.length > 0;

        try {
            if (isUpdate) {
                await updatePreference(axiosInstance, user.currentProperty?.code ?? "", selectedPreference);
            } else {
                await createPreference(axiosInstance, user.currentProperty?.code ?? "", selectedPreference);
            }
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.errorCreatingPreference
                    : strings.errorUpdatingPreference
                ).replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        setSelectedPreferenceId("");
        setGridRefresh(!gridRefresh);
        setDetailsDialogOpen(false);
        setErrors(initialErrorState);
        setNotify({
            isOpen: true,
            message: strings.preferenceSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleValidateForm() {
        setErrors(initialErrorState);

        const fields: ValidationField[] = [{
            property: "name.en",
            type: ValidationType.Required,
            message: strings.validationPreferenceNameRequired
        }];

        const errors = validate<PreferenceItem, ValidationErrors>(fields, selectedPreference);

        if (errors) {
            setErrors(errors);
            return false;
        }

        return true;
    }

    function handleAddClick() {
        setSelectedPreferenceId("");
        setSelectedPreference(initialPreferenceItemState);
        setDetailsDialogOpen(true);
    }

    function handleCancelClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setErrors(initialErrorState);
        setTabValue(0);
        setDetailsDialogOpen(false);
        setSelectedPreference(initialPreferenceItemState);
    }

    function handleTabChange(event: React.SyntheticEvent<Element, Event>, newValue: any) {
        setTabValue(newValue);
    };

    function handleNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedPreference((old: PreferenceItem) => ({
            ...old,
            name: {
                ...old.name,
                [event.target.language]: event.target.value
            }
        }))
    }

    function handleExternalIdChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedPreference((old: PreferenceItem) => ({
            ...old,
            externalId: event.target.value
        }))
    }

    function handleEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedPreference((old: PreferenceItem) => ({
            ...old,
            enabled: event.target.checked,
        }));
    }

    //function updateDisplayOrder(preferenceList: Preference[]) {
    function handleSaveDisplayOrder(idList: any[]) {
        //const idList = preferenceList.map(pref => pref.id);
        saveDisplayOrder(axiosInstance, user.currentProperty?.code ?? "", idList)
            .then(() => setGridRefresh(gridRefresh))
            .catch((e: unknown) => {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorUpdatingPreference.replace("{{error}}", error.message),
                    msgType: "error",
                });
            })
    }

    /*function handleSaveDisplayOrder(ids: any[]) {
        // https://stackoverflow.com/questions/13304543/javascript-sort-array-based-on-another-array
        const sorted = [...preferences];
        sorted.sort(function(a, b) {  
            return ids.findIndex(id => a.id === id) - ids.findIndex(id => b.id === id);
        });        
        updateDisplayOrder(sorted);
    }*/

    const gridColumns: GridColDef[] = [        
        { field: "name", headerName: strings.name, flex: 1, valueGetter: (value, row) => row.name.en },
        { field: "externalId", headerName: strings.externalId, flex: 1 },
        { field: "enabled", headerName: strings.enabled, type: "boolean", flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="content_restaurant"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditRowClick(params.id.toString())}
                />,
                <DataGridDeleteButton
                    permissionKey="content_restaurant"
                    rowId={params.id.toString()}
                    clickHandler={() => handleDeleteClick(params.id.toString())}
                />
            ],
        },
    ];

    const dialogTitle = selectedPreferenceId === ""
        ? strings.addPreferenceButtonText
        : strings.preferenceDialogTitleEdit.replace("{{name}}", selectedPreference?.name?.en ?? "");

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 171px)" }}>
            <DisplayOrderGrid
                permissionKey="content_restaurant"
                columns={gridColumns}
                getRowId={(row) => row.id} 
                rows={preferences}                    
                addButtonText={strings.addPreferenceButtonText}
                showAddButton={true}
                onAddButtonClick={handleAddClick}                                        
                onSaveDisplayOrder={handleSaveDisplayOrder}
                ref={gridRef}
                loading={isLoading}
            />
            <DetailsDialog
                permissionKey="content_restaurant"
                open={detailsDialogOpen}
                title={dialogTitle}
                onCancelClick={handleCancelClick}
                onSaveClick={handleSaveClick}
                onValidateForm={handleValidateForm}
                adding={selectedPreferenceId === ""}
                contentSize={{ width: 500, height: 380 }}
            >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="Preference Details Tab"
                    >
                        <Tab label={strings.detailsTabTitle} />
                    </Tabs>
                </Box>
                <TabPanelKeepMounted value={tabValue} index={0}>
                    <LanguageForm>
                        <LanguageSelect />
                        <Spacer />
                        <LanguageTextField
                            id="preference-name"
                            label={strings.name}
                            values={selectedPreference.name ?? {}}
                            width={250}
                            onChange={handleNameChange}
                            error={Boolean(errors.name)}
                            helperText={errors.name}
                        />
                        <Spacer />
                        <TextField
                            id="preference-external-id"
                            label={strings.externalId}
                            width={250}
                            value={selectedPreference.externalId ?? ""}
                            onChange={handleExternalIdChange}
                        />
                        <Spacer />
                        <Checkbox
                            id="preference-enabled"
                            label={strings.enabled}
                            checked={selectedPreference.enabled}
                            onChange={handleEnabledChange}
                        />
                    </LanguageForm>
                </TabPanelKeepMounted>
            </DetailsDialog>
            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    );    
}

export default PreferenceList; 