import { AxiosInstance } from "axios";
import { Guest, Summary } from "../models/common/Guest";

const URL = process.env.REACT_APP_MANAGER_API_URL + `/property-management/`;

export async function searchGuest(axiosInstance: AxiosInstance, propertyCode: string, search: string) {
  const response = await axiosInstance.get(`${URL}${propertyCode}/guests/rooms?${search}`);
  return response.data.guests as Guest[];
}

export async function getSummary(axiosInstance: AxiosInstance, propertyCode: string) {
  const response = await axiosInstance.get(`${URL}${propertyCode}/guests/summary`);
  return response.data.summary as Summary;
}


