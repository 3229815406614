import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BrandList from "./BrandList";
import PropertyList from "./PropertyList";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import LoyaltyLevelList from "./LoyaltyLevelList";
import { useUser } from "../../../providers/UserProvider";

const CompanyManager = () => {
    const strings = useLocalizedStrings();
    const location = useLocation();
    const theme = useTheme();
    const {user} = useUser();

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={location.pathname} aria-label="basic tabs example">
                    <Tab value="/company/brands" label={strings.companyManagerBrandsTab} component={Link} to="brands" />
                    <Tab value="/company/properties" label={strings.companyManagerPropertiesTab} component={Link} to="properties" />
                    <Tab 
                        sx={{display: !user.currentProperty?.code ? "none" : "inline-flex"}} 
                        value="/company/loyaltylevels"  
                        label={strings.companyManagerLoyaltyLevelTab} 
                        component={Link} 
                        to="loyaltylevels" 
                    /> 
                </Tabs>
            </Box>
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Routes>
                    <Route path="brands" element={<BrandList />} />
                    <Route path="properties" element={<PropertyList />} />
                    <Route path="loyaltylevels" element={ user.currentProperty?.code ? <LoyaltyLevelList /> : null} />
                </Routes>
            </Box>
        </Box>
    );
};

export default CompanyManager;

