import { StringLanguageDict } from "../../common/LanguageModel";

export interface Message {
    id: string;
    sectionId: string;
    code: string;
    title: StringLanguageDict;
    text: StringLanguageDict;
    overrideId?: string;
    isOverride: boolean;
}

export const initialMessageState: Message = {    
    id: "",
    sectionId: "",
    code: "",
    title: {},
    text: {},    
    isOverride: false
}