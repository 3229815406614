import { StringLanguageDict } from "../common/LanguageModel";

export interface LocationItem {
    id: string;
    name: StringLanguageDict;
}

export const initialLocationState: LocationItem = {
    id: "",
    name: { en: "", es: "" },    
};

export function mapLocationItemsToKeyValues(locations: LocationItem[]): { key: string; value: string }[] {
    let kvps: { key: string; value: string }[] = [];

    kvps = locations.map((location, index) => {
        return {
            key: location.id,
            value: location.name.en ?? location.name, // TODO: language
        };
    });

    return kvps;
}