import { StringLanguageDict } from "../../common/LanguageModel";

export interface LoyaltyLevel {
    id: string;
    name: string;
    tier: number;
    externalKey: string; // TODO: add back for grid when api is updated.
}

export interface LoyaltyLevelDetail {
  id: string;
  name: StringLanguageDict;
  externalKey: string;
  tier: number
}

export interface LoyaltyLevelResponse {
    loyaltyLevels: LoyaltyLevel[]
}

export const initialItemState: LoyaltyLevelDetail = {
    id: "",
    name: { en: "", es: "" },
    externalKey: "",
    tier: 0,
 }