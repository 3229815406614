import { StringLanguageDict } from "../../common/LanguageModel";
import { Orderable } from "../../../components/common/DisplayOrderButton";

const initialLanguageString: StringLanguageDict = {
    en: "",
    es: ""
}

export interface RestaurantItem {
    id: string;
    name: StringLanguageDict | null;
    description: StringLanguageDict
    bookable: boolean;
    enabled: boolean;
    previewOnly: boolean;
    minPartySize: number;
    maxPartySize: number;
    externalId: string;
    menus: Menu[];
    preferences: RestaurantPreference[];
    options: Option[];
    displayOrder: number;
}

export interface Restaurant extends Orderable {
    id: string;
    name: StringLanguageDict | null;
    enabled: boolean;
    bookable: boolean;
    displayOrder: number;
}

export interface Menu extends Orderable {
    id: string;
    name: StringLanguageDict | null;
    url: string;
    enabled: boolean;
    displayOrder: number;
}

export interface Option extends Orderable {
    id: string;
    name: StringLanguageDict;
    value: StringLanguageDict;
    displayOrder: number
}

export interface RestaurantPreference {
    id: string;
}

export const getInitialRestaurantItemState = (): RestaurantItem => {
    return {
        id: "",
        name: null,
        description: initialLanguageString,
        bookable: false,
        enabled: true,
        previewOnly: false,
        minPartySize: 1,
        maxPartySize: 2,
        externalId: "",
        menus: [],
        preferences: [],
        options: [],
        displayOrder: 0
    }
}

export const initialMenuState: Menu = {
    id: "",
    name: null,
    url: "",
    enabled: true,
    displayOrder: 0
}
  