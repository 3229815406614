import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import { Access, PermissionType } from "../../../models/configuration/security/Permission";
import { useDialog } from "../../common/details/DetailsDialog";
import { PermissionCheckboxValue } from "../../configuration/security/admin/AdminRolePermissions";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";

export interface AccessAllowedProps {
    permissionType: PermissionType;
    onClick: (event: any) => void;
    labelPlacement?: "start" | "end" | "top" | "bottom";
}

function AccessAllowed({permissionType, onClick, labelPlacement = "end", ...rest}: AccessAllowedProps) {
    const {setDirty} = useDialog();
    const strings = useLocalizedStrings();
    
    function handlePermissionTypeOnClick(event: any) {
        setDirty(true);
        onClick(event);
    }

    function createPermissionTypeValue(permissionType: PermissionType, access: Access) {
        const value : PermissionCheckboxValue = { access: access, type: permissionType };
        const result = JSON.stringify(value);
        return result; 
    }

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">{strings.accessAllowedLabel}</FormLabel>
            <FormGroup aria-label="position"  row >
                <FormControlLabel
                    value="read"
                    control={
                        <Checkbox
                            value={createPermissionTypeValue(permissionType, Access.Read)}
                            onClick={handlePermissionTypeOnClick}
                            checked={((permissionType.accessAllowed & Access.Read) === Access.Read)}
                        />
                    }
                    label={strings.view}
                    labelPlacement={labelPlacement}
                />
                <FormControlLabel
                    value="create"
                    control={
                        <Checkbox
                            value={createPermissionTypeValue(permissionType, Access.Create)}
                            onClick={handlePermissionTypeOnClick}
                            checked={((permissionType.accessAllowed & Access.Create) === Access.Create)}
                        />
                    }
                    label={strings.add}
                    labelPlacement={labelPlacement}
                />
                <FormControlLabel
                    value="update"
                    control={
                        <Checkbox
                            value={createPermissionTypeValue(permissionType, Access.Update)}
                            onClick={handlePermissionTypeOnClick}
                            checked={((permissionType.accessAllowed & Access.Update) === Access.Update)}
                        />
                    }
                    label={strings.edit}
                    labelPlacement={labelPlacement}
                />
                <FormControlLabel
                    value="delete"
                    control={
                        <Checkbox
                            value={createPermissionTypeValue(permissionType, Access.Delete)}
                            onClick={handlePermissionTypeOnClick}
                            checked={((permissionType.accessAllowed & Access.Delete) === Access.Delete)}
                        />
                    }
                    label={strings.delete}
                    labelPlacement={labelPlacement}
                />
            </FormGroup>
        </FormControl>
    )
}

export default AccessAllowed;