import { StringLanguageDict } from "../../common/LanguageModel";
import { Message } from "./Message";

export interface Section {
    id: string;
    name: string;
    messages: Message[];
}

export const initialSectionState: Section = {
    id: "",
    name: "",
    messages: []
};