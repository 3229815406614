import { AxiosInstance } from "axios";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";
import { v4 as uuid } from "uuid";
import { Section } from "../../models/configuration/errormessages/Section";

const url = process.env.REACT_APP_MANAGER_API_URL + "/configuration/system-messages/sections";

export async function getSections(axiosInstance: AxiosInstance): Promise<Section[]> {    
    return await axiosInstance
        .get(url)
        .then((response) => parseLanguageStringsFromJson(response.data.sections) as Section[]);
}

export async function createSection(axiosInstance: AxiosInstance, section: Section) {
    const id = uuid();
    const sectionToSave = {
        ...section,  
        id: id
    };

    return await axiosInstance.post(url, sectionToSave).then((response) => {
        return id;
    });
}

export async function updateSection(axiosInstance: AxiosInstance, section: Section) {    
    return await axiosInstance.put(`${url}/${section.id}`, section).then((response) => {
        return response.data;
    });
}

export async function deleteSection(axiosInstance: AxiosInstance, id: string) {
    return await axiosInstance.delete(`${url}/${id}`).then((response) => {
        return response.data;
    });
}