import { Orderable } from "../../../components/common/DisplayOrderButton";
import { StringLanguageDict } from "../../common/LanguageModel";

// For grid
export interface Preference extends Orderable {
    id: string;
    name: StringLanguageDict | null;
    externalId: string;
    displayOrder: number;
    enabled: boolean;
}

export interface PreferenceItem {
    id: string;
    name: StringLanguageDict | null;
    externalId: string;
    enabled: boolean;
}

export const initialPreferenceItemState: PreferenceItem = {
    id: "",
    name: null,
    externalId: "",
    enabled: true
}