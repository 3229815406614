import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from '@mui/icons-material/Search';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import MuiIconButton from "@mui/material/IconButton";
import Paper, { PaperProps } from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { getTickets } from "../../../api/roomservice/ticketsApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Ticket as TicketModel, initialTicketState } from "../../../models/modules/roomservice/Ticket";
import { useUser } from "../../../providers/UserProvider";
import themePrimary from "../../../styles/themePrimary";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import DataGridViewButton from "../../common/datatable/DataGridViewButton";
import { default as DataGrid } from "../../common/datatable/DataGridWrapper";
import DateSearchField from "../../common/search/DateSearchField";

const useStyles = makeStyles({
    draggableDialog: {
        cursor: "move",
    },
    dialogContent: {
        cursor: "default",
    },
    appBarSpacer: {
        ...themePrimary.mixins.toolbar,
    },
    searchBox: {
        display: 'flex',
        paddingBottom: themePrimary.spacing(2),
    },
    summaryGrid: {
        paddingBottom: themePrimary.spacing(2),
        display: 'grid',
        gridTemplateColumns: '25% 25% 25% 25%',
    },
    summaryBox: {
        marginRight: themePrimary.spacing(2),
    },
    summaryCard: {
        padding: themePrimary.spacing(2),
        backgroundColor: themePrimary.palette.primary.main + " !important",
    },
    text: {
        color: themePrimary.palette.primary.contrastText,
        textAlign: 'center',
    }
});

const Orders = () => {    
    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };
    
    const [startDate, setStartDate] = useState<Date>(getDefaultStartDate());
    const [endDate, setEndDate] = useState<Date>(getDefaultEndDate());
    const [gridRefresh, setGridRefresh] = useState(false);   
    const [tickets, setTickets] = useState<TicketModel[]>([]);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);       
    const [selectedTicketId, setSelectedTicketId] = useState("");
    const [selectedTicket, setSelectedTicket] = useState<TicketModel>(initialTicketState);    
    const [isLoading, setIsLoading] = useState(true);       
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);                
    const strings = useLocalizedStrings();
    const theme = useTheme();    
    const axiosInstance = useCreateAxios();
    const { user } = useUser();      
    const classes = useStyles();

    useEffect(() => {
        async function loadTickets() {
            try {
                const payload = await getTickets(axiosInstance, user.currentProperty?.code ?? "", startDate, endDate);                
                setTickets(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.roomServiceErrorRetreivingTickets.replace("{{error}}", error.message),
                    msgType: "error",
                });
            } finally {
                setIsLoading(false);
            }
        }

        loadTickets();
    }, [user.currentProperty?.code, gridRefresh, strings.roomServiceErrorRetreivingTickets]);
    
    function getDefaultStartDate() {
        var start = new Date();
        start.setHours(0, 0, 0, 0);
        return start;
    }   

    function getDefaultEndDate() {
        return new Date();
    }

    const onDateSearchFieldChangeHandler = (startDate: Date, endDate: Date) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    const onSearchClickHandler = () => {
        setIsLoading(true);
        setGridRefresh(!gridRefresh);
    }

    function handleEditRowClick(id: string) {        
        setSelectedTicketId(id);
        var ticket = tickets?.find(p => p.id === id);
        if (ticket) {
            setSelectedTicket(ticket);
            setDetailsDialogOpen(true);
        }
    }    
    
    function handleCloseClick(event: React.MouseEvent<HTMLButtonElement>) {        
        setDetailsDialogOpen(false);   
    }    

    const gridColumns: GridColDef[] = [        
        { field: "orderNumber", headerName: strings.orderNumber, valueGetter: (value, row) => row.orderNumber, flex: 1 }, 
        { field: "orderDate", headerName: strings.orderDate, valueGetter: (value, row) => row.orderDate, valueFormatter: ({ value }) => value ? (value as Date).toLocaleString() : "", flex: 1 }, 
        { field: "roomNumber", headerName: strings.roomNumber, valueGetter: (value, row) => row.roomNumber, flex: 1 }, 
        { field: "guestName", headerName: strings.guestName, valueGetter: (value, row) => row.guestName, flex: 1 }, 
        { field: "total", headerName: strings.total, valueGetter: (value, row) => row.pricePerUnit, valueFormatter: ({ value }) => value ? (value as number).toLocaleString(undefined, { style: "currency", currency: "USD" }) : "", flex: 1 }, 
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridViewButton 
                    permissionKey="content_roomservice" 
                    rowId={params.id.toString()} 
                    clickHandler={() => handleEditRowClick(params.id.toString())} 
                />
            ],
        },
    ];

    function PaperComponent(props: PaperProps) {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={"[class*='MuiDialogContent-root']"}
            >
                <Paper {...props} />
            </Draggable>
        );
    }

    function toHtml(text: string) {
        return text.replaceAll("\r\n", "<br/>").replaceAll(" ", "&nbsp");
    }

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 185px)" }}>  
            <div className={classes.searchBox}>
                <DateSearchField 
                    startDate={startDate}
                    endDate={endDate}
                    format={null}
                    onChange={onDateSearchFieldChangeHandler} />

                <Button
                    sx={{ marginLeft: themePrimary.spacing(2) }}
                    variant="contained"
                    size="large"
                    onClick={onSearchClickHandler}
                    startIcon={<SearchIcon />}
                    aria-label="Search">
                    {strings.search}
                </Button>
            </div>
            <DataGrid
                permissionKey="content_roomservice"
                rows={tickets}
                columns={gridColumns}      
                showAddButton={false}
                loading={isLoading}                
            />

            <Dialog
                scroll={"body"}
                open={detailsDialogOpen}
                onClose={handleCloseClick}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                PaperProps={{
                    sx: {
                        width: 420,
                        maxWidth: 420,
                        height: 748.5,
                    },
                }} // (173.5 is height of headers and footer) https://medium.com/the-clever-dev/how-to-size-and-position-the-material-ui-mui-dialog-component-a5601cedc1c9
                fullWidth={true}
            >
                <AppBar /* style={{ cursor: "move" }} */ id="draggable-dialog">
                    {/* Try to get default padding (themePrimary.mixins.toolbar?) and subtract the X button margin from the right (it has a bult in padding of 8px that you can see when you highlight). Hard coding 24 and 12 is the only way to get the X to look correct. */}
                    <Toolbar
                        disableGutters
                        sx={{ paddingLeft: "24px", paddingRight: "24px" }}
                    >
                        <Typography variant="h6" noWrap component="div">
                            {strings.roomServiceOrderDetailsTitle.replace("{{orderNumber}}", selectedTicket?.orderNumber.toString() ?? "")}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box>
                            <MuiIconButton
                                aria-label="close"
                                color="inherit"
                                size="large"
                                edge="end"
                                onClick={handleCloseClick}
                            >
                                <CloseIcon />
                            </MuiIconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                <div className={classes.appBarSpacer}></div>
                <Box sx={{ padding: theme.spacing(2), overflowY: "scroll" }}>
                    <Typography fontFamily="monospace">
                        <div style={{ height: "600px" }} dangerouslySetInnerHTML={{ __html: toHtml(selectedTicket.formatted) }}/>                            
                    </Typography>
                </Box>
                <DialogActions
                    sx={{ position: "absolute", bottom: "8px", right: "8px" }}
                >
                    <Button variant="outlined" onClick={handleCloseClick}>
                        {strings.closeButtonTitle}
                    </Button>                    
                </DialogActions>
            </Dialog>

            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>           
    )     
};

export default Orders;
