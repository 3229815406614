import { AxiosInstance } from "axios";
import { Item } from "../../models/modules/roomservice/Item";
import { Ticket } from "../../models/modules/roomservice/Ticket";
import { parseLanguageStringsFromJson, dateToLocalISOString } from "../../utilities/JsonHelper";
import { v4 as uuid } from "uuid";

const url = process.env.REACT_APP_MANAGER_API_URL + "/room-service/";

export async function getTickets(axiosInstance: AxiosInstance, propertyId: string, startDate: Date, endDate: Date): Promise<Ticket[]> {
    return await axiosInstance
        .get(`${url}${propertyId}/tickets?startDate=${dateToLocalISOString(startDate)}&endDate=${dateToLocalISOString(endDate)}`)
        .then((response) => parseLanguageStringsFromJson(response.data.tickets) as Ticket[]);
}