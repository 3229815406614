import Box from "@mui/material/Box";
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import * as React from "react";
import { createVideo, deleteVideo, getVideos, updateVideo } from "../../../api/videolibrary/videoLibraryApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { initialVideoState, Video as VideoLibraryModel } from "../../../models/modules/videolibrary/Video";
import { useUser } from "../../../providers/UserProvider";
import { Field as ValidationField, Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import DataGrid from "../../common/datatable/DataGridWrapper";
import Checkbox from "../../common/details/Checkbox";
import DetailsDialog from "../../common/details/DetailsDialog";
import TextField from "../../common/details/TextField";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import VideoManager, { VideoManagerRefObject } from "../../common/video/VideoManager";
import { deleteVideos } from "../../../api/videoApi";
import { VideoModel } from "../../../models/common/VideoModel";
import ListIcon from '@mui/icons-material/List';
import GridViewIcon from '@mui/icons-material/GridView';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { Stack } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import { useAuth } from "oidc-react";
import Typography from '@mui/material/Typography';
import Grid from "@material-ui/core/Grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { v4 as uuid } from "uuid";

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

interface VideoValidationErrors {
    internalName: string;    
}

const initialErrorState: VideoValidationErrors = { 
    internalName: ""    
};

const VideoList = () => {    
    const [gridRefresh, setGridRefresh] = React.useState(false);
    const strings = useLocalizedStrings();
    const [view, setView] = React.useState("grid");
    const [videos, setVideos] = React.useState<VideoLibraryModel[]>([]);
    const [errors, setErrors] = React.useState<VideoValidationErrors>(initialErrorState);    
    const [selectedVideoId, setSelectedVideoId] = React.useState("");
    const [selectedVideo, setSelectedVideo] = React.useState<VideoLibraryModel>(initialVideoState);
    const [detailsDialogOpen, setDetailsDialogOpen] = React.useState(false);    
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);   
    const [isLoading, setIsLoading] = React.useState(true);
    const theme = useTheme(); 
    const axiosInstance = useCreateAxios();
    const { user } = useUser();        
    const alertDialog = useAlertDialog();
    const videoManagerRef = React.useRef<VideoManagerRefObject>(null);

    React.useEffect(() => {
        async function load() {
            try {
                const payload = await getVideos(axiosInstance, user.currentProperty?.code ?? "");                
                setVideos(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingVideos.replace("{{error}}", error.message),
                    msgType: "error",
                });
            } finally {
                setIsLoading(false);
            }
        }

        load();
    }, [gridRefresh, user.currentProperty?.code, strings.errorRetreivingVideos]);

    function onViewButtonClick(event: React.MouseEvent<HTMLElement>, newViewButton: string) {
        setView(newViewButton);
    }

    function handleAddClick() {
        setSelectedVideoId("");
        setSelectedVideo(initialVideoState);
        setDetailsDialogOpen(true);
    }

    function handleEditRowClick(id: string) {
        setSelectedVideoId(id);
        const video = videos.find(v => v.id === id);
        if (video) {
            setSelectedVideo(video);
        }
        setDetailsDialogOpen(true);
    }

    async function deleteSelectedVideo(id: string) {
        const videos: VideoModel[] = [
            { videoKey: "main", url: "", fileData: null },
            { videoKey: "banner", url: "", fileData: null },
        ];

        try {
            await deleteVideos(axiosInstance, "videoLibrary", id, videos, (user.currentProperty === null || user.currentProperty === undefined) ? undefined : user.currentProperty!!.id);
            await deleteVideo(axiosInstance, user.currentProperty?.code ?? "", id);
        }                
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingVideo.replace("{{error}}", error.message),
                msgType: "error",
            });       

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.videoDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        setSelectedVideoId("");
        setSelectedVideo(initialVideoState);
    }

    function handleDeleteClick(id: string) {
        const name = videos.find(v => v.id === id)?.internalName ?? "";

        alertDialog({
            title: strings.deleteVideoAlertTitle,
            message: strings.deleteVideoAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedVideo(id);
        });
    }

    const dialogTitle = selectedVideoId === "" ? strings.addVideoTitle : strings.editVideoTitle.replace("{{name}}", selectedVideo?.internalName);  
   
    function handleInternalNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedVideo((prevState) => ({
            ...prevState,
            internalName: event.target.value,
        }));
    }

    function handleEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedVideo((prevState) => ({
            ...prevState,
            enabled: event.target.checked,
        }));
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);
        setDetailsDialogOpen(false);
    }

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);

        const isUpdate = selectedVideoId !== "";
        let id = isUpdate ? selectedVideo.id : uuid();        

        try {
            if (videoManagerRef.current) {
                await videoManagerRef.current.saveVideos(id, (user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id);
            }
        } catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorSavingVideo.replace("{{error}}", error.message), // TODO: get error message
                msgType: "error",
            });            

            return false;
        }

        try {
            if (isUpdate) {                
                await updateVideo(axiosInstance, user.currentProperty?.code ?? "", selectedVideo);
            } else {
                await createVideo(axiosInstance, user.currentProperty?.code ?? "", id, selectedVideo);
            }
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.errorAddingVideo
                    : strings.errorUpdatingVideo
                ).replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        setDetailsDialogOpen(false);
        setGridRefresh(!gridRefresh);
        setNotify({
            isOpen: true,
            message: strings.videoSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleValidateVideo() {
        var valid = true;

        var fieldsToValidate : ValidationField[] = [{
            property: "internalName",
            type: ValidationType.Required,
            message: strings.validationVideoName
        }];

        var errors = validate<VideoLibraryModel, VideoValidationErrors>(fieldsToValidate, selectedVideo);
                
        if (errors) {
            setErrors(errors);
            valid = false; 
        } 
        
        const videoIsValid = videoManagerRef.current?.validate() ?? false;
        valid = valid && videoIsValid;

        return valid; 
    }

    const videoColumns: GridColDef[] = [
        { field: "internalName", headerName: strings.name, sortable: false, flex: 4 },
        { field: "enabled", headerName: strings.enabled, type: "boolean", valueGetter: (value, row) => row.enabled, flex: 1 },  
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton 
                    permissionKey="content_activities" 
                    rowId={params.id.toString()} 
                    clickHandler={() => handleEditRowClick(params.id.toString())} 
                />,
                <DataGridDeleteButton
                    permissionKey="content_activities"
                    rowId={params.id.toString()}
                    clickHandler={() => handleDeleteClick(params.id.toString())}
                />,
            ],
        },
    ];
      
    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 123px)" }}>
            <Stack direction="row" justifyContent="end">
                <Tooltip title={strings.gridAddRowTooltipText}>
                    <Button 
                        variant="contained" 
                        size="large"
                        startIcon={<AddIcon />}                            
                        onClick={handleAddClick}
                    >
                        {strings.addVideoButton}
                    </Button>
                </Tooltip>
                <Spacer x={2} />
                <ToggleButtonGroup
                    value={view}
                    exclusive
                    size="small"
                    onChange={onViewButtonClick}
                >
                    {/*<Tooltip title={strings.gridView}>*/}
                        <ToggleButton value="grid"><GridViewIcon /></ToggleButton>
                    {/*</Tooltip>*/}
                    {/*<Tooltip title={strings.listView}>*/}
                        <ToggleButton value="list"><ListIcon /></ToggleButton>      
                    {/*</Tooltip>*/}
                </ToggleButtonGroup>
            </Stack>
            <Spacer y={2} />   
            {view === "list" ?                    
                <DataGrid
                    permissionKey="content_videolibrary"
                    rows={videos}
                    columns={videoColumns}       
                    showAddButton={false}   
                    loading={isLoading}
                /> :                  
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>        
                    {videos.map((v, idx) => { 
                        return (
                            <VideoCard 
                                video={v} 
                                onEditVideo={() => handleEditRowClick(v.id)}
                                onDeleteVideo={() => handleDeleteClick(v.id)}
                            />
                        )
                    })}
                </Box> 
            }

            <DetailsDialog
                permissionKey="content_videolibrary"
                open={detailsDialogOpen}
                title={dialogTitle}
                onCancelClick={handleCancelClick}
                onSaveClick={handleSaveClick}
                onValidateForm={handleValidateVideo}
                contentSize={{ width: 879.5, height: 398.68 }}
            >
                <Box sx={{ padding: theme.spacing(2) }}>
                    <TextField
                        id="internal-name"
                        value={selectedVideo.internalName}
                        label={strings.name}
                        width={250}                            
                        onChange={handleInternalNameChange}                            
                        error={Boolean(errors.internalName)}
                        helperText={errors.internalName}
                    />
                    <Spacer />
                    <Box sx={{ height: 237.78 }}>
                        <VideoManager
                            propertyId={(user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id}
                            itemType="videoLibrary"
                            itemKey={selectedVideo.id}
                            videoKeys={["main"]}                            
                            ref={videoManagerRef}                                
                        />
                    </Box>
                    <Spacer />                        
                    <Checkbox                            
                        label={strings.enabled}
                        checked={selectedVideo.enabled}
                        onChange={handleEnabledChange}
                    />                          
                </Box>
                
            </DetailsDialog>            

            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    );    
};

export default VideoList;

interface VideoCardProps {    
    video: VideoLibraryModel;
    onEditVideo: () => void;
    onDeleteVideo: () => void;
}

const VideoCard = (props: VideoCardProps) => {
    const { user } = useUser();    
    const auth = useAuth();
    const theme = useTheme(); 
    const strings = useLocalizedStrings();
    const [isLoading, setIsLoading] = React.useState(true);

    //React.useEffect(() => {
    //    setIsLoading(true);
    //}, [props.video.id]);

    function generateRandomNumber() {
        return Math.floor(Math.random() * 1000);
    }

    function buildVideoUrl(videoId: string) {
        return `${process.env.REACT_APP_MANAGER_API_URL}/videos/${user.currentProperty?.code}/videolibrary/${videoId}/main?nocache=${generateRandomNumber()}&access_token=${auth.userData?.access_token}`;
    }

    const videoStyle = {    
        border: "solid",
        borderWidth: 1,
        borderColor: theme.palette.secondary.main
    } as const;

    function onNotFoundError(e: React.SyntheticEvent<HTMLVideoElement | HTMLSourceElement, Event>) {
        //setNotify({
        //    isOpen: true,
        //    message: strings.videoNotFound,
        //    msgType: "error",
        //}); 
    }

    function onVideoLoad(e: React.SyntheticEvent<HTMLVideoElement, Event>) {
        //setIsLoading(false);
    }

    return (
        <Box sx={{ mr: 4, mb: 4 }}>    
            <video 
                key={props.video.id}
                preload="metadata" 
                controls 
                id="library-video"
                //style={videoStyle}                        
                width={382}
                height={214}
                onError={(e) => onNotFoundError(e)}
                onCanPlay={(e) => onVideoLoad(e)}
            >
                <source 
                    src={buildVideoUrl(props.video.id)} 
                    onError={(e) => onNotFoundError(e)}
                />
            </video>                  
            <Spacer />
            <Grid container direction="row">
                <Grid container item xs={9}>
                    <Typography variant="h5" color={props.video.enabled ? "inherit": theme.palette.grey[500]}>{props.video.internalName}</Typography>
                </Grid>                
                <Grid container item xs={3} justifyContent="flex-end">
                    <IconButton aria-label="delete" size="small">
                        <Tooltip title={strings.edit}>
                            <EditIcon 
                                fontSize="small" 
                                sx={{ color: "primary.dark" }}
                                onClick={props.onEditVideo}                            
                            />
                        </Tooltip>
                    </IconButton> 
                    <IconButton aria-label="delete" size="small">
                        <Tooltip title={strings.delete}>
                            <DeleteIcon 
                                fontSize="small" 
                                color="error"
                                onClick={props.onDeleteVideo}
                            />
                        </Tooltip>
                    </IconButton>                     
                </Grid>
            </Grid>            
        </Box>
    );
}
