export interface Location {
    code: string;
    internalName: string;    
}

export function mapLocationsToKeyValues(locations: Location[]): { key: string; value: string }[] {
    let kvps: { key: string; value: string }[] = [];

    kvps = locations.map((location, index) => {
        return {
            key: location.code,
            value: location.internalName,
        };
    });

    return kvps;
}