import { GuestSelectorData } from "../../common/GuestSelectorData";

export interface Schedule {
    id: string;    
    templateId: string;
    toData: GuestSelectorData | null;
    data: string;    
}

export function initialScheduledMessageState(): Schedule {
    return {
        id: "",
        templateId: "",
        toData: null,
        data: ""        
    }
}