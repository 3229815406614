
import EditIcon from "@mui/icons-material/Edit";
import { Button, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Box from '@mui/material/Box';
import FormControl from "@mui/material/FormControl";
import Grid from '@mui/material/Grid';
import InputLabel from "@mui/material/InputLabel";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import { default as MaterialSelect, SelectChangeEvent } from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useAuth } from "oidc-react";
import * as React from "react";
import { Table } from "reactstrap";
import { deleteGraphics } from "../../../api/graphicsApi";
import { listLocations } from "../../../api/menu/locationApi";
import { listMenus, updateMenu } from "../../../api/menu/menuApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import MissingImage from "../../../images/noimage_16_9.png";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { GraphicModel } from "../../../models/common/GraphicModel";
import { Access } from "../../../models/configuration/security/Permission";
import { Location } from "../../../models/menus/Location";
import { Menu, mapMenusToKeyValues } from "../../../models/menus/Menu";
import { MenuGroup } from "../../../models/menus/MenuGroup";
import { MenuItem as MenuItemModel } from "../../../models/menus/MenuItem";
import { useUser } from "../../../providers/UserProvider";
import themePrimary from "../../../styles/themePrimary";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import DetailsDialog from "../../common/details/DetailsDialog";
import { GraphicManagerRefObject } from "../../common/image/GraphicManager";
import MenuBuilder from "./MenuBuilder";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme: Theme) => ({
    graphicCell: {
        minWidth: '238px',
    },
    nameCell: {
        minWidth: '200px',
    },
    previewCell: {
        minWidth: '100px',
    },
    group: {

    },
    item: {
        padding: '0px !important',
        paddingLeft: '48px !important',
        paddingTop: '8px !important',
        paddingBottom: '5px !important',
    },
    permissionCell: {
        padding: '0px !important'
    },
    table: {
        position: 'relative',
        paddingLeft: themePrimary.spacing(2),
        paddingRight: themePrimary.spacing(2),
    }
}));

const MenuManager = () => {
    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [isLoading, setIsLoading] = React.useState(true);
    const [refreshMenus, setRefreshMenus] = React.useState(false);
    const [menus, setMenus] = React.useState<Menu[]>([]);
    const [locations, setLocations] = React.useState<Location[]>([]);
    const [selectedMenu, setSelectedMenu] = React.useState<Menu | undefined>();
    const [editMenu, setEditMenu] = React.useState<Menu | undefined>();
    const [graphicManagerRefs, setGraphicManagerRefs] = React.useState<{ itemId: string, ref: GraphicManagerRefObject }[]>([]);
    const [detailsDialogOpen, setDetailsDialogOpen] = React.useState(false);
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);
    const theme = useTheme();
    const classes = useStyles(useTheme());    
    const strings = useLocalizedStrings();
    const axiosInstance = useCreateAxios();
    const { user, checkAccess } = useUser();    
    const auth = useAuth();

    React.useEffect(() => {
        getMenus();
    }, [refreshMenus, strings.errorRetrievingMenus]);

    React.useEffect(() => {
        setSelectedMenu(undefined);
        getMenus();
    },[user.currentProperty?.code]);

    async function getMenus() {
        try {            
            const payload = await listMenus(axiosInstance, user.currentProperty?.code ?? "");
            setMenus(payload);
            setSelectedMenu(payload[0]);            
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingMenus.replace("{{error}}", error.message),
                msgType: "error",
            });
        } finally {
            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        async function getLocations() {
            try {
                const payload = await listLocations(axiosInstance, user.currentProperty?.code ?? "", selectedMenu?.deviceClass ?? 0);
                setLocations(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingLocations.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }            
        }

        getLocations();
    }, [selectedMenu, strings.errorRetrievingLocations]);

    function getLocationInternalName(code: string): string {
        const location = locations?.find(l => l.code == code);
        return location?.internalName ?? "";
    }

    const handleChange = (event: SelectChangeEvent) => {
        const findMenu = menus.find(m => m.id == event.target.value as string);
        if (findMenu) {
            setSelectedMenu(findMenu);
        }
    };

    function handleEditClick(event: React.MouseEvent<HTMLButtonElement>) {
        setDetailsDialogOpen(true);
    }

    const dialogTitle = strings.editMenuTitle.replace("{{name}}", mapMenusToKeyValues(menus).find(m => m.key == selectedMenu?.id ?? "")?.value ?? "");

    function handleMenuUpdated(menu: Menu, refs: { itemId: string, ref: GraphicManagerRefObject }[]) {
        setEditMenu(menu);
        setGraphicManagerRefs(refs);
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setDetailsDialogOpen(false);
    }

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (editMenu) {
            try {
                await updateMenu(axiosInstance, user.currentProperty?.code ?? "", editMenu);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: (strings.errorUpdatingMenu).replace("{{error}}", error.message),
                    msgType: "error",
                });

                return false;
            }

            try {
                graphicManagerRefs.forEach(async r =>
                    await r.ref.saveGraphics(r.itemId, (user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id)
                );                
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorSavingGraphic.replace("{{error}}", error.message),
                    msgType: "error",
                });

                return false;
            }

            if (selectedMenu) {
                // Find all the groups that were deleted and delete the images for all items in those groups
                let deletedGroups = selectedMenu.groups.filter(g1 => !editMenu.groups.some(g2 => g1.id === g2.id));
                for (let i = 0; i < deletedGroups.length; i++) {
                    let group = deletedGroups[i];
                    for (let j = 0; j < group.items.length; j++) {                        
                        await deleteItemImage(group.items[j].id);
                    }
                }

                // Now go through the remaining groups and delete images for any deleted items
                for (let i = 0; i < editMenu.groups.length; i++) {
                    let group2 = editMenu.groups[i];
                    let group1 = selectedMenu.groups.find(g => g.id === group2.id);
                    if (group1) {                        
                        let deletedItems = group1.items.filter(i1 => !group2.items.some(i2 => i1.id === i2.id));
                        for (let j = 0; j < deletedItems.length; j++) {
                            await deleteItemImage(deletedItems[j].id);
                        }
                    }
                }
            }

            setSelectedMenu(editMenu);
            setRefreshMenus(!refreshMenus);
        }
        
        setDetailsDialogOpen(false);
        setNotify({
            isOpen: true,
            message: strings.menuSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    async function deleteItemImage(id: string) {
        const images: GraphicModel[] = [
            { imageKey: "main", url: "", fileData: null },
        ];

        try {
            await deleteGraphics(axiosInstance, "MenuItem", id, images, (user.currentProperty === null || user.currentProperty === undefined) ? undefined : user.currentProperty!!.id);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingGraphic.replace("{{error}}", error.message),
                msgType: "error",
            });

            return;
        }
    }

    function buildImageUrl(id: string) {
        return `${process.env.REACT_APP_MANAGER_API_URL}/images/${user.currentProperty!!.id}/menuitem/${id}/main?nocache=${generateRandomNumber()}&access_token=${auth.userData?.access_token}`;
    }

    function generateRandomNumber() {
        return Math.floor(Math.random() * 1000);
    }

    if (isLoading) {
        return <LinearProgress color={"primary"} variant={"query"} />;
    }
    else {
        return (
            <Box sx={{ padding: theme.spacing(2) }}>
                {checkAccess("content_menu", Access.Update) &&
                    <>                            
                        <Tooltip title={strings.edit}>
                            <Button
                                variant="contained"
                                size="large"
                                aria-label="audit"
                                aria-haspopup="true"
                                onClick={handleEditClick}
                                disabled={!selectedMenu}
                                startIcon={<EditIcon />}
                            >
                                {strings.edit}
                            </Button>
                        </Tooltip>
                        <Spacer y={2} />
                    </>
                }
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    alignItems="center"           
                >
                    {menus.length > 1 &&
                        <Grid item>
                            <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-label">{strings.menu}</InputLabel>
                                <MaterialSelect
                                    id="select"
                                    aria-labelledby={"select-label-menu"}
                                    label={strings.menu}
                                    value={selectedMenu?.id ?? ""}
                                    onChange={handleChange}
                                >
                                    {mapMenusToKeyValues(menus).map((kvp, index) => (
                                        <MenuItem key={kvp.key} value={kvp.key}>{kvp.value}</MenuItem>
                                    ))}
                                </MaterialSelect>
                            </FormControl>
                        </Grid>
                    }
                </Grid>
                <Spacer y={2} />   
                <Grid container direction="row">
                    <Grid item> 
                        <TableContainer className={classes.table}>
                            <Table sx={{ maxWidth: 600 }} size="small" aria-label="Menu">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.graphicCell}>{strings.graphic}</TableCell>
                                        <TableCell className={classes.nameCell}>{strings.title}</TableCell>                                        
                                        <TableCell className={classes.nameCell}>{strings.location}</TableCell>
                                        <TableCell className={classes.previewCell}>{strings.previewOnly}</TableCell>
                                    </TableRow>
                                </TableHead>
                                {selectedMenu?.groups.map((group: MenuGroup) => (
                                    <TableBody key={group.id}>
                                        <TableRow>
                                            <TableCell align="left" colSpan={4} className={classes.group}>{group.name.en}</TableCell>
                                        </TableRow>
                                        {group.items.map((item: MenuItemModel) => (
                                            <TableRow key={item.id}>
                                                <TableCell align="left" className={classes.item}>
                                                    <img src={buildImageUrl(item.id)} onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => e.currentTarget.src = MissingImage} alt="" width={178} height={100} style={{ border: "solid", borderWidth: 1, borderColor: theme.palette.secondary.main }} />
                                                </TableCell>
                                                <TableCell align="left">{item.name.en}</TableCell>
                                                <TableCell align="left">{getLocationInternalName(item.locationCode)}</TableCell>
                                                <TableCell align="center">
                                                    {item.previewOnly ?
                                                        <CheckIcon fontSize="small" sx={{ opacity: 0.38 }} /> :
                                                        <CloseIcon fontSize="small" sx={{ opacity: 0.38 }} />
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                ))}
                            </Table>
                        </TableContainer>     
                    </Grid>                
                </Grid>         
            
                <DetailsDialog
                    permissionKey="content_menu"
                    open={detailsDialogOpen}
                    title={dialogTitle}
                    onCancelClick={handleCancelClick}
                    onSaveClick={handleSaveClick}                
                    contentSize={{ width: 703, height: 566 }}
                >
                    <MenuBuilder menuId={selectedMenu?.id ?? ""} onMenuUpdatedHandler={handleMenuUpdated} />
                </DetailsDialog>

                <NotificationMessage notificationState={[notify, setNotify]} />
            </Box>
        );
    }
};

export default MenuManager;