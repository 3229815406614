import { useState, useEffect, ChangeEvent, Ref, useImperativeHandle, forwardRef } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { v4 as uuid } from "uuid";
import { Box } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelectedLanguage } from "../../common/language/LanguageForm";
import { useDialog } from "../../common/details/DetailsDialog";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import themePrimary from "../../../styles/themePrimary";
import { Option } from "../../../models/modules/restaurants/Restaurant";
import Button from "@mui/material/Button/Button";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";
import DisplayOrderButton, { Orderable, moveBottom, moveDown, moveTop, moveUp } from "../../common/DisplayOrderButton";
import Spacer from "../../common/Spacer";

const initialState: Option = {
    id: "",
    name: { en: "", es: "" },
    value: { en: "", es: "" },
    displayOrder: 0
};

const buttonStyle = {
    float: "left",
    height: "24px",
    width: "24px",
    cursor: "pointer",
    color: "primary.main",
    bgcolor: "transparent"
} as const;

interface RepeaterProps {
    initialOptions: Option[];
    restaurantId: string;
    onChange: (options: Option[]) => void;
}

export interface OptionRefObject {
    validate: () => boolean;
}

interface OptionError {
    id: string;
    name: string;
    value: string;
}

const OptionRepeater = forwardRef(({onChange, initialOptions, restaurantId}: RepeaterProps, ref : Ref<OptionRefObject>) => {
    const langContext = useSelectedLanguage();
    const strings = useLocalizedStrings();
    const { setDirty, adding, permissionKey } = useDialog();
    const { checkAccess } = useUser();
    const [readOnly] = useState(!checkAccess(permissionKey, adding ? Access.Create : Access.Update));
    const [errors, setErrors] = useState<OptionError[]>([]);
    const [options, setOptions] = useState<Option[]>(() => {
        return initialOptions.length === 0
            ? []
            : initialOptions
    });
    
    useEffect(() => {
        onChange(options);
    }, [options, langContext.selectedLanguage]); // TODO: dont need lang here- i think

    function handleNameChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) {
        setOptions((old: Option[]) => {
            return [
                ...old.map((option) => {
                    return option.id === id
                        ? { ...option, name: { ...option.name, [langContext.selectedLanguage]: e.target.value } }
                        : option;
                })
            ];
        });

        setDirty(true);  
    }

    function handleValueChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) {
        setOptions((old: Option[]) => {
            return [
                ...old.map((option) => {
                    return option.id === id
                        ? { ...option, value: { ...option.value, [langContext.selectedLanguage]: e.target.value } }
                        : option;
                })
            ];
        });

        setDirty(true);
    }

    function handleAddClick() {
        if (options.length < 4) {
            setDirty(true);
            setOptions((old: Option[]) => [...old, { ...initialState, id: uuid(), displayOrder: options.length }])
        }
    }

    function handleRemoveClick(id: string) {
        setDirty(true);

        const errorItemIndex = errors.findIndex(error => error.id === id);
        if (errorItemIndex > -1) {
            const newErrors = [...errors];
            newErrors.splice(errorItemIndex, 1);
            setErrors(newErrors);
        }

        setOptions(options.filter((option) => option.id !== id));
    }

    function isEmptyPair(option: Option) {
        return (
            option.name["en"].trim().length === 0 &&
            option.value["en"].trim().length === 0 &&
            option.name["es"].trim().length === 0 &&
            option.value["es"].trim().length === 0
        );
    }

    function isIncompletePair(option: Option) {
        return (
            (option.name["en"].trim().length === 0 && option.value["en"].trim().length > 0) ||
            (option.name["en"].trim().length > 0 && option.value["en"].trim().length === 0) ||
            (option.name["es"].trim().length === 0 && option.value["es"].trim().length > 0) ||
            (option.name["es"].trim().length > 0 && option.value["es"].trim().length === 0)
        );
    }
   
    function handleMoveUpClick(id: string) {
        updateDisplayOrder(moveUp(options, id, 1));
    }

    function handleMoveDownClick(id: string) {
        updateDisplayOrder(moveDown(options, id, 1));
    }

    function handleMoveToTopClick(id: string) {
        updateDisplayOrder(moveTop(options, id));
    }

    function handleMoveToBottomClick(id: string) {
        updateDisplayOrder(moveBottom(options, id));
    }

    function getDisplayOrderForItem(id: string) {
        let index = options.findIndex(option => option.id === id);
        return index;
    }

    function updateDisplayOrder(options: Option[]) {
        setDirty(true);
        setOptions(options);
    }
    
    useImperativeHandle(ref, () => ({ validate }))

    function validate(): boolean {
        setErrors([]);
        const empty = options.filter((option: Option) => isEmptyPair(option));
        const incomplete = options.filter((option: Option) => isIncompletePair(option));
        const totalErrors = empty.concat(incomplete);

        if (totalErrors.length > 0) {
            setErrors(totalErrors.map(error => ({ id: error.id, name: '', value: '' }))) // todo: add more contextual info?
            return false;
        }

        return true;
    }

    return (
            <>
            {errors.length > 0  && (
                <Box sx={{ alignContent: "space-around", mb: 1 }} >
                    <Typography variant="caption" sx={{ color: 'error.main', ml: themePrimary.spacing(1) }}>
                        {strings.restaurantOptionsErrorMessage}
                    </Typography>
                </Box>
            )}
                <Box sx={{ overflowX: "hidden", maxHeight: "400px", height:"375px"}}>
                    {options.length === 0 && (checkAccess(permissionKey, Access.Update) || 
                       (restaurantId === "" && checkAccess(permissionKey, Access.Create))) &&(
                        
                        <Box m={1} display="flex" justifyContent={"flex-start"} >
                            <Button 
                                disabled={readOnly} 
                                variant="contained" 
                                color="primary" 
                                size="small" 
                                onClick={handleAddClick}
                            >
                                {strings.restaurantOptionsNewLabel}
                            </Button>
                        </Box>
                    )}
                    {options.map((opt) => (
                        <Grid alignItems="center" container key={opt.id}>
                            <Grid item>
                                <TextField
                                    id="option-name"
                                    onChange={(e) => handleNameChange(e, opt.id)}
                                    value={opt?.name[langContext.selectedLanguage] ?? ""}
                                    name="name"
                                    sx={{ m: 1, width: "275px" }}
                                    label={strings.restaurantOptionsNameLabel}
                                    error={Boolean(errors.find(err => err.id === opt.id))}
                                    helperText={errors.find(err => err.id === opt.id)?.name ?? ""}
                                    disabled={readOnly}
                                />
                            </Grid>
                            <Grid item >
                                <TextField
                                    id="option-value"
                                    onChange={(e) => handleValueChange(e, opt.id)}
                                    value={opt?.value[langContext.selectedLanguage] ?? ""}
                                    name="value"
                                    label={strings.restaurantOptionsValueLabel}
                                    error={Boolean(errors.find(err => err.id === opt.id))}
                                    helperText={errors.find(err => err.id === opt.id)?.value ?? ""}
                                    sx={{ m: 1, width: "275px" }}
                                    disabled={readOnly}
                                />
                            </Grid>
                            <Grid item > 
                                {(checkAccess(permissionKey, Access.Update) || 
                                    (restaurantId === "" && checkAccess(permissionKey, Access.Create))) && (
                                <Tooltip title={strings.gridAddRowTooltipText} >
                                    <Avatar sx={{ ...buttonStyle, }} onClick={handleAddClick}>
                                        <AddBoxIcon />
                                    </Avatar>
                                </Tooltip>
                                )}
                                {(checkAccess(permissionKey, Access.Update) || 
                                    (restaurantId === "" && checkAccess(permissionKey, Access.Create))) && (
                                <Tooltip title={strings.removeButtonTitle}>
                                    <Avatar
                                        sx={{ ...buttonStyle, color: "error.main" }}
                                        onClick={() => handleRemoveClick(opt.id)}
                                    >
                                        <DeleteIcon />
                                    </Avatar>
                                </Tooltip>
                                )}
                            </Grid>
                            <Grid item>
                            <DisplayOrderButton
                                permissionKey="content_restaurant"
                                current={getDisplayOrderForItem(opt.id)}
                                count={options.length}
                                onMoveTopClick={() => handleMoveToTopClick(opt.id)}
                                onMoveUpClick={() => handleMoveUpClick(opt.id)}
                                onMoveDownClick={() => handleMoveDownClick(opt.id)}
                                onMoveBottomClick={() => handleMoveToBottomClick(opt.id)}
                            />
                            </Grid>
                        </Grid>
                    ))}
                    <Spacer/>
                    <Typography variant="body1">{strings.maxOptionsWarning}</Typography>
                </Box>
            </>
        );
    });

    OptionRepeater.displayName = "OptionRepeater";
    export default OptionRepeater;
