import { AxiosInstance } from "axios";
import { AccessPoint } from "../../../models/dashboard/network/AccessPoint";
import { parseLanguageStringsFromJson } from "../../../utilities/JsonHelper";

const url = process.env.REACT_APP_MANAGER_API_URL + "/network-monitor/";

export async function getAccessPoints(axiosInstance: AxiosInstance, propertyCode: string): Promise<AccessPoint[]> {
    return await axiosInstance
        .get(`${url}${propertyCode}/devices/accesspoints`, { timeout: 120000 })
        .then((response) => response.data.accessPoints as AccessPoint[]);
}

export async function getAccessPoint(axiosInstance: AxiosInstance, propertyCode: string, id: string): Promise<AccessPoint> {
    return await axiosInstance
        .get(`${url}${propertyCode}/devices/accesspoints/${id}`, { timeout: 120000 })
        .then((response) => response.data.accessPoint as AccessPoint);
}
