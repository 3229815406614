export interface Schedule {
    id: string;   
    promotionId: string;
    locationCode: string;
    data: string;
}

export const initialScheduleState: Schedule = {
    id: "",
    promotionId: "",
    locationCode: "",
    data: ""
};

export interface ScheduledPromotion {
    id: string;
    scheduleId: string;
    promotionInternalName: string;
    start: Date;
    end: Date;
}

export interface ScheduledPromotionLocation {
    code: string;
    internalName: string;
    scheduledPromotions: ScheduledPromotion[];
}

