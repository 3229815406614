import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useTheme } from "@mui/material/styles";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { createBrand, deleteBrand, getBrandById, getBrandGridData, updateBrand } from "../../../api/accounts/brandApi";
import { deleteGraphics } from "../../../api/graphicsApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { BrandItemDetailModel, BrandItemModel, initialBrandState } from "../../../models/brandModels";
import { GraphicModel } from "../../../models/common/GraphicModel";
import { getAxiosError } from "../../../utilities/AxiosApiHelper";
import { parseLanguageStringsFromJson } from "../../../utilities/JsonHelper";
import { Field as ValidationField, Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import DataGrid from "../../common/datatable/DataGridWrapper";
import Checkbox from "../../common/details/Checkbox";
import DetailsDialog from "../../common/details/DetailsDialog";
import TextField from "../../common/details/TextField";
import GraphicManager, { GraphicManagerRefObject } from "../../common/image/GraphicManager";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageRichTextField from "../../common/language/LanguageRichTextField";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";

interface BrandValidationErrors {
  id: string;
  name: string;
}

const initialErrorState: BrandValidationErrors = {
    id: "",
    name: "",
}

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

function BrandList () {
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [tabValue, setTabValue] = useState<number>(0);
    const [selectedBrandId, setSelectedBrandId] = useState("");
    const [brandData, setBrandData] = useState<BrandItemModel[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<BrandItemDetailModel>(initialBrandState);
    const [isLoading, setIsLoading] = useState(true);
    const [gridRefresh, setGridRefresh] = useState(false);
    const [errors, setErrors] = useState<BrandValidationErrors>(initialErrorState);
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const graphicManagerRef = useRef<GraphicManagerRefObject>(null);
    const alertDialog = useAlertDialog();
    const strings = useLocalizedStrings();
    const axiosInstance = useCreateAxios();
    const theme = useTheme();

    useEffect(() => {
        async function getBrands() {
            try {
                const payload = await getBrandGridData(axiosInstance);
                const brands = payload.map(b => parseLanguageStringsFromJson(b, "en"));
                setBrandData(brands);
            } catch (e) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingBrands,
                    msgType: "error",
                });
            } finally {
                setIsLoading(false);
            }
        }

        getBrands();

    }, [gridRefresh, strings.errorRetrievingBrands]);

    function handleAddClick() {
        setSelectedBrandId("");
        setSelectedBrand(initialBrandState);
        setDetailsDialogOpen(true);
    }

    async function handleEditRowClick(id: string) {
        setSelectedBrandId(id);

        try {
            const payload = (await getBrandById(axiosInstance, id)) as BrandItemDetailModel;

            setSelectedBrand(payload);
            setDetailsDialogOpen(true);
        } catch (error) {
            setNotify({ isOpen: true, message: `${error}`, msgType: "error" });
        }
    }

    async function deleteSelectedBrand(brandSelected: string) {
        // deleting all possible images regardless if they're there.
        // soon backend will be handling child data so this won't be needed
        const images: GraphicModel[] = [
            { imageKey: "main", url: "", fileData: null },
        ];
        await deleteGraphics(axiosInstance, "brand", brandSelected, images, undefined);
        await deleteBrand(axiosInstance, brandSelected);
        setBrandData(brandData.filter((brand) => brand.id !== brandSelected));

        setNotify({
            isOpen: true,
            message: strings.brandDeletedSuccessfully,
            msgType: "success",
        });
    }

    function handleDeleteClick(id: string) {        
        const displayName  = brandData.find(brand => brand.id === id)?.name ?? "";

        alertDialog({
            title: strings.deleteBrandAlertTitle,
            message: strings.deleteBrandAlertMessage.replace("{{name}}", displayName),
            destructive: true,
            okButtonTitle: strings .deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedBrand(id);
        });
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    function handleNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedBrand((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            },
        }));
    }

    function handleTitleChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedBrand((prevState) => ({
            ...prevState,
            title: {
                ...prevState.title,
                [event.target.language]: event.target.value,
            },
        }));
    }

    function handleDescriptionChange(content: string, language: string) {
        setSelectedBrand((prevState) => ({
            ...prevState,
            description: {
                ...prevState.description,
                [language]: content,
            },
        }));
    }

    function handleIdChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedBrand((prevState) => ({
            ...prevState,
            id: event.target.value,
        }));
    }

    function handleEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedBrand((prevState) => ({
            ...prevState,
            enabled: event.target.checked,
        }));
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);
        setDetailsDialogOpen(false);
        setTabValue(0);
    }

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);

        const isUpdate = selectedBrandId.trim().length > 0;
        
        try {
            if (isUpdate) {
                await updateBrand(axiosInstance, selectedBrand);
            } else {
                await createBrand(axiosInstance, selectedBrand);
            }
        } catch (error: unknown) {
            let userMessage = "";
            const result = getAxiosError(error);

            if (result?.response?.status === 409) {
                userMessage = strings.errorBrandIdAlreadyExists;
            }
            else {
                userMessage = result?.message ?? "unable to parse error info";
            }
        
            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.errorAddingItem
                    : strings.errorUpdatingItem
                ).replace("{{error}}", userMessage),
                msgType: "error",
            });

            return false;
        }

        try {
            if (graphicManagerRef.current) {
                await graphicManagerRef.current.saveGraphics(selectedBrand.id, undefined);
            }
        } catch (err: unknown) {
            const error = axios.isAxiosError(err)
                ? { message: err.message }
                : { message: "unable to parse error info" };

            setNotify({
                isOpen: true,
                message: strings.errorSavingGraphic.replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        setDetailsDialogOpen(false);
        setTabValue(0);
        setGridRefresh(!gridRefresh);
        setNotify({
            isOpen: true,
            message: strings.brandSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleValidateBrand() {
      var fieldsToValidate: ValidationField[] = [{
        property: "name.en",
        type: ValidationType.Required,
        message: strings.validationErrorBrandNameRequired
      },
      {
        property: "id",
        type: ValidationType.Custom,
        custom: (value) => !(value.trim().length < 1 || value.trim().length > 10),
        message: strings.validationErrorBrandIdLength 
      }, 
      {
        property: "id",
        type: ValidationType.Pattern,
        pattern: /^[A-Za-z0-9]*$/,
        message: strings.validationErrorBrandIdInvalidChars 
      }];
          
      var errors = validate<BrandItemDetailModel, BrandValidationErrors>(fieldsToValidate, selectedBrand);
      if (errors) {
        setErrors(errors);
        return false;
      }
      
      return true;
    }

    const gridColumns: GridColDef[] = [
        { field: "id", headerName: strings.brandIdLabel, flex: 1 }, // matt todo: change to id in strings
        { field: "name", headerName: strings.name, flex: 1 },
        {
            field: "enabled",
            headerName: strings.enabled,
            type: "boolean",
            flex: 1
        },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
              <DataGridEditButton 
                permissionKey="administration_company" 
                rowId={params.id.toString()} 
                clickHandler={() => handleEditRowClick(params.id.toString())} 
              />
            ],
        },
    ]

    const dialogTitle = selectedBrandId === "" ? strings.companyManagerBrandsTitleAdd : strings.companyManagerBrandsTitleEdit.replace("{{name}}", selectedBrand?.name["en"] ?? ""); // TODO: default language
    
    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 171px)" }}> 
            <DataGrid
                getRowId={(row) => row.id} 
                permissionKey="administration_company"
                rows={brandData}
                columns={gridColumns}
                onAddButtonClick={handleAddClick}
                addButtonText={strings.brandAddButtonText}
                loading={isLoading}
            />

            <DetailsDialog
                permissionKey="administration_company"
                adding={selectedBrandId.length === 0}
                open={detailsDialogOpen}
                title={dialogTitle}
                onValidateForm={handleValidateBrand}
                onCancelClick={handleCancelClick}
                onSaveClick={handleSaveClick}
                contentSize={{ width: 879.5, height: 736.38 }}
            >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label={strings.details} />
                        <Tab label={strings.graphics} />
                    </Tabs>
                </Box>
                <TabPanelKeepMounted value={tabValue} index={0}>
                    <LanguageForm>
                        <LanguageSelect />
                        <Spacer />
                        <LanguageTextField
                            id="brand-name"
                            label={strings.name}
                            values={selectedBrand.name}
                            width={410}
                            onChange={handleNameChange}
                            error={Boolean(errors.name)}
                            helperText={errors.name}
                        />
                        <Spacer />
                        <LanguageTextField
                            id="brand-title"
                            label={strings.title}
                            width={410}
                            values={selectedBrand.title}
                            onChange={handleTitleChange}
                        />
                        <Spacer />
                        <LanguageRichTextField
                            values={selectedBrand.description}
                            label={strings.description}
                            id="brand-description"
                            onChange={handleDescriptionChange}
                        />
                    </LanguageForm>
                    <Spacer />
                    <TextField
                        label={strings.brandIdLabel}
                        value={selectedBrand?.id ?? ""}
                        width={300}
                        onChange={handleIdChange}
                        error={Boolean(errors.id)}
                        helperText={errors.id}
                        disabled={selectedBrandId.length > 1}
                    />
                    <Spacer />
                    <Checkbox
                        label={strings.enabled}
                        checked={selectedBrand?.enabled}
                        onChange={handleEnabledChange}
                    />
                </TabPanelKeepMounted>
                <TabPanelKeepMounted value={tabValue} index={1}>
                    <GraphicManager
                        propertyId={undefined}
                        itemType="Brand"
                        itemKey={selectedBrandId}
                        imageKeys={["main"]}
                        ref={graphicManagerRef}
                    />
                </TabPanelKeepMounted>
            </DetailsDialog>

            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    );    
};

export default BrandList;
