import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { addPermissionCategory, deletePermissionCategory, getPermissionCategory, listPermissionCategories, updatePermissionCategory } from "../../../../api/permissionApi";
import { useCreateAxios } from "../../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../../localization/LocalizedStringsProvider";
import { PermissionCategory, initialPermissionCategoryState } from "../../../../models/configuration/security/Permission";
import { Field as ValidationField, Type as ValidationType, validate } from "../../../../utilities/Validator";
import { useAlertDialog } from "../../../common/AlertDialog/AlertDialogProvider";
import { default as DisplayOrderButton, moveBottom, moveDown, moveTop, moveUp, } from "../../../common/DisplayOrderButton";
import NotificationMessage, { NotificationOptions } from "../../../common/NotificationMessage";
import Spacer from "../../../common/Spacer";
import TabPanelKeepMounted from "../../../common/TabPanelKeepMounted";
import DataGridDeleteButton from "../../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../../common/datatable/DataGridEditButton";
import DataGrid from "../../../common/datatable/DataGridWrapper";
import Checkbox from "../../../common/details/Checkbox";
import DetailsDialog from "../../../common/details/DetailsDialog";
import TextField from "../../../common/details/TextField";
import { useTheme } from '@mui/material/styles';

interface ValidationErrors {    
    name: string;
    displayOrder: string;
}

const initialErrorState : ValidationErrors = {
    name: "",
    displayOrder: ""
}

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

function PermissionCategoryList() {
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [gridRefresh, setGridRefresh] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [permissionCategories, setPermissionCategories] = useState<PermissionCategory[]>([]);
    const [selectedPermissionCategoryId, setSelectedPermissionCategoryId] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPermissionCategory, setSelectedPermissionCategory] = useState<PermissionCategory>(initialPermissionCategoryState);
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const strings = useLocalizedStrings();
    const alertDialog = useAlertDialog();
    const axiosInstance = useCreateAxios();
    const theme = useTheme();
    
    useEffect(() => {
        async function getCategories() {
            try {
                
                //const response = await listPermissionCategories(axiosInstance);
                const response = await listPermissionCategories(axiosInstance);
                //TEMP- should have displayOrder on each category in list. Remove later
                response.forEach((i, idx) => {
                    i.displayOrder = idx;
                })

                setPermissionCategories(response);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingPermissionCategories.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }
            finally {
                setIsLoading(false);
            }
        }

        getCategories();
    }, [gridRefresh])

    function handleTabChange(event: React.SyntheticEvent<Element, Event>, newValue: any) {
        setTabValue(newValue);
    };

    function handleNameChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSelectedPermissionCategory((current: PermissionCategory) => ({
            ...current,
            name: e.currentTarget.value
        }));
    }

    function handleDisplayOrderChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSelectedPermissionCategory((old: PermissionCategory) => ({
            ...old,
            displayOrder: Number(e.currentTarget.value)
        }));
    }
      
    function handleEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedPermissionCategory((prevState) => ({
            ...prevState,
            enabled: event.target.checked,
        }));
    }

    function handleAddClick() {
        setSelectedPermissionCategoryId("");
        setSelectedPermissionCategory(initialPermissionCategoryState);
        setDetailsDialogOpen(true);
    }

    function handleDeleteClick(id: string) {
        const displayName = permissionCategories.find(category => category.id === id)?.name ?? "";
        
        alertDialog({
            title: strings.deletePermissionCateogryAlertTitle,
            message: strings.deletePermissionCategoryAlertMessage.replace("{{name}}", displayName),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedCategory(id);
        });
    }

    async function deleteSelectedCategory(id: string) {
        try {
            await deletePermissionCategory(axiosInstance, id);
        }
        catch(e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingPermissionCategory.replace("{{error}}", error.message),
                msgType: "error",
            });       

            return;
        }
        
        setNotify({
            isOpen: true,
            message: strings.permissionCategoryDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        setSelectedPermissionCategoryId("");
        setSelectedPermissionCategory(initialPermissionCategoryState);
    }

    async function handleEditRowClick(id: string) {
        setSelectedPermissionCategoryId(id);

        try {
            const payload = await getPermissionCategory(axiosInstance, id);
            payload.displayOrder.toString(); //TODO: Remove if using DisplayOrderButton

            setSelectedPermissionCategory(payload);
            setDetailsDialogOpen(true);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingPermissionCategory.replace("{{error}}", error.message),
                msgType: "error",
            });
        }
        finally {
            setIsLoading(false);
        }
    }

    async function handleSaveClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        const isUpdate = selectedPermissionCategoryId.length > 0;

        try {
            let payload = selectedPermissionCategory;
            payload.displayOrder = Number(payload.displayOrder); //TODO: Remove if using DisplayOrderButton

            if (isUpdate) {
                await updatePermissionCategory(axiosInstance, payload);
            } else {
                await addPermissionCategory(axiosInstance, payload);
            }
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: selectedPermissionCategoryId === "" 
                    ? strings.errorCreatingPermissionCategory 
                    : strings.errorUpdatingPermissionCategory
                        .replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        setSelectedPermissionCategoryId("");
        setGridRefresh(!gridRefresh);
        setDetailsDialogOpen(false);
        setErrors(initialErrorState);
        setNotify({
            isOpen: true,
            message: strings.permissionCategorySavedSuccessfully, 
            msgType: "success",
        });

        return true;
    }

    function handleCancelClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setErrors(initialErrorState);
        setDetailsDialogOpen(false);
        setSelectedPermissionCategory(initialPermissionCategoryState);
    }

    function handleValidateForm() {
        setErrors(initialErrorState);

        const toValidate: ValidationField[] = [{ 
                property: "name", 
                type: ValidationType.Required, 
                message: strings.validationPermissionCategoryNameRequired
            }];

        const errors = validate<PermissionCategory, ValidationErrors>(toValidate, selectedPermissionCategory);

        if (errors) {
            setErrors(errors);
            return false;
        }

        return true; 
    }

    function handleMoveUpClick(id: string) {
        setPermissionCategories(moveUp(permissionCategories, id, 1));
    }

    function handleMoveDownClick(id: string) {
        setPermissionCategories(moveDown(permissionCategories, id, 1));
    }

    function handleMoveToTopClick(id: string) {
        setPermissionCategories(moveTop(permissionCategories, id));
    }

    function handleMoveToBottomClick(id: string) {
        setPermissionCategories(moveBottom(permissionCategories, id));
    }

    function getDisplayOrderForItem(id: string) {
        let index = permissionCategories.findIndex(category => category.id === id);
        return permissionCategories[index].displayOrder;
    }

    // TODO add title string
    const dialogTitle = selectedPermissionCategoryId === "" 
        ? strings.addPermissionCategoryButtonText 
        : strings.permissionCategoryDialogTitleEdit.replace("{{name}}", selectedPermissionCategory?.name ?? "");

    const gridColumns: GridColDef[] = [        
        { field: "name", headerName: strings.name, flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="configuration_security"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditRowClick(params.id.toString())}
                />,
                <DataGridDeleteButton
                    permissionKey="configuration_security"
                    rowId={params.id.toString()}
                    clickHandler={() => handleDeleteClick(params.id.toString())}
                />,
                <DisplayOrderButton
                    current={getDisplayOrderForItem(params.id.toString())} // new for displayOrderButton
                    count={permissionCategories.length} // new for displayOrderButton
                    onMoveTopClick={() => handleMoveToTopClick(params.id.toString())}
                    onMoveUpClick={() => handleMoveUpClick(params.id.toString())}
                    onMoveDownClick={() => handleMoveDownClick(params.id.toString())}
                    onMoveBottomClick={() => handleMoveToBottomClick(params.id.toString())}
                />
            ],
        },
    ];

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 171px)" }}> 
            <DataGrid
                permissionKey="configuration_security"
                getRowId={(row) => row.id} 
                rows={permissionCategories}
                columns={gridColumns}
                //pageSize={10}
                onAddButtonClick={handleAddClick}
                addButtonText={strings.addPermissionCategoryButtonText}
                loading={isLoading}                
            />

            <DetailsDialog
                permissionKey="configuration_security"
                open={detailsDialogOpen}
                title={dialogTitle}
                onCancelClick={handleCancelClick}
                onSaveClick={handleSaveClick}
                onValidateForm={handleValidateForm}
                adding={selectedPermissionCategoryId === ""}
                contentSize={{ width: 500, height: 325 }}
            >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="Permission Category Details Tab"
                    >
                        <Tab label={strings.detailsTabTitle} />
                    </Tabs>
                </Box>
                <TabPanelKeepMounted value={tabValue} index={0}>
                    <TextField
                        id="permission-category-name"
                        label={strings.name}
                        width={250}
                        value={selectedPermissionCategory.name}
                        onChange={handleNameChange}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                    />
                    <Spacer />

                    {/* //TODO: Remove if using DisplayOrderButton */}
                    <TextField
                        id="permission-category-display-order"
                        label={strings.displayOrder}
                        width={175} 
                        type="number"
                        value={selectedPermissionCategory.displayOrder.toString()}
                        onChange={handleDisplayOrderChange}
                        error={Boolean(errors.displayOrder)}
                        helperText={errors.displayOrder}
                    />
                    <Spacer />
                    <Checkbox
                        label={strings.enabled}
                        checked={selectedPermissionCategory.enabled}
                        onChange={handleEnabledChange}
                    />
                </TabPanelKeepMounted>
            </DetailsDialog>
            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    )
};

export default PermissionCategoryList;