import { AxiosInstance } from "axios";
import { ServiceTicketNote } from "../../../models/dashboard/support/ServiceTicketNote";

const url = process.env.REACT_APP_MANAGER_API_URL + "/service-ticket/";

export async function listNotes(axiosInstance: AxiosInstance, ticketId: string): Promise<ServiceTicketNote[]> {
    return await axiosInstance
        .get(`${url}tickets/${ticketId}/notes`)
        .then(
            (response) =>
                response.data.notes as ServiceTicketNote[]
        );
}

export async function createNote(axiosInstance: AxiosInstance, ticketId: string, note: ServiceTicketNote) {    
    return await axiosInstance.post(`${url}tickets/${ticketId}/notes`, note).then((response) => {
        return;
    });
}