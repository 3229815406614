import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import themePrimary from "../../../../styles/themePrimary";
import { Permission, PermissionType, PermissionCategory } from "../../../../models/configuration/security/Permission";
import { Access } from "../../../../models/configuration/security/Permission";
import { Role } from "../../../../models/configuration/security/Role";
import { TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, Checkbox } from "@mui/material";
import { Table } from "reactstrap";
import { Theme } from "@mui/material/styles";
import { useDialog } from "../../../common/details/DetailsDialog";
import { useLocalizedStrings } from "../../../../localization/LocalizedStringsProvider";

const useStyles = makeStyles((theme: Theme) => ({
  nameCell: {
      minWidth: '200px',
  },
  category: {

  },
  permission: {
     fontSize: '.9em', 
     padding: '0px !important',
     paddingLeft: '48px !important',
  },
  permissionCell: {
      padding: '0px !important'
  },
  tableContainer: {
      height: '506px',
      overflowY: 'scroll',
      position: 'relative',
      paddingLeft: themePrimary.spacing(2),
      paddingRight: themePrimary.spacing(2),
  },
  table: {
    width: '100%',
  }
}));
  
export interface RolePermissionProps {
    role: Role;
    permissionCategories: PermissionCategory[];
    onPermissionCheckClickHandler: (event: any) => void;
}

export interface PermissionCheckboxValue {
    access: Access;
    type: PermissionType;
}

const AdminRolePermissions = (props: RolePermissionProps) => {
    const { role, permissionCategories, onPermissionCheckClickHandler } = props;
    const classes = useStyles(useTheme());
    const { dirty, setDirty } = useDialog();
    const strings = useLocalizedStrings();

    function checkHasAccess(permissionType: PermissionType, access: Access): boolean {
        let hasAccess = false;

        if (role !== null) {
            let index = 0;
            let foundPermission = false;
            while (index < role.permissions.length && !foundPermission) {
                let permission = role.permissions[index];
                if (permission.typeId === permissionType.id) {
                    if (permission.access & access) {
                        hasAccess = true;
                    }
                    foundPermission = true;
                }
                index++;
            }
        }

        return hasAccess;
    }

    const createPermissionCheckboxValue = (permissionType: PermissionType, access: Access): string => {
        let value: PermissionCheckboxValue = {
            access: access,
            type: permissionType
        }

        return JSON.stringify(value);
    }

    const permissionCheckClickHandler = (event: any) => {
        setDirty(true);
        onPermissionCheckClickHandler(event);
    }

    return (
        <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} aria-label="Permissions">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.nameCell}></TableCell>
                        <TableCell align="center">{strings.permissionsView}</TableCell>
                        <TableCell align="center">{strings.permissionsAdd}</TableCell>
                        <TableCell align="center">{strings.permissionsEdit}</TableCell>
                        <TableCell align="center">{strings.permissionsDelete}</TableCell>
                    </TableRow>
                </TableHead>
                {permissionCategories.map((category) => (
                    <TableBody>
                        {category.types.length > 0 && (                                  
                            <TableRow>
                                <TableCell align="left" colSpan={5} className={classes.category}>{category.name}</TableCell>
                            </TableRow>
                        )}
                        {category.types.map((permissionType) => (
                            <TableRow>
                                <TableCell align="left" className={classes.permission}>
                                    {permissionType.name}
                                </TableCell>
                                <TableCell align="center" className={classes.permissionCell}>
                                    <Checkbox size="small" 
                                        value={createPermissionCheckboxValue(permissionType, Access.Read)}
                                        onClick={permissionCheckClickHandler}
                                        checked={checkHasAccess(permissionType, Access.Read)}
                                        disabled={!((permissionType.accessAllowed & Access.Read) === Access.Read)}
                                    />
                                </TableCell>
                                <TableCell align="center" className={classes.permissionCell}>
                                    <Checkbox size="small" 
                                        value={createPermissionCheckboxValue(permissionType, Access.Create)}
                                        onClick={permissionCheckClickHandler}
                                        checked={checkHasAccess(permissionType, Access.Create)}
                                        disabled={!((permissionType.accessAllowed & Access.Create) === Access.Create)}
                                    />
                                </TableCell>
                                <TableCell align="center" className={classes.permissionCell}>
                                    <Checkbox size="small" 
                                        value={createPermissionCheckboxValue(permissionType, Access.Update)}
                                        onClick={permissionCheckClickHandler}
                                        checked={checkHasAccess(permissionType, Access.Update)}
                                        disabled={!((permissionType.accessAllowed & Access.Update) === Access.Update)}
                                        />
                                </TableCell>
                                <TableCell align="center" className={classes.permissionCell}>
                                    <Checkbox size="small" 
                                        value={createPermissionCheckboxValue(permissionType, Access.Delete)}
                                        onClick={permissionCheckClickHandler}
                                        checked={checkHasAccess(permissionType, Access.Delete)}
                                        disabled={!((permissionType.accessAllowed & Access.Delete) === Access.Delete)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}                                    
                    </TableBody>
                ))}
            </Table>
        </TableContainer>
    );
};

export default AdminRolePermissions; 