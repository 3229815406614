import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import ActivityList from "./ActivityList";
import ActivitySchedule from "./ActivitySchedule";
import TypeList from "./TypeList";

const Activities = () => {
    const strings = useLocalizedStrings();   
    const location = useLocation();
    const theme = useTheme();

    return (
        <Box sx={{ width: '100%' }}>            
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={location.pathname} ria-label="basic tabs example">
                    <Tab value="/activities/schedule" label={strings.activitiesSchedule} component={Link} to="schedule" />
                    <Tab value="/activities/activities" label={strings.activities} component={Link} to="activities" />
                    <Tab value="/activities/types" label={strings.activityTypes} component={Link} to="types" />
                </Tabs>
            </Box>            
            <Box component="main" sx={{ flexGrow: 1, padding: 0 }}>
                <Routes>
                    <Route path="schedule" element={<ActivitySchedule />} />
                    <Route path="activities" element={<ActivityList />} />
                    <Route path="types" element={<TypeList />} />
                </Routes>
            </Box>
        </Box>
    );
};

export default Activities;
