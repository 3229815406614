import AddIcon from '@mui/icons-material/Add';
import { Button, FormControl, InputLabel, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress from '@mui/material/LinearProgress';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { addPermissionType, deletePermissionType, getPermissionType, listAdminPermissionCategories, listPermissionTypes, updatePermissionType } from "../../../../api/permissionApi";
import { useCreateAxios } from "../../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../../localization/LocalizedStringsProvider";
import { Access, PermissionCategory, PermissionType, initialPermissionTypeState } from "../../../../models/configuration/security/Permission";
import { useUser } from "../../../../providers/UserProvider";
import { Field as ValidationField, Type as ValidationType, validate } from "../../../../utilities/Validator";
import { useAlertDialog } from "../../../common/AlertDialog/AlertDialogProvider";
import NotificationMessage, { NotificationOptions } from "../../../common/NotificationMessage";
import Spacer from "../../../common/Spacer";
import TabPanelKeepMounted from "../../../common/TabPanelKeepMounted";
import DataGridDeleteButton from "../../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../../common/datatable/DataGridEditButton";
import DataGrid from "../../../common/datatable/DataGridWrapper";
import AccessAllowed from "../../../common/details/AccessAllowed";
import Checkbox from "../../../common/details/Checkbox";
import DetailsDialog from "../../../common/details/DetailsDialog";
import { default as DialogSelect } from "../../../common/details/Select";
import TextField from "../../../common/details/TextField";
import { PermissionCheckboxValue } from "./AdminRolePermissions";
import { useTheme } from '@mui/material/styles';

interface ValidationErrors {    
    name: string;
    key: string;
}

const initialErrorState : ValidationErrors = {
    name: "",
    key: ""
}

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

function PermissionTypeList() {
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [gridRefresh, setGridRefresh] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [permissionCategories, setPermissionCategories] = useState<PermissionCategory[]>([]); 
    const [permissionTypes, setPermissionTypes] = useState<PermissionType[]>([]);
    const [selectedPermissionCategoryId, setSelectedPermissionCategoryId] = useState("");
    const [selectedPermissionTypeId, setSelectedPermissionTypeId] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPermissionType, setSelectedPermissionType] = useState<PermissionType>(initialPermissionTypeState);
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const strings = useLocalizedStrings();
    const alertDialog = useAlertDialog();
    const axiosInstance = useCreateAxios();
    const { user, checkAccess } = useUser();   
    const theme = useTheme();

    useEffect(() => {
        async function getCategories() {
            try {
                const payload = await listAdminPermissionCategories(axiosInstance);
                setPermissionCategories(payload);
                setSelectedPermissionCategoryId(payload[0].id); // todo: not sure about this
            } catch (e) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };                
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingPermissionCategories.replace("{{error}}", error.message),
                    msgType: "error",
                });
            } finally {
                setIsLoading(false);
            }
        }

        getCategories();
    }, []);

    useEffect(() => {
        async function getTypes() {
            try {
                const response = await listPermissionTypes(axiosInstance, selectedPermissionCategoryId);
                setPermissionTypes(response);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };                
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingPermissionTypes.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }
            finally {
                setIsLoading(false);
            }
        }

        if (selectedPermissionCategoryId !== "") {
            getTypes();
        }
    }, [selectedPermissionCategoryId, gridRefresh])

    function handleTabChange(event: React.SyntheticEvent<Element, Event>, newValue: any) {
        setTabValue(newValue);
    }

    function handleTypeNameChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSelectedPermissionType((current: PermissionType) => ({
            ...current,
            name: e.currentTarget.value
        }));
    }

    function handleKeyChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSelectedPermissionType((old: PermissionType) => ({
            ...old,
            key: e.currentTarget.value
        }));
    }
      
    function handleEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedPermissionType((prevState) => ({
            ...prevState,
            enabled: event.target.checked,
        }));
    }

    function handleAdminOnlyChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedPermissionType((prevState) => ({
            ...prevState,
            adminOnly: event.target.checked,
        }));
    }

    function handleAddClick() {
        setSelectedPermissionTypeId("");
        setSelectedPermissionType({ ...initialPermissionTypeState, categoryId: selectedPermissionCategoryId });
        setDetailsDialogOpen(true);
    }

    function handleDeleteClick(id: string) {
        const displayName = permissionTypes.find(type => type.id === id)?.name ?? "";

        alertDialog({
            title: strings.deletePermissionTypeAlertTitle,
            message: strings.deletePermissionTypeAlertMessage.replace("{{name}}", displayName),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedPermissionType(id);
        });
    }

    async function deleteSelectedPermissionType(id: string) {
        try {
            await deletePermissionType(axiosInstance, id);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingPermissionType.replace("{{error}}", error.message),
                msgType: "error",
            });

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.permissionTypeDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        setSelectedPermissionTypeId("");
        setSelectedPermissionType(initialPermissionTypeState);
    }

    async function handleEditRowClick(id: string) {
        setSelectedPermissionTypeId(id);

        try {
            const payload = await getPermissionType(axiosInstance, id);
            // TODO: put categoryName back, and not do this?
            setSelectedPermissionType({
                accessAllowed: payload.accessAllowed,
                adminOnly: payload.adminOnly,
                categoryId: payload.categoryId,
                enabled: payload.enabled,
                id: payload.id,
                key: payload.key,
                name: payload.name
            });

            setDetailsDialogOpen(true);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingPermissionType.replace("{{error}}", error.message),
                msgType: "error",
            });
        }
        finally {
            setIsLoading(false);
        }
    }

    async function handleSaveClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        const isUpdate = selectedPermissionTypeId.length > 0;

        try {
            if (isUpdate) {
                await updatePermissionType(axiosInstance, selectedPermissionType);
            } else {
                await addPermissionType(axiosInstance, selectedPermissionType);
            }
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: selectedPermissionTypeId === "" 
                ? strings.errorCreatingPermissionType 
                : strings.errorUpdatingPermissionType
                    .replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        setSelectedPermissionTypeId("");
        setGridRefresh(!gridRefresh);
        setDetailsDialogOpen(false);
        setErrors(initialErrorState);
        setSelectedPermissionType(initialPermissionTypeState); // TODO: not sure if needed
        setNotify({
            isOpen: true,
            message: strings.permissionTypeSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleCancelClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setErrors(initialErrorState);
        setDetailsDialogOpen(false);
        setSelectedPermissionType(initialPermissionTypeState);
    }

    function handleCategorySelectionChange(e: SelectChangeEvent) {
        setSelectedPermissionCategoryId(e.target.value as string);
    }

    function handlePermissionTypeOnClick(event: any) {
        let newValue = JSON.parse(event.target.value) as PermissionCheckboxValue;

        setSelectedPermissionType((old: PermissionType) => ({
            ...old,
            accessAllowed: newValue.access ^ old.accessAllowed as number
        }))
    }

    function handleDialogSelectionChange(event: SelectChangeEvent) {
        setSelectedPermissionType((old: PermissionType) => ({
            ...old,
            categoryId: event.target.value as string
        }))
    }

    function handleValidateForm() {
        setErrors(initialErrorState);

        const toValidate: ValidationField[] = [
            {
                property: "name",
                type: ValidationType.Required,
                message: strings.validationPermissionTypeNameRequired
            },
            {
                property: "key",
                type: ValidationType.Required,
                message: strings.validationPermissionTypeKeyRequired 
            }];

        const errors = validate<PermissionType, ValidationErrors>(toValidate, selectedPermissionType);

        if (errors) {
            setErrors(errors);
            return false;
        }

        return true;
    }

    // TODO: add title string
    const dialogTitle = selectedPermissionTypeId === "" 
        ? strings.addPermissionTypeButtonText 
        : strings.permissionTypeDialogTitleEdit.replace("{{name}}", selectedPermissionType?.name ?? "");

    const gridColumns: GridColDef[] = [        
        { field: "name", headerName: strings.name, flex: 1 },
        { field: "key", headerName: strings.key, flex: 1},
        { field: "adminOnly", headerName: strings.adminOnlyLabel, type: "boolean", flex: 1 },
        { field: "enabled", headerName: strings.enabled, type: "boolean", flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="configuration_security"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditRowClick(params.id.toString())}
                />,
                <DataGridDeleteButton
                    permissionKey="configuration_security"
                    rowId={params.id.toString()}
                    clickHandler={() => handleDeleteClick(params.id.toString())}
                />,
            ],
        },
    ];
    
    if (isLoading) {
        return <LinearProgress color={"primary"} variant={"query"} />;
    }
    else {
        return (
            <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 185px)" }}> 
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                        <InputLabel id="demo-simple-select-label">
                            {strings.permissionCategoryLabel}
                        </InputLabel>
                        <Select
                            id="selectCategoryList"                            
                            label={strings.permissionCategoryLabel}
                            onChange={handleCategorySelectionChange}
                            MenuProps={{
                                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                transformOrigin: { vertical: "top", horizontal: "left" }
                            }}
                        >
                            {permissionCategories.map((kvp, index) => (
                                <MenuItem key={index} value={kvp.id}>{kvp.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {checkAccess("configuration_security", Access.Create) &&
                        <Button
                            startIcon={<AddIcon />}
                            sx={{ marginLeft: 'auto' }}
                            variant="contained"                            
                            onClick={handleAddClick}
                            aria-label="Add Permission"                    
                        >
                            {strings.addPermissionTypeButtonText}                    
                        </Button>
                    }
                </Box>
                <Spacer y={2}/>                      
                <DataGrid
                    permissionKey="configuration_security"
                    getRowId={(row) => row.id} 
                    rows={permissionTypes}
                    columns={gridColumns}                                        
                    showAddButton={false}
                    loading={isLoading}                    
                />
                <DetailsDialog
                    permissionKey="configuration_security"
                    open={detailsDialogOpen}
                    title={dialogTitle}
                    onCancelClick={handleCancelClick}
                    onSaveClick={handleSaveClick}
                    onValidateForm={handleValidateForm}
                    adding={selectedPermissionTypeId === ""}
                    contentSize={{ width: 595, height: 550 }}
                >
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="Permission Type Details Tab"
                        >
                            <Tab label={strings.detailsTabTitle} />
                        </Tabs>
                    </Box>
                    <TabPanelKeepMounted value={tabValue} index={0}>
                        <DialogSelect
                            label={strings.permissionCategoryLabel}
                            keyValues={permissionCategories.map(p => ({ key: p.id, value: p.name }))}
                            selectedValue={selectedPermissionType.categoryId}
                            onChangeHandler={handleDialogSelectionChange}
                            sx={{ minWidth: 310 }}
                        />
                        <Spacer />
                        <TextField
                            id="permission-type-name"
                            label={strings.name}
                            width={310}
                            value={selectedPermissionType.name}
                            onChange={handleTypeNameChange}
                            error={Boolean(errors.name)}
                            helperText={errors.name}
                        />
                        <Spacer />
                        <TextField
                            id="permission-type-key"
                            label={strings.key}
                            width={310}
                            value={selectedPermissionType.key}
                            onChange={handleKeyChange}
                            error={Boolean(errors.key)}
                            helperText={errors.key}
                        />
                        <Spacer />
                        <Spacer />
                        <AccessAllowed 
                            permissionType={selectedPermissionType} 
                            onClick={handlePermissionTypeOnClick} 
                        />
                        <Spacer />
                        <Checkbox
                            label={strings.adminOnlyLabel}
                            checked={selectedPermissionType.adminOnly}
                            onChange={handleAdminOnlyChange}
                        />
                        <Spacer />
                        <Checkbox
                            label={strings.enabled}
                            checked={selectedPermissionType.enabled}
                            onChange={handleEnabledChange}
                        />
                    </TabPanelKeepMounted>
                </DetailsDialog>
                <NotificationMessage notificationState={[notify, setNotify]} />
            </Box>
        )
    }
};

export default PermissionTypeList;