import { Book, Item, Section, Topic } from "../models/modules/Information/Information";
import { parseLanguageStringsFromJson } from "../utilities/JsonHelper";
import { AxiosInstance } from "axios";

const url = process.env.REACT_APP_MANAGER_API_URL + '/information/';

// TODO: change prop code variables to PropertyId, etc

// BOOKS
export async function getBookList(axiosInstance : AxiosInstance, propertyCode: string) : Promise<Book[]> {
    return axiosInstance.get(`${url}${propertyCode}/books`)
        .then(r => parseLanguageStringsFromJson(r.data.books) as Book[])  
}

export async function getBookWithChildren(axiosInstance: AxiosInstance, propertyCode: string, bookId: string) : Promise<Book> {
    return axiosInstance.get(`${url}${propertyCode}/books/${bookId}`)
        .then(r => parseLanguageStringsFromJson(r.data.book) as Book);
}

export async function getTopicList(axiosInstance : AxiosInstance, propertyCode: string) : Promise<Topic[]> {
    return axiosInstance.get(`${url}${propertyCode}/topics`)
        .then(r => parseLanguageStringsFromJson(r.data.topics) as Topic[])
}

export async function getSectionList(axiosInstance : AxiosInstance, propertyCode: string) : Promise<Section[]> {
    return axiosInstance.get(`${url}${propertyCode}/sections`)
        .then(r => parseLanguageStringsFromJson(r.data.sections) as Section[])
}

export async function getItemList(axiosInstance : AxiosInstance, propertyCode: string) : Promise<Item[]> {
    return axiosInstance.get(`${url}${propertyCode}/items`)
        .then(r => parseLanguageStringsFromJson(r.data.items) as Item[])
}

export async function createBook(axiosInstance: AxiosInstance, propertyCode: string, book: Book) {
    const {topics, ...bookDto} = book; 
    const bookToSave = {
        ...bookDto, 
        name: JSON.stringify({...bookDto.name}), 
        propertyId: propertyCode 
    };

    return axiosInstance.post(`${url}${propertyCode}/books`, bookToSave);
}

export async function updateBook(axiosInstance: AxiosInstance, propertyCode: string, book: Book) {
    const bookToSave = {
        name: JSON.stringify({...book.name}), 
    };

    return axiosInstance.put(`${url}${propertyCode}/books/${book.id}`, bookToSave);
}

export async function deleteBook(axiosInstance: AxiosInstance, propertyCode: string, bookId: string) {
    return axiosInstance.delete(`${url}${propertyCode}/books/${bookId}`);
}

export async function saveBookDisplayOrder(axiosInstance: AxiosInstance, propertyId: string, idList: string[]) {
     const response = await axiosInstance.post(`${url}${propertyId}/books/reorder`, idList);
     return response.status
 }

// TOPICS
export async function createTopic(axiosInstance : AxiosInstance, propertyCode: string, bookId: string, topic: Topic) {
    const topicToSave = {
        id: topic.id,
        bookId: bookId,
        name: JSON.stringify({...topic.name}),
        propertyId: propertyCode
    };

    return axiosInstance.post(`${url}${propertyCode}/topics`, topicToSave);
}

export async function updateTopic(axiosInstance: AxiosInstance, propertyCode: string, topic: Topic) {
    const topicToSave = {
        name: JSON.stringify({...topic.name}), 
    };
    
    return axiosInstance.put(`${url}${propertyCode}/topics/${topic.id}`, topicToSave);
}

export async function deleteOrRemoveTopic(axiosInstance: AxiosInstance, propertyCode: string, topicId: string, bookId?: string) {
    const queryString = !bookId
        ? `${url}${propertyCode}/topics/${topicId}`
        : `${url}${propertyCode}/topics/${topicId}?bookId=${bookId}`
    
    return axiosInstance.delete(queryString);
}

export async function saveTopicDisplayOrder(axiosInstance: AxiosInstance, propertyId: string, idList: string[], bookId: string, alphabetical?: boolean) {
    const response = await axiosInstance.post(`${url}${propertyId}/topics/reorder?bookId=${bookId}&alphabetical=${alphabetical ?? false}`, idList);
    return response.status
}

// SECTIONS
export async function createSection(axiosInstance : AxiosInstance, propertyCode: string, topicId: string, section: Section) {
    const sectionToSave = {
        id: section.id,
        topicId: topicId,
        name: JSON.stringify({...section.name}),
        propertyId: propertyCode 
    };

    return axiosInstance.post(`${url}${propertyCode}/sections`, sectionToSave);
}

export async function updateSection(axiosInstance: AxiosInstance, propertyCode: string, section: Section) {
    const sectionToSave = {
        name: JSON.stringify({...section.name}), 
    };
    
    return axiosInstance.put(`${url}${propertyCode}/sections/${section.id}`, sectionToSave);
}

export async function deleteOrRemoveSection(axiosInstance: AxiosInstance, propertyCode: string, sectionId: string, topicId?: string) {
    const queryString = !topicId
        ? `${url}${propertyCode}/sections/${sectionId}`
        : `${url}${propertyCode}/sections/${sectionId}?topicId=${topicId}`

    return axiosInstance.delete(queryString);
}

export async function saveSectionDisplayOrder(axiosInstance: AxiosInstance, propertyId: string, idList: string[], bookId: string, alphabetical?: boolean) {
    const response = await axiosInstance.post(`${url}${propertyId}/sections/reorder?bookId=${bookId}&alphabetical=${alphabetical ?? false}`, idList);
    return response.status
}

// ITEMS
export async function createItem(axiosInstance : AxiosInstance, propertyCode: string, sectionId: string, item: Item) {
    const itemToSave = {
        ...item,
        id: item.id,
        sectionId: sectionId,
        name: JSON.stringify({...item.name}),
        description: JSON.stringify({...item.description}),
        propertyId: propertyCode 
    };

    return axiosInstance.post(`${url}${propertyCode}/items`, itemToSave);
}

export async function updateItem(axiosInstance: AxiosInstance, propertyCode: string, item: Item) {
    const itemToSave = {
        ...item,
        name: JSON.stringify({...item.name}), 
        description: JSON.stringify({...item.description})
    };
    
    return axiosInstance.put(`${url}${propertyCode}/items/${item.id}`, itemToSave);
}

export async function deleteOrRemoveItem(axiosInstance: AxiosInstance, propertyCode: string, itemId: string, sectionId?: string) {
    const queryString = !sectionId
        ? `${url}${propertyCode}/items/${itemId}`
        : `${url}${propertyCode}/items/${itemId}?sectionId=${sectionId}`

    return axiosInstance.delete(queryString);
}

export async function saveItemDisplayOrder(axiosInstance: AxiosInstance, propertyId: string, idList: string[], bookId: string, alphabetical?: boolean) {
    const response = await axiosInstance.post(`${url}${propertyId}/items/reorder?bookId=${bookId}&alphabetical=${alphabetical ?? false}`, idList);
    return response.status
}