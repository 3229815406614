import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import MuiIconButton from "@mui/material/IconButton";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { useLocalizedStrings } from "../../localization/LocalizedStringsProvider";
import { Access } from "../../models/configuration/security/Permission";
import { useUser } from "../../providers/UserProvider";

export interface Orderable {
    id: string;
    displayOrder: number;
}

export const moveTop = <T extends Orderable>(array: T[], id: string): T[] => {
    const index = array.findIndex(a => a.id === id);
    return moveUp(array, id, index);
}

export const moveUp = <T extends Orderable>(array: T[], id: string, distance: number = 1): T[] => {
    if (distance == 0) {
        return array;
    }

    const newArray = [...array];
    const index = array.findIndex(a => a.id === id);
    const temp = newArray[index];
    newArray[index] = newArray[index - 1];
    newArray[index - 1] = temp;
    
    return moveUp(newArray, id, distance - 1);
}

export const moveDown = <T extends Orderable>(array: T[], id: string, distance: number = 1): T[] => {
    if (distance == 0) {
        return array;
    }

    const newArray = [...array];
    const index = array.findIndex(a => a.id === id);
    const temp = newArray[index];
    newArray[index] = newArray[index + 1];
    newArray[index + 1] = temp;    

    return moveDown(newArray, id, distance - 1);
}

export const moveBottom = <T extends Orderable>(array: T[], id: string): T[] => {
    const index = array.findIndex(a => a.id === id);
    return moveDown(array, id, array.length - index - 1);
}

export const reorder = <T extends Orderable>(array: T[]): T[] => {
    const newArray = [...array];

    for (var i = 0; i < newArray.length; i++) {
        newArray[i].displayOrder = i;
    }

    return newArray;
}

export const sortAlphabetical = (array: any[], field: string): any[] => {
    const newArray = [...array];

    // If the field is string language we need to a little more work
    if (field.includes('.')) {
        const fieldSplit = field.split('.');
        newArray.sort((a: any, b: any) => (a[fieldSplit[0]][fieldSplit[1]].toString() > b[fieldSplit[0]][fieldSplit[1]].toString()) ? 1 : -1);
    }
    else {
        newArray.sort((a: any, b: any) => (a[field].toString() > b[field].toString()) ? 1 : -1);
    }

    //// We'll set all display orders to 0 so that when we sort by name (2nd) it will basicall ignore the display order
    //for (var i = 0; i < newArray.length; i++) {
    //    newArray[i].displayOrder = 0;
    //}

    return newArray;
}

export interface DisplayOrderButtonProps {
    current: number;
    count: number;
    permissionKey?: string;
    disabled?: boolean;
    onMoveTopClick: () => void;
    onMoveUpClick: () => void;
    onMoveDownClick: () => void;
    onMoveBottomClick: () => void;
    onSortAlphabetical?: () => void;    
}

const DisplayOrderButton = (props: DisplayOrderButtonProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);    
    const theme = useTheme();
    const strings = useLocalizedStrings();
    const { checkAccess } = useUser();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (props.disabled) {
            return;
        }

        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };    

    function handleMoveTopClick() {
        setAnchorEl(null);
        props.onMoveTopClick();
    }

    function handleMoveUpClick() {
        setAnchorEl(null);
        props.onMoveUpClick();
    }

    function handleMoveDownClick() {
        setAnchorEl(null);
        props.onMoveDownClick();
    }

    function handleMoveBottomClick() {
        setAnchorEl(null);
        props.onMoveBottomClick();
    }

    function handleSortAlphabeticalClick() {
        setAnchorEl(null);
        props.onSortAlphabetical?.();
    }

    return (
        <>
            {checkAccess(props.permissionKey, Access.Update) &&
                 <Tooltip title={strings.displayOrder}>
                    <MuiIconButton
                        size="small"
                        aria-label="audit"
                        aria-haspopup="true"
                        style={{ color: props.disabled ? theme.palette.action.disabled : theme.palette.primary.dark }}
                        onClick={handleClick}                        
                    >
                        <CompareArrowsIcon
                            sx={{ transform: "rotate(90deg)" }}                            
                            fontSize="small" />
                    </MuiIconButton>
                </Tooltip>
            }
           
            <Menu
                id="buttonDisplayOrder"
                MenuListProps={{
                    'aria-labelledby': 'buttonDisplayOrder',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem disabled={props.count === 1 || props.current === 0} onClick={handleMoveTopClick}>{strings.moveTop}</MenuItem>
                <MenuItem disabled={props.count === 1 || props.current === 0} onClick={handleMoveUpClick}>{strings.moveUp}</MenuItem>
                <MenuItem disabled={props.count === 1 || props.current === props.count - 1} onClick={handleMoveDownClick}>{strings.moveDown}</MenuItem>
                <MenuItem disabled={props.count === 1 || props.current === props.count - 1} onClick={handleMoveBottomClick}>{strings.moveBottom}</MenuItem>
                {props.onSortAlphabetical &&
                    <MenuItem onClick={handleSortAlphabeticalClick}>{strings.sortAlphabetical}</MenuItem>
                }
            </Menu>
         </>
    )
}
export default DisplayOrderButton;