import { AxiosInstance, CancelTokenSource } from "axios";
import { PotsStatus } from "../../../models/dashboard/network/PotsStatus";
import { parseLanguageStringsFromJson } from "../../../utilities/JsonHelper";

const url = process.env.REACT_APP_MANAGER_API_URL + "/network-monitor/";

export async function getPotsStatus(axiosInstance: AxiosInstance, propertyCode: string, cancelToken: CancelTokenSource): Promise<PotsStatus> {
    return await axiosInstance
        .get(`${url}${propertyCode}/pots/status`, { cancelToken: cancelToken.token })
        .then((response) => parseLanguageStringsFromJson(response.data).potsStatus as PotsStatus);
}
