import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Button, Typography } from "@mui/material";
import TimelineIcon from '@mui/icons-material/Timeline';

const useStyles = makeStyles((theme: Theme) => ({
    propertyHeader: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        width: '100%',
    },

}));

export interface PropertyCircuitHeaderProps {
    propertyId: string;
    propertyName: string;
    onViewCustomGraph: (propertyId: string, propertyName: string) => void
    onViewLiveGraph: (propertyId: string, propertyName: string) => void;
}

const PropertyCircuitHeader = (props: PropertyCircuitHeaderProps) => {

    const strings = useLocalizedStrings();
    const theme = useTheme();  
    const classes = useStyles(theme);

    const onCustomGraphClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {

        props.onViewCustomGraph(props.propertyId, props.propertyName);
        event.stopPropagation();
    }

    const onLiveGraphClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.onViewLiveGraph(props.propertyId, props.propertyName);
        event.stopPropagation();
    }

    return (
        <div className={classes.propertyHeader}>
            <Typography variant="h6">
                {props.propertyName}
            </Typography>
            <div style={{ display: 'flex', justifySelf: 'end'}}>
                <Button variant="contained" size="small" style={{marginRight: '20px', width: '300px'}} 
                    onClick={onCustomGraphClickHandler}
                    startIcon={<TimelineIcon />}>
                    {strings.circuitCustomGraph}
                </Button>
                <Button variant="contained" size="small" style={{width: '200px'}} 
                    onClick={onLiveGraphClickHandler}
                    startIcon={<TimelineIcon />}>
                    {strings.circuitLiveGraph}
                </Button>
            </div>
        </div>
    );
}

export default PropertyCircuitHeader;