import { StringLanguageDict } from "../../common/LanguageModel";
import { Orderable } from "../../../components/common/DisplayOrderButton";

export interface OptionSet extends Orderable {
    id: string;
    name?: StringLanguageDict | null;      
    internalName: string;
    minimum: number,
    maximum: number,
    options: Option[];
}

export const initialOptionSetState: OptionSet = {
    id: "",
    name: null,      
    internalName: "",
    minimum: 0,
    maximum: 0,
    options: [],
    displayOrder: 0
}

export interface Option extends Orderable {
    id: string;
    name?: StringLanguageDict | null;   
    sku?: string | null;
    pricePerUnit: number;  
    pricePerUnitString?: string;
    optionSets: OptionSet[]
}

export const initialOptionState: Option = {
    id: "",
    name: null,    
    sku: null,
    pricePerUnit: 0,    
    pricePerUnitString: undefined,
    displayOrder: 0,
    optionSets: []
}