import { default as MaterialAvatar } from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import { useAuth } from "oidc-react";

//TODO: pass in/look up logged in user
const Avatar = () => {
    const theme = useTheme();
    const auth = useAuth();

    return (
        <MaterialAvatar
            alt=""
            sx={{ bgcolor: theme.palette.primary.light }}
        >
            {(auth && auth.userData && auth.userData.profile && auth.userData.profile.name!!) ? auth.userData.profile.name!!.charAt(0) : ""}
        </MaterialAvatar>
    );
};

export default Avatar;
