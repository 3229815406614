export interface UserNotifications {
    prtgSensorNotifications: PrtgSensorNotification[];
    ruckusAccessPointNotification: RuckusAccessPointNotification[];
    apsMinutesOffline: number;
    numberOfApsOffline: number;
    circuitSensorMinutesOffline: number;
    gatewaySensorMinutesOffline: number;
    emailAddress: string;
    phoneNumber: string;
    sendEmailNotification: boolean;
    sendPhoneNotification: boolean;
}

export interface RuckusAccessPointNotification {
    propertyCode: string;
}

export interface PrtgSensorNotification {
    type: string;
    propertyCode: string;
}

export function getInitialUserNotificationState(): UserNotifications {
    return {
        prtgSensorNotifications: [],
        ruckusAccessPointNotification: [],
        apsMinutesOffline: 5,
        numberOfApsOffline: 5,
        circuitSensorMinutesOffline: 5,
        gatewaySensorMinutesOffline: 5,
        emailAddress: '',
        phoneNumber: '',
        sendEmailNotification: false,
        sendPhoneNotification: false
    }
}

export const mapMinutesOfflineToKeyValues = (): { key: string; value: string }[] => {
    let kvps: { key: string; value: string }[] = [];
    kvps.push({key: "5", value: "5"})
    kvps.push({key: "10", value: "10"})
    kvps.push({key: "15", value: "15"})
    kvps.push({key: "30", value: "30"})
    kvps.push({key: "60", value: "60"})

    return kvps;
}