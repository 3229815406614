import { AxiosInstance } from "axios";
import { Customer } from "../../models/accounts/Customer";

const baseUrl = process.env.REACT_APP_MANAGER_API_URL + "/account-management/customers";

export async function listCustomers(axiosInstance: AxiosInstance): Promise<Customer[]> {
    return await axiosInstance.get(baseUrl)
        .then(response => response.data.customers as Customer[]);
}

export async function getCustomer(axiosInstance: AxiosInstance, id: string): Promise<Customer> {
    return await axiosInstance.get(`${baseUrl}/${id}`)
        .then(response => response.data as Customer);
}

export async function updateCustomer(axiosInstance: AxiosInstance, payload: Customer) {
    return await axiosInstance.put(`${baseUrl}/${payload.id}`, payload)
        .then(response => response)
}

export async function createCustomer(axiosInstance: AxiosInstance, payload: Customer) {
    return await axiosInstance.post(`${baseUrl}`, payload)
        .then(response => response)
}

export async function deleteCustomer(axiosInstance: AxiosInstance, id: string) {
    return await axiosInstance.delete(`${baseUrl}/${id}`)
        .then(response => response)
}