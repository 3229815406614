import { AxiosInstance } from "axios";
import { Skin } from "../../../models/administration/Skin";
  
const url = process.env.REACT_APP_MANAGER_API_URL + "/skin";

export async function listPropertySkins(axiosInstance: AxiosInstance, propertyId: string): Promise<Skin[]> {
    return await axiosInstance
        .get(`${url}/properties/${propertyId}`)
        .then(
            (response) =>
                response.data.skins as Skin[]
        );
}

export async function listBrandSkins(axiosInstance: AxiosInstance, brandId: string): Promise<Skin[]> {
    return await axiosInstance
        .get(`${url}/brands/${brandId}`)
        .then(
            (response) =>
                response.data.skins as Skin[]
        );
}

export async function savePropertySkin(axiosInstance: AxiosInstance, propertyId: string, skin: Skin){
    return await axiosInstance
        .put(`${url}/properties/${propertyId}`, skin)
        .then(
            (response) => response.data
        );
}

export async function saveBrandSkin(axiosInstance: AxiosInstance, brandId: string, skin: Skin){
    return await axiosInstance
        .put(`${url}/brands/${brandId}`, skin)
        .then(
            (response) => response.data
        );
}

export async function listDefaultSkins(axiosInstance: AxiosInstance): Promise<Skin[]>{
    return await axiosInstance
        .get(`${url}`)
        .then(
            (response) =>
                response.data.skins as Skin[]
        );
}

export async function saveDefaultSkin(axiosInstance: AxiosInstance, skin: Skin, isNewKey: boolean){
    if (isNewKey) {
        return await axiosInstance
        .post(`${url}`, skin)
        .then(
            (response) => response.data
        );
    }
    else {
        return await axiosInstance
        .put(`${url}`, skin)
        .then(
            (response) => response.data
        );
    }
    
    
}