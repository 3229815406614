// Adapted from https://dev.to/dmtrkovalenko/the-neatest-way-to-handle-alert-dialogs-in-react-1aoe

import * as React from "react";
import { AlertDialog, AlertDialogOptions } from "./AlertDialog";

const AlertDialogContext = React.createContext<(options: AlertDialogOptions) => Promise<void>>(Promise.reject);

export const useAlertDialog = () => React.useContext(AlertDialogContext);

const AlertDialogProvider = (props: { children: React.ReactNode }) => {
    const [alertDialogState, setAlertDialogState] = React.useState<AlertDialogOptions | null>(null);

    const awaitingPromiseRef = React.useRef<{
        resolve: () => void;
        reject: () => void;
    }>();

    const openAlertDialog = (options: AlertDialogOptions) => {
        setAlertDialogState(options);
        return new Promise<void>((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };

    const handleCancelClick = () => {
        if (alertDialogState?.catchOnCancel && awaitingPromiseRef.current) {
            awaitingPromiseRef.current.reject();
        }

        setAlertDialogState(null);
    };

    const handleOkCLick = () => { 
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve();
        }

        setAlertDialogState(null);
    };

    return (
        <>
            <AlertDialogContext.Provider
                value={openAlertDialog}
                children={props.children}
            />

            <AlertDialog
                open={Boolean(alertDialogState)}
                onOkClick={handleOkCLick}
                onCancelClick={handleCancelClick}
                //{...alertDialogState}
                title={alertDialogState?.title ?? ""}
                message={alertDialogState?.message ?? ""}
                destructive={alertDialogState?.destructive ?? false}
                okButtonTitle={alertDialogState?.okButtonTitle ?? ""}
                cancelButtonTitle={alertDialogState?.cancelButtonTitle ?? ""}
            />
        </>
    );
};

export default AlertDialogProvider;