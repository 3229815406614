import { MenuGroup } from "./MenuGroup";

export interface Menu {
    id: string;
    internalName: string;
    deviceClass: number
    groups: MenuGroup[]
}

export const initialMenuState: Menu = {
    id: "",
    internalName: "",
    deviceClass: 0,
    groups: []
};

export function mapMenusToKeyValues(menus: Menu[]): { key: string; value: string }[] {
    let kvps: { key: string; value: string }[] = [];

    kvps = menus.map((menu, index) => {
        return {
            key: menu.id,
            value: menu.internalName,
        };
    });

    return kvps;
}