import { useAuth } from "oidc-react";
import { useEffect, useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { useCreateAxios } from "../../hooks/useCreateAxios";
import { getCurrentUser } from "../../api/security/userApi";
import { User } from "../../models/configuration/security/User";
import NotificationMessage, { NotificationOptions } from "../common/NotificationMessage";
import { useUser } from "../../providers/UserProvider";
import axios from "axios";
import { useLocalizedStrings } from "../../localization/LocalizedStringsProvider";

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

const SignInRedirect = () => {
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const { user, setUser } = useUser();
    const axiosInstance = useCreateAxios();
    const auth = useAuth();
    const strings = useLocalizedStrings();

    useEffect(() => {
        async function getUser() {
            try {
                const response = await getCurrentUser(axiosInstance);
                setUser(response);
            } 
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.signInError.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }
        }
       
        if (auth?.userData && user.id === undefined) {
            getUser();
        }
    }, [auth?.userData?.access_token, user]);

    const pathName = window.localStorage.getItem('pathName') ?? "/"
    if (auth?.userData && user.id !== undefined) {
        return <Navigate replace to={pathName} />;
    } 
    else {
        return <div></div>;
    }
}

export default SignInRedirect;