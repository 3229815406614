import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useTheme } from '@mui/material/styles';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";
import Configuration from "./Configuration";
import ItemList from "./ItemList";
import MenuList from "./MenuList";
import OptionSetList from "./OptionSetList";
import Orders from "./Orders";

const Activities = () => {
    const strings = useLocalizedStrings();   
    const location = useLocation();
    const { checkAccess } = useUser();

    return (
        <Box sx={{ width: '100%' }}>            
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={location.pathname} ria-label="basic tabs example">                    
                    <Tab value="/roomservice/menus" label={strings.roomServiceMenus} component={Link} to="menus" />
                    <Tab value="/roomservice/items" label={strings.roomServiceItems} component={Link} to="items" />
                    <Tab value="/roomservice/options" label={strings.roomsServiceItemOptions} component={Link} to="options" />
                    <Tab value="/roomservice/orders" label={strings.roomServiceOrders} component={Link} to="orders" />
                    { checkAccess("configuration_roomservice", Access.Read | Access.Update, true) &&
                        <Tab value="/roomservice/configuration" label={strings.roomServiceConfiguration} component={Link} to="configuration" />
                    }
                </Tabs>
            </Box>            
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Routes>                    
                    <Route path="menus" element={<MenuList />} />       
                    <Route path="items" element={<ItemList />} />   
                    <Route path="options" element={<OptionSetList />} />   
                    <Route path="orders" element={<Orders />} />
                    <Route path="configuration" element={<Configuration />} />
                </Routes>
            </Box>
        </Box>
    );
};

export default Activities;
