import { AppBar, Button, Card, Dialog, Paper, PaperProps, Toolbar, Typography, styled } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles, propsToClassKey } from "@mui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { useUser } from "../../../providers/UserProvider";
import themePrimary from "../../../styles/themePrimary";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Box from '@mui/material/Box';
import { CircuitStatsByProperty } from "../../../models/dashboard/support/CircuitStatsByProperty";
import { CircuitStats } from "../../../models/dashboard/support/CircuitStats";
import TimelineIcon from '@mui/icons-material/Timeline';
import DateSearchField, { getDefaultEndDate } from "../../common/search/DateSearchField";
import SearchIcon from '@mui/icons-material/Search';
import PropertyCircuitStats from "./PropertyCircuitStats";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import AppDialog from "../../common/AppDialog";
import { CircuitDailyPeaks } from "../../../models/dashboard/support/CircuitDailyPeaks";
import { default as DataGrid } from "../../common/datatable/DataGridWrapper";
import { GridColDef } from "@mui/x-data-grid";
import DetailsDialog from "../../common/details/DetailsDialog";
import { BandwidthDateSearchFilter } from "../../../models/dashboard/network/BandwidthDateSearchFilter";
import PropertyCircuitHeader from "./PropertyCircuitHeader";
import MuiIconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";
import { getDate } from "date-fns";
import { getBandwidthGraph } from "../../../api/dashboard/network/networkMonitorApi";

const useStyles = makeStyles((theme: Theme) => ({
    draggableDialog: {
        cursor: "move",
    },
    dialogContent: {
        cursor: "default",
    },
    appBarSpacer: {
        ...themePrimary.mixins.toolbar,
    },
    propertyCard: {
        padding: themePrimary.spacing(2),
    },
    propertyHeaderAccordion: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        width: '100%',
    },
    propertyHeader: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        marginBottom: themePrimary.spacing(2),
    },
    searchBox: {
        display: 'flex',
        paddingBottom: themePrimary.spacing(2),
        width: '100%',
    },
    expanderButtons: {
        height: '36px',
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    expanderButtonsContainer: {
        flex: 8,
        display: 'flex',
        justifyContent: 'end',
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    graphContainer: {
        width: 1000,
        height: 532,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '100px',
    },
    graphLoader: {
        marginTop: '80px',
    },
}));

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:before': {
      display: 'none',
    },
    marginBottom: themePrimary.spacing(2),
}));
  
const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      {...props}
    />
  ))(({ theme }) => ({
    color: '#ffffff',
    flexDirection: 'row-reverse',
    margin: '0px !important',
    height: '24px !important',
    backgroundColor: theme.palette.grey[500],
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(2),
    },
}));
  
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface AccordionPanelExpandedState {
    panel: string;
    expanded: boolean;
}

const getDefaultSearchStartDate = (): Date => {
    var date = new Date();
    date.setDate(1);

    return date;
}

interface PropertyGraphUrl {
    propertyId: string;
    graphUrl: string;
}

const Circuit = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [dailyBreakdownDialogOpen, setDailyBreakdownDialogOpen] = useState(false);
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const [graphDialogOpen, setGraphDialogOpen] = useState(false);
    const [graphTitle, setGraphTitle] = useState("");
    const [graphUrl, setGraphUrl] = useState<string>("");
    const [propertyGraphUrls, setPropertyGraphUrls] = useState<PropertyGraphUrl[]>([]);
    const [circuitDailyPeaks, setCircuitDailyPeaks] = useState<CircuitDailyPeaks[]>([]);
    const [circuitStatsByProperty, setCircuitStatsByProperty] = useState<CircuitStatsByProperty[]>([]);
    const [selectedProperty, setSelectedProperty] = useState<string | null>(null);
    const [selectedCircuitStats, setSelectedCircuitStats] = useState<CircuitStats | null>(null);
    const [accordionPanelsExpandedState, setAccordionPanelsExpandedState] = useState<AccordionPanelExpandedState[]>([]);
    const [startDate, setStartDate] = useState<Date>(getDefaultSearchStartDate());
    const [endDate, setEndDate] = useState<Date>(getDefaultEndDate); 
    const [graphLoaded, setGraphLoaded] = useState<boolean>(false);
    const strings = useLocalizedStrings();
    const axiosInstance = useCreateAxios();
    const theme = useTheme();  
    const classes = useStyles(theme);
    const { user } = useUser();

    async function apiGetMonthlyCircuitStats() {
        try {            
             // HACK: Since most requests require property code, but we want to be able to get brand tickets as a batch. We will configure a fake property with the brand code.
             var propertyCode = "";
             if (user.currentBrand) {
                 propertyCode = user.currentBrand!.code;
             }
             else if (user.currentProperty) {
                 propertyCode = user.currentProperty!.code;
             }

             var circuitStats1: CircuitStats= {
                month: 1,
                year: 2023,
                circuitSize: "100M",
                daysInbound0To2: 10,
                daysOutbound0To2: 20,
                daysInbound2To30: 30,
                daysOutbound2To30: 40,
                daysInbound30To50: 50,
                daysOutbound30To50: 60,
                daysInbound50To70: 70,
                daysOutbound50To70: 80,
                daysInbound70To95: 90,
                daysOutbound70To95: 100,
                daysInboundOver95: 110,
                daysOutboundOver95: 120
             }

             var circuitStats2: CircuitStats = {
                month: 2,
                year: 2023,
                circuitSize: "100M",
                daysInbound0To2: 10,
                daysOutbound0To2: 20,
                daysInbound2To30: 30,
                daysOutbound2To30: 40,
                daysInbound30To50: 50,
                daysOutbound30To50: 60,
                daysInbound50To70: 70,
                daysOutbound50To70: 80,
                daysInbound70To95: 90,
                daysOutbound70To95: 100,
                daysInboundOver95: 110,
                daysOutboundOver95: 120
             }

             var stats: CircuitStats[] = []
             stats.push(circuitStats1)
             stats.push(circuitStats2)

             var prop: CircuitStatsByProperty = {
                propertyId: "1",
                propertyName: "Property 1",
                circuitStats: stats
             }

             var prop2: CircuitStatsByProperty = {
                propertyId: "2",
                propertyName: "Property 2",
                circuitStats: stats
             }

             var propArray: CircuitStatsByProperty[] = []
             propArray.push(prop)
             propArray.push(prop2)

             if (propArray.length > 1) {
                var accordionPanelStates: AccordionPanelExpandedState[] = []
                propArray.map(p => {
                    var state: AccordionPanelExpandedState = {
                        panel: p.propertyId,
                        expanded: true
                    };

                    accordionPanelStates.push(state);
                }
             );

             setAccordionPanelsExpandedState(accordionPanelStates)
            }

             setCircuitStatsByProperty(propArray)
             setIsLoading(false)

            /*const response = await getCallStats(axiosInstance, propertyCode, startDate, endDate);            
            setCallStats(response);*/
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };            
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingCallStats.replace("{{error}}", error.message),
                msgType: "error",
            });
        }
        finally {
        }
    }

    async function apiGetDailyBreakdown() {
        try {            
            var circuitDailyPeaksArray: CircuitDailyPeaks[] = []
            var circuitDailyPeaks1: CircuitDailyPeaks = {
                day: 1,
                inboundPeak: 10,
                outboundPeak: 90
            }
            var circuitDailyPeaks2: CircuitDailyPeaks = {
                day: 2,
                inboundPeak: 20,
                outboundPeak: 80
            }
            var circuitDailyPeaks3: CircuitDailyPeaks = {
                day: 3,
                inboundPeak: 30,
                outboundPeak: 70
            }
            var circuitDailyPeaks4: CircuitDailyPeaks = {
                day: 4,
                inboundPeak: 40,
                outboundPeak: 60
            }
            var circuitDailyPeaks5: CircuitDailyPeaks = {
                day: 5,
                inboundPeak: 50,
                outboundPeak: 50
            }
            var circuitDailyPeaks6: CircuitDailyPeaks = {
                day: 6,
                inboundPeak: 10,
                outboundPeak: 90
            }
            var circuitDailyPeaks7: CircuitDailyPeaks = {
                day: 7,
                inboundPeak: 20,
                outboundPeak: 80
            }
            var circuitDailyPeaks8: CircuitDailyPeaks = {
                day: 8,
                inboundPeak: 30,
                outboundPeak: 70
            }
            var circuitDailyPeaks9: CircuitDailyPeaks = {
                day: 9,
                inboundPeak: 40,
                outboundPeak: 60
            }
            var circuitDailyPeaks10: CircuitDailyPeaks = {
                day: 10,
                inboundPeak: 50,
                outboundPeak: 50
            }
            var circuitDailyPeaks11: CircuitDailyPeaks = {
                day: 11,
                inboundPeak: 40,
                outboundPeak: 60
            }
            var circuitDailyPeaks12: CircuitDailyPeaks = {
                day: 12,
                inboundPeak: 50,
                outboundPeak: 50
            }

            circuitDailyPeaksArray.push(circuitDailyPeaks1)
            circuitDailyPeaksArray.push(circuitDailyPeaks2)
            circuitDailyPeaksArray.push(circuitDailyPeaks3)
            circuitDailyPeaksArray.push(circuitDailyPeaks4)
            circuitDailyPeaksArray.push(circuitDailyPeaks5)
            circuitDailyPeaksArray.push(circuitDailyPeaks6)
            circuitDailyPeaksArray.push(circuitDailyPeaks7)
            circuitDailyPeaksArray.push(circuitDailyPeaks8)
            circuitDailyPeaksArray.push(circuitDailyPeaks9)
            circuitDailyPeaksArray.push(circuitDailyPeaks10)
            circuitDailyPeaksArray.push(circuitDailyPeaks11)
            circuitDailyPeaksArray.push(circuitDailyPeaks12)

            setCircuitDailyPeaks(circuitDailyPeaksArray)

            setDailyBreakdownDialogOpen(true)
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };            
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingCallStats.replace("{{error}}", error.message),
                msgType: "error",
            });
        }
        finally {
        }
    }

    useEffect(() => {
        apiGetMonthlyCircuitStats()
    }, [user.currentProperty?.code]);

    const getAccordionExpandedState = (panel: string): boolean => {
        var expanded: boolean = true

        var state = accordionPanelsExpandedState.find(a => a.panel === panel);
        if (state !== undefined) {
            return state!!.expanded
        }
        else {
            return expanded;
        }
    }

    const handleAccordionExpandChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        var state = accordionPanelsExpandedState.find(a => a.panel === panel);
        if (state !== null) {
            var accordionPanelStates: AccordionPanelExpandedState[] = []
            accordionPanelsExpandedState.map(p => {
                if (p.panel === panel) {
                    var state: AccordionPanelExpandedState = {
                        panel: p.panel,
                        expanded: !p.expanded
                    };

                    accordionPanelStates.push(state);
                }
                else {
                    var state: AccordionPanelExpandedState = {
                        panel: p.panel,
                        expanded: p.expanded
                    };

                    accordionPanelStates.push(state);
                }
            });
            setAccordionPanelsExpandedState(accordionPanelStates)
        }
    }

    const handleExpandAllClick = () => {
        var accordionPanelStates: AccordionPanelExpandedState[] = []
        accordionPanelsExpandedState.map(p => {
            var state: AccordionPanelExpandedState = {
                panel: p.panel,
                expanded: true
            };

            accordionPanelStates.push(state);
        });
        setAccordionPanelsExpandedState(accordionPanelStates)
    }

    const handleCollapseAllClick = () => {
        var accordionPanelStates: AccordionPanelExpandedState[] = []
        accordionPanelsExpandedState.map(p => {
            var state: AccordionPanelExpandedState = {
                panel: p.panel,
                expanded: false
            };

            accordionPanelStates.push(state);
        });
        setAccordionPanelsExpandedState(accordionPanelStates)
    }

    const handleViewDailyBreakdownClick = (stats: CircuitStats, propertyId: string, propertyName: string) => {
        setSelectedProperty(propertyName)
        setSelectedCircuitStats(stats)

        apiGetDailyBreakdown()
    }

    const handleViewLiveGraphClick = (propertyId: string, propertyName: string) => {
        setGraphLoaded(false);
        setGraphUrl("");

        if (circuitStatsByProperty.length > 1) {
            setGraphTitle(propertyName + ": " + strings.bandWidthLive);
        }
        else {
            setGraphTitle(strings.bandWidthLive);
        }
        
        setGraphDialogOpen(true);

        var propertyGraphUrl = propertyGraphUrls.find(p => p.propertyId === propertyId);

        if (propertyGraphUrl === undefined) {
            apiGetBandwidthGraph(propertyId, BandwidthDateSearchFilter.Live);
        }
        else {
            loadGraph(propertyGraphUrl!!.graphUrl, BandwidthDateSearchFilter.Live);
        }
    }

    const handleViewCustomGraphClick = (propertyId: string, propertyName: string) => {
        setGraphLoaded(false);
        setGraphUrl("");

        if (circuitStatsByProperty.length > 1) {
            setGraphTitle(propertyName + ": " + startDate.toDateString() + " - " + endDate.toDateString());
        }
        else {
            setGraphTitle(strings.bandWidthCustom);
        }

        setGraphDialogOpen(true);

        var propertyGraphUrl = propertyGraphUrls.find(p => p.propertyId === propertyId);
        
        if (propertyGraphUrl === undefined) {
            apiGetBandwidthGraph(propertyId, BandwidthDateSearchFilter.Custom);
        }
        else {
            loadGraph(propertyGraphUrl!!.graphUrl, BandwidthDateSearchFilter.Custom);
        }
    }

    const handleViewMonthlyGraphClick = (propertyId: string, propertyName: string) => {
        setGraphLoaded(false);
        setGraphUrl("");

        if (circuitStatsByProperty.length > 1) {
            setGraphTitle(propertyName + ": " + strings.bandWidthMonth);
        }
        else {
            setGraphTitle(strings.bandWidthMonth);
        }

        setGraphDialogOpen(true);

        var propertyGraphUrl = propertyGraphUrls.find(p => p.propertyId === propertyId);

        if (propertyGraphUrl === undefined) {
            apiGetBandwidthGraph(propertyId, BandwidthDateSearchFilter.Month);
        }
        else {
            loadGraph(propertyGraphUrl!!.graphUrl, BandwidthDateSearchFilter.Month);
        }
    }

    const onDateSearchFieldChangeHandler = (startDate: Date, endDate: Date) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    async function apiGetBandwidthGraph(propertyId: string, filterType: BandwidthDateSearchFilter) {
        try {
            const response = await getBandwidthGraph(axiosInstance, user.currentProperty?.code ?? "");

            var urls = propertyGraphUrls;
            urls.push({propertyId: propertyId, graphUrl: response});
            setPropertyGraphUrls(urls);

            loadGraph(response, filterType);
        } 
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setIsLoading(false);
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingBandwidthGraphs.replace("{{error}}", error.message),
                msgType: "error",
            });
        }
    }

    const handleDailyDialogCancelClick = () => {
        setDailyBreakdownDialogOpen(false)
        setSelectedProperty(null)
        setSelectedCircuitStats(null)
        setCircuitDailyPeaks([])
    }

    const getDialogTitle = (): string => {
        var dialogTitle = ""

        if (selectedProperty !== null) {
            dialogTitle += selectedProperty
        }

        if (selectedCircuitStats !== null) {
            dialogTitle += " - " + getDate(selectedCircuitStats.month, selectedCircuitStats.year)
        }

        return dialogTitle;
    }

    const getDate = (month: number, year: number): string => {
        var date = ""

        if (month === 1) {
            date = strings.january
        }
        else if (month === 2) {
            date = strings.february
        }
        else if (month === 3) {
            date = strings.march
        }
        else if (month === 4) {
            date = strings.april
        }
        else if (month === 5) {
            date = strings.may
        }
        else if (month === 6) {
            date = strings.june
        }
        else if (month === 7) {
            date = strings.july
        }
        else if (month === 8) {
            date = strings.august
        }
        else if (month === 9) {
            date = strings.september
        }
        else if (month === 10) {
            date = strings.october
        }
        else if (month === 11) {
            date = strings.november
        }
        else if (month === 12) {
            date = strings.december
        }

        return date + " " + year.toString()
    }

    function handleCancelGraphDialogClick(event: React.MouseEvent<HTMLButtonElement>) {
        setGraphDialogOpen(false);
    }

    function PaperComponent(props: PaperProps) {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={"[class*='MuiDialogContent-root']"}
            >
                <Paper {...props} />
            </Draggable>
        );
    }

    function loadGraph(url: string, filterType: BandwidthDateSearchFilter) {
        const queryParams = "&width=1000&height=500&graphstyling=showLegend%3D%271%27+baseFontSize%3D%2712%27&hide=-4";

        if (filterType === BandwidthDateSearchFilter.Live) {
            setGraphUrl(url + "&graphid=0" + queryParams)
        }
        else if (filterType === BandwidthDateSearchFilter.Day) {
            setGraphUrl(url + "&graphid=1" + queryParams)
        }
        else if (filterType === BandwidthDateSearchFilter.Month) {
            setGraphUrl(url + "&graphid=2" + queryParams)
        }
        else if (filterType === BandwidthDateSearchFilter.Year) {
            setGraphUrl(url + "&graphid=3" + queryParams)
        }
        else {
            var startDateString = startDate.toISOString();
            startDateString = startDateString.substring(0, startDateString.indexOf("T")) + "-00-00-00";

            var endDateString = endDate.toISOString();
            endDateString = endDateString.substring(0, endDateString.indexOf("T")) + "-23-59-59";
            setGraphUrl(url + "&sdate=" + startDateString + "&edate=" + endDateString + "&graphid=-1" + queryParams)
        }
    }

    const onGraphLoad = () => {
        setGraphLoaded(true);
    }

    const gridColumns: GridColDef[] = [   
        { field: "day", headerName: strings.day },     
        { field: "inboundPeak", headerName: "Inbound Peak %", flex: 4 }, 
        { field: "outboundPeak", headerName: "Outbound Peak %", flex: 4 }
    ];

    if (isLoading) {
        return <Box><LinearProgress color={"primary"} variant={"query"} /></Box>;
    }
    else {
        return (
            <Box sx={{ padding: theme.spacing(2) }}>
                <div>    
                    <div className={classes.searchBox}>
                        <DateSearchField 
                            startDate={getDefaultSearchStartDate()}
                            endDate={null}
                            format={null}
                            onChange={onDateSearchFieldChangeHandler} />
                        <Button
                            sx={{ my: 0.5, marginLeft: themePrimary.spacing(2) }}
                            variant="contained"
                            size="large"
                            disabled={false}
                            onClick={() => {}}
                            startIcon={<SearchIcon />}
                            aria-label="Search">
                            {strings.search}
                        </Button>

                        {circuitStatsByProperty.length > 1 &&
                            <div className={classes.expanderButtonsContainer}>
                                <Button
                                    className={classes.expanderButtons}
                                    sx={{ my: 0.5, marginLeft: themePrimary.spacing(2) }}
                                    variant="outlined"
                                    size="small"
                                    disabled={false}
                                    onClick={handleExpandAllClick}
                                    startIcon={<ExpandLessIcon />}
                                    aria-label="Expand All">
                                    {strings.expandAll}
                                </Button>
                                <Button
                                    className={classes.expanderButtons}
                                    sx={{ my: 0.5, marginLeft: themePrimary.spacing(2) }}
                                    variant="outlined"
                                    size="small"
                                    disabled={false}
                                    onClick={handleCollapseAllClick}
                                    startIcon={<ExpandMoreIcon />}
                                    aria-label="Collapse All">
                                    {strings.collapseAll}
                                </Button>
                            </div>
                        }
                    </div>

                    {circuitStatsByProperty.length > 1 &&
                        circuitStatsByProperty.map(p =>
                            <Accordion expanded={getAccordionExpandedState(p.propertyId)} onChange={handleAccordionExpandChange(p.propertyId)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }}/>}
                                    aria-controls="accordion-calls-summary"
                                    id="accordion-calls-summary"
                                >
                                    <PropertyCircuitHeader
                                        propertyId={p.propertyId}
                                        propertyName={p.propertyName}
                                        onViewCustomGraph={handleViewCustomGraphClick}
                                        onViewLiveGraph={handleViewLiveGraphClick} />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <PropertyCircuitStats
                                        propertyId={p.propertyId}
                                        propertyName={p.propertyName}
                                        stats={p.circuitStats}
                                        onViewDailyBreakDown={handleViewDailyBreakdownClick}
                                        onViewMonthGraph={handleViewMonthlyGraphClick} />
                                </AccordionDetails>
                            </Accordion>
                        )
                    }
                    
                    {circuitStatsByProperty.length === 1 &&
                        circuitStatsByProperty.map(p =>
                            <Box>
                                <Card variant="outlined" className={classes.propertyCard}>
                                    <PropertyCircuitHeader
                                        propertyId={p.propertyId}
                                        propertyName={p.propertyName}
                                        onViewCustomGraph={handleViewCustomGraphClick}
                                        onViewLiveGraph={handleViewLiveGraphClick} />

                                    <PropertyCircuitStats
                                        propertyId={p.propertyId}
                                        propertyName={p.propertyName}
                                        stats={p.circuitStats}
                                        onViewDailyBreakDown={handleViewDailyBreakdownClick}
                                        onViewMonthGraph={handleViewMonthlyGraphClick}  />
                                </Card>
                            </Box>
                        )
                    }

                    <AppDialog
                        open={dailyBreakdownDialogOpen}
                        title={getDialogTitle()}
                        onCancelClick={handleDailyDialogCancelClick}
                        contentSize={{ width: 626.41, height: 626.41 }}
                    >
                        <Box sx={{ padding: '20px' }}>
                            <DataGrid
                                getRowId={(row) => row.day}
                                rows={circuitDailyPeaks}
                                columns={gridColumns}
                                showAddButton={false}                                
                                height={650}
                            />
                        </Box>
                    </AppDialog>

                    <Dialog
                        scroll={"body"}
                        open={graphDialogOpen}
                        onClose={handleCancelGraphDialogClick}
                        PaperComponent={PaperComponent}
                        aria-labelledby="draggable-dialog-title"
                        PaperProps={{
                            sx: {
                                width: '1060px',
                                maxWidth: '1060px',
                                height: '626.41px',
                            },
                        }} fullWidth={true}
                        >
                        <AppBar id="draggable-dialog">
                            <Toolbar
                                disableGutters
                                sx={{ paddingLeft: "24px", paddingRight: "24px" }}
                            >
                                <Typography variant="h6" noWrap component="div">
                                    {graphTitle}
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />
                                <Box>
                                    <MuiIconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="large"
                                        edge="end"
                                        onClick={handleCancelGraphDialogClick}
                                    >
                                        <CloseIcon />
                                    </MuiIconButton>
                                </Box>
                            </Toolbar>
                        </AppBar>
                        <div className={classes.appBarSpacer}>
                            {!graphLoaded &&
                                <Box className={classes.graphLoader}><LinearProgress color={"primary"} variant={"query"} /></Box>
                            }
                            {graphUrl !== "" &&
                                <div className={classes.graphContainer}>
                                    <img src={graphUrl} alt={"Bandwidth Graph"} onLoad={onGraphLoad}/>
                                </div>
                            }
                        </div>
                    </Dialog>
                    
                    <NotificationMessage notificationState={[notify, setNotify]} />
                </div>
            </Box>
        );
    }
};

export default Circuit;