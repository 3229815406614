import { Orderable } from "../../components/common/DisplayOrderButton";
import { StringLanguageDict } from "../common/LanguageModel";
import { MenuItem } from "./MenuItem";

export interface MenuGroup extends Orderable {
    id: string;
    name: StringLanguageDict;
    displayOrder: number;
    readOnly: boolean;
    items: MenuItem[];
}

export const initialMenuGroupState: MenuGroup = {
    id: "",
    name: { en: "", es: "" },    
    displayOrder: 0,
    readOnly: false,
    items: []
};