export type ProviderType = "NONE" | "EMAIL";
export interface Options {
    taxRate: number;
    provider: ProviderType;
    email?: EmailOptions | undefined | null;
}

export interface EmailOptions {
    toAddress: string;
    fromAddress: string;
}

export const initialOptionsState: Options = {
    taxRate: 0,
    provider: "NONE"  //,
    //emailOptions: { toAddress: "", fromAddress: "" }
}

