import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import DataGrid from "../../common/datatable/DataGridWrapper";
import { GraphicManagerRefObject } from "../../common/image/GraphicManager";
import TrafficIcon from '@mui/icons-material/Traffic';
import Tooltip from "@mui/material/Tooltip";
import MuiIconButton from "@mui/material/IconButton";
import CircleIcon from '@mui/icons-material/Circle';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import RefreshIcon from '@mui/icons-material/Refresh';
import TimeSpan from "../../../utilities/classes/TimeSpan";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import { Status } from "../../../models/pms/Status";
import { getHostStatuses, restartHost, startHost, stopHost } from "../../../api/pms/controllerApi";
import axios from "axios";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";

function Controller () {    
    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [isLoading, setIsLoading] = useState(true);
    const [hosts, setHosts] = useState<Status[]>([]);
    const [gridRefresh, setGridRefresh] = useState(false);  
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const [alertDialogOpen, setAlertDialogOpen] = useState(false);
    const [selectedPropertyId, setSelectedPropertyId] = useState("");
    const [stopping, setStopping] = useState(true);
    const [forceStop, setForceStop] = useState(false);
    const strings = useLocalizedStrings();
    const axiosInstance = useCreateAxios();
    const theme = useTheme();

    useEffect(() => {
        async function load() {
            try {
                const payload = await getHostStatuses(axiosInstance);
                setHosts(payload);                
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.pmsControllerErrorRetrievingStatus.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }

            setIsLoading(false);
        }

        load();       

    }, [gridRefresh, strings.errorRetrievingBrands]);    

    async function startButtonClicked(id: string, event: React.MouseEvent<HTMLElement>) {        
        try {            
            await startHost(axiosInstance, id);            
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.pmsControllerErrorStartingHost.replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        setGridRefresh(!gridRefresh);        
        setNotify({
            isOpen: true,
            message: strings.pmsControllerStartedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function stopButtonClicked(id: string, event: React.MouseEvent<HTMLElement>) {
        setStopping(true);
        setSelectedPropertyId(id);
        setAlertDialogOpen(true);
    }

    function restartButtonClicked(id: string, event: React.MouseEvent<HTMLElement>) {
        setStopping(false);
        setSelectedPropertyId(id);
        setAlertDialogOpen(true);
    }

    function getStatus(running: boolean): ReactNode {
        return (
            running ?
            <Stack direction="row" alignItems="center" gap={1}>
                <CircleIcon  fontSize="small" color="success"/>
                {strings.running}
            </Stack> :
            <Stack direction="row" alignItems="center" gap={1}>
                <CircleIcon  fontSize="small" color="error"/>
                {strings.stopped}
            </Stack>
        )
    }

    const gridColumns: GridColDef[] = [
        { field: "running", headerName: strings.status, renderCell: (params) => getStatus(params.row.running), flex: 1 },
        { field: "propertyId", headerName: strings.property, flex: 2 },
        { field: "pmsProvider", headerName: strings.pmsProvider, flex: 2 },       
        { field: "address", headerName: strings.address, flex: 2 },      
        { field: "pendingRequestCount", headerName: strings.pendingRequests, flex: 1 },      
        { field: "uptime", headerName: strings.uptime, valueGetter: (value, row) => row.running ? new TimeSpan(row.uptime).toString("dhms") : "", flex: 1 },       
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <Tooltip title={strings.start}>
                    <MuiIconButton sx={{ color: "primary.dark" }} disabled={params.row.running} aria-label="more" component="label" onClick={(event: React.MouseEvent<HTMLElement>) => startButtonClicked(params.row.propertyId, event)}>                    
                        <PlayArrowIcon />
                    </MuiIconButton>
                </Tooltip>,
                <Tooltip title={strings.stop}>
                    <MuiIconButton sx={{ color: "primary.dark" }} disabled={!params.row.running} aria-label="more" component="label" onClick={(event: React.MouseEvent<HTMLElement>) => stopButtonClicked(params.row.propertyId, event)}>                    
                        <StopIcon />
                    </MuiIconButton>
                </Tooltip>,
                <Tooltip title={strings.restart}>
                    <MuiIconButton sx={{ color: "primary.dark" }} disabled={!params.row.running} aria-label="more" component="label" onClick={(event: React.MouseEvent<HTMLElement>) => restartButtonClicked(params.row.propertyId, event)}>                    
                        <RefreshIcon />
                    </MuiIconButton>
                </Tooltip>
            ],
        },

        //administration_pmscontroller
    ]   

    function handleForceChange(event: React.ChangeEvent<HTMLInputElement>) {
        setForceStop(event.target.checked);
    }

    function handleAlertCancelClick(event: React.MouseEvent<HTMLButtonElement>) {        
        setAlertDialogOpen(false);        
    }

    function handleAlertOkClick(event: React.MouseEvent<HTMLButtonElement>) {        
        setAlertDialogOpen(false);    
        stopRestart();
    }

    async function stopRestart() {        
        try {        
            if (stopping) {
                await stopHost(axiosInstance, selectedPropertyId, forceStop);    
            }
            else {
                await restartHost(axiosInstance, selectedPropertyId, forceStop); 
            }                    
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: (stopping ? strings.pmsControllerErrorStoppingHost : strings.pmsControllerErrorRestartingHost).replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        setGridRefresh(!gridRefresh);        
        setNotify({
            isOpen: true,
            message: stopping ? strings.pmsControllerStoppedSuccessfully : strings.pmsControllerRestartedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 64px)" }}> 
            <DataGrid
                getRowId={(row) => row.propertyId} 
                permissionKey="administration_pmscontroller"
                rows={hosts}
                columns={gridColumns}   
                showAddButton={false}
            />

            <Dialog
                open={alertDialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {stopping ? strings.stopTitle : strings.restartTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div dangerouslySetInnerHTML={{ __html: (stopping ? strings.stopMessage : strings.restartMessage).replace("{{name}}", selectedPropertyId) }}/>                              
                    </DialogContentText>
                    <FormControlLabel
                        value="force"
                        control={
                            <Checkbox                                    
                                onChange={handleForceChange}
                                checked={forceStop}
                            />
                        }
                        label={strings.force}                            
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAlertCancelClick}>{strings.cancelButtonTitle}</Button>
                    <Button onClick={handleAlertOkClick} color={"error"}>{stopping ? strings.stopButtonTitle : strings.restartButtonTitle}</Button>
                </DialogActions>
            </Dialog>

            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    );    
};

export default Controller;
