import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from '@mui/material/styles';
import { GridColDef, GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { getAutomatedMessages, createAutomatedMessage, deleteAutomatedMessage } from "../../../api/messaging/automatedMessagesApi";
import { getTemplates } from "../../../api/messaging/templatesApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { AutomatedMessage as AutomatedMessageModel, initialAutomatedMessageState } from "../../../models/modules/messaging/AutomatedMessage";
import { mapTemplatesToKeyValues, Template as TemplateModel } from "../../../models/modules/messaging/Template";
import { useUser } from "../../../providers/UserProvider";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import { default as DataGrid } from "../../common/datatable/DataGridWrapper";
import { default as DetailsDialog } from "../../common/details/DetailsDialog";
import { default as DialogSelect } from "../../common/details/Select";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import { Type as ValidationType, validate } from "../../../utilities/Validator";
import RttIcon from '@mui/icons-material/Rtt';
import ImageIcon from '@mui/icons-material/Image';
import VideocamIcon from '@mui/icons-material/Videocam';

interface ValidationErrors {
    trigger: string,
    templateId: string;      
}

const Automated = () => {
    const initialErrorState: ValidationErrors = {
        trigger: "",
        templateId: ""        
    }

     const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [gridRefresh, setGridRefresh] = useState(false);
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);
    const [messages, setMessages] = useState<AutomatedMessageModel[]>([]);
    const [templates, setTemplates] = useState<TemplateModel[]>([]);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState<AutomatedMessageModel>(initialAutomatedMessageState);
    const [isLoading, setIsLoading] = useState(true);        
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);    
    const alertDialog = useAlertDialog();
    const strings = useLocalizedStrings();
    const theme = useTheme();    
    const axiosInstance = useCreateAxios();
    const { user } = useUser();  

    useEffect(() => {
        async function loadMessages() {            
            try {
                const payload = await getAutomatedMessages(axiosInstance, user.currentProperty?.code ?? "");
                setMessages(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingAutomatedMessages.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }
                        
            try {
                const payload = await getTemplates(axiosInstance, user.currentProperty?.code ?? "");
                setTemplates(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingTemplates.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }

            setIsLoading(false);            
        }

        loadMessages();
    }, [gridRefresh, user.currentProperty?.code, strings.errorRetreivingAutomatedMessages, strings.errorRetreivingTemplates]);
 
    function handleNewMessageClick(event: React.MouseEvent<HTMLButtonElement>) {
        setSelectedMessage(initialAutomatedMessageState);
        setDetailsDialogOpen(true);
    }

    function handleDeleteClick(id: string) {
        const split = id.split("_");
        const trigger = split[0];
        const templateId = split[1];
        const internalName = messages.find(m => m.trigger === trigger && m.template.id === templateId)?.template.internalName ?? "";

        alertDialog({
            title: strings.deleteAutomatedMessageAlertTitle,
            message: strings.deleteAutomatedMessageAlertMessage,
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedMessage(trigger, templateId);
        });
    }

    async function deleteSelectedMessage(trigger: string, templateId: string) {
        try {
            await deleteAutomatedMessage(axiosInstance, user.currentProperty?.code ?? "", trigger, templateId);
        }                
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingAutomatedMessage.replace("{{error}}", error.message),
                msgType: "error",
            });       

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.automatedMessageDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);        
        setSelectedMessage(initialAutomatedMessageState);
    }

    function buildNameField(template: TemplateModel) {
        return (
            <>
                <span style={{ marginRight: theme.spacing(1) }}>{getIcon(template.type)}</span>
                <span>{template.internalName}</span>
            </>
        );
    }

    const gridColumns: GridColDef[] = [        
        { field: "trigger", headerName: strings.trigger, valueGetter: (value, row) => strings.getString(`trigger${row.trigger}`), flex: 1 },
        { field: "templateName", headerName: strings.messageTemplateName, renderCell: (params) => buildNameField(params.row.template), flex: 1 },        
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridDeleteButton
                    permissionKey="content_messaging"
                    rowId={params.id.toString()}
                    clickHandler={(id: string, event: React.MouseEvent<HTMLButtonElement>) => handleDeleteClick(params.id.toString())}
                />
            ],
        },
    ];

    function handleTriggerChange(event: SelectChangeEvent) {       
        setSelectedMessage((prevState) => ({
            ...prevState,
            trigger: event.target.value as string
        }));
    }

    function handleTemplateChange(event: SelectChangeEvent) {       
        setSelectedMessage((prevState) => ({
            ...prevState,
            template: {
                ...prevState.template,
                id: event.target.value as string
            }
        }));
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);
        setDetailsDialogOpen(false);        
    }

    function handleValidate() {
        var errors = validate<AutomatedMessageModel, ValidationErrors>([
            { property: "trigger", type: ValidationType.Required, message: strings.validationAutomatedMessageTrigger },
            { property: "template.id", type: ValidationType.Required, message: strings.validationAutomatedMessageTemplate },            
        ], selectedMessage);
        if (errors) {
            setErrors(errors);
            return false;
        }
        else {
            return true;
        }        
    }

    async function handleSaveClick() {
        setErrors(initialErrorState);

        try {
            await createAutomatedMessage(axiosInstance, user.currentProperty?.code ?? "", selectedMessage.trigger, selectedMessage.template.id);            
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorCreatingAutomatedMessage.replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        setDetailsDialogOpen(false);
        setGridRefresh(!gridRefresh);
        setNotify({
            isOpen: true,
            message: strings.automatedMessageSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }    

    function getIcon(type: string) {
        switch (type) {            
            case "Image":
                return (<ImageIcon fontSize="small" />);
            case "Video":
                return (<VideocamIcon fontSize="small" />);
            case "Text":
            default:
                return (<RttIcon fontSize="small" />);
        }
    }

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 171px)" }}>            
            <DataGrid
                permissionKey="content_messaging"
                rows={messages}
                getRowId={(row) => `${row.trigger}_${row.template.id}`}
                columns={gridColumns}
                onAddButtonClick={handleNewMessageClick}
                addButtonText={strings.newMessage}
                autoPageSize={true}
                loading={isLoading}
            />               

            <DetailsDialog
                permissionKey="content_messaging"
                adding={true}
                open={detailsDialogOpen}
                title={strings.newMessage}
                onValidateForm={handleValidate}
                onCancelClick={handleCancelClick}
                onSaveClick={handleSaveClick}
                contentSize={{ width: 400, height: 179.81 }}
            >
                <Box sx={{ padding: theme.spacing(2) }}>
                    <DialogSelect
                        label="Trigger"
                        keyValues={[{"key":"Checkin","value":strings.triggerCheckin}]}
                        selectedValue={selectedMessage?.trigger ?? ""}
                        onChangeHandler={handleTriggerChange}
                        sx={{ minWidth: 200 }}                                
                        error={Boolean(errors.trigger)}
                        helperText={strings.validationAutomatedMessageTrigger}
                    />     
                    <Spacer />
                    <DialogSelect
                        label={strings.messageTemplate}
                        keyValues={mapTemplatesToKeyValues(templates.filter(t => t.enabled === true)).map(kvp => {
                            return {
                                key: kvp.key,
                                value: kvp.value.name,
                                icon: getIcon(kvp.value.type)
                            };
                        })}
                        selectedValue={selectedMessage?.template.id ?? ""}
                        onChangeHandler={handleTemplateChange}
                        sx={{ minWidth: 200 }}                                
                        error={Boolean(errors.templateId)}
                        helperText={strings.validationAutomatedMessageTemplate}
                    />                 
                </Box>
            </DetailsDialog>

            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>           
    )    
}

export default Automated;