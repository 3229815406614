import * as React from "react";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import DataGrid from "../../common/datatable/DataGridWrapper";
import { GridColDef } from "@mui/x-data-grid";
import LinearProgress from '@mui/material/LinearProgress';
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { Button, Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import themePrimary from "../../../styles/themePrimary";
import DateSearchField, { getDefaultEndDate } from "../../common/search/DateSearchField"
import SearchIcon from '@mui/icons-material/Search';
import { useUser } from "../../../providers/UserProvider";
import axios from "axios";
import { getCheckoutEvents } from "../../../api/reporting/itv/reportingApi";
import { CheckoutEvent } from "../../../models/reporting/itv/CheckoutEvent";
import { GridToolbarExport } from '@mui/x-data-grid';
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
    searchBox: {
        display: 'flex',
        paddingBottom: themePrimary.spacing(2),
        width: '100%',
    }
}));

const getDefaultSearchStartDate = (): Date => {
    var date = new Date();
    date.setMonth(0, 1);

    return date;
}

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

function CheckoutEvents() {
    const [isLoading, setIsLoading] = React.useState(true);
    const [startDate, setStartDate] = React.useState<Date>(getDefaultSearchStartDate());
    const [endDate, setEndDate] = React.useState<Date>(getDefaultEndDate); 
    const [viewData, setViewData] = React.useState<CheckoutEvent[]>([]); 
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);
    const strings = useLocalizedStrings();
    const axiosInstance = useCreateAxios();
    const { user } = useUser();
    const classes = useStyles(); 
    const theme = useTheme();

    React.useEffect(() => {
        async function apiGetCheckoutEvents() {
            try {
                const payload = await getCheckoutEvents(axiosInstance, user?.currentProperty?.code ?? "", startDate, endDate);
                setViewData(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };            
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingCheckoutEvents.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }
            finally {
                setIsLoading(false);
            }
        }

        if (isLoading) {
            apiGetCheckoutEvents();
        }
           
    }, [user.currentProperty?.code, isLoading])

    function onSearchClick() {
        setIsLoading(true);
    }

    function onDateSearchFieldChangeHandler (startDate: Date, endDate: Date) {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    const gridColumns: GridColDef[] = [        
        { field: 'moduleName', headerName: strings.reportingModuleName, flex: 1 },
        { field: 'viewCount', headerName: strings.reportingViewCount, flex: 1 }
    ];

    return (
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 201px)" }}> 
            <Box className={classes.searchBox}>
                <DateSearchField 
                    startDate={getDefaultSearchStartDate()}
                    endDate={null}
                    format={null}
                    onChange={onDateSearchFieldChangeHandler} />

                <Button
                    sx={{ marginLeft: themePrimary.spacing(2) }}
                    variant="contained"
                    size="large"
                    disabled={false}
                    onClick={onSearchClick}
                    startIcon={<SearchIcon />}
                    aria-label="Search">
                    {strings.search}
                </Button>
            </Box>
            <DataGrid 
                permissionKey="reporting_itv"
                showAddButton={false}
                columns={gridColumns} 
                getRowId={(row) => `${row.moduleId}-${row.moduleName}`} 
                rows={viewData}                 
                loading={isLoading} 
                slots={
                    { toolbar: GridToolbarExport }
                } 
                slotProps={
                    { toolbar: {fileName: strings.reportingCheckoutEventsTabLabel.replace(" ", "")} }
                } 
            />

            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    );
};

export default CheckoutEvents; 