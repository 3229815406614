import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useHistory } from "react-router";
import themePrimary from "../../styles/themePrimary";
import { signIn, signOut } from "../../utilities/auth/AuthRedirect";
import HomeSupportInfo from "./HomeSupportInfo";
import { useLocalizedStrings } from "../../localization/LocalizedStringsProvider";
import BackgroundImage from "../../images/Digimanager_BG.png";
import { useAuth } from "oidc-react";

const useStyles = makeStyles({
    homePageWidget: {
        //marginBottom: "20px",
        //width: "80%",
        //marginLeft: "auto",
        //marginRight: "auto",
    },
    root: {
        padding: "20px",
        paddingTop: "0px",        
        minHeight: "calc(100vh - 64px)",
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "top right",
    },
    signInButton: {
        color: themePrimary.palette.primary.contrastText,
        backgroundColor: themePrimary.palette.primary.main,
    },
    titleContainer: {
        paddingTop: "2em",
        paddingBottom: "2em",
        width: "100%",
    },
    loginNewsGrid: {
        display: "grid",
        gridTemplateColumns: "35% 65%",
        marginBottom: "20px",
    },
    lastLoginWidget: {
        marginRight: "10px",
    },
    newsWidget: {
        marginLeft: "10px",
    },
});

const Home = () => {
    const classes = useStyles();
    const strings = useLocalizedStrings();
    const auth = useAuth();

    return (
        <div className={classes.root}>
            <div className={classes.titleContainer}>
                <Typography variant="h3">{strings.homeTitle}</Typography>
                {(!auth || !auth.userData) &&
                    <Typography variant="h6">{strings.homeSignIn}</Typography>
                }
            </div>
        </div>
    );
};

export default Home;
