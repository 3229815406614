import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import { useLocalizedStrings } from "../../../../localization/LocalizedStringsProvider";
import AdminRoleList from "./AdminRoleList";
import AdminUserList from "./AdminUserList";
import PermissionCategoryList from "./PermissionCategoryList";
import PermissionTypeList from "./PermissionTypeList";
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const AdminSecurityManager = () => {
    const strings = useLocalizedStrings();
    const location = useLocation();
    const theme = useTheme();

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={location.pathname} aria-label="basic tabs example">
                    <Tab value="/security/admin/users" label={strings.usersTabLabel} component={Link} to="users" />
                    <Tab value="/security/admin/roles" label={strings.rolesTabLabel} component={Link} to="roles" />
                    <Tab value="/security/admin/categories" label={strings.permissionCategoryTabLabel} component={Link} to="categories" />
                    <Tab value="/security/admin/categorytypes" label={strings.permissionTypesTabLabel} component={Link} to="categorytypes" />
                </Tabs>
            </Box>
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Routes>
                    <Route path="users" element={<AdminUserList />} />
                    <Route path="roles" element={<AdminRoleList />} />
                    <Route path="categories" element={<PermissionCategoryList />} />
                    <Route path="categorytypes" element={<PermissionTypeList />} />
                </Routes>
            </Box>
        </Box>
    );
};

export default AdminSecurityManager;