import { default as ReactValidator } from 'validator';

export enum Type {
    Required,    
    Email,
    Phone,
    Alpha,
    Numeric,
    MACAddress,
    URL,
    Pattern,
    Custom
}

export interface Field {
    property: string;
    type: Type;    
    pattern?: RegExp;
    custom?: (value: string) => boolean;    
    message: string;
    errorProperty?: string
}

export function validate<T1 extends Record<string, any>, T2 extends Record<string, any>>(fields: Field[], model: T1): T2 | null {    
    var errors: Record<string, any> = {};    
    for (var i = 0; i < fields.length; i++) {
        let field = fields[i];        
        const split = field.property.split('.');
        let value: string;        

        if (split.length > 1) {
            // If we are validating a stringlanguage field            
            if (!model[split[0]]) {
                value = "";
            }
            else {
                value = model[split[0]][split[1]];
            }            
        }
        else {
            value = model[field.property];
        }
        
        // Check if the field is valid
        var valid: boolean;

        if (value === undefined || value === null) {
            valid = false;
        }
        else {            
            switch (+field.type) {
                case Type.Required:
                    if (typeof value === 'string') {
                        valid = value.trim() !== "";                    
                    }      
                    else if (Array.isArray(value)) {
                        valid = (value as Array<any>).length > 0;                    
                    }
                    else {
                        valid = true;                    
                    }
                    break;
                case Type.Email:
                    valid = ReactValidator.isEmail(value);
                    break;
                case Type.Phone:
                    valid = ReactValidator.isMobilePhone(value);
                    break;
                case Type.Alpha:
                    valid = ReactValidator.isAlpha(value);
                    break;
                case Type.Numeric:
                    valid = ReactValidator.isNumeric(value);
                    break;
                case Type.MACAddress:
                    valid = ReactValidator.isMACAddress(value);
                    break;
                case Type.URL:                           
                    valid = ReactValidator.isURL(value);                    
                    break;
                case Type.Pattern:
                    if (field.pattern) {
                        valid = field.pattern.test(value);
                    }
                    else {
                        throw "Validation pattern is required.";
                    }
                    break;
                case Type.Custom:
                    if (field.custom) {
                        valid = field.custom(value);
                    }
                    else {
                        throw "Validation custom callback is required."
                    }
                    break;
                default:
                    valid = value.trim() !== "";
                    break;
            }
        }

        // Add field to errors
        if (!valid) {
            errors[field.errorProperty ?? split[0]] = field.message;
        }
    }

    return Object.keys(errors).length > 0 ? errors as T2 : null;
}