import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import Sent from "./Sent";
import InReview from "./InReview";
import Scheduled from "./Scheduled";
import Templates from "./Templates";
import Automated from "./Automated";
import { useUser } from "../../../providers/UserProvider";
import { Access } from "../../../models/configuration/security/Permission";
import { getInReviewMessages } from "../../../api/messaging/messagesApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import axios from "axios";
import NewReleasesIcon from '@mui/icons-material/NewReleases';

const Messaging = () => {
    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const strings = useLocalizedStrings();   
    const location = useLocation();
    const theme = useTheme();
    const { user, checkAccess } = useUser();
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);
    const axiosInstance = useCreateAxios();
    const [hasInReviewMessages, setHasInReviewMessages] = React.useState(false);
    const [gridRefresh, setGridRefresh] = React.useState(false);

    React.useEffect(() => {
        async function checkForMessages() {
            try {
                const payload = await getInReviewMessages(axiosInstance, user.currentProperty?.code ?? "");
                setHasInReviewMessages(payload.length > 0);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingMessages.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }            
        }

        if (checkAccess("content_messaging_review", Access.Read, true)) {
            checkForMessages();
        }
    }, [gridRefresh, user.currentProperty?.code, strings.errorRetreivingMessages]);

    return (
        <Box sx={{ width: '100%' }}>            
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={location.pathname} ria-label="basic tabs example">                    
                    <Tab value="/messaging/sent" label={strings.sent} component={Link} to="sent" />                    
                    {checkAccess("content_messaging_review", Access.Read, true) &&
                        hasInReviewMessages ?
                        <Tab value="/messaging/inreview" label={strings.inReview} component={Link} to="inreview" icon={<NewReleasesIcon />} iconPosition="end" sx={{ minHeight: "48px" }} /> :
                        <Tab value="/messaging/inreview" label={strings.inReview} component={Link} to="inreview" />
                    }                    
                    <Tab value="/messaging/scheduled" label={strings.scheduledMessages} component={Link} to="scheduled" />                                        
                    <Tab value="/messaging/automated" label={strings.automatedMessages} component={Link} to="automated" />                    
                    <Tab value="/messaging/templates" label={strings.messageTemplates} component={Link} to="templates" />                    
                </Tabs>
            </Box>            
            <Box component="main" sx={{ flexGrow: 1, padding: 0 }}>
                <Routes>
                    <Route path="sent" element={<Sent onGridRefresh={() => { setGridRefresh(!gridRefresh); }} />} />
                    <Route path="inreview" element={<InReview onGridRefresh={() => { setGridRefresh(!gridRefresh); }} />} />
                    <Route path="scheduled" element={<Scheduled />} />                    
                    <Route path="automated" element={<Automated />} />
                    <Route path="templates" element={<Templates />} />
                </Routes>
            </Box>
        </Box>
    );
};

export default Messaging;
