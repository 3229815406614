import { AxiosInstance } from "axios";
import {Language, LanguageResponse} from "../models/configuration/settings/Language";
import { parseLanguageStringsFromJson } from "../utilities/JsonHelper";

const URL = process.env.REACT_APP_MANAGER_API_URL + `/property-management/`;

export function getLanguageGridData(axiosInstance: AxiosInstance, propertyCode: string) : Promise<LanguageResponse> {
    return axiosInstance
        .get(`${URL}${propertyCode}/languages`)
        .then(res => parseLanguageStringsFromJson(res.data) as LanguageResponse)
}

export function getEnabledLanguages(axiosInstance: AxiosInstance, propertyCode: string) : Promise<LanguageResponse> {
    return axiosInstance
        .get(`${URL}${propertyCode}/languages?enabledOnly=true`)
        .then(res => parseLanguageStringsFromJson(res.data) as LanguageResponse)
}

export function getLanguageById(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return axiosInstance.get(`${URL}${propertyCode}/languages/${id}`)
        .then((response) => parseLanguageStringsFromJson(response.data) as Language);
}

export async function updateLanguage(axiosInstance: AxiosInstance, propertyCode: string, language: Language) {
    const languageToSave = {
        ...language,                  
        name: JSON.stringify({ ...language.name })             
    };

    await axiosInstance.put(`${URL}${propertyCode}/languages/${language.id}`, languageToSave);
}