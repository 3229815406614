import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { default as MaterialSelect, SelectChangeEvent } from "@mui/material/Select";
import * as React from "react";
import { useDialog } from "./DetailsDialog";
import { SxProps } from '@mui/system';
import { Theme } from "@mui/material/styles"
import FormHelperText from  "@mui/material/FormHelperText";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";
import { useTheme } from '@mui/material/styles';

export type SelectChangeHandler = (
    event: SelectChangeEvent
) => any;

export interface SelectProps {
    id?: string;
    label: string;
    selectedValue: string | undefined;
    keyValues: { key: string; value: string, icon?: JSX.Element }[];
    onChangeHandler: SelectChangeHandler;
    sx?: SxProps<Theme>;
    disabled?: boolean;
    error?: boolean;
    helperText?: React.ReactNode;
    permissionKey?: string;
}

const Select = (props: SelectProps) => {
    const {
        label, 
        selectedValue,
        keyValues,
        onChangeHandler,
        id = "selectDefault",
        sx,
        disabled,
        error,
        helperText
    } = props;
    const [hasChanged, setHasChanged] = React.useState<boolean>(false);
    //const [value, setValue] = React.useState<string>(selectedValue);
    const { dirty, setDirty, adding, permissionKey } = useDialog();
    const { checkAccess } = useUser();
    const [readOnly] = React.useState(!checkAccess(props.permissionKey ?? permissionKey, adding ? Access.Create : Access.Update));
    const theme = useTheme();

    //React.useEffect(() => {
    //    setValue(selectedValue);
    //}, [selectedValue]);

    const handleChange = (event: SelectChangeEvent) => {
        setHasChanged(true);
        //setValue(event.target.value as string);
        setDirty(true);
        onChangeHandler(event);
    };

    function getHelperText() {
        if (!helperText || !error) {
            return " ";
        }
        else {
            return helperText;
        }
    }

    return (
        <div>
            <FormControl variant="outlined" error={error}>
                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                <MaterialSelect
                    id={id}
                    aria-labelledby={"select-label-" + label}
                    label={label}
                    value={selectedValue}
                    onChange={handleChange}
                    disabled={disabled || readOnly}
                    sx={sx}
                    error={error}                    
                >
                    {/*{!hasChanged && selectedValue === "-1" && (*/}
                    {/*  <option value="-1">Select {label}</option>*/}
                    {/*)}*/}
                    {keyValues.map((kvp, index) => {
                        if (kvp.icon) {
                            return (
                                <MenuItem key={kvp.key} value={kvp.key}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {kvp.icon}
                                        <div style={{ marginLeft: theme.spacing(1) }}>{kvp.value}</div>
                                    </div>                                   
                                </MenuItem>
                            )
                        }
                        else {
                            return (<MenuItem value={kvp.key}>{kvp.value}</MenuItem>);
                        }
                    })}
                </MaterialSelect>
                <FormHelperText>{getHelperText()}</FormHelperText>
            </FormControl>
        </div>
    );
};

export default Select;
