import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as React from "react";
import { useSelectedLanguage } from "./LanguageForm";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import FormHelperText from "@mui/material/FormHelperText";
import { getEnabledLanguages } from "../../../api/languageApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useUser } from "../../../providers/UserProvider";
import { mapLanguagesToKeyValues } from '../../../models/configuration/settings/Language';

const LanguageSelect = () => {
    const { selectedLanguage, setSelectedLanguage } = useSelectedLanguage();
    const [keyValues, setKeyValues] = React.useState<{ key: string; value: string }[]>([]);
    const strings = useLocalizedStrings();
    const axiosInstance = useCreateAxios();
    const {user, checkAccess} = useUser();

    React.useEffect(() => {
        var propertyLanguages = mapLanguagesToKeyValues(user.currentProperty?.languages ?? []);
        if (propertyLanguages && propertyLanguages.length > 0) {
            setKeyValues(propertyLanguages);
        }
        else {
            const allLanguages = [ // TODO: get this from server (initial selection should be default langauge)?
                { key: "en", value: strings.getString("en") },
                { key: "es", value: strings.getString("es") },
            ];
            setKeyValues(allLanguages);
        }
    }, [])

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedLanguage(event.target.value as string);
    };    

    return (
        <FormControl>
            <InputLabel id="demo-simple-select-label">{strings.language}</InputLabel>
            <Select
                aria-labelledby={"select-label-langauge"}
                label="Language"
                value={selectedLanguage}
                onChange={handleChange}
                variant="outlined"
            >
                {keyValues.map((kvp, index) => (
                    <MenuItem key={index} value={kvp.key}>{kvp.value}</MenuItem>
                ))}
            </Select>
            <FormHelperText color="error"> </FormHelperText>
        </FormControl>
    );
}

export default LanguageSelect;
