import Box from "@mui/material/Box";
import LinearProgress from '@mui/material/LinearProgress';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useTheme } from "@mui/material/styles";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import * as React from "react";
import { getRoomsForTransferList } from "../../../api/roomApi";
import {
    createRoomClass,
    deleteRoomClass,
    getRoomClassById,
    getRoomClassGridData,
    updateRoomClass,
} from "../../../api/roomClassApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Access } from "../../../models/configuration/security/Permission";
import { RoomClass, RoomClassDetail, initialItemState } from "../../../models/modules/roommanager/RoomClass";
import { useUser } from "../../../providers/UserProvider";
import { Field as ValidationField, Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import DataGrid from "../../common/datatable/DataGridWrapper";
import Checkbox from "../../common/details/Checkbox";
import DetailsDialog from "../../common/details/DetailsDialog";
import TextField from "../../common/details/TextField";
import TransferList from "../../common/details/TransferList";

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

interface RoomClassValidationErrors {
    name: string;
}

const initialErrorState : RoomClassValidationErrors = {
    name: ""
}
function RoomClassList() {
    const [roomClasses, setRoomClasses] = React.useState<RoomClass[]>([]);
    const [detailsDialogOpen, setDetailsDialogOpen] = React.useState(false);
    const [errors, setErrors] = React.useState<RoomClassValidationErrors>(initialErrorState);
    const [selectedItem, setSelectedItem] = React.useState<RoomClass>(initialItemState);
    const [tabValue, setTabValue] = React.useState(0);
    const [selectedRoomClassId, setSelectedRoomClassId] = React.useState("");
    const [allRooms, setAllRooms] = React.useState<{ id: string, number: string }[]>([]);
    const [roomDeltaChanges, setRoomDeltaChanges] = React.useState<{ id: string; action: "add" | "remove"; }[]>([]);
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);
    const [gridRefresh, setGridRefresh] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const strings = useLocalizedStrings();
    const alertDialog = useAlertDialog();
    const axiosInstance = useCreateAxios();
    const { user, checkAccess } = useUser();
    const [readOnly] = React.useState(!checkAccess("configuration_property", Access.Update));
    const theme = useTheme();

    React.useEffect(() => {
        async function getRoomClasses() {
            try {
                setIsLoading(true);
                const response = await getRoomClassGridData(axiosInstance, user.currentProperty?.code ?? "");
                setRoomClasses(response.roomClasses);
            }
            catch (e) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingRoomClasses,
                    msgType: "error",
                });
            }
            finally {
                setIsLoading(false);
            }
        }

        getRoomClasses();
    }, [gridRefresh, user.currentProperty?.code, strings.errorRetrievingRoomClasses]);

    React.useEffect(() => {
        async function getAllRooms() {
            try {
                const roomData = await getRoomsForTransferList(axiosInstance, user.currentProperty?.code ?? "");
                setAllRooms(roomData);
            }
            catch (e) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingRooms,
                    msgType: "error",
                });
            }
        }
        getAllRooms();
    }, [])

    async function handleEditRowClick(id: string) {
        setSelectedRoomClassId(id);

        try {
            const roomClassItem = await getRoomClassById(axiosInstance, user.currentProperty?.code ?? "", id);
            setSelectedItem(roomClassItem ?? initialItemState);
            setDetailsDialogOpen(true);
        }
        catch (error: unknown) {
            const axiosErrorObject = axios.isAxiosError(error)
                ? { message: error.message }
                : { message: "unable to parse error info." };
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingItem
                    .replace("{{error}}", axiosErrorObject.message),
                msgType: "error",
            });
        }
    }

    async function deleteSelectedRoomClass(id: string) {
        try {
            await deleteRoomClass(axiosInstance, user.currentProperty?.code ?? "", id);
            setRoomClasses(roomClasses.filter((item) => item.id !== id));
            setNotify({
                isOpen: true,
                message: strings.roomClassDeletedSuccessfully,
                msgType: "success",
            });
        }
        catch (error: unknown) {
            const axiosErrorObject = axios.isAxiosError(error)
                ? { message: error.message }
                : { message: "unable to parse error info." };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingItem
                    .replace("{{error}}", axiosErrorObject.message),
                msgType: "error",
            });
        }
    }

    function handleDeleteClick(id: string) {
        const displayName = roomClasses.find(roomClass => roomClass.id === id)?.name ?? "";

        alertDialog({
            title: strings.deleteRoomClassAlertTitle,
            message: strings.deleteRoomClassAlertMessage.replace("{{name}}", displayName),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedRoomClass(id);
        });
    }

    function handleCancelClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setErrors(initialErrorState);
        setRoomDeltaChanges([]);
        setDetailsDialogOpen(false);
        setTabValue(0);
    }

    async function handleSaveClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setErrors(initialErrorState);

        const detailChanges = (({ id, name, enabled }) => ({ id, name, enabled }))(selectedItem) as RoomClassDetail;
        const isUpdate = selectedRoomClassId.trim().length > 0;
        try {
            if (isUpdate) {
                await updateRoomClass(axiosInstance, user.currentProperty?.code ?? "", detailChanges, roomDeltaChanges);
            } else {
                await createRoomClass(axiosInstance, user.currentProperty?.code ?? "", detailChanges, roomDeltaChanges);
            }

            setRoomDeltaChanges([]);

        } catch (err: unknown) {
            const error = axios.isAxiosError(err)
                ? { message: err.message }
                : { message: "unable to parse error info" };

            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.errorAddingItem
                    : strings.errorUpdatingItem
                ).replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        setDetailsDialogOpen(false);
        setTabValue(0);
        setGridRefresh(!gridRefresh);
        setNotify({
            isOpen: true,
            message: strings.roomClassSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleValidateRoomClass() {
        var fieldsToValidate: ValidationField[] = [{
            property: "name",
            type: ValidationType.Required,
            message: strings.validationErrorRoomClassNameRequired
          }];
              
          var errors = validate<RoomClassDetail, RoomClassValidationErrors>(fieldsToValidate, selectedItem);
          if (errors) {
            setErrors(errors);
            return false;
          }

         return true;
    }

    function handleNameChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSelectedItem((current: RoomClass) => ({
            ...current,
            name: e.currentTarget.value,
        }));
    }

    function handleAddClick() {
        setSelectedRoomClassId("");
        setSelectedItem(initialItemState);
        setDetailsDialogOpen(true);
    }

    function handleEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedItem((prevState) => ({
            ...prevState,
            enabled: event.target.checked,
        }));
    }

    function handleTabChange(event: React.SyntheticEvent<Element, Event>, newValue: any) {
        setTabValue(newValue);
    };

    function handleRoomAssignmentChange(values: { key: string; value: string }[]) {
        // required prop, but might want to make this and delta mutually exclusive via type alias
    }

    function handleRoomDelta(values: { key: string; value: string, action: "add" | "remove" }[]) {
        const deltas = values.map(({ key, action }) => ({ id: key, action }));
        setRoomDeltaChanges(deltas);
    }

    function mapRoomsToKeyValues(values: { id: string; number: string; }[]): { key: string; value: string; }[] {
        return values.map((item) => ({
            key: item.id,
            value: item.number
        }))
    }

    const gridColumns: GridColDef[] = [
        { field: "name", headerName: strings.name, flex: 1 },
        { field: "enabled", headerName: strings.enabled, type: "boolean", flex: 1 },
        //{ field: "externalKey", headerName: strings.externalId, width: 600 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton 
                    permissionKey="configuration_property" 
                    rowId={params.id.toString()} 
                    clickHandler={() => handleEditRowClick(params.id.toString())} 
                    //clickHandler={() => console.log('row in edit click is ' + JSON.stringify(params.row.name, null, 2))}
                />,
                <DataGridDeleteButton
                    permissionKey="configuration_property"
                    rowId={params.id.toString()}
                    clickHandler={() => handleDeleteClick(params.id.toString())}
                />,
            ],
        },
    ];

    const dialogTitle = selectedRoomClassId === "" ? strings.roomManagerClassTitleAdd : strings.roomManagerClassTitleEdit.replace("{{name}}", selectedItem?.name ?? "");

    return (    
        <Box sx={{ padding: theme.spacing(2), height: "calc(100vh - 171px)" }}>
            <DataGrid
                permissionKey="configuration_property"
                columns={gridColumns}
                rows={roomClasses}
                onAddButtonClick={handleAddClick}
                addButtonText={strings.addRoomClassButtonText}   
                loading={isLoading}
            />
            <DetailsDialog
                permissionKey="configuration_property"
                adding={selectedRoomClassId.length === 0}
                open={detailsDialogOpen}
                title={dialogTitle}
                onCancelClick={handleCancelClick}
                onSaveClick={handleSaveClick}
                onValidateForm={handleValidateRoomClass}
                contentSize={{ width: 600, height: 454.98 }}
            >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label={strings.detailsTabTitle} />
                        <Tab label={strings.roomManagerClassRoomsTabTitle} />
                    </Tabs>
                </Box>
                <TabPanelKeepMounted value={tabValue} index={0}>
                    <TextField
                        id="roomclass-name"
                        label={strings.name}
                        width={200}
                        disabled={false}
                        value={selectedItem.name}
                        onChange={handleNameChange}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                    />
                    <Spacer />
                    <Checkbox
                        label={strings.enabled}
                        checked={selectedItem?.enabled}
                        onChange={handleEnabledChange}
                    />
                </TabPanelKeepMounted>
                <TabPanelKeepMounted value={tabValue} index={1}>
                    <TransferList
                        leftKeyValues={mapRoomsToKeyValues(allRooms)}
                        rightKeyValues={mapRoomsToKeyValues(selectedItem.rooms)}
                        onChangeHandler={handleRoomAssignmentChange}
                        onDeltaChangeHandler={handleRoomDelta}
                        height={400}
                    />
                </TabPanelKeepMounted>
            </DetailsDialog>
            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    );    
}

export default RoomClassList;
