import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getDate } from "date-fns";
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from "react";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
    },
    startDatePicker: {
        marginRight: '16px',
    },
}));

export interface SearchEvent<T = Element> extends React.SyntheticEvent<T> {
    target: EventTarget & T;
}

export type DateSearchFieldChangeHandler = (
    startDate: Date,
    endDate: Date
) => any;

export const getDefaultEndDate = (): Date => {
    var date = new Date();
    date.setDate(getDate(date));

    return date;
}

export const getDefaultStartDate = (): Date => {
    return new Date();
}

export interface DateSearchFieldProps {
    startDate: Date | null;
    endDate: Date | null;
    format: string | null;
    onChange: DateSearchFieldChangeHandler;
}

const DateSearchField = (props: DateSearchFieldProps) => {
    
    const [startDateValue, setStartDateValue] = useState<Date>(props.startDate === null ? getDefaultStartDate() : props.startDate);
    const [endDateValue, setEndDateValue] = useState<Date>(props.endDate === null ? getDefaultEndDate() : props.endDate);
    const strings = useLocalizedStrings();
    const classes = useStyles(useTheme());  

    function handleStartDateChange(newValue: Dayjs | null) {
        const tempValue = (newValue ?? dayjs()).toDate();
        setStartDateValue(tempValue);
        props.onChange(tempValue, endDateValue);
    }
    
    function handleEndDateChange(newValue: Dayjs | null) {
        const tempValue = (newValue ?? dayjs()).toDate();
        setEndDateValue(tempValue);
        props.onChange(startDateValue, tempValue);
    }
    
    return (
        <div className={classes.root}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className={classes.startDatePicker}>
                    <DesktopDatePicker
                        label={strings.startDate}
                        format={props.format === null ? "MM/DD/YYYY" : props.format}
                        value={dayjs(startDateValue)}
                        views={props.format !== null && props.format === "MM/YYYY" ? ["month"] : ["day"]}
                        onChange={handleStartDateChange}                          
                    />
                </div>
                <DesktopDatePicker
                    label={strings.endDate}
                    format={props.format === null ? "MM/DD/YYYY" : props.format}
                    value={dayjs(endDateValue)}
                    onChange={handleEndDateChange}
                    views={props.format !== null && props.format === "MM/YYYY" ? ["month"] : ["day"]}                    
                />
            </LocalizationProvider>
        </div>
    );
};

export default DateSearchField;