import { Template, initialTemplateState } from "./Template";

export interface AutomatedMessage {
    trigger: string;
    template: Template;
}

export const initialAutomatedMessageState: AutomatedMessage = {
    trigger: "",
    template: initialTemplateState("Text")
}