import { AxiosInstance } from "axios";
import { User } from "../../models/configuration/security/User";  
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper"; 
  
const url = process.env.REACT_APP_MANAGER_API_URL + "/user-management/users";

export async function getCurrentUser(axiosInstance: AxiosInstance): Promise<User> {
    return await axiosInstance
        .get(`${url}/current`)
        .then(
            (response) => parseLanguageStringsFromJson(response.data.user) as User
        );
}

export async function getUser(axiosInstance: AxiosInstance, id: string): Promise<User> {
    return await axiosInstance
        .get(`${url}/${id}`)
        .then(
            (response) => parseLanguageStringsFromJson(response.data) as User
        );
}

export async function listUsers(axiosInstance: AxiosInstance): Promise<User[]> {
    return await axiosInstance
        .get(`${url}`)
        .then(
            (response) =>
                response.data.users as User[]
        );
}


export async function createUser(axiosInstance: AxiosInstance, user: User) {
    return await axiosInstance
        .post(url, user)
        .then(function(response) {

        });
}

export async function updateUser(axiosInstance: AxiosInstance, user: User) {
    return await axiosInstance
        .put(`${url}/${user.id}`, user)
        .then(function(response) {
            
        });
}

export async function deleteUser(axiosInstance: AxiosInstance, id: string) {
    return await axiosInstance
        .delete(`${url}/${id}`)
        .then(function(response) {

        });
}

export async function createAdminUser(axiosInstance: AxiosInstance, user: User) {
    const adminUser = {
        username: user.username,
        enabled: user.enabled,
        roles: user.roles.map(role => role.id ),
    };

    return await axiosInstance
        .post(`${url}/add`, adminUser)
        .then(function (response) {

        });
}
