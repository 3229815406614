import { v4 as uuid } from "uuid";

export interface Configuration {
    network: ConfigurationProvider;
    wifi: ConfigurationProvider;
    pots: ConfigurationProvider;
}

export interface ConfigurationProvider {    
    areas: ConfigurationArea[];
}

export interface ConfigurationArea {
    id: string;
    name: string;
    configuration: string;
    provider: string;
}

export function getInitialConfigurationState(): Configuration {
    return {
        network: {            
            areas: []
        },
        wifi: {            
            areas: []
        },
        pots: {            
            areas: []
        }
    };
}

export function getInitialNetworkConfigurationAreaState(): ConfigurationArea {
    return {
        id: uuid(),
        name: "",
        provider: "Prtg",
        configuration: "{\"bandwidthDeviceId\":\"\",\"circuitDeviceId\":\"\",\"gatewayDeviceId\":\"\"}"
    };
}

export function getInitialPotsConfigurationAreaState(): ConfigurationArea {
    return {
        id: uuid(),
        name: "",
        provider: "Dataremote",
        configuration: "[{\"oui\":\"\",\"serialNumber\":\"\"}]"
    };
}

export function getInitialWiFiConfigurationAreaState(): ConfigurationArea {
    return {
        id: uuid(),
        name: "",
        provider: "Rukus",
        configuration: "{\"zone\":\"\"}"
    };
}