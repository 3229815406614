import { default as axios, AxiosInstance, AxiosError } from "axios";
import { VideoModel } from "../models/common/VideoModel";

export async function deleteVideos(
  axiosInstance: AxiosInstance,
  itemType: string,
  itemKey: string,
  videos: VideoModel[],
  propertyId: string | undefined
) {
    videos.forEach(async (video) => {
      if (propertyId === undefined) {
        try {
          return await axiosInstance.delete(
              `${process.env.REACT_APP_MANAGER_API_URL}/videos/${itemType}/${itemKey}/${video.videoKey}`
          );
        }
        catch (ex) {
            const isAxiosError = axios.isAxiosError(ex);
            if ((isAxiosError && (ex as AxiosError)?.response?.status !== 404) || !isAxiosError) {
                throw ex;
            }
        }
      }
      else {
        try {
          return await axiosInstance.delete(
              `${process.env.REACT_APP_MANAGER_API_URL}/videos/${propertyId!!}/${itemType}/${itemKey}/${video.videoKey}`
          );
        }
        catch (ex) {
            const isAxiosError = axios.isAxiosError(ex);
            if ((isAxiosError && (ex as AxiosError)?.response?.status !== 404) || !isAxiosError) {
                throw ex;
            }
        }
      }
  });
}

export async function uploadVideos(
  axiosInstance: AxiosInstance,
  itemType: string,
  itemKey: string,
  videos: VideoModel[],
  propertyId: string | undefined
) {
  if (videos.length < 1) return;

  const formData = new FormData();
  formData.append("ItemType", itemType);
  formData.append("ItemKey", itemKey);

  if (propertyId !== undefined) {
    formData.append("PropertyId", propertyId!!);
  }

  videos.forEach((video) => {
    if (video.fileData) {
      formData.append(video.videoKey, video.fileData, video.fileData.name);
    }
  });

  const config = {
    headers: {
      Accept: "application/json",
      "content-type": "multipart/form-data",
    },
  };

  return await axiosInstance.put(
    process.env.REACT_APP_MANAGER_API_URL + "/videos",
    formData,
    config
  );
}

export async function copyVideo(
  axiosInstance: AxiosInstance,
  itemType: string,
  sourceItemKey: string,
  destinationItemKey: string,
  key: string,
  propertyId: string | undefined 
) {

  const formData = new FormData();
  if (propertyId !== undefined) {
    formData.append("PropertyId", propertyId!!);
  }
  if (propertyId === undefined) {
    return await axiosInstance.post(`${process.env.REACT_APP_MANAGER_API_URL}/videos/${itemType}/${destinationItemKey}/${key}`, { itemKey: sourceItemKey }).then((response) => {
      return response.data;
    });
  }
  else {
    return await axiosInstance.post(`${process.env.REACT_APP_MANAGER_API_URL}/videos/${propertyId!!}/${itemType}/${destinationItemKey}/${key}`, { itemKey: sourceItemKey }).then((response) => {
      return response.data;
    });
  }
}
