import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import ListItemText from '@mui/material/ListItemText';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useTheme } from "@mui/material/styles";
import { GridColDef, GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid";
import axios from "axios";
import React from "react";
import { createItem, getItem, updateItem } from "../../../api/roomservice/itemApi";
import { deleteOptionSet, getOptionSets } from "../../../api/roomservice/optionApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Item as ItemModel, initialItemState } from "../../../models/modules/roomservice/Item";
import { Option as OptionModel, OptionSet as OptionSetModel, initialOptionSetState, initialOptionState } from "../../../models/modules/roomservice/Option";
import { useUser } from "../../../providers/UserProvider";
import { Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import Checkbox from "../../common/details/Checkbox";
import { default as DetailsDialog } from "../../common/details/DetailsDialog";
import DetailsDisplayOrderGrid, { DetailsDisplayOrderGridRefObject } from "../../common/details/DetailsDisplayOrderGrid";
import TextField from "../../common/details/TextField";
import GraphicManager, { GraphicManagerRefObject } from "../../common/image/GraphicManager";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageRichTextField from "../../common/language/LanguageRichTextField";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";
import Option from "./Option";
import OptionSet from "./OptionSet";

interface ItemProps {
    open: boolean;  
    adding: boolean;
    itemId: string;
    sectionId?: string;
    onCancelClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onSaved: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onNotification: (options: NotificationOptions) => void;
}

interface ItemValidationErrors {
    name: string;
    description: string;
    sku: string;
    pricePerUnit: string;
}

const Item = (props: ItemProps) => {
    const initialItemErrorState: ItemValidationErrors = {
        name: "",
        description: "",
        sku: "",
        pricePerUnit: ""
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [loading, setLoading] = React.useState(true);
    const theme = useTheme(); 
    const strings = useLocalizedStrings();

    // Item
    const [item, setItem] = React.useState<ItemModel>(initialItemState);    
    const [addItemMenuAnchor, setAddItemMenuAnchor] = React.useState<null | HTMLElement>(null);
    const addItemMenuOpen = Boolean(addItemMenuAnchor);
    const [deleteItemMenuAnchorEl, setDeleteItemMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const deleteItemMenuOpen = Boolean(deleteItemMenuAnchorEl);   
    const [itemDialogOpen, setItemDialogOpen] = React.useState(false);        
    const [tabValue, setTabValue] = React.useState(0);
    const graphicManagerItemRef = React.useRef<GraphicManagerRefObject>(null);    
    const [itemErrors, setItemErrors] = React.useState<ItemValidationErrors>(initialItemErrorState);
    const [closing, setClosing] = React.useState(false);

    // Option Set
    const [allOptionSets, setAllOptionSets] = React.useState<OptionSetModel[]>([]);
    const [selectedOptionSetId, setSelectedOptionSetId] = React.useState("");
    const [selectedOptionSet, setSelectedOptionSet] = React.useState<OptionSetModel>(initialOptionSetState);
    const [addOptionSetMenuAnchorEl, setAddOptionSetMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const addOptionSetMenuOpen = Boolean(addOptionSetMenuAnchorEl);        
    const [deleteOptionSetMenuAnchorEl, setDeleteOptionSetMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const deleteOptionSetMenuOpen = Boolean(deleteOptionSetMenuAnchorEl); 
    const [optionSetDialogOpen, setOptionSetDialogOpen] = React.useState(false);
    const optionSetGridRef = React.useRef<DetailsDisplayOrderGridRefObject>(null);

    // Option   
    const [selectedOptionId, setSelectedOptionId] = React.useState("");
    const [selectedOption, setSelectedOption] = React.useState<OptionModel>(initialOptionState);    
    const [optionDialogOpen, setOptionDialogOpen] = React.useState(false);
    const optionGridRef = React.useRef<DetailsDisplayOrderGridRefObject>(null);
        
    const [gridRefresh, setGridRefresh] = React.useState(false);    
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);
    const alertDialog = useAlertDialog();
    const axiosInstance = useCreateAxios();
    const {user} = useUser();       

    React.useEffect(() => {
        const getData = async() => {
            if (props.open) {
                setLoading(true);
                
                try {                                                
                    const sectionResponse = await getOptionSets(axiosInstance, user?.currentProperty?.code ?? ""); 
                    setAllOptionSets(sectionResponse);                           
                }
                catch(e: unknown) {
                    const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };

                    props.onNotification({
                        isOpen: true,
                        message: strings.roomServiceErrorRetreivingOptionSets.replace("{{error}}", error.message),
                        msgType: "error",
                    });

                    setLoading(false);
                    return;
                }                

                if (!props.adding) {
                    try {                                
                        const itemResponse = await getItem(axiosInstance, user?.currentProperty?.code ?? "", props.itemId);                        
                        setItem(itemResponse);                          
                    }
                    catch(e: unknown) {
                        const error = axios.isAxiosError(e)
                        ? { message: e.message }
                        : { message: "unable to parse error info" };

                        props.onNotification({
                            isOpen: true,
                            message: strings.roomServiceErrorRetreivingItem.replace("{{error}}", error.message),
                            msgType: "error",
                        });

                        setLoading(false);
                        return;
                    }
                }

                setLoading(false);
            }
        }
        
        getData();                    
    }, [user.currentProperty?.code, props.open, props.itemId, props.adding])

    type RowType = 'Item' | 'OptionSet' | 'Option'

    function selectRow(id: string, rowType: RowType, openDialogOnSelect: boolean = false) { 
        switch (rowType) {
            case 'OptionSet': {
                setSelectedOptionSetId(id);               
                const optionSet = item.optionSets?.find(os => os.id === id);
                if (optionSet) {
                    setSelectedOptionSet(optionSet); 
                    return optionSet;
                }
                break;
            }
            case 'Option': {
                setSelectedOptionId(id);               
                const option = selectedOptionSet?.options.find(o => o.id === id);
                if (option) {
                    setSelectedOption(option); 
                    return option;
                }
                break;
            }
            default: {
                return null;
            }
        }

        return null;
    }

    function clearRows(type: RowType) {
        switch (type) {  
            case 'OptionSet': {                
                setSelectedOptionSetId("");
                setSelectedOptionSet(initialOptionSetState);
                setSelectedOptionId("");
                setSelectedOption(initialOptionState);
                break;
            }
            case 'Option': {                
                setSelectedOptionId("");
                setSelectedOption(initialOptionState);
                break;
            }
            default: {

            }
        }
    }   

    // ITEM
    function handleItemTabChange(event: React.SyntheticEvent, newValue: number) {
        if (optionSetGridRef.current?.isDirty() || optionGridRef.current?.isDirty()) {
            return;
        }

        setTabValue(newValue);
    }

    function handleItemNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setItem((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            }
        }));
    }    
    
    function handleItemDescriptionChange(content: string, language: string) {
        setItem((prevState) => ({
            ...prevState,
            description: {
                ...prevState.description,
                [language]: content,
            },
        }));
    }

    function handleSkuChange(event: React.ChangeEvent<HTMLInputElement>) {       
        setItem((prevState) => ({
            ...prevState,
            sku: event.target.value
        }));
    }

    function handlePriceChange(event: React.ChangeEvent<HTMLInputElement>) {  
        const value = event.target.value;
        
        // This is kinda ugly but we need to check as we're typing. Valdate that the last char entered is a number or decimal
        if (value !== "" && !value.charAt(value.length - 1).match(/(\d)?(\.)?/)) {
            return;
        }

        var index = value.indexOf('.');

        // Only allow 1 decimal point
        if (index !== -1 && value.indexOf('.', index + 1) !== -1) {
            return;
        }

        // Ensure only 2 decimal places
        if (index !== -1 && value.length - index > 3) {
            return;
        }

        setItem((prevState) => ({
            ...prevState,
            pricePerUnitString: value            
        }));
    }

    function handlePreviewOnlyChange(event: React.ChangeEvent<HTMLInputElement>) {
        setItem((prevState) => ({
            ...prevState,
            previewOnly: event.target.checked,
        }));
    }    

    function handleItemCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        // HACK: This prevents the dirty popup from firing when handleOptionSetCanPerformAction is called
        setClosing(true);

        setItemErrors(initialItemErrorState);
        setTabValue(0);
        setItem(initialItemState);
        clearRows("OptionSet");
        clearRows("Option");
        props.onCancelClick(event);
    }

    async function handleItemSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setItemErrors(initialItemErrorState);        

        const isUpdate = props.itemId !== ""; 
        let id;
        try {
            if (isUpdate) {
                await updateItem(axiosInstance, user?.currentProperty?.code ?? "", item);
                id = item.id;
            }
            else {
                if (props.sectionId) {
                    id = await createItem(axiosInstance, user?.currentProperty?.code ?? "", item, props.sectionId);
                }
                else {
                    id = await createItem(axiosInstance, user?.currentProperty?.code ?? "", item);
                }                
            }
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            props.onNotification({
                isOpen: true,
                message: (!isUpdate
                    ? strings.roomServiceErrorAddingItem
                    : strings.roomServiceErrorUpdatingItem
                ).replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }                
        
        try {
            if (graphicManagerItemRef.current) {                
                await graphicManagerItemRef.current.saveGraphics(id, user?.currentProperty?.code);
            }
        } catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            props.onNotification({
                isOpen: true,
                message: strings.errorSavingGraphic.replace("{{error}}", error.message), // TODO: get error message
                msgType: "error",
            });

            return false;
        }

        props.onNotification({
            isOpen: true,
            message: strings.roomServiceItemSavedSuccessfully,
            msgType: "success",
        });

        setTabValue(0);
        setItem(initialItemState);                   
        clearRows("OptionSet");
        clearRows("Option");

        props.onSaved(event);
        return true;
    }

    function handleValidateItem() {
        let errors = validate<ItemModel, ItemValidationErrors>([
            { property: "name.en", type: ValidationType.Required, message: strings.validationRoomServiceItemName },            
            { property: "description.en", type: ValidationType.Required, message: strings.validationRoomServiceItemDescription }        
        ], item);
        if (errors) {
            setItemErrors(errors);
            return false;
        }
        return true;
    }        

    function handleOptionSetCanPerformAction() {      
        // HACK: This seems to fire after cancelling the popup, which causes the dirty popup to show again (see other HACK)
        if (closing) {
            return true;
        }

        return !optionGridRef.current?.isDirty();
    }

    // OPTION SET
    function handleOptionSetSelectionChange(optionSetSelectionModel: GridRowSelectionModel) {
        if (optionSetSelectionModel.length > 0) {
            selectRow(optionSetSelectionModel[0].toString(), 'OptionSet');
        }
    }

    function handleOptionSetSaveDisplayOrder(ids: any[]) {
        // https://stackoverflow.com/questions/13304543/javascript-sort-array-based-on-another-array
        const optionSets = [...item.optionSets];
        optionSets.sort(function(a, b) {  
            return ids.findIndex(id => a.id === id) - ids.findIndex(id => b.id === id);
        });
        updateOptionSetOrder(optionSets);        
    }

    function updateOptionSetOrder(optionSetList: OptionSetModel[]) {                
        setItem((prevState) => ({
            ...prevState,
            optionSets: optionSetList
        }));
    }      

    function handleAddOptionSetButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (getFilteredOptionSets().length > 0) {            
            setAddOptionSetMenuAnchorEl(event.currentTarget);
        }
        else {
            handleAddNewOptionSetMenuClick();
        }        
    }

    function handleAddOptionSetMenuClose() {
        setAddOptionSetMenuAnchorEl(null);
    }

    function handleAddNewOptionSetMenuClick() {
        clearRows('OptionSet');
        setAddOptionSetMenuAnchorEl(null);
        setOptionSetDialogOpen(true);
    }    

    function handleAddExistingOptionSetMenuClick(id: string) {
        setAddOptionSetMenuAnchorEl(null);    
        
        var newOptionSet = allOptionSets.find(os => os.id === id);        
        if (newOptionSet) {            
            newOptionSet!!.displayOrder = item.optionSets.length;

            setItem((prevState) => ({
                ...prevState,
                optionSets: [...prevState.optionSets, newOptionSet!!]
            }));
        }
    }    

    function handleEditOptionSetClick(id: string) {
        if (optionSetGridRef.current?.isDirty() || !handleOptionSetCanPerformAction()) {
            return;
        }

        if (selectRow(id, "OptionSet")) {
            setOptionSetDialogOpen(true);
        }        
    }

    function handleDeleteOptionSetClick(id: string, event: React.MouseEvent<HTMLButtonElement>) {
        if (optionSetGridRef.current?.isDirty() || !handleOptionSetCanPerformAction()) {
            return;
        }

        selectRow(id, "OptionSet");
        setDeleteOptionSetMenuAnchorEl(event.currentTarget);
    }

    function handleDeleteOptionSetMenuClose() {
        setDeleteOptionSetMenuAnchorEl(null);
    }    

    function handleRemoveOptionSet() {
        setDeleteOptionSetMenuAnchorEl(null);

        const index = item.optionSets.findIndex(os => os.id === selectedOptionSet.id);       
        var newOptionSets = [...item.optionSets];
        newOptionSets.splice(index, 1);
        setItem((prevState) => ({
            ...prevState,
            optionSets: newOptionSets
        }));
        
        clearRows("OptionSet");
    }

    async function deleteSelectedOptionSet(id: string) {   
        // Since we have a list of the existong option sets, we can check to see if this was a new one or add existing
        if (allOptionSets.find(os => os.id === id)) 
        {            
            try {            
                await deleteOptionSet(axiosInstance, user.currentProperty?.code ?? "", id);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                props.onNotification({
                    isOpen: true,
                    message: strings.roomServiceErrorDeletingOptionSet.replace("{{error}}", error.message),
                    msgType: "error",
                });

                return;
            }

            props.onNotification({
                isOpen: true,
                message: strings.roomServiceOptionSetDeletedSuccessfully,
                msgType: "success",
            });
        }

        handleRemoveOptionSet();
    }

    function handleDeleteOptionSet() {   
        setDeleteOptionSetMenuAnchorEl(null);

        const name = item.optionSets.find(os => os.id === selectedOptionSetId)?.internalName ?? ""; 
        alertDialog({
            title: strings.roomServiceDeleteOptionSetAlertTitle,
            message: strings.roomServiceDeleteOptionSetAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedOptionSet(selectedOptionSetId);
        });       
    }

    function handleOptionSetCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setOptionSetDialogOpen(false);
        clearRows("OptionSet");
    } 

    async function handleOptionSetSaved(optionSet: OptionSetModel, adding: boolean, event: React.MouseEvent<HTMLButtonElement>) {            
        if (adding) {
            optionSet.displayOrder = item.optionSets.length;
            setItem((prevState) => ({
                ...prevState,
                optionSets: [...prevState.optionSets, optionSet]
            }));   
        }
        else {
            var index = item.optionSets.findIndex(os => os.id === optionSet.id);
            if (index !== -1) {
                var tempOptionSets = [...item.optionSets];
                tempOptionSets[index] = optionSet;

                setItem((prevState) => ({
                    ...prevState,
                    optionSets: tempOptionSets
                }));  
            }
        }   
                
        setOptionSetDialogOpen(false);

        return true;
    }       

    const optionSetColumns: GridColDef[] = [
        { field: "internalName", headerName: strings.internalName, flex: 3 },
        { field: "minimum", headerName: strings.min, valueGetter: (value, row) => row.minimum, flex: 1 },
        { field: "maximum", headerName: strings.max, valueGetter: (value, row) => row.maximum, flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 2,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditOptionSetClick(params.id.toString())}
                />,
                <DataGridDeleteButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={(id: string, event: React.MouseEvent<HTMLButtonElement>) => handleDeleteOptionSetClick(params.id.toString(), event)}
                />
            ],
        }
    ]

    // OPTION
    function handleOptionSelectionChange(optionSelectionModel: GridRowSelectionModel) {
        if (optionSelectionModel.length > 0) {
            selectRow(optionSelectionModel[0].toString(), 'Option');
        }
    }

    function handleOptionSaveDisplayOrder(ids: any[]) {
        // https://stackoverflow.com/questions/13304543/javascript-sort-array-based-on-another-array
        const options = [...selectedOptionSet.options];
        options.sort(function(a, b) {  
            return ids.findIndex(id => a.id === id) - ids.findIndex(id => b.id === id);
        });
        updateOptionOrder(options);        
    }

    function updateOptionOrder(optionList: OptionModel[]) {        
        // Update the selected option set with the new options array
        setSelectedOptionSet((prevState) => ({
            ...prevState,
            options: optionList
        }));

        // We also need to update the item (since thats what is being saved).
        var optionSetIndex = item.optionSets.findIndex(os => os.id === selectedOptionSetId);
        if (optionSetIndex !== -1) {
            // Find the option set and update it with the new options array
            var tempOptionSets = [...item.optionSets];
            tempOptionSets[optionSetIndex] = {
                ...tempOptionSets[optionSetIndex],
                options: optionList
            }

            // Update the item with the updates option set
            setItem((prevState) => ({
                ...prevState,
                optionSets: tempOptionSets
            }));  
        }        
    }  

    function handleAddOptionButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        setOptionDialogOpen(true);        
    }    

    async function handleEditOptionClick(id: string) {       
        if (optionGridRef.current?.isDirty()) {
            return;
        }

        if (selectRow(id, "Option")) {            
            setOptionDialogOpen(true);
        }
    }

    function handleOptionDeleteClick(id: string, event: React.MouseEvent<HTMLButtonElement>) {
        if (optionGridRef.current?.isDirty()) {
            return;
        }

        selectRow(id, "Option");        

        const name = selectedOptionSet.options.find(o => o.id === id)?.name?.en ?? ""; 
        alertDialog({
            title: strings.roomServiceDeleteOptionAlertTitle,
            message: strings.roomServiceDeleteOptionAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedOption(id);
        });    
    }

    function deleteSelectedOption(id: string) {    
        const index = selectedOptionSet.options.findIndex(o => o.id === id);       
        var tempOptions = [...selectedOptionSet.options];
        tempOptions.splice(index, 1);

        // Now update the selected option set with the new options array
        setSelectedOptionSet((prevState) => ({
            ...prevState,
            options: tempOptions
        }));

        // We also need to update the item (since thats what is being saved).
        var optionSetIndex = item.optionSets.findIndex(os => os.id === selectedOptionSetId);
        if (optionSetIndex !== -1) {
            // Find the option set and update it with the new options array
            var tempOptionSets = [...item.optionSets];
            tempOptionSets[optionSetIndex] = {
                ...tempOptionSets[optionSetIndex],
                options: tempOptions
            }

            // Update the item with the updates option set
            setItem((prevState) => ({
                ...prevState,
                optionSets: tempOptionSets
            }));  
        }
      
        clearRows("Option");          
    }

    function handleOptionCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setOptionDialogOpen(false);
        clearRows("Option");
    } 

    async function handleOptionSaved(option: OptionModel, adding: boolean, event: React.MouseEvent<HTMLButtonElement>) { 
        // First update the selected option
        var tempOptions = [...selectedOptionSet.options]; 
        if (adding) {
            // Add new option
            option.displayOrder = selectedOptionSet.options.length;
            tempOptions.push(option);
        }
        else {
            // Update existing option
            var optionIndex = selectedOptionSet.options.findIndex(o => o.id === option.id);
            if (optionIndex !== -1) {                
                tempOptions[optionIndex] = option;                 
            }
        }   

        // Now update the selected option set with the new options array
        setSelectedOptionSet((prevState) => ({
            ...prevState,
            options: tempOptions
        }));

        // We also need to update the item (since thats what is being saved).
        var optionSetIndex = item.optionSets.findIndex(os => os.id === selectedOptionSetId);
        if (optionSetIndex !== -1) {
            // Find the option set and update it with the new options array
            var tempOptionSets = [...item.optionSets];
            tempOptionSets[optionSetIndex] = {
                ...tempOptionSets[optionSetIndex],
                options: tempOptions
            }

            // Update the item with the updates option set
            setItem((prevState) => ({
                ...prevState,
                optionSets: tempOptionSets
            }));  
        }

        clearRows("Option");        
        setOptionDialogOpen(false);

        return true;
    }       

    const optionColumns: GridColDef[] = [
        { field: "name", headerName: strings.name, valueGetter: (value, row) => row.name.en, flex: 2 }, 
        { field: "price", headerName: strings.price, valueGetter: (value, row) => row.pricePerUnit, valueFormatter: ({ value }) => value && value !== 0 ? (value as number).toLocaleString(undefined, { style: "currency", currency: "USD" }) : "", flex: 1 }, 
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditOptionClick(params.id.toString())}
                />,
                <DataGridDeleteButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={(id: string, event: React.MouseEvent<HTMLButtonElement>) => handleOptionDeleteClick(params.id.toString(), event)}
                />
            ],
        }
    ]    

    function getOptionSetRows() {
        return item.optionSets ?? [];
    }

    function getFilteredOptionSets() {
        const selectedIds = getOptionSetRows().map(o => o.id);
        return allOptionSets.filter(o => !selectedIds.includes(o.id));
    }        

    const dialogTitle = props.adding ? strings.roomServiceAddItemTitle : strings.roomServiceEditItemTitle.replace("{{name}}", item.name?.en ?? strings.loading); // TODO: default language

    return (
        <DetailsDialog
            permissionKey="content_roomservice"
            open={props.open}
            adding={props.adding}
            title={dialogTitle}
            onValidateForm={handleValidateItem}
            onCancelClick={handleItemCancelClick}
            onSaveClick={handleItemSaveClick}
            contentSize={{ width: 1200, height: 646.47 }}
        >
            <>
                { loading ?
                    <LinearProgress color={"primary"} variant={"query"} /> :
                    <>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={tabValue}
                                onChange={handleItemTabChange}
                                aria-label="basic tabs example"
                            >
                                <Tab label={strings.details} />
                                <Tab label={strings.graphics} />
                                <Tab label={strings.roomsServiceItemOptions} />
                            </Tabs>
                        </Box>
                        <LanguageForm>
                            <TabPanelKeepMounted value={tabValue} index={0}>
                                <LanguageSelect />
                                <Spacer />
                                <LanguageTextField
                                    id="item-name"
                                    label={strings.name}
                                    values={item.name ?? {}}
                                    width={410}
                                    onChange={handleItemNameChange}
                                    error={Boolean(itemErrors.name)}
                                    helperText={itemErrors.name}
                                />
                                <Spacer />
                                <LanguageRichTextField
                                    values={item.description ?? {}}
                                    label={strings.description}
                                    id="item-description"
                                    onChange={handleItemDescriptionChange}
                                    width="100%"
                                    error={Boolean(itemErrors.description)}
                                    helperText={itemErrors.description}
                                />
                                <Spacer />
                                <Stack direction="row">
                                    <TextField
                                        id="sku"
                                        value={item.sku ?? ""}
                                        label={strings.sku}
                                        width={200}                            
                                        onChange={handleSkuChange}                            
                                        error={Boolean(itemErrors.pricePerUnit)}
                                        helperText={itemErrors.pricePerUnit}
                                    />
                                    <Spacer x={2} />
                                    <TextField
                                        id="price"
                                        value={item.pricePerUnitString === undefined ? item.pricePerUnit.toFixed(2) : item.pricePerUnitString}                                
                                        label={strings.price}
                                        width={150}                            
                                        onChange={handlePriceChange}                            
                                        error={Boolean(itemErrors.pricePerUnit)}
                                        helperText={itemErrors.pricePerUnit}
                                    />
                                </Stack>
                                <Spacer />
                                <Checkbox  
                                    label={strings.previewOnly}
                                    checked={item.previewOnly ?? false}
                                    onChange={handlePreviewOnlyChange}                                                                
                                />
                            </TabPanelKeepMounted>
                            <TabPanelKeepMounted value={tabValue} index={1}>
                                <GraphicManager
                                    propertyId={(user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id}
                                    itemType="roomServiceItem" 
                                    itemKey={item.id}
                                    imageKeys={["main"]}                            
                                    ref={graphicManagerItemRef}
                                />
                            </TabPanelKeepMounted>
                            <TabPanelKeepMounted value={tabValue} index={2}>
                                <Grid container spacing={2}>                
                                    <Grid item xs={6}>
                                        {/*OptionSet Add Button Menu*/}
                                        <Menu
                                            id="addOptionSetMenu"
                                            MenuListProps={{
                                                'aria-labelledby': 'optionSetAdd',
                                            }}
                                            anchorEl={addOptionSetMenuAnchorEl}
                                            open={addOptionSetMenuOpen}
                                            onClose={handleAddOptionSetMenuClose}
                                        >                
                                            <MenuItem onClick={handleAddNewOptionSetMenuClick}>{strings.roomServiceAddOptionSetButton}</MenuItem>                                            
                                            {getFilteredOptionSets().map((optionSet, index) => (
                                                <>
                                                    {index === 0 && <Divider />}
                                                    <MenuItem key={optionSet.id} value={optionSet.id} onClick={() => handleAddExistingOptionSetMenuClick(optionSet.id)}>
                                                        <ListItemText>
                                                            {optionSet.internalName}
                                                        </ListItemText>
                                                    </MenuItem>
                                                </>                                                
                                            ))}    
                                        </Menu>

                                        {/*OptionSet Delete Button Menu*/}
                                        <Menu
                                            id="deleteOptionSetMenu"
                                            MenuListProps={{
                                                'aria-labelledby': 'buttonDeleteItem',
                                            }}
                                            anchorEl={deleteOptionSetMenuAnchorEl}
                                            open={deleteOptionSetMenuOpen}
                                            onClose={handleDeleteOptionSetMenuClose}
                                        >
                                            <MenuItem onClick={handleRemoveOptionSet}>{strings.removeButtonTitle}</MenuItem>
                                            <MenuItem onClick={handleDeleteOptionSet}>{strings.deleteButtonTitle}</MenuItem>
                                        </Menu>

                                        {/*OptionSet*/}
                                        <OptionSet
                                            open={optionSetDialogOpen}
                                            adding={selectedOptionSetId === ""}
                                            optionLevel={0}
                                            saveButtonLabel={strings.doneButtonTitle}
                                            optionSet={selectedOptionSet}
                                            onCancelClick={handleOptionSetCancelClick}
                                            onSaved={handleOptionSetSaved}
                                            onNotification={(options: NotificationOptions) => props.onNotification(options)}
                                        />
                            
                                        <DetailsDisplayOrderGrid
                                            permissionKey="content_roomservice"
                                            rows={getOptionSetRows()}                            
                                            columns={optionSetColumns}
                                            canPerformAction={handleOptionSetCanPerformAction}
                                            rowSelectionModel={selectedOptionSetId}
                                            onRowSelectionModelChange={handleOptionSetSelectionChange}                                        
                                            onAddButtonClick={handleAddOptionSetButtonClick}
                                            addButtonText={strings.roomServiceAddOptionSetButton}
                                            onSaveDisplayOrder={handleOptionSetSaveDisplayOrder}
                                            height={508}                                                                                   
                                            ref={optionSetGridRef}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/*Option*/}
                                        <Option
                                            open={optionDialogOpen}
                                            adding={selectedOptionId === ""}
                                            level={1}
                                            optionSetId={selectedOptionSetId}
                                            saveButtonLabel={strings.doneButtonTitle}
                                            option={selectedOption}
                                            onCancelClick={handleOptionCancelClick}
                                            onSaved={handleOptionSaved}
                                            onNotification={(options: NotificationOptions) => props.onNotification(options)}
                                        />

                                        <DetailsDisplayOrderGrid
                                            permissionKey="content_roomservice"
                                            rows={selectedOptionSet.options ?? []}                            
                                            columns={optionColumns}
                                            rowSelectionModel={selectedOptionId}
                                            onRowSelectionModelChange={handleOptionSelectionChange}                                        
                                            onAddButtonClick={handleAddOptionButtonClick}
                                            addButtonDisabled={selectedOptionSetId === ""}
                                            addButtonText={strings.roomServiceAddOptionButton}
                                            onSaveDisplayOrder={handleOptionSaveDisplayOrder}
                                            height={508}                                                                                        
                                            ref={optionGridRef}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanelKeepMounted>
                        </LanguageForm>
                    </>
                }                
            </>
        </DetailsDialog>
    );
}

export default Item