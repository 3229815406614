import axios from "axios";
import { useAuth } from "oidc-react";
import { useUser } from "../providers/UserProvider";

export function useCreateAxios() {
    const auth = useAuth();
    const {user, setUser} = useUser();
    const instance = axios.create();
    instance.interceptors.request.use(function (config) {
        if (auth && auth.userData) {
            config.headers = { "Authorization": `Bearer ${auth.userData!!.access_token}` }
        }
        return config;
    });
    instance.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response.status === 401) {
            const selectedCustomerId = user?.currentCustomer?.id;
            if (selectedCustomerId && selectedCustomerId !== "admin") {
                auth.userManager.removeUser();
                auth.userManager.signinRedirect({ acr_values: `tenant_id:${selectedCustomerId}`, prompt: "login" })
                    .then(() => console.log(''))
            } else {
                auth.signIn();
            }
        }
        else if (error.response.status === 403) {
            window.location.assign(window.location.origin + "/not-authorized");
        }

        return Promise.reject(error);
    });

    return instance;
}