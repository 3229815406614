import { AxiosInstance } from "axios";
import { Type as ServiceType } from "../../models/modules/guestservices/Type";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";
import { v4 as uuid } from "uuid";

const url = process.env.REACT_APP_MANAGER_API_URL + "/guest-service/";

export async function getTypes(axiosInstance: AxiosInstance, propertyCode: string, enabledOnly: boolean): Promise<ServiceType[]> {
    if (enabledOnly) {
        return await axiosInstance
            .get(`${url}${propertyCode}/types?Enabled=true`)
            .then((response) => parseLanguageStringsFromJson(response.data.types) as ServiceType[]);
    }
    else {
        return await axiosInstance
            .get(`${url}${propertyCode}/types`)
            .then((response) => parseLanguageStringsFromJson(response.data.types) as ServiceType[]);
    }
}

export async function getType(axiosInstance: AxiosInstance, propertyCode: string, id: string): Promise<ServiceType> {
    return await axiosInstance
        .get(`${url}${propertyCode}/types/${id}`)
        .then((response) => parseLanguageStringsFromJson(response.data.type) as ServiceType);
}

export async function createType(axiosInstance: AxiosInstance, propertyCode: string, type: ServiceType) {
    const id = uuid();

    const typeToSave = {
        ...type,  
        id: id,
        propertyId: propertyCode,
        name: JSON.stringify({ ...type.name })        
    };

    return await axiosInstance.post(`${url}${propertyCode}/types`, typeToSave).then((response) => {
        return id;
    });
}

export async function updateType(axiosInstance: AxiosInstance, propertyCode: string, type: ServiceType) {
    const typeToSave = {
        ...type,          
        propertyId: propertyCode,
        name: JSON.stringify({ ...type.name })             
    };

    return await axiosInstance.put(`${url}${propertyCode}/types/${type.id}`, typeToSave).then((response) => {
        return response.data;
    });
}

export async function deleteType(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return await axiosInstance.delete(`${url}${propertyCode}/types/${id}`).then((response) => {
        return response.data;
    });
}

export async function saveDisplayOrder(axiosInstance: AxiosInstance, propertyId: string, idList: string[]) {
    const response = await axiosInstance.post(`${url}${propertyId}/types/reorder`, idList);
    return response.status
}