import { useAuth } from "oidc-react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { initialUserState } from "../../models/configuration/security/User";
import { useUser } from "../../providers/UserProvider";

const SignOutRedirect = () => {
    const auth = useAuth();
    const { user, setUser } = useUser();

    useEffect(() => {
        setUser(initialUserState);
    });

    window.localStorage.removeItem('pathName')

    if (!auth?.userData) {
        return <Navigate replace to={"/"} />;
    } 
    else {
        return <div></div>;
    }
}

export default SignOutRedirect;