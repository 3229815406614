import { createTheme, alpha } from "@mui/material/styles";

let themePrimary = createTheme({
    palette: {
        primary: {
            light: "#55B8C5",
            main: "#00838f",
            dark: "#00515D",
            contrastText: "#ffffff",
        },
        secondary: {
            light: "#f3f3f3",
            main: "#4a4a4a",
            dark: "#313131",
            contrastText: "#ffffff",
        },
        info: {
            light: "#80d6ff",
            main: "#42a5f5",
            dark: "#0077c2",
            contrastText: "#000",
        },
        error: {
            light: "#80d6ff",
            main: "#C70000",
            dark: "#940000",
            contrastText: "#ffffff",
        }        
    },
});

themePrimary = createTheme(themePrimary, {
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: themePrimary.palette.secondary.main
                }
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: themePrimary.palette.secondary.light
                }
            },
        },
        //MuiListItem: {
        //    styleOverrides: {
        //        root: {
        //            "&.Mui-selected": {                        
        //                backgroundColor: alpha("#FFFFFF", 0.0),
        //                "& .MuiListItemText-root": {
        //                    color: theme.palette.primary.main
        //                },
        //                "& .MuiListItemIcon-root": {
        //                    color: theme.palette.primary.main
        //                }
        //            },
        //            "&.Mui-selected:hover, &:hover": {
        //                background: theme.palette.primary.light,
        //                "& .MuiListItemText-root": {
        //                    color: theme.palette.primary.contrastText
        //                },
        //                "& .MuiListItemIcon-root": {
        //                    color: theme.palette.primary.contrastText
        //                }
        //            },                                   
        //        },
        //        selected: {}
        //    }
        //},
        MuiTabs: {
            styleOverrides: {
                root: {
                    backgroundColor: themePrimary.palette.secondary.light
                }
            },
        },
    },
});

themePrimary = createTheme(themePrimary, {
    typography: {
        copyright: {
           fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
           lineHeight: 1.5,
           fontWeight: 500,
           letterSpacing: "0.025em",
           fontSize: "0.50rem"
        }
    }
})

export default themePrimary;

//const themePrimary = createTheme({
    //overrides: {
    //    MuiDrawer: {
    //        paper: {
    //            backgroundColor: "#4a4a4a",
    //        },
    //    },
    //    MuiOutlinedInput: {
    //        input: {
    //            padding: "10px",
    //            fontSize: ".8em",
    //        },
    //        notchedOutline: {
    //            top: "0px !important",
    //        },
    //    },
    //    MuiTextField: {
    //        root: {
    //            marginTop: "0px",
    //            marginBottom: "16px",
    //        },
    //    },
    //    MuiFormControl: {
    //        root: {
    //            marginTop: "0px",
    //            marginBottom: "16px",
    //        },
    //    },
    //    MuiTabs: {
    //        indicator: {
    //            height: "4px",
    //        },
    //    },
    //    MuiTab: {
    //        wrapper: {
    //            marginBottom: "2px",
    //        },
    //    },
    //    MuiSelect: {
    //        select: {
    //            paddingRight: "30px !important",
    //            lineHeight: "1.2em",
    //        },
    //        selectMenu: {
    //            minHeight: ".8em",
    //            fontSize: ".8em",
    //        },
    //        icon: {
    //            top: "calc(50% - 15px)",
    //            height: "30px",
    //            width: "34px",
    //            color: "#4a4a4a",
    //        },
    //        iconOutlined: {
    //            right: "0px",
    //        },
    //    },
    //    MuiMenuItem: {
    //        root: {
    //            fontSize: ".8em",
    //        },
    //    },
    //    MuiTable: {
    //        root: {
    //            marginBottom: "26px",
    //        },
    //    },
    //    MuiTableCell: {
    //        sizeSmall: {
    //            padding: "3px 8px 3px 8px",
    //            fontSize: ".75rem",
    //        },
    //        paddingCheckbox: {
    //            padding: "0px 2px 0px 2px !important",
    //        },
    //    },
    //    MuiTablePagination: {
    //        root: {
    //            marginTop: "-26px",
    //        },
    //        caption: {
    //            fontSize: ".8em",
    //        },
    //    },
    //    MuiRadio: {
    //        root: {
    //            padding: "4px",
    //        },
    //    },
    //    MuiTypography: {
    //        body1: {
    //            fontSize: ".8rem",
    //        },
    //    },
    //    MuiDialogContent: {
    //        root: {
    //            "&:first-child": {
    //                padding: "0px",
    //                paddingTop: "0px",
    //            },
    //        },
    //    },
    //    MuiList: {
    //        padding: {
    //            paddingTop: "0px",
    //            paddingBottom: "0px",
    //        },
    //    },

    //    MuiButton: {
    //        containedPrimary: {
    //            "&$disabled": {
    //                backgroundColor: "#005661",
    //                color: "#ffffff",
    //                opacity: "0.5",
    //            },
    //        },
    //    },

    //    MuiAppBar: {
    //        colorPrimary: {
    //            color: "#363636",
    //            opacity: "1",
    //        },
    //    },
    //},
//    palette: {
//        primary: {
//            light: "#4fb3bf",
//            main: "#00838f",
//            dark: "#005661",
//            contrastText: "#ffffff",
//        },
//        secondary: {
//            light: "#f3f3f3",
//            main: "#9e9e9e",
//            dark: "#4a4a4a",
//            contrastText: "#ffffff",
//        },
//        info: {
//            light: "#80d6ff",
//            main: "#42a5f5",
//            dark: "#0077c2",
//            contrastText: "#000",
//        },
//    },
//});

//export default themePrimary;
