import { AxiosInstance } from "axios";
import { Option } from "../../models/configuration/options/Option";

const url = process.env.REACT_APP_MANAGER_API_URL + "/configuration/";

export async function listPropertyOptions(axiosInstance: AxiosInstance, service: string, propertyCode: string) {
    return await axiosInstance
        .get(`${url}properties/${propertyCode}/services/${service}`)
        .then((response) => response.data.options as Option[]);
}

export async function listCustomerOptions(axiosInstance: AxiosInstance, service: string, corporationCode: string) {
    return await axiosInstance
        .get(`${url}customers/${corporationCode}/services/${service}`)
        .then((response) => response.data.options as Option[]);
}

export async function updateOption(axiosInstance: AxiosInstance, option: Option) {
    return await axiosInstance.put(`${url}`, option).then((response) => {
        return response.data;
    });
}