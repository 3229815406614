import { AxiosInstance } from "axios";
import { Role } from "../../models/configuration/security/Role";  
  
const url = process.env.REACT_APP_MANAGER_API_URL + "/user-management/roles";

export async function getRole(axiosInstance: AxiosInstance, id: string): Promise<Role> {
    return await axiosInstance
        .get(`${url}/${id}`)
        .then(
            (response) =>
                response.data as Role
        );
}

export async function listRoles(axiosInstance: AxiosInstance): Promise<Role[]> {
    return await axiosInstance
        .get(`${url}`)
        .then(
            (response) =>
                response.data.roles as Role[]
        );
}

export async function createRole(axiosInstance: AxiosInstance, role: Role) {
    return await axiosInstance
        .post(url, role)
        .then(function(response) {

        });
}

export async function updateRole(axiosInstance: AxiosInstance, role: Role) {
    return await axiosInstance
        .put(`${url}/${role.id}`, role)
        .then(function(response) {
            
        });
}

export async function deleteRole(axiosInstance: AxiosInstance, id: string) {
    return await axiosInstance
        .delete(`${url}/${id}`)
        .then(function(response) {

        });
}
