import VisibilityIcon from '@mui/icons-material/Visibility';
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";

interface DataGridViewButtonProps {
    rowId: string;
    clickHandler: (id: string, event: React.MouseEvent<HTMLButtonElement>) => void;
    permissionKey?: string;
}

const DataGridViewButton = (props: DataGridViewButtonProps) => {
    const strings = useLocalizedStrings();
    const { checkAccess } = useUser();    

    return (
        <div>
            {checkAccess(props.permissionKey, Access.Read) &&
                <GridActionsCellItem
                    icon={<VisibilityIcon titleAccess={strings.gridActionViewTitleAccess} sx={{ color: "primary.dark" }} />}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => props.clickHandler(props.rowId, event)}
                    label={strings.view}
                />
            }
        </div>
    );
}

export default DataGridViewButton;
