import { AxiosInstance } from "axios";
import { Schedule } from "../../models/modules/promotions/Schedule";

const url = process.env.REACT_APP_MANAGER_API_URL + "/promotions/";

export async function getSchedule(axiosInstance: AxiosInstance, propertyCode: string, id: string): Promise<Schedule> {
    return await axiosInstance
        .get(`${url}${propertyCode}/schedules/${id}`)
        .then((response) => response.data.schedule as Schedule);
}

export async function updateSchedule(axiosInstance: AxiosInstance, propertyCode: string, schedule: Schedule) {   
    return await axiosInstance.put(`${url}${propertyCode}/schedules/${schedule.id}`, schedule).then((response) => {
        return response.data;
    });
}

export async function createSchedule(axiosInstance: AxiosInstance, propertyCode: string, schedule: Schedule) {
    return await axiosInstance.post(`${url}${propertyCode}/schedules`, schedule).then((response) => {
        return response.data;
    });
}

export async function deleteSchedule(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return await axiosInstance.delete(`${url}${propertyCode}/schedules/${id}`).then((response) => {
        return response.data;
    });
}
