export class DateRange {
    start: Date;
    end: Date;

    constructor(start: Date, end: Date) {
        this.start = start;
        this.end = end;
    }

    contains(range: DateRange) {
        return this.start <= range.start && this.end >= range.end;
    }

    extend(range: DateRange) {
        if (range.start < this.start) {
            this.start = range.start;
        }

        if (range.end > this.end) {
            this.end = range.end;
        }
    }
}