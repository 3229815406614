import { AxiosInstance } from "axios";
import { Menu } from "../../models/menus/Menu";
import { MenuGroup } from "../../models/menus/MenuGroup";
import { MenuItem } from "../../models/menus/MenuItem";
import { parseLanguageStringsFromJson } from "../../utilities/JsonHelper";

const url = process.env.REACT_APP_MANAGER_API_URL + "/menu-management";

export async function listMenus(axiosInstance: AxiosInstance, propertyCode: string) {
    return await axiosInstance
        .get(`${url}/${propertyCode}/menus`)
        .then(
            (response) =>
                parseLanguageStringsFromJson(response.data.menus) as Menu[]
        );
}

export async function getMenu(axiosInstance: AxiosInstance, propertyCode: string, id: string) {
    return await axiosInstance
        .get(`${url}/${propertyCode}/menus/${id}`)
        .then(
            (response) =>
                parseLanguageStringsFromJson(response.data.menu) as Menu
        );
}

export async function updateMenu(axiosInstance: AxiosInstance, propertyCode: string, menu: Menu) {
    const menuToSave = {
        ...menu,
        groups: menu.groups.map((group: MenuGroup) => {
            return {
                ...group,
                name: JSON.stringify({ ...group.name }),
                items: group.items.map((item: MenuItem) => {
                    return {
                        ...item,
                        name: JSON.stringify({ ...item.name })
                    }                        
                })
            }
        })
    };

    return await axiosInstance.put(`${url}/${propertyCode}/menus/${menuToSave.id}`, menuToSave).then((response) => {
        return response.data;
    });
}

export async function createGroup(axiosInstance: AxiosInstance, propertyCode: string, menuId: string, group: MenuGroup) {
    const groupToSave = {
        ...group,
        name: JSON.stringify({ ...group.name }),
        items: []
    };

    return await axiosInstance.post(`${url}/${propertyCode}/menus/${menuId}/groups`, groupToSave).then((response) => {
        return response.data;
    });
}

export async function updateGroup(axiosInstance: AxiosInstance, propertyCode: string, menuId: string, group: MenuGroup) {
    const groupToSave = {
        ...group,
        name: JSON.stringify({ ...group.name }),
        items: []
    };

    return await axiosInstance.put(`${url}/${propertyCode}/menus/${menuId}/groups/${group.id}`, groupToSave).then((response) => {
        return response.data;
    });
}

export async function deleteGroup(axiosInstance: AxiosInstance, propertyCode: string, menuId: string, groupId: string) {
    return await axiosInstance.delete(`${url}/${propertyCode}/menus/${menuId}/groups/${groupId}`).then((response) => {
        return response.data;
    });
}

export async function createItem(axiosInstance: AxiosInstance, propertyCode: string, menuId: string, groupId: string, item: MenuItem) {
    const itemToSave = {
        ...item,
        name: JSON.stringify({ ...item.name })        
    };

    return await axiosInstance.post(`${url}/${propertyCode}/menus/${menuId}/groups/${groupId}/items`, itemToSave).then((response) => {
        return response.data;
    });
}

export async function updateItem(axiosInstance: AxiosInstance, propertyCode: string, menuId: string, groupId: string, item: MenuItem) {
    const itemToSave = {
        ...item,
        name: JSON.stringify({ ...item.name })
    };

    return await axiosInstance.put(`${url}/${propertyCode}/menus/${menuId}/groups/${groupId}/items/${item.id}`, itemToSave).then((response) => {
        return response.data;
    });
}

export async function deleteItem(axiosInstance: AxiosInstance, propertyCode: string, menuId: string, groupId: string, itemId: string) {
    return await axiosInstance.delete(`${url}/${propertyCode}/menus/${menuId}/groups/${groupId}/items/${itemId}`).then((response) => {
        return response.data;
    });
}
