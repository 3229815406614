import { AxiosInstance } from "axios";
import { ModuleView, ModuleViewApiResponse } from "../../../models/reporting/itv/ModuleView";
import { ItemView, ItemViewApiResponse } from "../../../models/reporting/itv/ItemView";
import { ContactView, ContactViewApiResponse } from "../../../models/reporting/itv/ContactView";
import { CheckoutEvent, CheckoutEventsApiResponse } from "../../../models/reporting/itv/CheckoutEvent";
import { MenuFilter, MenuFilterApiResponse } from "../../../models/reporting/itv/MenuFilter"; 

const url = process.env.REACT_APP_MANAGER_API_URL + `/reporting/`;

export async function getItemViews(axiosInstance: AxiosInstance, propertyCode: string, moduleId: string, startDate: Date, endDate: Date) : Promise<ItemView[]> {
    return axiosInstance.get<ItemViewApiResponse>(`${url}${propertyCode}/itv/itemviews?moduleId=${moduleId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`)
        .then(response => response.data.itemViews)
}

export async function getModuleViews(axiosInstance: AxiosInstance, propertyCode: string, startDate: Date, endDate: Date) : Promise<ModuleView[]> {
    return axiosInstance.get<ModuleViewApiResponse>(`${url}${propertyCode}/itv/moduleviews?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`)
        .then(response => response.data.moduleViews)
}

export async function getContactViews(axiosInstance: AxiosInstance, propertyCode: string, moduleId: string, startDate: Date, endDate: Date) : Promise<ContactView[]> {
    return axiosInstance.get<ContactViewApiResponse>(`${url}${propertyCode}/itv/contactviews?moduleId=${moduleId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`)
        .then(response => response.data.contactViews)
}

export async function getCheckoutEvents(axiosInstance: AxiosInstance, propertyCode: string, startDate: Date, endDate: Date) : Promise<CheckoutEvent[]> {
    return axiosInstance.get<CheckoutEventsApiResponse>(`${url}${propertyCode}/itv/checkouts?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`)
        .then(response => response.data.checkoutEvents)
}

export async function getMenuFilters(axiosInstance: AxiosInstance, propertyCode: string, reportType: ReportType) : Promise<MenuFilter[]> {
    return axiosInstance.get<MenuFilterApiResponse>(`${url}${propertyCode}/itv/menuitems?reportType=${reportType}`)
        .then(response => response.data.menuItems)
}

export enum ReportType {
    Contact = 1,
    Item = 2,
}

