import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { SyntheticEvent } from "react";
import React from "react";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={4} ref={ref} variant="filled" {...props} />;
});

export type NotificationOptions = {
  isOpen: boolean;
  message: string;
  msgType: "warning" | "success" | "info" | "error" | undefined;
};

export interface NotificationProps {
  notificationState: [NotificationOptions, React.Dispatch<React.SetStateAction<NotificationOptions>>];
}

const NotificationMessage: React.FC<NotificationProps> = ({notificationState: [NotificationOptions, setNotificationOptions]}) => {

  const handleMessageClose = (event: Event | SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
    setNotificationOptions({
      ...NotificationOptions,
      isOpen: false,
    });
  };

  return (
    <div>
      <Snackbar
        autoHideDuration={4000}
        open={NotificationOptions.isOpen}
        onClose={handleMessageClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity={NotificationOptions.msgType}
          onClose={(e) => handleMessageClose(e, "timeout")}
        >
          {NotificationOptions.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default NotificationMessage;
