import moment from "moment";

export function parseLanguageStringsFromJson(object: any, languageCode?: string, options?: { ignore?: string[] }) {   
    if (Array.isArray(object)) {
        // It's possible that we are parsing an array of objects
        return object.map(o => parse(o, languageCode, options));
    }
    else {        
        return parse(object, languageCode, options);
    }    
}

export function parse(rowObj: {}, languageCode?: string, options?: { ignore?: string[] }) {
    const cols = Object.keys(rowObj);
    const rows = Object.values(rowObj) as any;

    // @ts-ignore
    var result = rows.reduce(function (result, field, index) {    
        if (options?.ignore?.includes(cols[index])) {
            // Check if we have explicitly ignored this field (in case we want a json string)
            result[cols[index]] = field;
        }
        else if (isStringLanguage(field)) {
            // This is a string-language field so turn it into a more usable object
            result[cols[index]] = languageCode ? JSON.parse(field)[languageCode] : JSON.parse(field);
        }
        else if (isJson(field)) {            
            result[cols[index]] = JSON.parse(field);
        }
        else if (Array.isArray(field)) {
            const isArrayOfObjects = field.every(fld => typeof fld === "object");

            if (!isArrayOfObjects) {
                // don't call parseLanguageFromJson if it's not object[]. eg, property.features is a string[].
                result[cols[index]] = field;
            }
            else {
                // This is a nested array of objects, so recurse down
                result[cols[index]] = parseLanguageStringsFromJson(field, languageCode);
            } 
        }
        else if (isDateTime(field)) {
            result[cols[index]] = new Date(field);
        }   
        else if (isDateOnly(field)) {
            result[cols[index]] = new Date(field);
        }   
        else if (isTimeOnly(field)) {
            var split = field.split(':');
            var d = new Date();
            d.setHours(parseInt(split[0]));
            d.setMinutes(parseInt(split[1]));
            d.setSeconds(parseInt(split[2].substr(0, 2)));
            result[cols[index]] = d;
        }   
        else {            
            result[cols[index]] = field;
        }

        return result;
    }, {});

    return result;
}

function isStringLanguage(value: any) {
    return typeof value === "string" && value.indexOf("{") !== -1 && value.indexOf("en") !== -1;
}

function isJson(value: string): Boolean {    
    return typeof value === "string" && value.match(/.*{.*".*:.*}/) !== null;
}

function isDateTime(value: string): boolean {
    if (typeof value !== 'string') {
        return false;
    }

    // This is ugly! Is there an easier way to chec if a string is a date?
    if (value.charAt(4) === '-' && value.charAt(7) === '-' && value.charAt(10) === 'T' && value.charAt(13) === ':' && value.charAt(16) === ':') {
        return true;
    }
    
    return false;
}

function isDateOnly(value: string): boolean {
    if (typeof value !== 'string') {
        return false;
    }

    // It might be date only
    if (value.charAt(4) === '-' && value.charAt(5) === '-') {
        return true;
    }
    
    return false;
}

function isTimeOnly(value: string): boolean {
    if (typeof value !== 'string') {
        return false;
    }

    // https://stackoverflow.com/questions/881085/count-the-number-of-occurrences-of-a-character-in-a-string-in-javascript
    // If the string has more than 2 : it's probably a MAC address
    if ((value.match(/:/g) || []).length > 2) {
        return false;
    }

    // It might be a time only
    if (value.charAt(2) === ':' && value.charAt(5) === ':') {
        return true;
    }
    
    return false;
}

export function dateToLocalISOString(value: Date) {
    return moment(value).format("YYYY-MM-DDTHH:mm:ss");    
}

export function dateToTimeOnlyString(date?: Date | null | undefined) {
    if (date) {
        // Don't use a formatter or it will offset timezone
        return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    }
    else {
        return null;
    }
}